import React from "react";
import ItemIcon from "./ItemIcon";

const TrashIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
);

interface Props {
    items: { [key: string]: { [key: string]: any } };
    onDelete: (item: string) => void;
}

const SelectedItemsRow = ({ items, onDelete }: Props) => {
    return (
        <div className="w-full flex flex-row flex-wrap gap-3 mb-7">
            {Object.keys(items).map((key) => {
                return (
                    <div key={key} className="rounded-full bg-[#121212] flex flex-row items-center px-4 py-2 justify-center">
                        <p style={{ fontFamily: "minecraftFont" }} className="mr-2">
                            {items[key].count} x
                        </p>
                        <ItemIcon item={items[key].item} size={32} />
                        <button onClick={() => onDelete(key)} className="bg-transparent p-0 ml-3">
                            <TrashIcon />
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default SelectedItemsRow;
