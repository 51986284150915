import React from "react";

type Tab = {
    title: string;
    children: React.ReactNode;
};

type Props = {
    tabsData: Tab[];
};

const Tabs = ({ tabsData }: Props) => {
    const [activeTab, setActiveTab] = React.useState(0);

    const TabItem = ({ title, children, index }: { title: string; children: React.ReactNode; index: number }) => {
        return (
            <li className="me-2 cursor-pointer" onClick={() => setActiveTab(index)}>
                <p
                    className={`inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 ${
                        activeTab === index ? "text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500" : ""
                    }`}
                    onClick={() => setActiveTab(tabsData.indexOf({ title, children }))}
                >
                    {title}
                </p>
            </li>
        );
    };

    return (
        <div>
            <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                {tabsData.map((tab, index) => (
                    <TabItem key={index} children={tab.children} title={tab.title} index={index} />
                ))}
            </ul>

            <div className="mt-4">{tabsData[activeTab].children}</div>
        </div>
    );
};

export default Tabs;
