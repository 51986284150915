import React, { useEffect } from "react";
import MotdEditor from "./components/MotdEditor";
import MotdPreview from "./components/MotdPreview";
import defaultServerIcon from "./assets/images/server_icon.jpg";
import pako from "pako";
import { getTextWidth, motdToSlate } from "./utils";
import "./app.css";

const App = () => {
    const [motdData, setMotdData] = React.useState([
        {
            type: "paragraph",
            children: [
                {
                    type: "text",
                    text: "",
                    color: "#AAAAAA",
                },
            ],
        },
    ]);

    const [maxLineWidth, setMaxLineWidth] = React.useState(
        getTextWidth("a".repeat(45), "minecraftFont", window.innerWidth >= 1200 ? "1.65vw" : window.innerWidth >= 640 ? "1.6vw" : "2vw", 400)
    );
    const [serverIcon, setServerIcon] = React.useState(defaultServerIcon);
    const [serverName, setServerName] = React.useState("WiseHosting Server");
    const [reloadChildren, setReloadChildren] = React.useState(0);

    const decodeMotdId = (encoded: string) => {
        let urlDecoded = decodeURIComponent(encoded);
        let base64Decoded = atob(urlDecoded);
        let uint8Array = new Uint8Array(base64Decoded.split("").map((char) => char.charCodeAt(0)));
        let inflated = pako.inflateRaw(uint8Array);
        return new TextDecoder().decode(inflated);
    };

    useEffect(() => {
        const id = new URLSearchParams(window.location.search).get("id")?.replace(/ /g, "+");
        if (id != null) {
            try {
                let motdString = decodeMotdId(id);
                motdString = motdString.replaceAll("\\u00A7", "§");
                setMotdData(motdToSlate(motdString));
                setReloadChildren((p) => p + 1);
            } catch (e) {
                alert("Couldn't load MOTD from ID");
                console.error(e);
                let url = new URL(window.location.href);
                let params = new URLSearchParams(url.search);
                params.delete("id");
                url.search = params.toString();
                window.history.pushState({}, "", url);
            }
        }
    }, []);

    return (
        <div className="m-10 flex items-center flex-col gap-5 sm:gap-10">
            <MotdEditor
                setData={setMotdData}
                data={motdData}
                setMaxLineWidth={setMaxLineWidth}
                maxLineWidth={maxLineWidth}
                setServerIcon={setServerIcon}
                setServerName={setServerName}
                setReloadChildren={setReloadChildren}
                reloadChildren={reloadChildren}
            />
            <MotdPreview data={motdData} serverName={serverName} serverIcon={serverIcon} setServerIcon={setServerIcon} />
        </div>
    );
};

export default App;
