import React, { useEffect, useState } from "react";
import CraftingTableImage from "../assets/crafting.png";
import ItemImage from "./ItemImage";

interface Item {
    name: string;
    id: string;
    offsetX: number;
    offsetY: number;
}

interface Material {
    item: Item;
    amount: number;
}

interface Props {
    slotClickCallback: (slotIndex: number) => void;
    slotData: Material[];
}

const presetSlotPositions = [
    { x: 20, y: 20, width: 64, height: 64, index: 0 },
    { x: 92, y: 20, width: 64, height: 64, index: 1 },
    { x: 164, y: 20, width: 64, height: 64, index: 2 },
    { x: 20, y: 92, width: 64, height: 64, index: 3 },
    { x: 92, y: 92, width: 64, height: 64, index: 4 },
    { x: 164, y: 92, width: 64, height: 64, index: 5 },
    { x: 20, y: 164, width: 64, height: 64, index: 6 },
    { x: 92, y: 164, width: 64, height: 64, index: 7 },
    { x: 164, y: 164, width: 64, height: 64, index: 8 },
    { x: 316, y: 68, width: 96, height: 96, index: 9 },
];

const CraftingTable = ({ slotClickCallback, slotData }: Props) => {
    const [currentSlotHover, setCurrentSlotHover] = useState<number | null>(null);
    const [slotPositions, setSlotPositions] = useState<{ x: number; y: number; width: number; height: number; index: number }[]>([]);
    const imgRef = React.createRef<HTMLImageElement>();

    const windowSizeChanged = () => {
        if (imgRef.current) {
            const width = imgRef.current.width;
            const factor = width / 432; // The width we calced with

            const newSlotPositions = presetSlotPositions.map((slot) => {
                return {
                    x: slot.x * factor,
                    y: slot.y * factor,
                    width: slot.width * factor,
                    height: slot.height * factor,
                    index: slot.index,
                };
            });

            setSlotPositions(newSlotPositions);
        }
    };

    useEffect(() => {
        windowSizeChanged();
    }, [imgRef]);

    useEffect(() => {
        window.addEventListener("resize", windowSizeChanged);
        setTimeout(windowSizeChanged, 100);

        return () => {
            window.removeEventListener("resize", windowSizeChanged);
        };
    }, []);

    return (
        <div className="h-full w-full flex relative">
            <img ref={imgRef} src={CraftingTableImage} alt="Crafting Table" className="object-contain" />
            {slotPositions.map((slot, index) => (
                <div
                    key={index}
                    className={`absolute cursor-pointer flex justify-center items-center`}
                    onMouseEnter={() => setCurrentSlotHover(slot.index)}
                    onMouseLeave={() => setCurrentSlotHover(null)}
                    onClick={() => slotClickCallback(slot.index)}
                    style={{
                        left: slot.x,
                        top: slot.y,
                        width: slot.width,
                        height: slot.height,
                        backgroundColor: currentSlotHover === slot.index ? "rgba(255, 255, 255, 0.2)" : "transparent",
                    }}
                >
                    {slotData[slot.index] && (
                        <ItemImage item={slotData[slot.index].item} size={slot.width * 0.8} amount={slotData[slot.index].amount} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default CraftingTable;
