import React, { useEffect } from "react";
import { getItem, getNewBaseItemsByAmount, getSplitBaseItems } from "../utils";
import ItemIcon from "./ItemIcon";

interface Props {
    trees: any;
    itemAssetData: any;
    recipeData: any;
}
const PathPanel = ({ trees, itemAssetData, recipeData }: Props) => {
    const [baseItems, setBaseItems] = React.useState<{ [item: string]: number }>({});

    useEffect(() => {
        if (!Object.keys(trees).length) return;
        const baseItems: { [item: string]: number } = {};
        for (const item in trees) {
            const tree = trees[item];
            const split = getSplitBaseItems(tree).map((item) => {
                delete item.pathData;
                return item;
            });
            const data = getNewBaseItemsByAmount(tree, split as any, tree.amount, recipeData);
            for (const pair of data) {
                const itemKey = Object.keys(pair)[0];
                baseItems[itemKey] = (baseItems[itemKey] || 0) + pair[itemKey];
            }
        }
        setBaseItems(baseItems);
    }, [trees]);

    return (
        <div className="w-full h-full flex flex-col justify-center items-center gap-2">
            <p
                style={{
                    fontFamily: "minecraftFont",
                }}
            >
                ITEMS NEEDED
            </p>
            <div className="flex flex-row gap-3">
                {Object.keys(baseItems).map((item) => (
                    <ItemIcon item={getItem(item, itemAssetData)} amount={baseItems[item]} size={40} alwaysShowCount />
                ))}
            </div>
        </div>
    );
};

export default PathPanel;
