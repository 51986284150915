import React from "react";

const TimeField = ({ label, onChange, value }: { label: string; onChange: (v: any) => any; value: number }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
        <div className="flex flex-row items-center">
            <label className="font-semibold mr-2 w-[40%] inline-block h-fit">{label}</label>
            <input
                defaultValue="0"
                value={value.toString()}
                type="number"
                ref={inputRef}
                onChange={(e) => onChange(parseInt(e.target.value) ?? 0)}
                className="rounded-md p-3 py-1"
            />
        </div>
    );
};

export default TimeField;
