import React, { useEffect } from "react";
import { Item } from "./ItemIcon";
import CraftingTable from "../recipe_views/CraftingTable";
import Modal from "./Modal";
import Furnace from "../recipe_views/Furnace";
import StoneCutter from "../recipe_views/StoneCutter";
import { getItem } from "../utils";

type Props = {
    data: {
        visible: boolean;
        recipe: string[];
        action: string | null;
        item: Item | null;
        amount: number;
    };
    itemAssetData: any;
    onClose: () => void;
};
const RecipeViewModal = ({ data, itemAssetData, onClose }: Props) => {
    const [items, setItems] = React.useState<any>([]);

    useEffect(() => {
        const itms = [];
        let ind = 0;
        if (data.recipe.length)
            for (const item of [...data.recipe, "minecraft:" + data.item?.id]) {
                let itemAsset = getItem(item, itemAssetData);
                if (!itemAsset) {
                    itemAsset = getItem("air", itemAssetData);
                }
                // itms.push({ item: itemAsset, amount: ind === 9 ? data?.amount : 1 });
                itms.push({ item: itemAsset, amount: 1 });
                ind++;
            }

        if (data.action === "smelt") {
            itms[1] = { item: getItem("coal", itemAssetData), amount: 1 };
        }

        if (data.action === "stonecut") {
            itms[1] = itms[9];
        }
        setItems(itms);
    }, [data.recipe, itemAssetData]);

    if (!data.visible) return null;
    if (data.action === "craft") {
        return (
            <Modal isOpen={true} onClose={onClose} title="Crafting Recipe">
                <CraftingTable slotData={items} />
            </Modal>
        );
    }

    if (data.action === "smelt") {
        return (
            <Modal isOpen={true} onClose={onClose} title="Smelting Recipe">
                <Furnace slotData={items} />
            </Modal>
        );
    }

    if (data.action === "stonecut") {
        return (
            <Modal isOpen={true} onClose={onClose} title="Stonecutting Recipe">
                <StoneCutter slotData={items} />
            </Modal>
        );
    }

    return null;
};

export default RecipeViewModal;
