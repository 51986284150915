import { useCallback, useEffect, useState } from "react";
import { createEditor, Editor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import serverBackground from "../assets/images/server_bg.png";
import widthGroups from "../data/char_size_groups";
import widthGroupsBold from "../data/char_size_groups_bold";
import ServerIconUploader from "./ServerIconUploader";
import { BlockElement, getTextWidth, LeafElement } from "../utils";

const widthGroupsCache = new Map();
let fontSize = window.innerWidth >= 1200 ? "1.65vw" : window.innerWidth >= 640 ? "1.6vw" : "2vw";

interface Params {
    data: any;
    serverIcon: string;
    setServerIcon: React.Dispatch<React.SetStateAction<string>>;
    serverName: string;
}
const MotdPreview = ({ data, serverIcon, setServerIcon, serverName }: Params) => {
    const [editor] = useState(() => withReact(createEditor()));

    const styles = {
        preview: {
            fontFamily: "minecraftFont",
            whiteSpace: "pre",
            width: "103%",
            backgroundColor: "transparent",
            zIndex: 1,
            userSelect: "none" as const,
        },
        element: {
            lineHeight: "2.5vw",
            margin: 0,
            fontSize: fontSize,
        },
        serverName: {
            fontFamily: "minecraftFont",
            fontSize: fontSize,
        },
    };

    const processColor = (leaf: LeafElement, baseElement: any) => {
        if (leaf.color) {
            baseElement.props.style.color = leaf.color;
        }
    };

    const processStyles = (leaf: LeafElement, baseElement: any) => {
        let textDecor = "";

        if (leaf.obf) {
            baseElement.props.style.display = "inline-block";
            baseElement.props.style.overflow = "hidden";
            baseElement.props.style.verticalAlign = "top";
        }

        if (leaf.bold) {
            baseElement.props.style.fontWeight = "bold";
        }

        if (leaf.italic) {
            baseElement.props.style.fontStyle = "italic";
        }

        if (leaf.underline) {
            textDecor += "underline ";
        }

        if (leaf.strike) {
            textDecor += "line-through";
        }

        if (textDecor) {
            baseElement.props.style.textDecoration = textDecor;
        }
    };
    const Leaf = ({ attributes, children, leaf }: { attributes: any; children: HTMLElement[]; leaf: LeafElement }) => {
        let baseElement = (
            <span
                {...attributes}
                style={{
                    ...styles.element,
                    fontSize: fontSize,
                    verticalAlign: "top",
                }}
                className={leaf.obf ? "obf" : ""}
                data-text={leaf.text}
            >
                {children}
            </span>
        );

        processColor(leaf, baseElement);
        processStyles(leaf, baseElement);

        return baseElement;
    };

    const Element = ({ attributes, children, element }: { attributes: any; children: HTMLElement[]; element: BlockElement }) => {
        let baseElement = (
            <p
                {...attributes}
                style={{
                    ...styles.element,
                    textAlign: element.alignment,
                }}
            >
                {children}
            </p>
        );

        return baseElement;
    };
    const renderLeaf = useCallback((props: any) => {
        return <Leaf {...props} />;
    }, []);

    const renderElement = useCallback((props: any) => {
        return <Element {...props} />;
    }, []);

    const forceRender = (editor: Editor) => {
        editor.children = data;
        editor.onChange();
    };

    const getCharGroupIndex = (char: string) => {
        for (let i = 0; i < widthGroups.length; i++) {
            if (widthGroups[i].includes(char)) {
                return i;
            }
        }
    };

    const getRandomCharFromGroup = (groupIndex: number | undefined, bold: boolean) => {
        if (groupIndex === undefined) {
            return "a";
        }
        if (bold) {
            return widthGroupsBold[groupIndex][Math.floor(Math.random() * widthGroupsBold[groupIndex].length)];
        }
        return widthGroups[groupIndex][Math.floor(Math.random() * widthGroups[groupIndex].length)];
    };

    const getRandomChars = (textToRandomize: string, bold: boolean) => {
        let randomText = "";

        for (let i = 0; i < textToRandomize.length; i++) {
            const char = textToRandomize[i];
            if (widthGroupsCache.has(char + (bold ? 1 : 0))) {
                randomText += getRandomCharFromGroup(widthGroupsCache.get(char + (bold ? 1 : 0)), bold);
            } else {
                const groupIndex = getCharGroupIndex(char);
                if (Object.keys(widthGroupsCache).length < 200) {
                    widthGroupsCache.set(char + (bold ? 1 : 0), groupIndex);
                }
                randomText += getRandomCharFromGroup(groupIndex, bold);
            }
        }

        return randomText;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const obfLeaves = document.querySelectorAll(".obf") as NodeListOf<HTMLElement>;

            for (const obfLeaf of obfLeaves) {
                if (obfLeaf.dataset.text === "" || obfLeaf.innerText === "") continue;
                const isBold = obfLeaf.style.fontWeight === "bold";
                const children = obfLeaf.childNodes as NodeListOf<HTMLElement>;
                for (const child of children) {
                    child.innerText = getRandomChars(obfLeaf.dataset.text || child.innerText, isBold);
                }
            }
        }, 10);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        forceRender(editor);
    }, [data]);

    return (
        <Slate editor={editor} initialValue={data}>
            <div className="relative sm:max-w-[71%] w-[105%]">
                <img src={serverBackground} alt="" width={"100%"} className="block" />
                <div className="absolute top-0 left-0 w-full h-full flex flex-row">
                    <ServerIconUploader serverIcon={serverIcon} setServerIcon={setServerIcon} />
                    <div className="flex flex-col ml-1 sm:ml-5 w-[80%]">
                        <p
                            className="top-0 m-0 mt-1 w-fit outline-0"
                            id="serverName"
                            style={{ ...styles.serverName }}
                            contentEditable
                            suppressContentEditableWarning={true}
                        >
                            {serverName}
                        </p>
                        <Editable
                            readOnly
                            renderLeaf={renderLeaf}
                            id={"preview"}
                            className="lg:mt-[0.6vw] mt-[0.3rem]"
                            renderElement={renderElement}
                            style={{ ...styles.preview }}
                        />
                    </div>
                </div>
            </div>
        </Slate>
    );
};

export default MotdPreview;
