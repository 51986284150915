import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, getSimpleBezierPath, getSmoothStepPath, getStraightPath } from "@xyflow/react";
import React from "react";

const RecipeItemEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd }: EdgeProps) => {
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        centerX: sourceX - 80,
    });

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: "absolute",
                        transform: `translateY(-50%, -50%) translate(${labelX - 50}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: "all",
                    }}
                    className="nodrag nopan"
                ></div>
            </EdgeLabelRenderer>
        </>
    );
};
export default RecipeItemEdge;
