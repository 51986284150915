import React, { useState, useRef, useEffect } from "react";

interface HorizontalNumberPickerProps {
    startNumber: number;
    totalSteps: number;
    onChange: (value: number) => void;
    title: string;
    defaultValue?: number;
    disabled?: boolean;
}

const HorizontalNumberPicker: React.FC<HorizontalNumberPickerProps> = ({ startNumber, totalSteps, onChange, title, defaultValue, disabled }) => {
    const [selectedStep, setSelectedStep] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);

    const handleStepChange = (step: number) => {
        const clampedStep = Math.max(0, Math.min(step, totalSteps - 1));
        setSelectedStep(clampedStep);
        onChange(startNumber + clampedStep);
    };

    const getBarColor = (index: number): string => {
        if (disabled) {
            return "#7f8c8d";
        }
        if (index <= selectedStep) {
            return `linear-gradient(to right, 
        rgb(${70 + (148 - 70) * (index / (totalSteps - 1))}, 
            ${35 + (87 - 35) * (index / (totalSteps - 1))}, 
            ${92 + (186 - 92) * (index / (totalSteps - 1))}), 
        rgb(${70 + (148 - 70) * ((index + 1) / (totalSteps - 1))}, 
            ${35 + (87 - 35) * ((index + 1) / (totalSteps - 1))}, 
            ${92 + (186 - 92) * ((index + 1) / (totalSteps - 1))}))`;
        }
        return "#7f8c8d";
    };

    const handleMouseDown = (event: React.MouseEvent) => {
        if (disabled) return;
        isDragging.current = true;
        handleDrag(event.clientX);
        document.body.style.cursor = "grabbing";
    };

    const handleMouseUp = () => {
        if (disabled) return;
        isDragging.current = false;
        document.body.style.cursor = "default";
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (disabled) return;
        if (isDragging.current) {
            handleDrag(event.clientX);
        }
    };

    const handleDrag = (clientX: number) => {
        if (disabled) return;
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const position = clientX - rect.left;
            const percentage = position / rect.width;
            const newStep = Math.round(percentage * (totalSteps - 1));
            handleStepChange(newStep);
        }
    };

    const handleTouchStart = (event: React.TouchEvent) => {
        if (disabled) return;
        isDragging.current = true;
        handleTouchDrag(event.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (disabled) return;
        isDragging.current = false;
    };

    const handleTouchMove = (event: TouchEvent) => {
        if (disabled) return;
        if (isDragging.current) {
            handleTouchDrag(event.touches[0].clientX);
        }
    };

    const handleTouchDrag = (clientX: number) => {
        if (disabled) return;
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const position = clientX - rect.left;
            const percentage = position / rect.width;
            const newStep = Math.round(percentage * (totalSteps - 1));
            handleStepChange(newStep);
        }

        document.body.style.cursor = "grabbing";
        return false;
    };

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchend", handleTouchEnd);

        if (defaultValue) {
            handleStepChange(defaultValue - startNumber);
            setSelectedStep(defaultValue - startNumber);
        }
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("touchmove", handleTouchMove);
            document.removeEventListener("touchend", handleTouchEnd);
        };
    }, []);

    return (
        <div className="h-16 bg-[#1e1e1e] rounded-lg flex flex-col gap-1 px-2 py-2 w-full">
            <div className="flex justify-between items-center">
                <p className="text-white text-sm">{title}</p>
                <p className="text-white text-sm">{startNumber + selectedStep}</p>
            </div>
            <div ref={containerRef} className="relative w-full h-6 cursor-ew-resize" onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
                <div className="absolute inset-0 flex items-center">
                    {Array.from({ length: totalSteps }, (_, index) => (
                        <div key={index} className="flex-1 flex items-center justify-center">
                            <div className="w-0.5 h-3 rounded-full" style={{ background: getBarColor(index) }}></div>
                        </div>
                    ))}
                    <div
                        className={`absolute w-4 h-4 bg-blue-500 rounded-full ${disabled ? "bg-slate-600" : ""}`}
                        style={{
                            left: `${(selectedStep / (totalSteps - 1)) * 100}%`,
                            transform: "translateX(-50%)",
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default HorizontalNumberPicker;
