import React from "react";
import styled from "styled-components";

interface InputFieldProps {
    label: string;
    description: string;
    onChange: (value: string) => void;
    value: string;
    type?: "text" | "dropdown";
    values?: string[];
    className?: string;
}

const StyledInputField = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    border-radius: 8px;
`;

const Label = styled.label`
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const Description = styled.p`
    color: #b0b0b0;
    font-size: 14px;
    margin-bottom: 12px;
`;

const Input = styled.input`
    background-color: #2d2d2d;
    color: #ffffff;
    border: 1px solid #444444;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #0077ff;
    }
`;

const Select = styled.select`
    background-color: #2d2d2d;
    color: #ffffff;
    border: 1px solid #444444;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #0077ff;
    }
`;

const InputField: React.FC<InputFieldProps> = ({ label, description, onChange, value, type = "text", values = [], className = "" }) => {
    return (
        <StyledInputField>
            <Label>{label}</Label>
            <Description>{description}</Description>
            {type === "dropdown" ? (
                <Select onChange={(e) => onChange(e.target.value)} value={value}>
                    {values.map((value, index) => (
                        <option key={index} value={value}>
                            {value}
                        </option>
                    ))}
                </Select>
            ) : (
                <Input type="text" onChange={(e) => onChange(e.target.value)} value={value} />
            )}
        </StyledInputField>
    );
};

export default InputField;
