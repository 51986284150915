import { useEffect, useRef, useState } from "react";
import ReactSkinview3d from "react-skinview3d";
import { IdleAnimation } from "skinview3d";
import Panorama from "./assets/panorama.png";
import Spinner from "./components/Spinner";

function App() {
    const [skinUrl, setSkinUrl] = useState<string | null>(null);
    const [capeUrl, setCapeUrl] = useState<string | null>(null);
    const [playerName, setPlayerName] = useState<string>("");
    const [headIconUrl, setHeadIconUrl] = useState<string>("");
    const playerInputRef = useRef<HTMLInputElement>(null);
    const [showBadName, setShowBadName] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const skinViewConfig = {
        panorama: Panorama,
        zoom: 0.7,
        fov: 90,
        speed: 0.1,
        animation: new IdleAnimation(),
    };

    const onViewSkin = async () => {
        if (!playerInputRef.current) return;
        setIsLoading(true);
        fetch("https://api.capes.dev/load/" + playerInputRef.current.value).then((capeData) => {
            capeData.json().then((capeJson) => {
                if (!capeJson.minecraft?.playerName) {
                    setShowBadName(true);
                    setIsLoading(false);
                    return;
                }
                setCapeUrl("");
                for (const [provider, data] of Object.entries(capeJson)) {
                    if (!(data as { stillImageUrl?: string }).stillImageUrl) continue;
                    setCapeUrl((data as { stillImageUrl: string }).stillImageUrl);
                    break;
                }
                setPlayerName(capeJson.minecraft.playerName);
            });
        });
        setSkinUrl("https://crafthead.net/skin/" + playerInputRef.current.value);
        setHeadIconUrl("https://crafthead.net/avatar/" + playerInputRef.current.value);

        setIsLoading(false);
        setShowBadName(false);
    };

    return (
        <div className="w-full p-10 flex items-center flex-col gap-5" style={{ fontFamily: "minecraft" }}>
            <div className="flex flex-row gap-3">
                <input type="text" className="w-[50vw] max-w-[30rem] rounded-md p-3" placeholder="Enter a username / uuid" ref={playerInputRef} />
                <button onClick={onViewSkin}>View Skin</button>
            </div>
            {skinUrl && !showBadName && (
                <ReactSkinview3d
                    skinUrl={skinUrl}
                    capeUrl={capeUrl ?? ""}
                    width={window.innerWidth <= 400 ? 360 : 600}
                    height={600}
                    options={skinViewConfig}
                    className="rounded-2xl cursor-move"
                />
            )}
            {!showBadName && skinUrl && headIconUrl && (
                <div className="flex flex-row gap-3 items-center">
                    <img hidden={!headIconUrl} src={headIconUrl} alt="" className="w-10 aspect-square rounded-full" />
                    <p className="h-fit text-lg">{playerName}</p>
                </div>
            )}
            {showBadName && playerInputRef.current && <p className="text-red-500">This player does not exist!</p>}
            {isLoading && <Spinner />}
        </div>
    );
}

export default App;
