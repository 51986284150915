import React, { useState, useEffect } from "react";

interface Params {
    icon?: React.FC;
    text: string;
    action: () => void;
    copiedText: string;
}

const AnimatedButton = ({ icon: Icon, text, action, copiedText }: Params) => {
    const [showCopied, setShowCopied] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showCopied) {
            timer = setTimeout(() => setShowCopied(false), 600);
        }
        return () => clearTimeout(timer);
    }, [showCopied]);

    const handleClick = () => {
        action();
        setShowCopied(true);
    };

    return (
        <div
            className={
                "flex items-center gap-2 cursor-pointer rounded-md px-3 h-[50%] justify-center relative overflow-hidden bg-green-700" +
                (showCopied ? "bg-green-900" : "")
            }
            style={{ transition: "background-color 0.3s ease" }}
            onClick={handleClick}
        >
            {Icon && <Icon />}
            <span className="text-white text-sm">{text}</span>
            {showCopied && (
                <div
                    className="absolute inset-0 flex items-center justify-center bg-opacity-90 text-white text-sm bg-green-900"
                    style={{
                        animation: "fadeInOut 0.7s ease-in-out",
                    }}
                >
                    {copiedText}
                </div>
            )}
            <style>{`
                @keyframes fadeInOut {
                    0% {
                        opacity: 0;
                    }
                    20% {
                        opacity: 1;
                    }
                    80% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            `}</style>
        </div>
    );
};

export default AnimatedButton;
