import { Editor, Text } from "slate";

interface GetTextWidthFunction {
    (txt: string, fontname: string, fontsize: string | number, weight?: number): number;
    c?: HTMLCanvasElement;
    ctx?: CanvasRenderingContext2D;
  }
  
  const getTextWidth: GetTextWidthFunction = (txt, fontname, fontsize, weight = 400): number => {
      let fontspec = weight + " " + fontsize + " " + fontname;
  
      if (!getTextWidth.c) {
          getTextWidth.c = document.createElement("canvas");
          getTextWidth.ctx = getTextWidth.c.getContext("2d") as CanvasRenderingContext2D;
      }
      if (getTextWidth.ctx && getTextWidth.ctx.font !== fontspec) {
          getTextWidth.ctx.font = fontspec;
      }
      return getTextWidth.ctx?.measureText(txt).width || 0;
  };

const styleCodes: {[key: string]: string} = {
    "#000000": "\\u00A70",
    "#0000AA": "\\u00A71",
    "#00AA00": "\\u00A72",
    "#00AAAA": "\\u00A73",
    "#AA0000": "\\u00A74",
    "#AA00AA": "\\u00A75",
    "#FFAA00": "\\u00A76",
    "#AAAAAA": "\\u00A77",
    "#555555": "\\u00A78",
    "#5555FF": "\\u00A79",
    "#55FF55": "\\u00A7a",
    "#55FFFF": "\\u00A7b",
    "#FF5555": "\\u00A7c",
    "#FF55FF": "\\u00A7d",
    "#FFFF55": "\\u00A7e",
    "#FFFFFF": "\\u00A7f",
    obf: "\\u00A7k",
    bold: "\\u00A7l",
    strikethrough: "\\u00A7m",
    underline: "\\u00A7n",
    italic: "\\u00A7o",
    reset: "\\u00A7r",
};

const reversedStyleCodes = Object.fromEntries(Object.entries(styleCodes).map(([key, value]) => ["§" + value.slice(value.length - 1), key]));

type LeafElement = {
    text: string;
    color?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    strike?: boolean;
    obf?: boolean;
    [key: string]: string | boolean | undefined;
};

type BlockElement = {
    alignment: string;
    type: string;
    children: LeafElement[];
};

const getClosestColor = (colorList: string[], targetColor: string) => {
    const hexToRgb = (hex: string) => {
        const match = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16));
        if (!match) return [0, 0, 0];
        return [match[0], match[1], match[2]];
    }
    const dist = (a: number[], b: number[]) => Math.hypot(...a.map((x, i) => x - b[i]));
    const targetRgb = hexToRgb(targetColor);
    return colorList.reduce((closest, color) => (dist(hexToRgb(color), targetRgb) < dist(hexToRgb(closest), targetRgb) ? color : closest));
};

const verifyElement = (element: string) => {
    const color = element.slice(1);
    if (color.startsWith("#")) {
        const style =
            styleCodes[
                getClosestColor(
                    Object.keys(styleCodes).filter((x) => x.startsWith("#")),
                    color
                )
            ];
        return "§" + style.slice(style.length - 1);
    }
    return element;
};

const getLeavesFromMotdLine = (line: string) => {
    if (!line) return [];
    const leaves = [];
    const regex = /§[0-9a-fl-ork]|§#......|./g;
    const elements: string[] = Array.from(line.matchAll(regex), (match) => match[0]);

    let currLeaf: Record<string, any> = { text: "", color: "#AAAAAA", bold: false, italic: false, underline: false, strike: false, obf: false };

    for (let element of elements) {
        element = verifyElement(element);

        if (reversedStyleCodes[element]) {
            if (currLeaf.text.length > 0) {
                leaves.push(JSON.parse(JSON.stringify(currLeaf)));
            }

            if (element === "§r" || reversedStyleCodes[element].startsWith("#")) {
                currLeaf = { text: "", color: "#AAAAAA", bold: false, italic: false, underline: false, strike: false, obf: false };
            }

            if (element !== "§r") {
                currLeaf.text = "";
                if (reversedStyleCodes[element].startsWith("#")) {
                    currLeaf.color = reversedStyleCodes[element];
                } else {
                    currLeaf[reversedStyleCodes[element]] = true;
                }
            }
            continue;
        }

        currLeaf.text += element;
    }
    if (currLeaf.text.length > 0) {
        leaves.push(currLeaf);
    }

    return leaves;
};

const motdToSlate = (motd: string) => {
    const lines = motd.split("\\n");
    const firstLineLeaves = getLeavesFromMotdLine(lines[0]);
    const paras = [{ type: "paragraph", children: firstLineLeaves.length ? firstLineLeaves : [{ type: "text", text: "" }] }];

    const secondLineLeaves = getLeavesFromMotdLine(lines[1]);
    if (secondLineLeaves.length) {
        paras.push({
            type: "paragraph",
            children: secondLineLeaves,
        });
    }
    return paras;
};

const getFontSize = () => {
    if (window.innerWidth < 640) {
        return window.innerWidth * 0.02 + "px";
    } else if (window.innerWidth < 1250) {
        return window.innerWidth * 0.017 + "px";
    } else {
        return 16 * 1.45 + "px";
    }
};

const isValidMotd = (motd: string, maxLineWidth: number) => {
    if (motd.split("\\n").length > 2) {
        return { valid: false, reason: "Too many lines" };
    }

    const firstLineLeaves = getLeavesFromMotdLine(motd.split("\\n")[0]);
    const secondLineLeaves = getLeavesFromMotdLine(motd.split("\\n").length === 1 ? "" : motd.split("\\n")[1]);
    const fontSize = getFontSize();

    let firstLineWidth = 0;
    for (const leaf of firstLineLeaves) {
        firstLineWidth += getTextWidth(leaf.text, "minecraftFont", fontSize, leaf.bold ? 800 : 400);
    }

    let secondLineWidth = 0;
    for (const leaf of secondLineLeaves) {
        secondLineWidth += getTextWidth(leaf.text, "minecraftFont", fontSize, leaf.bold ? 800 : 400);
    }

    if (firstLineWidth > maxLineWidth) return { valid: false, reason: "First line too long" };

    if (secondLineWidth > maxLineWidth) return { valid: false, reason: "Second line too long" };

    return { valid: true, reason: "" };
};

const getAllLeaves = (editor: Editor) => {
    const leaves = [];

    for (const [node, ] of Editor.nodes(editor, {
        at: [],
        match: Text.isText,
        mode: "lowest",
    })) {
        leaves.push(node);
    }

    return leaves;
};

const unmergeLeaves = (leaves: LeafElement[]) => {
    const newLeaves = [];
    for (let leaf of leaves) {
        for (let char of leaf.text) {
            newLeaves.push({ ...leaf, text: char });
        }
    }
    return newLeaves;
};

export {
    getTextWidth,
    styleCodes,
    reversedStyleCodes,
    getClosestColor,
    verifyElement,
    motdToSlate,
    getLeavesFromMotdLine,
    isValidMotd,
    getAllLeaves,
    unmergeLeaves
};

export type { LeafElement,BlockElement };
