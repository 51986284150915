import React from "react";
import StyleOption from "./StyleOption";

const AlignRightIcon = () => (
    <div className="w-3 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M448 64c0 17.7-14.3 32-32 32L192 96c-17.7 0-32-14.3-32-32s14.3-32 32-32l224 0c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32l-224 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l224 0c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 224c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32L32 480c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
        </svg>
    </div>
);

const AlignLeftIcon = () => (
    <div className="w-3 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M288 64c0 17.7-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l224 0c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32L32 352c-17.7 0-32-14.3-32-32s14.3-32 32-32l224 0c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 224c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32L32 480c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
        </svg>
    </div>
);

const AlignCenterIcon = () => (
    <div className="w-3 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M352 64c0-17.7-14.3-32-32-32L128 32c-17.7 0-32 14.3-32 32s14.3 32 32 32l192 0c17.7 0 32-14.3 32-32zm96 128c0-17.7-14.3-32-32-32L32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l384 0c17.7 0 32-14.3 32-32zM0 448c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 416c-17.7 0-32 14.3-32 32zM352 320c0-17.7-14.3-32-32-32l-192 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l192 0c17.7 0 32-14.3 32-32z" />
        </svg>
    </div>
);

interface Params {
    styleCallback: (style: string) => void;
    selectedStyles: string[];
}

const Styles = ({ styleCallback, selectedStyles }: Params) => {
    const [obfChar, setObfChar] = React.useState("?");

    const getRenderableObfuscatedChar = () => {
        const obfuscatedChars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+-=[]{}|;:,.<>?/~`áéíóúüñ¿¡";
        return obfuscatedChars[Math.floor(Math.random() * obfuscatedChars.length)];
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            setObfChar(getRenderableObfuscatedChar());
        }, 10);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="flex flex-col gap-2 w-[91%] sm:w-[50%] h-1/3 sm:h-full">
            <div className="flex flex-row gap-1 justify-around w-full h-1/2">
                <StyleOption
                    selected={selectedStyles.includes("bold")}
                    onClick={() => styleCallback("bold")}
                    label="B"
                    style={{
                        fontWeight: "bold",
                        fontFamily: "minecraftFont",
                        fontSize: "1rem",
                    }}
                />
                <StyleOption
                    selected={selectedStyles.includes("italic")}
                    onClick={() => styleCallback("italic")}
                    label="I"
                    style={{
                        fontStyle: "italic",
                        fontFamily: "minecraftFont",
                        fontSize: "1rem",
                    }}
                />
                <StyleOption
                    selected={selectedStyles.includes("underline")}
                    onClick={() => styleCallback("underline")}
                    label="U"
                    style={{
                        textDecoration: "underline",
                        fontFamily: "minecraftFont",
                        fontSize: "1rem",
                    }}
                />
                <StyleOption
                    selected={selectedStyles.includes("strike")}
                    onClick={() => styleCallback("strike")}
                    label="S"
                    style={{
                        textDecoration: "line-through",
                        fontFamily: "minecraftFont",
                        fontSize: "1rem",
                    }}
                />
                <StyleOption
                    selected={selectedStyles.includes("obf")}
                    onClick={() => styleCallback("obf")}
                    label={obfChar}
                    style={{ fontFamily: "minecraftFont", fontSize: "1rem" }}
                />
            </div>
            <div className="flex flex-row gap-1 justify-around w-full h-1/2">
                <StyleOption
                    selected={selectedStyles.includes("left")}
                    onClick={() => styleCallback("left")}
                    cIcon={AlignLeftIcon()}
                    style={{ display: "flex", justifyContent: "center" }}
                />
                <StyleOption
                    selected={selectedStyles.includes("center")}
                    onClick={() => styleCallback("center")}
                    cIcon={AlignCenterIcon()}
                    style={{ display: "flex", justifyContent: "center" }}
                />
                <StyleOption
                    selected={selectedStyles.includes("right")}
                    onClick={() => styleCallback("right")}
                    cIcon={AlignRightIcon()}
                    style={{ display: "flex", justifyContent: "center" }}
                />
            </div>
        </div>
    );
};

export default Styles;
