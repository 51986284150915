import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ToolButton = ({ title, href }: { title: string; href: string }) => {
    const [mousePosition, setMousePosition] = useState({ x: -1, y: -1 });
    const navigate = useNavigate();

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setMousePosition({ x, y });
    };

    const handleMouseLeave = () => {
        setMousePosition({ x: -1, y: -1 });
    };

    const calculateGlowPosition = () => {
        if (mousePosition.x === -1 && mousePosition.y === -1) {
            return "radial-gradient(circle at center, transparent 0%, transparent 100%)";
        }
        return `radial-gradient(circle 80px at ${mousePosition.x}px ${mousePosition.y}px, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.2) 50%, transparent 70%)`;
    };

    return (
        <div
            onClick={() => navigate(href)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            className="relative p-4 w-48 h-24 rounded-xl flex justify-center items-center cursor-pointer transition-all duration-300 shadow-lg hover:shadow-xl"
        >
            <div
                className="absolute inset-0 rounded-lg"
                style={{
                    backgroundImage: calculateGlowPosition(),
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    opacity: mousePosition.x !== -1 ? 1 : 0,
                    transition: "opacity 0.3s ease",
                    pointerEvents: "none",
                }}
            />
            <div
                className="absolute rounded-lg bg-slate-700"
                style={{
                    inset: "1px",
                    pointerEvents: "none",
                }}
            />
            <p className="relative w-full text-center font-semibold text-white z-10">{title}</p>
        </div>
    );
};

export default ToolButton;
