import React from "react";
import Section from "./Section";
import CoordinateInput from "./CoordinateInput";

const DimensionCalculator = () => {
    const [overworld, setOverworld] = React.useState<{ x: number; y: number; z: number }>({ x: 0, y: 0, z: 0 });
    const [nether, setNether] = React.useState<{ x: number; y: number; z: number }>({ x: 0, y: 0, z: 0 });

    const overworldChange = (val: string, type: string) => {
        console.log(val);
        const newData = { ...nether, [type]: type === "y" ? parseInt(val) : Math.floor(parseInt(val) / 8) };
        setOverworld({ ...overworld, [type]: parseFloat(val) });
        setNether(newData);
    };

    const netherChange = (val: string, type: string) => {
        const newData = { ...overworld, [type]: type === "y" ? parseInt(val) : Math.floor(parseInt(val) * 8) };
        setNether({ ...nether, [type]: parseFloat(val) });
        setOverworld(newData);
    };

    return (
        <div className="flex items-center justify-center pt-6 h-min flex-col">
            <Section title={"Overworld Coordinates"} className="flex sm:flex-row flex-col justify-around sm:px-[10%]">
                <CoordinateInput label="X" onChange={(v: string) => overworldChange(v, "x")} value={overworld.x} />
                <CoordinateInput label="Y" onChange={(v: string) => overworldChange(v, "y")} value={overworld.y} />
                <CoordinateInput label="Z" onChange={(v: string) => overworldChange(v, "z")} value={overworld.z} />
            </Section>
            <Section title={"Nether Coordinates"} className="flex sm:flex-row flex-col justify-around sm:px-[10%]">
                <CoordinateInput label="X" onChange={(v: string) => netherChange(v, "x")} value={nether.x} />
                <CoordinateInput label="Y" onChange={(v: string) => netherChange(v, "y")} value={nether.y} />
                <CoordinateInput label="Z" onChange={(v: string) => netherChange(v, "z")} value={nether.z} />
            </Section>
        </div>
    );
};

export default DimensionCalculator;
