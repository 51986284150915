import { useState } from "react";
import TimeField from "./components/TimeField";

const ResetIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
        />
    </svg>
);

function App() {
    const [ticks, setTicks] = useState(0);
    const [timeData, setTimeData] = useState({ seconds: 0, minutes: 0, hours: 0, days: 0 });

    const calculateTicks = (data: { seconds: number; minutes: number; hours: number; days: number }) => {
        const { seconds, minutes, hours, days } = data;
        const totalSeconds = seconds + minutes * 60 + hours * 3600 + days * 86400;
        setTicks(totalSeconds * 20);
    };

    const timeChanged = (type: string, value: number) => {
        console.log(type, value);
        const newData = { ...timeData, [type]: isNaN(value) ? 0 : value };
        setTimeData(newData);
        calculateTicks(newData);
    };

    return (
        <div className="w-full p-10 flex">
            <div className="flex flex-col gap-3">
                <TimeField value={timeData.seconds} label="SECONDS" onChange={(v) => timeChanged("seconds", v)} />
                <TimeField value={timeData.minutes} label="MINUTES" onChange={(v) => timeChanged("minutes", v)} />
                <TimeField value={timeData.hours} label="HOURS" onChange={(v) => timeChanged("hours", v)} />
                <TimeField value={timeData.days} label="DAYS" onChange={(v) => timeChanged("days", v)} />
                <div className="bg-[#383737] p-3 py-1 mt-5 rounded-md flex flex-row justify-between items-center">
                    <div>
                        <span className="font-semibold mr-3">TICKS:</span> {ticks}
                    </div>
                    <button
                        className="flex flex-row py-1 items-center gap-1 ml-3"
                        onClick={() => {
                            setTimeData({ seconds: 0, minutes: 0, hours: 0, days: 0 });
                            setTicks(0);
                        }}
                    >
                        <ResetIcon />
                        <span>CLEAR</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default App;
