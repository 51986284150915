export default [
    [
        "ʺ",
        "ˀ",
        "ˁ",
        "˘",
        "˜",
        "˟",
        "ˠ",
        "ˣ",
        "ˤ",
        "˭",
        "ˮ",
        "˷",
        "˽",
        "˾",
        "˿",
        "ͱ",
        "Ͷ",
        "ͻ",
        "ͼ",
        "ͽ",
        "Ϗ",
        "ϐ",
        "Ϙ",
        "ϙ",
        "Ϝ",
        "Ϟ",
        "ϟ",
        "Ϥ",
        "ϧ",
        "Ϩ",
        "ϩ",
        "ϫ",
        "Ϭ",
        "ϭ",
        "ϱ",
        "ϲ",
        "ϴ",
        "ϸ",
        "Ϲ",
        "Ϻ",
        "Ͻ",
        "Ͼ",
        "Ͽ",
        "Ѯ",
        "ѯ",
        "Ҁ",
        "ҁ",
        "ԁ",
        "؝",
        "ؠ",
        "ؤ",
        "ئ",
        "ب",
        "ت",
        "ث",
        "ج",
        "ح",
        "خ",
        "د",
        "ذ",
        "ر",
        "ز",
        "ط",
        "ظ",
        "ع",
        "غ",
        "ؽ",
        "ؾ",
        "ؿ",
        "ف",
        "ق",
        "ك",
        "ل",
        "ن",
        "و",
        "ى",
        "ي",
        "٤",
        "٥",
        "ٮ",
        "ٯ",
        "ٲ",
        "ٳ",
        "ٵ",
        "ٸ",
        "ٹ",
        "ٺ",
        "ٻ",
        "ټ",
        "ٽ",
        "پ",
        "ٿ",
        "ڀ",
        "ځ",
        "ڂ",
        "ڃ",
        "ڄ",
        "څ",
        "چ",
        "ڇ",
        "ڈ",
        "ډ",
        "ڊ",
        "ڋ",
        "ڌ",
        "ڍ",
        "ڎ",
        "ڏ",
        "ڐ",
        "ڑ",
        "ڒ",
        "ړ",
        "ڔ",
        "ږ",
        "ڗ",
        "ژ",
        "ڙ",
        "ڟ",
        "ڠ",
        "ڡ",
        "ڢ",
        "ڣ",
        "ڤ",
        "ڥ",
        "ڦ",
        "ڧ",
        "ڨ",
        "ڬ",
        "ڭ",
        "ڮ",
        "ڶ",
        "ڸ",
        "ڹ",
        "ں",
        "ڻ",
        "ڼ",
        "ڽ",
        "ھ",
        "ڿ",
        "ۄ",
        "ۅ",
        "ۆ",
        "ۇ",
        "ۈ",
        "ۉ",
        "ۊ",
        "ۋ",
        "ی",
        "ێ",
        "ۏ",
        "ې",
        "ۑ",
        "ۮ",
        "۴",
        "۶",
        "ۼ",
        "۾",
        "ۿ",
        "܅",
        "܆",
        "܇",
        "܈",
        "܉",
        "܊",
        "ܕ",
        "ܖ",
        "ܪ",
        "ݐ",
        "ݑ",
        "ݒ",
        "ݓ",
        "ݔ",
        "ݕ",
        "ݖ",
        "ݗ",
        "ݘ",
        "ݙ",
        "ݚ",
        "ݝ",
        "ݞ",
        "ݟ",
        "ݠ",
        "ݡ",
        "ݧ",
        "ݨ",
        "ݩ",
        "ݪ",
        "ݫ",
        "ݬ",
        "ݮ",
        "ݯ",
        "ݱ",
        "ݲ",
        "ݵ",
        "ݷ",
        "ݸ",
        "ݹ",
        "ݿ",
        "ރ",
        "ޅ",
        "ޏ",
        "ޑ",
        "ޕ",
        "ޜ",
        "߀",
        "߁",
        "߇",
        "ߋ",
        "ߑ",
        "ߘ",
        "ߚ",
        "ߛ",
        "ߝ",
        "ߟ",
        "ߨ",
        "ߩ",
        "߾",
        "߿",
        "‒",
        "‟",
        "‾",
        "‿",
        "⁀",
        "⁁",
        "⁄",
        "⁐",
        "⁔",
        "⁖",
        "⁘",
        "⁙",
        "⁛",
        "⃀",
        "℄",
        "ℇ",
        "℈",
        "ℓ",
        "℘",
        "ℙ",
        "℞",
        "℟",
        "ℤ",
        "℥",
        "K",
        "Å",
        "ℭ",
        "ℰ",
        "ℱ",
        "ℵ",
        "ℷ",
        "⅂",
        "⅃",
        "⅊",
        "Ⅱ",
        "Ⅼ",
        "Ⅽ",
        "Ⅾ",
        "ⅱ",
        "ⅽ",
        "ↁ",
        "Ↄ",
        "ↄ",
        "↊",
        "↋",
        "↖",
        "↗",
        "↘",
        "↙",
        "↤",
        "↦",
        "↯",
        "↸",
        "↹",
        "↺",
        "↻",
        "⇆",
        "⇇",
        "⇉",
        "⇋",
        "⇌",
        "⇐",
        "⇖",
        "⇗",
        "⇘",
        "⇙",
        "∊",
        "∍",
        "∎",
        "∕",
        "∖",
        "∟",
        "∠",
        "∡",
        "∢",
        "∤",
        "∦",
        "∪",
        "∱",
        "∲",
        "∳",
        "∷",
        "∸",
        "∹",
        "∺",
        "∻",
        "∼",
        "∽",
        "≁",
        "≂",
        "≃",
        "≄",
        "≅",
        "≆",
        "≇",
        "≉",
        "≊",
        "≋",
        "≌",
        "≍",
        "≎",
        "≏",
        "≐",
        "≑",
        "≒",
        "≓",
        "≕",
        "≖",
        "≗",
        "≘",
        "≙",
        "≚",
        "≛",
        "≜",
        "≝",
        "≞",
        "≟",
        "≣",
        "≦",
        "≧",
        "≨",
        "≩",
        "≰",
        "≱",
        "≲",
        "≳",
        "≴",
        "≵",
        "≶",
        "≷",
        "≹",
        "≺",
        "≻",
        "≼",
        "≽",
        "⊀",
        "⊁",
        "⊈",
        "⊉",
        "⊊",
        "⊋",
        "⊌",
        "⊍",
        "⊎",
        "⊏",
        "⊐",
        "⊑",
        "⊒",
        "⊓",
        "⊔",
        "⊣",
        "⊩",
        "⊫",
        "⊬",
        "⊭",
        "⊮",
        "⊯",
        "⊰",
        "⊱",
        "⊲",
        "⊳",
        "⊴",
        "⊵",
        "⊾",
        "⊿",
        "⋂",
        "⋇",
        "⋍",
        "⋐",
        "⋑",
        "⋕",
        "⋚",
        "⋛",
        "⋜",
        "⋝",
        "⋞",
        "⋟",
        "⋢",
        "⋣",
        "⋤",
        "⋥",
        "⋦",
        "⋧",
        "⋨",
        "⋩",
        "⋪",
        "⋫",
        "⋬",
        "⋭",
        "⋴",
        "⋷",
        "⋸",
        "⋼",
        "⋾",
        "⌐",
        "⌙",
        "⌢",
        "⌣",
        "⌨",
        "⍨",
        "⍩",
        "⍭",
        "⍱",
        "⍲",
        "⍴",
        "⍶",
        "⍷",
        "⍺",
        "⎰",
        "⎱",
        "⎾",
        "⎿",
        "⏋",
        "⏌",
        "⏨",
        "␢",
        "␣",
        "␤",
        "␦",
        "⑆",
        "▊",
        "▴",
        "▵",
        "►",
        "▻",
        "▾",
        "▿",
        "◄",
        "◅",
        "◊",
        "◧",
        "◨",
        "◩",
        "◪",
        "◭",
        "◮",
        "◰",
        "◱",
        "◲",
        "◳",
        "◻",
        "◼",
        "☧",
        "☾",
        "♃",
        "♄",
        "♇",
        "♒",
        "♓",
        "♘",
        "♙",
        "♞",
        "♟",
        "⚳",
        "⚷",
        "⚸",
        "⚼",
        "❮",
        "❯",
        "❴",
        "❵",
        "⟀",
        "⟓",
        "⟔",
        "⟪",
        "⟫",
        "גּ",
        "דּ",
        "הּ",
        "טּ",
        "ךּ",
        "סּ",
        "ףּ",
        "צּ",
        "קּ",
        "רּ",
        "כֿ",
        "ﭏ",
        "ﭒ",
        "ﭔ",
        "ﭖ",
        "ﭘ",
        "ﭚ",
        "ﭜ",
        "ﭞ",
        "ﭠ",
        "ﭢ",
        "ﭤ",
        "ﭦ",
        "ﭨ",
        "ﭪ",
        "ﭬ",
        "ﭮ",
        "ﭰ",
        "ﭲ",
        "ﭴ",
        "ﭶ",
        "ﭸ",
        "ﭺ",
        "ﭼ",
        "ﭾ",
        "ﮀ",
        "ﮂ",
        "ﮄ",
        "ﮆ",
        "ﮈ",
        "ﮊ",
        "ﮌ",
        "ﮞ",
        "ﮠ",
        "ﮢ",
        "ﮥ",
        "ﮨ",
        "ﮪ",
        "ﯓ",
        "ﯗ",
        "ﯙ",
        "ﯛ",
        "ﯞ",
        "ﯠ",
        "ﯢ",
        "ﯤ",
        "ﯦ",
        "ﯨ",
        "ﯬ",
        "ﯹ",
        "ﯼ",
        "ﯾ",
        "ﰃ",
        "ﰄ",
        "ﰅ",
        "ﰆ",
        "ﰇ",
        "ﰋ",
        "ﰌ",
        "ﰏ",
        "ﰐ",
        "ﰓ",
        "ﰔ",
        "ﰕ",
        "ﰗ",
        "ﰙ",
        "ﰚ",
        "ﰦ",
        "ﰩ",
        "ﰱ",
        "ﰲ",
        "ﰳ",
        "ﰵ",
        "ﰶ",
        "ﰸ",
        "ﰹ",
        "ﱃ",
        "ﱄ",
        "ﱅ",
        "ﱆ",
        "ﱇ",
        "ﱉ",
        "ﱊ",
        "ﱋ",
        "ﱌ",
        "ﱍ",
        "ﱏ",
        "ﱐ",
        "ﱑ",
        "ﱕ",
        "ﱖ",
        "ﱗ",
        "ﱛ",
        "ﱜ",
        "ﱝ",
        "ﱞ",
        "ﱟ",
        "ﱠ",
        "ﱡ",
        "ﱢ",
        "ﲀ",
        "ﲂ",
        "ﲜ",
        "ﲝ",
        "ﲞ",
        "ﲻ",
        "ﲽ",
        "ﳈ",
        "ﳉ",
        "ﳊ",
        "ﳋ",
        "ﳒ",
        "ﳓ",
        "ﳔ",
        "ﳷ",
        "ﳸ",
        "ﳹ",
        "ﳺ",
        "ﳿ",
        "ﴀ",
        "ﴃ",
        "ﴄ",
        "︖",
        "ﺅ",
        "ﺉ",
        "ﺋ",
        "ﺎ",
        "ﺏ",
        "ﺑ",
        "ﺔ",
        "ﺕ",
        "ﺗ",
        "ﺙ",
        "ﺛ",
        "ﺝ",
        "ﺡ",
        "ﺣ",
        "ﺥ",
        "ﺧ",
        "ﺩ",
        "ﺫ",
        "ﺭ",
        "ﺯ",
        "ﻁ",
        "ﻅ",
        "ﻉ",
        "ﻍ",
        "ﻑ",
        "ﻓ",
        "ﻕ",
        "ﻗ",
        "ﻙ",
        "ﻝ",
        "ﻟ",
        "ﻢ",
        "ﻥ",
        "ﻧ",
        "ﻪ",
        "ﻭ",
        "ﻯ",
        "ﻱ",
        "ﻳ",
        "ﻷ",
        "ｦ",
        "ｧ",
        "ｨ",
        "ｮ",
        "ｯ",
        "ｺ",
        "ﾂ",
        "ﾉ",
        "ﾋ",
        "ﾏ",
        "ﾖ",
        "ﾗ",
        "ﾚ",
        "ﾛ",
        "ﾜ",
        "ﾡ",
        "ﾤ",
        "ﾥ",
        "ﾦ",
        "ﾧ",
        "ﾪ",
        "ﾲ",
        "ﾻ",
        "ﾼ",
        "ﾽ",
        "ￍ",
        "ￕ",
        "ￚ",
        "￩",
        "￫",
        "￭",
        "￮",
        "𐀈",
        "𐀖",
        "𐀡",
        "𐀨",
        "𐀱",
        "𐀲",
        "𐁐",
        "𐁓",
        "𐁖",
        "𐂏",
        "𐂒",
        "𐂔",
        "𐂚",
        "𐄓",
        "𐄔",
        "𐄕",
        "𐄖",
        "𐄗",
        "𐄝",
        "𐄟",
        "𐄺",
        "𐅀",
        "𐅁",
        "𐅂",
        "𐅃",
        "𐅝",
        "𐅞",
        "𐅟",
        "𐅠",
        "𐅳",
        "𐅵",
        "𐅸",
        "𐅾",
        "𐅿",
        "𐆀",
        "𐆂",
        "𐆄",
        "𐆇",
        "𐆎",
        "𐆐",
        "𐆓",
        "𐆕",
        "𐆚",
        "𐆠",
        "𐇟",
        "𐇠",
        "𐇣",
        "𐇥",
        "𐇫",
        "𐇸",
        "𐇹",
        "𐊂",
        "𐊃",
        "𐊆",
        "𐊇",
        "𐊒",
        "𐊕",
        "𐊖",
        "𐊪",
        "𐋉",
        "𐋏",
        "𐋦",
        "𐋨",
        "𐋮",
        "𐋴",
        "𐋷",
        "𐋸",
        "𐋻",
        "𐌀",
        "𐌃",
        "𐌌",
        "𐌯",
        "𐍔",
        "𐍗",
        "𐍙",
        "𐍚",
        "𐍛",
        "𐍞",
        "𐍟",
        "𐍡",
        "𐍣",
        "𐍩",
        "𐍬",
        "𐍰",
        "𐎓",
        "𐎘",
        "𐏓",
        "𐐀",
        "𐐁",
        "𐐂",
        "𐐇",
        "𐐊",
        "𐐌",
        "𐐍",
        "𐐐",
        "𐐑",
        "𐐒",
        "𐐔",
        "𐐕",
        "𐐚",
        "𐐛",
        "𐐠",
        "𐐣",
        "𐐥",
        "𐐨",
        "𐐩",
        "𐐪",
        "𐐯",
        "𐐲",
        "𐐴",
        "𐐵",
        "𐐸",
        "𐐺",
        "𐐼",
        "𐐽",
        "𐑂",
        "𐑇",
        "𐑈",
        "𐑋",
        "𐑍",
        "𐑔",
        "𐑖",
        "𐑗",
        "𐑞",
        "𐑠",
        "𐑡",
        "𐑭",
        "𐑱",
        "𐑲",
        "𐑷",
        "𐑼",
        "𐑽",
        "𐑾",
        "𐒁",
        "𐒈",
        "𐒉",
        "𐒋",
        "𐒍",
        "𐒏",
        "𐒔",
        "𐒕",
        "𐒘",
        "𐒵",
        "𐒷",
        "𐒸",
        "𐒹",
        "𐒺",
        "𐒻",
        "𐒼",
        "𐒾",
        "𐒿",
        "𐓄",
        "𐓆",
        "𐓇",
        "𐓈",
        "𐓉",
        "𐓊",
        "𐓋",
        "𐓎",
        "𐓏",
        "𐓒",
        "𐓓",
        "𐓛",
        "𐓝",
        "𐓟",
        "𐓠",
        "𐓡",
        "𐓢",
        "𐓣",
        "𐓤",
        "𐓦",
        "𐓧",
        "𐓬",
        "𐓮",
        "𐓯",
        "𐓰",
        "𐓱",
        "𐓲",
        "𐓳",
        "𐓶",
        "𐓷",
        "𐓺",
        "𐓻",
        "𐔄",
        "𐔅",
        "𐔇",
        "𐔈",
        "𐔉",
        "𐔌",
        "𐔍",
        "𐔏",
        "𐔒",
        "𐔓",
        "𐔔",
        "𐔕",
        "𐔖",
        "𐔗",
        "𐔙",
        "𐔚",
        "𐔛",
        "𐔜",
        "𐔞",
        "𐔠",
        "𐔡",
        "𐔢",
        "𐔣",
        "𐔥",
        "𐔦",
        "𐔰",
        "𐔱",
        "𐔲",
        "𐔵",
        "𐔶",
        "𐔷",
        "𐔸",
        "𐔺",
        "𐔻",
        "𐔼",
        "𐔽",
        "𐔾",
        "𐔿",
        "𐕀",
        "𐕁",
        "𐕃",
        "𐕄",
        "𐕅",
        "𐕆",
        "𐕇",
        "𐕊",
        "𐕋",
        "𐕌",
        "𐕍",
        "𐕎",
        "𐕏",
        "𐕐",
        "𐕑",
        "𐕕",
        "𐕖",
        "𐕘",
        "𐕛",
        "𐕝",
        "𐕱",
        "𐕳",
        "𐕵",
        "𐕷",
        "𐕸",
        "𐕺",
        "𐖀",
        "𐖁",
        "𐖄",
        "𐖇",
        "𐖊",
        "𐖌",
        "𐖍",
        "𐖎",
        "𐖑",
        "𐖒",
        "𐖘",
        "𐖚",
        "𐖜",
        "𐖞",
        "𐖟",
        "𐖡",
        "𐖧",
        "𐖨",
        "𐖮",
        "𐖳",
        "𐖴",
        "𐖵",
        "𐖸",
        "𐖹",
        "𐘀",
        "𐘕",
        "𐘖",
        "𐘧",
        "𐘭",
        "𐘯",
        "𐘰",
        "𐘱",
        "𐘲",
        "𐘳",
        "𐙒",
        "𐙙",
        "𐙧",
        "𐚆",
        "𐚞",
        "𐜍",
        "𐜏",
        "𐝂",
        "𐝃",
        "𐝄",
        "𐝅",
        "𐝈",
        "𐞇",
        "𐞏",
        "𐞐",
        "𐞑",
        "𐞔",
        "𐞕",
        "𐞚",
        "𐞛",
        "𐞝",
        "𐞳",
        "𐞴",
        "𐞵",
        "𐞺",
        "𐡄",
        "𐡇",
        "𐡌",
        "𐡒",
        "𐡔",
        "𐡕",
        "𐡗",
        "𐡚",
        "𐡛",
        "𐡜",
        "𐡝",
        "𐡟",
        "𐡥",
        "𐡨",
        "𐡺",
        "𐢁",
        "𐢅",
        "𐢆",
        "𐢇",
        "𐢌",
        "𐢍",
        "𐢏",
        "𐢐",
        "𐢒",
        "𐢓",
        "𐢘",
        "𐢜",
        "𐢝",
        "𐢮",
        "𐣣",
        "𐣧",
        "𐣨",
        "𐣪",
        "𐣰",
        "𐣱",
        "𐣵",
        "𐣽",
        "𐤀",
        "𐤂",
        "𐤃",
        "𐤄",
        "𐤇",
        "𐤑",
        "𐤗",
        "𐤚",
        "𐤠",
        "𐤡",
        "𐤧",
        "𐤪",
        "𐤫",
        "𐤬",
        "𐤭",
        "𐤱",
        "𐤸",
        "𐤿",
        "𐦁",
        "𐦦",
        "𐦧",
        "𐦬",
        "𐦮",
        "𐦯",
        "𐦰",
        "𐦲",
        "𐦷",
        "𐦾",
        "𐨡",
        "𐨢",
        "𐨤",
        "𐨪",
        "𐨴",
        "𐩃",
        "𐩄",
        "𐩅",
        "𐩶",
        "𐪀",
        "𐪁",
        "𐪃",
        "𐪄",
        "𐪐",
        "𐪔",
        "𐪕",
        "𐪖",
        "𐪜",
        "𐫀",
        "𐫃",
        "𐫄",
        "𐫉",
        "𐫊",
        "𐫋",
        "𐫌",
        "𐫎",
        "𐬗",
        "𐬘",
        "𐬜",
        "𐬞",
        "𐬟",
        "𐬮",
        "𐬼",
        "𐬽",
        "𐭅",
        "𐭋",
        "𐭎",
        "𐭏",
        "𐭒",
        "𐭓",
        "𐭜",
        "𐭟",
        "𐭠",
        "𐭡",
        "𐭩",
        "𐭯",
        "𐭲",
        "𐭼",
        "𐭾",
        "𐮀",
        "𐮈",
        "𐮊",
        "𐮋",
        "𐮌",
        "𐮎",
        "𐮏",
        "𐮑",
        "𐰏",
        "𐰑",
        "𐰶",
        "𐰷",
        "𐰽",
        "𐰿",
        "𐲀",
        "𐲁",
        "𐲆",
        "𐲘",
        "𐲛",
        "𐲜",
        "𐲝",
        "𐲟",
        "𐲢",
        "𐲣",
        "𐲦",
        "𐲯",
        "𐳀",
        "𐳁",
        "𐳆",
        "𐳛",
        "𐳜",
        "𐳝",
        "𐳞",
        "𐳟",
        "𐳢",
        "𐳣",
        "𐳭",
        "𐳯",
        "𐴳",
        "𐴴",
        "𐴶",
        "𐴸",
        "𐴹",
        "𐹡",
        "𐹣",
        "𐹤",
        "𐹩",
        "𐹮",
        "𐺃",
        "𐺅",
        "𐺋",
        "𐺌",
        "𐺍",
        "𐺎",
        "𐺗",
        "𐺘",
        "𐺙",
        "𐺢",
        "𐺨",
        "𐺩",
        "𐺱",
        "𐼁",
        "𐼋",
        "𐼌",
        "𐼍",
        "𐼑",
        "𐼔",
        "𐼕",
        "𐼗",
        "𐼜",
        "𐼞",
        "𐼻",
        "𐽗",
        "𐽳",
        "𐽶",
        "𐽾",
        "𐾇",
        "𐾈",
        "𐾉",
        "𐾰",
        "𐾲",
        "𐾳",
        "𐾴",
        "𐾵",
        "𐾹",
        "𐾻",
        "𐾽",
        "𐿉",
        "𐿊",
        "𐿡",
        "𐿣",
        "𐿪",
        "𐿫",
        "𐿬",
        "𐿭",
        "𐿯",
        "𐿰",
        "𐿳",
        "𐿶",
        "𑀘",
        "𑀚",
        "𑀛",
        "𑀥",
        "𑀴",
        "𑀶",
        "𑁘",
        "𑁙",
        "𑁠",
        "𑁥",
        "𑁬",
        "𑁭",
        "𑁮",
        "𑂉",
        "𑂊",
        "𑂙",
        "𑂚",
        "𑂩",
        "𑂯",
        "𑃁",
        "𑃒",
        "𑃔",
        "𑃖",
        "𑃘",
        "𑃝",
        "𑃟",
        "𑃢",
        "𑃣",
        "𑃥",
        "𑃦",
        "𑃴",
        "𑃹",
        "𑄸",
        "𑅓",
        "𑅕",
        "𑅞",
        "𑅟",
        "𑅥",
        "𑅭",
        "𑅯",
        "𑅰",
        "𑇘",
        "𑈆",
        "𑊁",
        "𑊦",
        "𑊲",
        "𑊴",
        "𑊵",
        "𑊻",
        "𑋊",
        "𑋕",
        "𑋙",
        "𑋚",
        "𑋛",
        "𑋰",
        "𑋲",
        "𑋳",
        "𑋶",
        "𑑊",
        "𑑑",
        "𑖝",
        "𑙧",
        "𑚂",
        "𑚃",
        "𑚆",
        "𑚇",
        "𑚕",
        "𑚟",
        "𑚩",
        "𑛃",
        "𑛆",
        "𑛈",
        "𑛉",
        "𑜃",
        "𑜆",
        "𑜈",
        "𑜉",
        "𑜑",
        "𑜰",
        "𑜽",
        "𑠂",
        "𑠃",
        "𑢥",
        "𑢧",
        "𑢫",
        "𑢮",
        "𑣁",
        "𑣂",
        "𑣃",
        "𑣅",
        "𑣆",
        "𑣉",
        "𑣊",
        "𑣎",
        "𑣏",
        "𑣒",
        "𑣕",
        "𑣖",
        "𑣚",
        "𑤧",
        "𑥐",
        "𑥑",
        "𑥗",
        "𑦺",
        "𑦿",
        "𑧡",
        "𑪜",
        "𑪰",
        "𑪱",
        "𑪲",
        "𑪳",
        "𑪴",
        "𑪵",
        "𑪼",
        "𑪽",
        "𑪾",
        "𑪿",
        "𑫁",
        "𑫃",
        "𑫄",
        "𑫇",
        "𑫉",
        "𑫌",
        "𑫍",
        "𑫎",
        "𑫑",
        "𑫒",
        "𑫓",
        "𑫖",
        "𑫘",
        "𑫙",
        "𑫡",
        "𑫢",
        "𑫤",
        "𑬀",
        "𑬁",
        "𑬉",
        "𑰔",
        "𑰖",
        "𑰚",
        "𑰛",
        "𑰝",
        "𑰥",
        "𑰪",
        "𑱅",
        "𑱑",
        "𑱒",
        "𑱗",
        "𑱚",
        "𑱜",
        "𑱺",
        "𑱿",
        "𑲀",
        "𑲁",
        "𑲃",
        "𑲅",
        "𑲇",
        "𑲋",
        "𑴂",
        "𑴅",
        "𑴆",
        "𑴎",
        "𑵐",
        "𑵑",
        "𑵒",
        "𑵓",
        "𑵔",
        "𑵕",
        "𑵗",
        "𑵘",
        "𑵙",
        "𑻱",
        "𑽐",
        "𒾙",
        "𒾚",
        "𖩀",
        "𖩁",
        "𖩂",
        "𖩃",
        "𖩆",
        "𖩇",
        "𖩈",
        "𖩉",
        "𖩊",
        "𖩌",
        "𖩎",
        "𖩐",
        "𖩓",
        "𖩗",
        "𖩘",
        "𖩚",
        "𖩝",
        "𖪬",
        "𖫂",
        "𖫈",
        "𖫐",
        "𖫑",
        "𖫖",
        "𖫗",
        "𖫘",
        "𖫙",
        "𖫝",
        "𖫞",
        "𖫟",
        "𖫠",
        "𖫢",
        "𖫣",
        "𖫥",
        "𖫦",
        "𖬀",
        "𖬁",
        "𖬂",
        "𖬄",
        "𖬇",
        "𖬈",
        "𖬉",
        "𖬊",
        "𖬌",
        "𖬎",
        "𖬓",
        "𖬔",
        "𖬕",
        "𖬖",
        "𖬗",
        "𖬘",
        "𖬙",
        "𖬜",
        "𖬝",
        "𖬟",
        "𖬠",
        "𖬡",
        "𖬢",
        "𖬣",
        "𖬥",
        "𖬦",
        "𖬧",
        "𖬨",
        "𖬬",
        "𖬭",
        "𖬮",
        "𖬯",
        "𖬺",
        "𖬼",
        "𖬽",
        "𖬿",
        "𖭂",
        "𖭃",
        "𖭑",
        "𖭒",
        "𖭕",
        "𖭖",
        "𖭗",
        "𖭘",
        "𖭙",
        "𖭜",
        "𖭞",
        "𖭠",
        "𖭣",
        "𖭤",
        "𖭧",
        "𖭭",
        "𖭯",
        "𖭰",
        "𖭱",
        "𖭲",
        "𖭳",
        "𖭴",
        "𖭵",
        "𖭶",
        "𖮇",
        "𖮏",
        "𖹔",
        "𖹨",
        "𖹲",
        "𖹴",
        "𖹷",
        "𖹹",
        "𖺀",
        "𖺁",
        "𖺂",
        "𖺃",
        "𖺅",
        "𖺆",
        "𖺋",
        "𖺌",
        "𖺘",
        "𖼀",
        "𖼁",
        "𖼂",
        "𖼄",
        "𖼅",
        "𖼇",
        "𖼐",
        "𖼖",
        "𖼞",
        "𖼟",
        "𖼠",
        "𖼦",
        "𖼪",
        "𖼫",
        "𖼮",
        "𖼰",
        "𖼱",
        "𖼲",
        "𖼴",
        "𖼵",
        "𖼺",
        "𖼻",
        "𖼼",
        "𖼿",
        "𖽂",
        "𖽇",
        "𖽈",
        "𖽉",
        "𖽊",
        "𖿡",
        "𘲠",
        "𘲬",
        "𚿳",
        "𚿹",
        "𛅲",
        "𛅴",
        "𛅻",
        "𛅼",
        "𛅽",
        "𛅾",
        "𛆃",
        "𛆄",
        "𛆇",
        "𛆈",
        "𛆋",
        "𛆎",
        "𛆑",
        "𛆓",
        "𛆞",
        "𛆡",
        "𛆣",
        "𛆤",
        "𛆧",
        "𛆴",
        "𛆶",
        "𛆺",
        "𛇀",
        "𛇃",
        "𛇆",
        "𛇇",
        "𛇈",
        "𛇉",
        "𛇮",
        "𛇯",
        "𛇳",
        "𛇸",
        "𛇺",
        "𛇽",
        "𛈔",
        "𛈕",
        "𛈛",
        "𛈤",
        "𛈿",
        "𛉁",
        "𛉂",
        "𛉄",
        "𛉅",
        "𛉖",
        "𛉟",
        "𛉥",
        "𛉺",
        "𛊖",
        "𛋃",
        "𛰔",
        "𛰖",
        "𛰲",
        "𛰴",
        "𛰶",
        "𛰸",
        "𛰻",
        "𛰽",
        "𛰿",
        "𛱑",
        "𛱒",
        "𛱓",
        "𛱕",
        "𛱖",
        "𛱢",
        "𛲃",
        "𛲇",
        "𛲓",
        "𛲗",
        "𜽗",
        "𜽚",
        "𜽿",
        "𝀇",
        "𝀍",
        "𝀐",
        "𝀚",
        "𝀠",
        "𝀰",
        "𝁇",
        "𝁈",
        "𝁐",
        "𝁑",
        "𝁾",
        "𝂞",
        "𝂣",
        "𝂫",
        "𝂵",
        "𝃐",
        "𝃔",
        "𝃬",
        "𝃮",
        "𝄰",
        "𝄱",
        "𝄿",
        "𝅖",
        "𝅘𝅥𝅮",
        "𝅘𝅥𝅯",
        "𝅘𝅥𝅰",
        "𝅘𝅥𝅱",
        "𝅘𝅥𝅲",
        "𝆥",
        "𝆨",
        "𝆩",
        "𝇕",
        "𝇖",
        "𝇞",
        "𝇦",
        "𝇧",
        "𝇪",
        "𝈂",
        "𝈆",
        "𝈇",
        "𝈋",
        "𝈌",
        "𝈑",
        "𝈒",
        "𝈓",
        "𝈕",
        "𝈖",
        "𝈗",
        "𝈡",
        "𝈣",
        "𝈤",
        "𝈥",
        "𝈨",
        "𝈪",
        "𝈫",
        "𝈬",
        "𝈮",
        "𝈰",
        "𝈲",
        "𝋅",
        "𝋆",
        "𝋊",
        "𝋋",
        "𝐈",
        "𝐟",
        "𝐢",
        "𝐣",
        "𝐥",
        "𝐭",
        "𝐿",
        "𝑖",
        "𝒍",
        "𝒕",
        "𝓈",
        "𝓼",
        "𝔢",
        "𝔨",
        "𝔬",
        "𝔭",
        "𝔮",
        "𝔯",
        "𝔱",
        "𝔵",
        "𝕊",
        "𝕛",
        "𝕤",
        "𝕫",
        "𝕮",
        "𝖊",
        "𝖐",
        "𝖔",
        "𝖕",
        "𝖖",
        "𝖗",
        "𝖙",
        "𝖝",
        "𝖠",
        "𝖡",
        "𝖢",
        "𝖣",
        "𝖤",
        "𝖥",
        "𝖦",
        "𝖧",
        "𝖪",
        "𝖫",
        "𝖬",
        "𝖭",
        "𝖮",
        "𝖯",
        "𝖱",
        "𝖲",
        "𝖴",
        "𝖶",
        "𝖷",
        "𝖹",
        "𝖺",
        "𝖻",
        "𝖼",
        "𝖽",
        "𝖾",
        "𝗀",
        "𝗁",
        "𝗄",
        "𝗇",
        "𝗈",
        "𝗉",
        "𝗊",
        "𝗋",
        "𝗌",
        "𝗎",
        "𝗏",
        "𝗑",
        "𝗒",
        "𝗓",
        "𝗜",
        "𝗳",
        "𝗶",
        "𝗷",
        "𝗹",
        "𝘁",
        "𝘓",
        "𝘪",
        "𝙡",
        "𝙩",
        "𝚉",
        "𝚏",
        "𝚝",
        "𝚰",
        "𝛇",
        "𝛋",
        "𝛜",
        "𝜆",
        "𝜏",
        "𝝞",
        "𝝵",
        "𝝹",
        "𝞊",
        "𝟋",
        "𝟏",
        "𝟚",
        "𝟝",
        "𝟢",
        "𝟤",
        "𝟥",
        "𝟦",
        "𝟧",
        "𝟨",
        "𝟩",
        "𝟪",
        "𝟫",
        "𝟭",
        "𝟸",
        "𝟹",
        "𝟺",
        "𝟻",
        "𝟽",
        "𝤎",
        "𝦙",
        "𝧻",
        "𝧼",
        "𝧾",
        "𝼁",
        "𝼂",
        "𝼃",
        "𝼅",
        "𝼇",
        "𝼉",
        "𝼌",
        "𝼍",
        "𝼎",
        "𝼏",
        "𝼑",
        "𝼓",
        "𝼕",
        "𝼖",
        "𝼚",
        "𝼛",
        "𝼞",
        "𞀰",
        "𞀱",
        "𞀲",
        "𞀵",
        "𞀷",
        "𞀸",
        "𞀺",
        "𞀻",
        "𞀼",
        "𞀾",
        "𞀿",
        "𞁁",
        "𞁃",
        "𞁄",
        "𞁅",
        "𞁇",
        "𞁈",
        "𞁉",
        "𞁋",
        "𞁎",
        "𞁑",
        "𞁒",
        "𞁓",
        "𞁖",
        "𞁘",
        "𞁙",
        "𞁛",
        "𞁜",
        "𞁞",
        "𞁟",
        "𞁡",
        "𞁢",
        "𞁣",
        "𞁦",
        "𞁫",
        "𞄉",
        "𞄍",
        "𞄎",
        "𞄏",
        "𞄑",
        "𞄓",
        "𞄕",
        "𞄖",
        "𞄗",
        "𞄜",
        "𞄡",
        "𞄢",
        "𞄧",
        "𞄩",
        "𞄪",
        "𞄬",
        "𞅀",
        "𞅄",
        "𞅆",
        "𞅇",
        "𞅈",
        "𞊑",
        "𞊞",
        "𞊣",
        "𞊤",
        "𞊥",
        "𞊦",
        "𞊧",
        "𞊨",
        "𞊩",
        "𞊪",
        "𞊫",
        "𞊬",
        "𞓐",
        "𞓒",
        "𞓔",
        "𞓕",
        "𞓛",
        "𞓝",
        "𞓟",
        "𞓤",
        "𞓫",
        "𞓰",
        "𞓲",
        "𞓳",
        "𞓵",
        "𞓶",
        "𞓷",
        "𞓸",
        "𞓹",
        "𞠓",
        "𞠔",
        "𞠕",
        "𞠖",
        "𞠛",
        "𞠜",
        "𞠝",
        "𞠞",
        "𞠳",
        "𞡋",
        "𞡌",
        "𞡍",
        "𞡪",
        "𞡳",
        "𞡵",
        "𞡽",
        "𞢂",
        "𞢉",
        "𞢐",
        "𞢖",
        "𞢭",
        "𞢶",
        "𞣋",
        "𞣌",
        "𞣍",
        "𞤀",
        "𞤁",
        "𞤆",
        "𞤈",
        "𞤊",
        "𞤋",
        "𞤌",
        "𞤎",
        "𞤐",
        "𞤑",
        "𞤕",
        "𞤖",
        "𞤘",
        "𞤙",
        "𞤠",
        "𞤢",
        "𞤣",
        "𞤤",
        "𞤥",
        "𞤨",
        "𞤩",
        "𞤪",
        "𞤬",
        "𞤭",
        "𞤮",
        "𞤯",
        "𞤰",
        "𞤱",
        "𞤲",
        "𞤳",
        "𞤴",
        "𞤷",
        "𞤸",
        "𞤺",
        "𞤻",
        "𞤾",
        "𞥀",
        "𞥂",
        "𞥐",
        "𞥓",
        "𞥔",
        "𞥙",
        "𞥟",
        "𞱵",
        "𞲅",
        "𞲉",
        "𞲊",
        "𞲲",
        "𞴯",
        "𞸁",
        "𞸂",
        "𞸃",
        "𞸅",
        "𞸆",
        "𞸇",
        "𞸈",
        "𞸉",
        "𞸊",
        "𞸋",
        "𞸍",
        "𞸏",
        "𞸐",
        "𞸒",
        "𞸓",
        "𞸕",
        "𞸖",
        "𞸗",
        "𞸘",
        "𞸚",
        "𞸛",
        "𞸜",
        "𞸝",
        "𞸞",
        "𞸟",
        "𞸡",
        "𞸧",
        "𞸩",
        "𞸫",
        "𞸭",
        "𞸰",
        "𞸲",
        "𞸵",
        "𞸶",
        "𞸷",
        "𞹏",
        "𞹛",
        "𞹭",
        "𞹯",
        "𞹰",
        "𞹲",
        "𞹵",
        "𞹶",
        "𞹻",
        "𞹼",
        "𞹾",
        "𞺁",
        "𞺃",
        "𞺅",
        "𞺆",
        "𞺋",
        "𞺍",
        "𞺏",
        "𞺐",
        "𞺒",
        "𞺓",
        "𞺕",
        "𞺖",
        "𞺘",
        "𞺛",
        "🌂",
        "🎖",
        "🔈",
        "🕨",
        "🕯",
        "🕴",
        "🖞",
        "🖟",
        "🖠",
        "🖡",
        "🚦",
        "🜱",
        "🜶",
        "🝡",
        "🞀",
        "🞂",
        "🟰",
        "🠕",
        "🠗",
        "🠸",
        "🠺",
        "🢔",
        "🢖",
        "🢝",
        "🢭",
        "🢱",
        "🤈",
        "🤉",
        "🤊",
        "🤋",
        "🧎",
        "🪜",
        "🮊",
        "🮰",
        "🮷",
        "🮸",
        "🯳"
    ],
    [
        "#",
        "$",
        "%",
        "&",
        "+",
        "-",
        "/",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "=",
        "?",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "\\",
        "^",
        "_",
        "a",
        "b",
        "c",
        "d",
        "e",
        "g",
        "h",
        "j",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "¢",
        "£",
        "¥",
        "§",
        "¬",
        "¯",
        "±",
        "µ",
        "¿",
        "À",
        "Á",
        "Â",
        "Ã",
        "Ä",
        "Å",
        "Ç",
        "È",
        "É",
        "Ê",
        "Ë",
        "Ñ",
        "Ò",
        "Ó",
        "Ô",
        "Õ",
        "Ö",
        "×",
        "Ø",
        "Ù",
        "Ú",
        "Û",
        "Ü",
        "Ý",
        "Þ",
        "ß",
        "à",
        "á",
        "â",
        "ã",
        "ä",
        "å",
        "ç",
        "è",
        "é",
        "ê",
        "ë",
        "ð",
        "ñ",
        "ò",
        "ó",
        "ô",
        "õ",
        "ö",
        "÷",
        "ø",
        "ù",
        "ú",
        "û",
        "ü",
        "ý",
        "þ",
        "ÿ",
        "Ā",
        "ā",
        "Ă",
        "ă",
        "Ą",
        "ą",
        "Ć",
        "ć",
        "Ĉ",
        "ĉ",
        "Ċ",
        "ċ",
        "Č",
        "č",
        "Ď",
        "Ē",
        "ē",
        "Ĕ",
        "ĕ",
        "Ė",
        "ė",
        "Ę",
        "ę",
        "Ě",
        "ě",
        "Ĝ",
        "ĝ",
        "Ğ",
        "ğ",
        "Ġ",
        "ġ",
        "Ģ",
        "ģ",
        "Ĥ",
        "ĥ",
        "Ĳ",
        "Ĵ",
        "ĵ",
        "Ķ",
        "Ĺ",
        "Ļ",
        "Ľ",
        "Ŀ",
        "Ń",
        "ń",
        "Ņ",
        "ņ",
        "Ň",
        "ň",
        "Ŋ",
        "ŋ",
        "Ō",
        "ō",
        "Ŏ",
        "ŏ",
        "Ő",
        "ő",
        "Ŕ",
        "ŕ",
        "Ŗ",
        "ŗ",
        "Ř",
        "ř",
        "Ś",
        "ś",
        "Ŝ",
        "ŝ",
        "Ş",
        "ş",
        "Š",
        "š",
        "Ţ",
        "Ť",
        "Ŧ",
        "Ũ",
        "ũ",
        "Ū",
        "ū",
        "Ŭ",
        "ŭ",
        "Ů",
        "ů",
        "Ű",
        "ű",
        "Ų",
        "ų",
        "Ŵ",
        "ŵ",
        "Ŷ",
        "ŷ",
        "Ÿ",
        "Ź",
        "ź",
        "Ż",
        "ż",
        "Ž",
        "ž",
        "Ƃ",
        "ƃ",
        "Ɔ",
        "Ƌ",
        "ƌ",
        "ƍ",
        "Ǝ",
        "Ə",
        "Ɛ",
        "ƒ",
        "Ɣ",
        "Ƙ",
        "ƛ",
        "Ɯ",
        "ƞ",
        "Ɵ",
        "ƥ",
        "Ʀ",
        "Ƨ",
        "ƨ",
        "Ʃ",
        "ƪ",
        "Ʈ",
        "Ʊ",
        "Ʋ",
        "Ƶ",
        "ƶ",
        "Ʒ",
        "Ƹ",
        "ƹ",
        "ƺ",
        "ƻ",
        "Ƽ",
        "ƽ",
        "ƾ",
        "ƿ",
        "ǂ",
        "Ǎ",
        "ǎ",
        "Ǒ",
        "ǒ",
        "Ǔ",
        "ǔ",
        "Ǖ",
        "ǖ",
        "Ǘ",
        "ǘ",
        "Ǚ",
        "ǚ",
        "Ǜ",
        "ǜ",
        "ǝ",
        "Ǟ",
        "ǟ",
        "Ǡ",
        "ǡ",
        "Ǧ",
        "ǧ",
        "Ǩ",
        "Ǫ",
        "ǫ",
        "Ǭ",
        "ǭ",
        "Ǯ",
        "ǯ",
        "ǰ",
        "Ǵ",
        "ǵ",
        "Ƿ",
        "Ǹ",
        "ǹ",
        "Ǻ",
        "ǻ",
        "Ǿ",
        "ǿ",
        "Ȁ",
        "ȁ",
        "Ȃ",
        "ȃ",
        "Ȅ",
        "ȅ",
        "Ȇ",
        "ȇ",
        "Ȉ",
        "ȉ",
        "Ȍ",
        "ȍ",
        "Ȏ",
        "ȏ",
        "Ȑ",
        "ȑ",
        "Ȓ",
        "ȓ",
        "Ȕ",
        "ȕ",
        "Ȗ",
        "ȗ",
        "Ș",
        "ș",
        "Ț",
        "Ȝ",
        "ȝ",
        "Ȟ",
        "ȟ",
        "Ƞ",
        "Ȣ",
        "ȣ",
        "Ȥ",
        "ȥ",
        "Ȧ",
        "ȧ",
        "Ȩ",
        "ȩ",
        "Ȫ",
        "ȫ",
        "Ȭ",
        "ȭ",
        "Ȯ",
        "ȯ",
        "Ȱ",
        "ȱ",
        "Ȳ",
        "ȳ",
        "ȷ",
        "Ⱥ",
        "Ȼ",
        "ȼ",
        "Ⱦ",
        "ȿ",
        "ɀ",
        "Ɂ",
        "ɂ",
        "Ʌ",
        "Ɇ",
        "ɐ",
        "ɑ",
        "ɒ",
        "ɓ",
        "ɔ",
        "ɕ",
        "ɘ",
        "ə",
        "ɛ",
        "ɜ",
        "ɞ",
        "ɡ",
        "ɢ",
        "ɣ",
        "ɤ",
        "ɥ",
        "ɦ",
        "ɧ",
        "ɫ",
        "ɮ",
        "ɯ",
        "ɰ",
        "ɱ",
        "ɴ",
        "ɵ",
        "ɸ",
        "ɹ",
        "ɺ",
        "ɼ",
        "ɽ",
        "ɾ",
        "ɿ",
        "ʀ",
        "ʁ",
        "ʂ",
        "ʃ",
        "ʅ",
        "ʊ",
        "ʋ",
        "ʌ",
        "ʍ",
        "ʎ",
        "ʏ",
        "ʑ",
        "ʒ",
        "ʓ",
        "ʔ",
        "ʕ",
        "ʖ",
        "ʗ",
        "ʘ",
        "ʙ",
        "ʚ",
        "ʜ",
        "ʝ",
        "ʟ",
        "ʡ",
        "ʢ",
        "ʬ",
        "ʭ",
        "Ί",
        "ΐ",
        "Α",
        "Β",
        "Γ",
        "Δ",
        "Ε",
        "Ζ",
        "Η",
        "Θ",
        "Κ",
        "Λ",
        "Μ",
        "Ν",
        "Ξ",
        "Ο",
        "Π",
        "Ρ",
        "Σ",
        "Τ",
        "Υ",
        "Φ",
        "Χ",
        "Ψ",
        "Ω",
        "Ϋ",
        "ά",
        "έ",
        "ή",
        "ΰ",
        "α",
        "β",
        "γ",
        "δ",
        "ε",
        "ζ",
        "η",
        "θ",
        "λ",
        "μ",
        "ν",
        "ξ",
        "ο",
        "π",
        "ρ",
        "ς",
        "τ",
        "υ",
        "φ",
        "χ",
        "ψ",
        "ϋ",
        "ό",
        "ύ",
        "ϛ",
        "Ѐ",
        "Ё",
        "Ѓ",
        "Є",
        "Ѕ",
        "Ј",
        "Ќ",
        "Ѝ",
        "Ў",
        "Џ",
        "А",
        "Б",
        "В",
        "Г",
        "Е",
        "З",
        "И",
        "Й",
        "К",
        "Л",
        "М",
        "Н",
        "О",
        "П",
        "Р",
        "С",
        "Т",
        "У",
        "Х",
        "Ч",
        "Ь",
        "Э",
        "Я",
        "а",
        "б",
        "в",
        "е",
        "ж",
        "з",
        "и",
        "й",
        "л",
        "м",
        "н",
        "о",
        "п",
        "р",
        "с",
        "т",
        "у",
        "ф",
        "х",
        "ц",
        "ч",
        "ш",
        "ь",
        "э",
        "я",
        "ѐ",
        "ё",
        "є",
        "ѕ",
        "ј",
        "ѝ",
        "ў",
        "џ",
        "Ѳ",
        "ѳ",
        "Ҏ",
        "ҏ",
        "Ґ",
        "ґ",
        "ғ",
        "Ҕ",
        "Ҙ",
        "ҙ",
        "қ",
        "Ҝ",
        "ҝ",
        "ҟ",
        "ҡ",
        "Ҫ",
        "ҫ",
        "Ҭ",
        "ҭ",
        "Ү",
        "ү",
        "Ұ",
        "ұ",
        "Ҹ",
        "ҹ",
        "Һ",
        "һ",
        "ӂ",
        "Ӄ",
        "Ӈ",
        "ӈ",
        "Ӌ",
        "ӌ",
        "Ӑ",
        "ӑ",
        "Ӓ",
        "ӓ",
        "Ӗ",
        "ӗ",
        "Ә",
        "ә",
        "Ӛ",
        "ӛ",
        "ӝ",
        "Ӟ",
        "ӟ",
        "Ӡ",
        "ӡ",
        "Ӣ",
        "ӣ",
        "Ӥ",
        "ӥ",
        "Ӧ",
        "ӧ",
        "Ө",
        "ө",
        "Ӫ",
        "ӫ",
        "Ӭ",
        "ӭ",
        "Ӯ",
        "ӯ",
        "Ӱ",
        "ӱ",
        "Ӳ",
        "ӳ",
        "Ӵ",
        "ӵ",
        "Ӷ",
        "ӻ",
        "Ӽ",
        "ӽ",
        "Ӿ",
        "ӿ",
        "Ԁ",
        "Ԍ",
        "ԍ",
        "Ԑ",
        "ԑ",
        "Ԓ",
        "ԓ",
        "Ԛ",
        "ԛ",
        "Ԝ",
        "ԝ",
        "Ԟ",
        "ԟ",
        "Ա",
        "Բ",
        "Ե",
        "Զ",
        "Է",
        "Ը",
        "Ի",
        "Ծ",
        "Կ",
        "Հ",
        "Ձ",
        "Ճ",
        "Յ",
        "Ն",
        "Շ",
        "Ո",
        "Չ",
        "Պ",
        "Ջ",
        "Ս",
        "Վ",
        "Տ",
        "Ր",
        "Ց",
        "Փ",
        "Ք",
        "Օ",
        "Ֆ",
        "՟",
        "ՠ",
        "ա",
        "բ",
        "ե",
        "ը",
        "թ",
        "ի",
        "ծ",
        "կ",
        "հ",
        "ձ",
        "ճ",
        "յ",
        "շ",
        "ո",
        "չ",
        "պ",
        "ջ",
        "ռ",
        "ս",
        "ր",
        "ց",
        "օ",
        "ֆ",
        "֊",
        "֏",
        "֐",
        "־",
        "א",
        "ב",
        "ד",
        "ה",
        "ח",
        "ט",
        "ך",
        "כ",
        "ל",
        "ם",
        "מ",
        "ס",
        "ע",
        "ף",
        "פ",
        "ץ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת",
        "߈",
        "Ⴀ",
        "Ⴃ",
        "Ⴄ",
        "Ⴈ",
        "Ⴉ",
        "Ⴌ",
        "Ⴑ",
        "Ⴒ",
        "Ⴔ",
        "Ⴕ",
        "Ⴖ",
        "Ⴗ",
        "Ⴘ",
        "Ⴙ",
        "Ⴚ",
        "Ⴜ",
        "Ⴝ",
        "Ⴞ",
        "Ⴟ",
        "Ⴡ",
        "Ⴢ",
        "Ⴣ",
        "Ⴤ",
        "Ⴧ",
        "Ⴭ",
        "ა",
        "ბ",
        "გ",
        "დ",
        "ე",
        "ვ",
        "ზ",
        "თ",
        "ი",
        "კ",
        "მ",
        "ნ",
        "ო",
        "პ",
        "ჟ",
        "რ",
        "ს",
        "ტ",
        "უ",
        "ფ",
        "ქ",
        "ღ",
        "ყ",
        "შ",
        "ჩ",
        "ც",
        "ძ",
        "წ",
        "ჭ",
        "ხ",
        "ჰ",
        "ჲ",
        "ჳ",
        "ჴ",
        "ჵ",
        "ჶ",
        "ჷ",
        "ჸ",
        "ჹ",
        "ჺ",
        "ჽ",
        "ჾ",
        "ჿ",
        "ᗡ",
        "ᗺ",
        "ᘔ",
        "ᚢ",
        "ᚣ",
        "ᚤ",
        "ᚥ",
        "ᚬ",
        "ᚯ",
        "ᚰ",
        "ᚶ",
        "ᚷ",
        "ᚸ",
        "ᚺ",
        "ᚻ",
        "ᚼ",
        "ᚾ",
        "ᛀ",
        "ᛄ",
        "ᛅ",
        "ᛇ",
        "ᛉ",
        "ᛋ",
        "ᛎ",
        "ᛏ",
        "ᛖ",
        "ᛗ",
        "ᛘ",
        "ᛜ",
        "ᛝ",
        "ᛞ",
        "ᛡ",
        "ᛣ",
        "ᛤ",
        "ᛥ",
        "ᛦ",
        "ᛨ",
        "᛭",
        "ᛮ",
        "ᛯ",
        "ᛰ",
        "ᛱ",
        "ᛲ",
        "ᛶ",
        "ᛷ",
        "ᛸ",
        "ᴀ",
        "ᴄ",
        "ᴅ",
        "ᴇ",
        "ᴊ",
        "ᴋ",
        "ᴍ",
        "ᴏ",
        "ᴘ",
        "ᴚ",
        "ᴛ",
        "ᴜ",
        "ᴠ",
        "ᴡ",
        "ᴢ",
        "ᵮ",
        "ᵵ",
        "ᵶ",
        "ᵷ",
        "ᶀ",
        "ᶄ",
        "ᶈ",
        "ᶉ",
        "ᶊ",
        "ᶋ",
        "ᶌ",
        "ᶔ",
        "ᶘ",
        "ᶚ",
        "Ḁ",
        "ḁ",
        "Ḃ",
        "ḃ",
        "Ḅ",
        "ḅ",
        "Ḇ",
        "ḇ",
        "Ḉ",
        "ḉ",
        "Ḋ",
        "ḋ",
        "Ḍ",
        "ḍ",
        "Ḏ",
        "ḏ",
        "Ḑ",
        "ḑ",
        "Ḓ",
        "ḓ",
        "Ḕ",
        "ḕ",
        "Ḗ",
        "ḗ",
        "Ḙ",
        "ḙ",
        "Ḛ",
        "ḛ",
        "Ḝ",
        "ḝ",
        "Ḟ",
        "Ḡ",
        "ḡ",
        "Ḣ",
        "ḣ",
        "Ḥ",
        "ḥ",
        "Ḧ",
        "ḧ",
        "Ḩ",
        "ḩ",
        "Ḫ",
        "ḫ",
        "Ḱ",
        "Ḳ",
        "Ḵ",
        "Ḷ",
        "Ḹ",
        "Ḻ",
        "Ḽ",
        "Ḿ",
        "ḿ",
        "Ṁ",
        "ṁ",
        "Ṃ",
        "ṃ",
        "Ṅ",
        "ṅ",
        "Ṇ",
        "ṇ",
        "Ṉ",
        "ṉ",
        "Ṋ",
        "ṋ",
        "Ṍ",
        "ṍ",
        "Ṏ",
        "ṏ",
        "Ṑ",
        "ṑ",
        "Ṓ",
        "ṓ",
        "Ṕ",
        "ṕ",
        "Ṗ",
        "ṗ",
        "Ṙ",
        "ṙ",
        "Ṛ",
        "ṛ",
        "Ṝ",
        "ṝ",
        "Ṟ",
        "ṟ",
        "Ṡ",
        "ṡ",
        "Ṣ",
        "ṣ",
        "Ṥ",
        "ṥ",
        "Ṧ",
        "ṧ",
        "Ṩ",
        "ṩ",
        "Ṫ",
        "Ṭ",
        "Ṯ",
        "Ṱ",
        "Ṳ",
        "ṳ",
        "Ṵ",
        "ṵ",
        "Ṷ",
        "ṷ",
        "Ṹ",
        "ṹ",
        "Ṻ",
        "ṻ",
        "Ṽ",
        "ṽ",
        "Ṿ",
        "ṿ",
        "Ẁ",
        "ẁ",
        "Ẃ",
        "ẃ",
        "Ẅ",
        "ẅ",
        "Ẇ",
        "ẇ",
        "Ẉ",
        "ẉ",
        "Ẋ",
        "ẋ",
        "Ẍ",
        "ẍ",
        "Ẏ",
        "ẏ",
        "Ẑ",
        "ẑ",
        "Ẓ",
        "ẓ",
        "Ẕ",
        "ẕ",
        "ẖ",
        "ẘ",
        "ẙ",
        "ẚ",
        "ẞ",
        "ẟ",
        "Ạ",
        "ạ",
        "Ả",
        "ả",
        "Ấ",
        "ấ",
        "Ầ",
        "ầ",
        "Ẩ",
        "ẩ",
        "Ẫ",
        "ẫ",
        "Ậ",
        "ậ",
        "Ắ",
        "ắ",
        "Ằ",
        "ằ",
        "Ẳ",
        "ẳ",
        "Ẵ",
        "ẵ",
        "Ặ",
        "ặ",
        "Ẹ",
        "ẹ",
        "Ẻ",
        "ẻ",
        "Ẽ",
        "ẽ",
        "Ế",
        "ế",
        "Ề",
        "ề",
        "Ể",
        "ể",
        "Ễ",
        "ễ",
        "Ệ",
        "ệ",
        "Ọ",
        "ọ",
        "Ỏ",
        "ỏ",
        "Ố",
        "ố",
        "Ồ",
        "ồ",
        "Ổ",
        "ổ",
        "Ỗ",
        "ỗ",
        "Ộ",
        "ộ",
        "Ụ",
        "ụ",
        "Ủ",
        "ủ",
        "Ỳ",
        "ỳ",
        "Ỵ",
        "ỵ",
        "Ỷ",
        "ỷ",
        "Ỹ",
        "ỹ",
        "Ỽ",
        "Ỿ",
        "ἀ",
        "ἁ",
        "ἂ",
        "ἃ",
        "ἄ",
        "ἆ",
        "ἇ",
        "ἐ",
        "ἑ",
        "ἒ",
        "ἓ",
        "ἔ",
        "ἕ",
        "ἠ",
        "ἡ",
        "ἢ",
        "ἣ",
        "ἤ",
        "ἥ",
        "ἦ",
        "ἧ",
        "ἲ",
        "ἳ",
        "ἴ",
        "ἵ",
        "ὀ",
        "ὁ",
        "ὂ",
        "ὃ",
        "ὄ",
        "ὅ",
        "ὐ",
        "ὑ",
        "ὒ",
        "ὓ",
        "ὔ",
        "ὕ",
        "ὖ",
        "ὗ",
        "ὰ",
        "ά",
        "ὲ",
        "έ",
        "ὴ",
        "ή",
        "ὸ",
        "ό",
        "ὺ",
        "ύ",
        "ᾀ",
        "ᾁ",
        "ᾂ",
        "ᾃ",
        "ᾄ",
        "ᾅ",
        "ᾆ",
        "ᾇ",
        "ᾐ",
        "ᾑ",
        "ᾒ",
        "ᾓ",
        "ᾔ",
        "ᾕ",
        "ᾖ",
        "ᾗ",
        "ᾰ",
        "ᾱ",
        "ᾲ",
        "ᾳ",
        "ᾴ",
        "ᾶ",
        "ᾷ",
        "Ᾰ",
        "Ᾱ",
        "ᾼ",
        "ῂ",
        "ῃ",
        "ῄ",
        "ῆ",
        "ῇ",
        "ῌ",
        "ῠ",
        "ῡ",
        "ῢ",
        "ΰ",
        "ῤ",
        "ῥ",
        "ῦ",
        "ῧ",
        "Ῠ",
        "Ῡ",
        "ῼ",
        "†",
        "‡",
        "‽",
        "₠",
        "₡",
        "₢",
        "₤",
        "₥",
        "₮",
        "₰",
        "₲",
        "₵",
        "₶",
        "₸",
        "₹",
        "₼",
        "₿",
        "Ⅎ",
        "⅁",
        "⅄",
        "⅋",
        "↑",
        "↓",
        "∂",
        "∃",
        "∄",
        "∈",
        "∉",
        "∋",
        "∌",
        "∑",
        "−",
        "∓",
        "∙",
        "∧",
        "∨",
        "∩",
        "∫",
        "∮",
        "∴",
        "∵",
        "≠",
        "≤",
        "≥",
        "⊂",
        "⊃",
        "⊄",
        "⊅",
        "⊆",
        "⊇",
        "⊢",
        "⊤",
        "⊥",
        "⊨",
        "⊻",
        "⊼",
        "⊽",
        "⋃",
        "⏏",
        "⏶",
        "⏷",
        "⏹",
        "⏺",
        "│",
        "┐",
        "┘",
        "┤",
        "╕",
        "╛",
        "╡",
        "■",
        "□",
        "▲",
        "△",
        "▼",
        "▽",
        "◆",
        "◇",
        "○",
        "☰",
        "☱",
        "☲",
        "☳",
        "☴",
        "☵",
        "☶",
        "☷",
        "☹",
        "☺",
        "♂",
        "♠",
        "♡",
        "♢",
        "♣",
        "♤",
        "♥",
        "♦",
        "♧",
        "♪",
        "♯",
        "⚡",
        "⚥",
        "❣",
        "⟘",
        "⥝",
        "ⱦ",
        "Ɑ",
        "Ɐ",
        "Ȿ",
        "Ɀ",
        "ⴀ",
        "ⴃ",
        "ⴄ",
        "ⴅ",
        "ⴇ",
        "ⴈ",
        "ⴉ",
        "ⴊ",
        "ⴋ",
        "ⴌ",
        "ⴍ",
        "ⴐ",
        "ⴑ",
        "ⴒ",
        "ⴓ",
        "ⴔ",
        "ⴕ",
        "ⴖ",
        "ⴗ",
        "ⴘ",
        "ⴙ",
        "ⴚ",
        "ⴛ",
        "ⴜ",
        "ⴞ",
        "ⴟ",
        "ⴠ",
        "ⴡ",
        "ⴢ",
        "ⴣ",
        "ⴤ",
        "ⴥ",
        "⸘",
        "⸮",
        "⸸",
        "⹋",
        "〒",
        "ㄥ",
        "Ꚃ",
        "ꚃ",
        "Ꚇ",
        "ꚇ",
        "Ꚍ",
        "ꚍ",
        "ꚏ",
        "Ꚑ",
        "ꚑ",
        "Ꚕ",
        "ꚕ",
        "Ꜧ",
        "ꜧ",
        "ꜰ",
        "ꜱ",
        "Ꜿ",
        "ꜿ",
        "Ꝛ",
        "Ꝡ",
        "ꝡ",
        "Ꝺ",
        "Ꞁ",
        "ꞔ",
        "ꞩ",
        "ꞯ",
        "Ʞ",
        "Ꞔ",
        "ꭐ",
        "ꭑ",
        "ﬁ",
        "ﬅ",
        "שׂ",
        "אַ",
        "אָ",
        "בּ",
        "פּ",
        "תּ",
        "בֿ",
        "פֿ",
        "＋",
        "𐌰",
        "𐌱",
        "𐌲",
        "𐌳",
        "𐌴",
        "𐌵",
        "𐌶",
        "𐌷",
        "𐌸",
        "𐌺",
        "𐌻",
        "𐌼",
        "𐌽",
        "𐌾",
        "𐌿",
        "𐍀",
        "𐍁",
        "𐍂",
        "𐍃",
        "𐍄",
        "𐍅",
        "𐍆",
        "𐍇",
        "𐍈",
        "𐍉",
        "𐍊"
    ],
    [
        "ʵ",
        "ʷ",
        "˄",
        "˅",
        "˝",
        "˞",
        "˵",
        "˶",
        "Ͳ",
        "ͷ",
        "Ϳ",
        "ϑ",
        "ϒ",
        "ϕ",
        "ϖ",
        "Ϣ",
        "ϣ",
        "Ϧ",
        "Ϯ",
        "ϯ",
        "Ϸ",
        "ϻ",
        "ϼ",
        "Ѡ",
        "ѡ",
        "Ѥ",
        "ѥ",
        "Ѧ",
        "ѧ",
        "Ѩ",
        "ѩ",
        "Ѫ",
        "ѫ",
        "Ѭ",
        "ѭ",
        "Ѱ",
        "ѱ",
        "Ѹ",
        "ѹ",
        "Ѻ",
        "ѻ",
        "Ѽ",
        "ѽ",
        "Ѿ",
        "ѿ",
        "҂",
        "ׯ",
        "؊",
        "؏",
        "؟",
        "آ",
        "س",
        "ش",
        "ص",
        "ض",
        "ػ",
        "ؼ",
        "٣",
        "٦",
        "٧",
        "٨",
        "٩",
        "٭",
        "ٶ",
        "ٷ",
        "ڕ",
        "ښ",
        "ڛ",
        "ڜ",
        "ڝ",
        "ڞ",
        "ک",
        "ګ",
        "گ",
        "ڰ",
        "ڱ",
        "ڲ",
        "ڳ",
        "ڴ",
        "ڵ",
        "ڷ",
        "ۂ",
        "ۃ",
        "ۍ",
        "ے",
        "ۓ",
        "ۦ",
        "ۯ",
        "۳",
        "۵",
        "۷",
        "۸",
        "۹",
        "ۺ",
        "ۻ",
        "ܯ",
        "ݛ",
        "ݜ",
        "ݢ",
        "ݣ",
        "ݤ",
        "ݭ",
        "ݰ",
        "ݶ",
        "ݺ",
        "ݻ",
        "ݼ",
        "ݽ",
        "ݾ",
        "ށ",
        "ނ",
        "އ",
        "ދ",
        "ތ",
        "ޔ",
        "ޛ",
        "ޠ",
        "ޡ",
        "ޢ",
        "ޣ",
        "ߌ",
        "߷",
        "ﬠ",
        "﬩",
        "שׁ",
        "שּׁ",
        "שּׂ",
        "אּ",
        "מּ",
        "שּ",
        "ﭓ",
        "ﭗ",
        "ﭛ",
        "ﭟ",
        "ﭣ",
        "ﭧ",
        "ﭫ",
        "ﭯ",
        "ﭳ",
        "ﭷ",
        "ﭻ",
        "ﭿ",
        "ﮃ",
        "ﮅ",
        "ﮇ",
        "ﮉ",
        "ﮋ",
        "ﮍ",
        "ﮎ",
        "ﮐ",
        "ﮒ",
        "ﮔ",
        "ﮖ",
        "ﮘ",
        "ﮚ",
        "ﮜ",
        "ﮟ",
        "ﮡ",
        "ﮧ",
        "ﮫ",
        "ﮬ",
        "ﮮ",
        "ﮯ",
        "ﮰ",
        "ﮱ",
        "﮺",
        "﮻",
        "ﯔ",
        "ﯕ",
        "ﯘ",
        "ﯚ",
        "ﯜ",
        "ﯝ",
        "ﯟ",
        "ﯡ",
        "ﯣ",
        "ﯥ",
        "ﯫ",
        "ﯭ",
        "ﯮ",
        "ﯯ",
        "ﯰ",
        "ﯱ",
        "ﯴ",
        "ﯵ",
        "ﯷ",
        "ﯸ",
        "ﯺ",
        "ﯻ",
        "ﯽ",
        "ﰀ",
        "ﰁ",
        "ﰂ",
        "ﰈ",
        "ﰉ",
        "ﰊ",
        "ﰍ",
        "ﰎ",
        "ﰑ",
        "ﰒ",
        "ﰖ",
        "ﰘ",
        "ﰛ",
        "ﰜ",
        "ﰝ",
        "ﰞ",
        "ﰠ",
        "ﰢ",
        "ﰣ",
        "ﰧ",
        "ﰨ",
        "ﰪ",
        "ﰫ",
        "ﰬ",
        "ﰷ",
        "ﰺ",
        "ﰻ",
        "ﰿ",
        "ﱀ",
        "ﱁ",
        "ﱈ",
        "ﱎ",
        "ﱓ",
        "ﱔ",
        "ﱘ",
        "ﱙ",
        "ﱚ",
        "ﱤ",
        "ﱦ",
        "ﱨ",
        "ﱩ",
        "ﱪ",
        "ﱫ",
        "ﱬ",
        "ﱭ",
        "ﱮ",
        "ﱯ",
        "ﱰ",
        "ﱱ",
        "ﱲ",
        "ﱳ",
        "ﱴ",
        "ﱵ",
        "ﱶ",
        "ﱷ",
        "ﱸ",
        "ﱹ",
        "ﱺ",
        "ﱻ",
        "ﱾ",
        "ﱿ",
        "ﲈ",
        "ﲊ",
        "ﲋ",
        "ﲍ",
        "ﲎ",
        "ﲏ",
        "ﲐ",
        "ﲑ",
        "ﲒ",
        "ﲓ",
        "ﲔ",
        "ﲕ",
        "ﲖ",
        "ﲗ",
        "ﲘ",
        "ﲙ",
        "ﲚ",
        "ﲟ",
        "ﲡ",
        "ﲢ",
        "ﲣ",
        "ﲤ",
        "ﲦ",
        "ﲧ",
        "ﲩ",
        "ﲫ",
        "ﲺ",
        "ﲼ",
        "ﲾ",
        "ﲿ",
        "ﳀ",
        "ﳂ",
        "ﳄ",
        "ﳅ",
        "ﳆ",
        "ﳇ",
        "ﳌ",
        "ﳍ",
        "ﳎ",
        "ﳏ",
        "ﳐ",
        "ﳑ",
        "ﳕ",
        "ﳖ",
        "ﳙ",
        "ﳚ",
        "ﳛ",
        "ﳜ",
        "ﳝ",
        "ﳵ",
        "ﳶ",
        "ﴁ",
        "ﴂ",
        "ﴅ",
        "ﴆ",
        "ﴇ",
        "ﴈ",
        "ﴉ",
        "ﴊ",
        "ﴌ",
        "ﴑ",
        "ﴒ",
        "ﴓ",
        "ﴔ",
        "ﴕ",
        "ﴖ",
        "ﺁ",
        "ﺄ",
        "ﺆ",
        "ﺈ",
        "ﺊ",
        "ﺐ",
        "ﺖ",
        "ﺚ",
        "ﺞ",
        "ﺟ",
        "ﺢ",
        "ﺦ",
        "ﺪ",
        "ﺬ",
        "ﺮ",
        "ﺰ",
        "ﺱ",
        "ﺲ",
        "ﺳ",
        "ﺵ",
        "ﺶ",
        "ﺷ",
        "ﺹ",
        "ﺺ",
        "ﺻ",
        "ﺽ",
        "ﺾ",
        "ﺿ",
        "ﻂ",
        "ﻃ",
        "ﻆ",
        "ﻇ",
        "ﻊ",
        "ﻋ",
        "ﻎ",
        "ﻏ",
        "ﻒ",
        "ﻖ",
        "ﻚ",
        "ﻛ",
        "ﻞ",
        "ﻣ",
        "ﻦ",
        "ﻫ",
        "ﻮ",
        "ﻰ",
        "ﻲ",
        "ﻵ",
        "ﻸ",
        "ﻺ",
        "ﻼ",
        "ｩ",
        "ｪ",
        "ｫ",
        "ｬ",
        "ｭ",
        "ｰ",
        "ｱ",
        "ｲ",
        "ｳ",
        "ｴ",
        "ｵ",
        "ｶ",
        "ｷ",
        "ｸ",
        "ｹ",
        "ｻ",
        "ｼ",
        "ｽ",
        "ｾ",
        "ｿ",
        "ﾀ",
        "ﾁ",
        "ﾃ",
        "ﾅ",
        "ﾆ",
        "ﾇ",
        "ﾈ",
        "ﾊ",
        "ﾌ",
        "ﾍ",
        "ﾎ",
        "ﾐ",
        "ﾑ",
        "ﾒ",
        "ﾓ",
        "ﾔ",
        "ﾕ",
        "ﾙ",
        "ﾝ",
        "ﾣ",
        "ﾫ",
        "ﾬ",
        "ﾭ",
        "ﾮ",
        "ﾰ",
        "ﾳ",
        "ﾴ",
        "ﾾ",
        "ￎ",
        "𐀀",
        "𐀁",
        "𐀂",
        "𐀃",
        "𐀆",
        "𐀇",
        "𐀉",
        "𐀊",
        "𐀋",
        "𐀍",
        "𐀐",
        "𐀑",
        "𐀒",
        "𐀔",
        "𐀕",
        "𐀗",
        "𐀘",
        "𐀚",
        "𐀛",
        "𐀝",
        "𐀠",
        "𐀥",
        "𐀦",
        "𐀩",
        "𐀬",
        "𐀭",
        "𐀮",
        "𐀯",
        "𐀰",
        "𐀳",
        "𐀴",
        "𐀵",
        "𐀷",
        "𐀹",
        "𐀿",
        "𐁀",
        "𐁃",
        "𐁇",
        "𐁈",
        "𐁉",
        "𐁊",
        "𐁔",
        "𐁚",
        "𐂀",
        "𐂁",
        "𐂆",
        "𐂇",
        "𐂈",
        "𐂉",
        "𐂑",
        "𐂘",
        "𐂤",
        "𐂧",
        "𐂳",
        "𐂵",
        "𐂼",
        "𐂾",
        "𐃃",
        "𐃉",
        "𐃋",
        "𐃯",
        "𐄍",
        "𐄎",
        "𐄚",
        "𐄜",
        "𐄞",
        "𐄠",
        "𐄢",
        "𐄤",
        "𐄫",
        "𐄭",
        "𐅄",
        "𐅅",
        "𐅆",
        "𐅈",
        "𐅉",
        "𐅊",
        "𐅋",
        "𐅌",
        "𐅍",
        "𐅎",
        "𐅏",
        "𐅐",
        "𐅑",
        "𐅒",
        "𐅓",
        "𐅔",
        "𐅕",
        "𐅗",
        "𐅘",
        "𐅚",
        "𐅜",
        "𐅢",
        "𐅤",
        "𐅦",
        "𐅧",
        "𐅨",
        "𐅯",
        "𐅰",
        "𐅱",
        "𐅲",
        "𐅴",
        "𐅶",
        "𐅺",
        "𐅼",
        "𐅽",
        "𐆃",
        "𐆅",
        "𐆆",
        "𐆉",
        "𐆊",
        "𐆑",
        "𐆒",
        "𐆛",
        "𐇖",
        "𐇙",
        "𐇢",
        "𐇴",
        "𐊅",
        "𐊉",
        "𐊌",
        "𐊍",
        "𐊐",
        "𐊗",
        "𐊜",
        "𐊠",
        "𐊣",
        "𐊧",
        "𐊨",
        "𐊫",
        "𐊬",
        "𐊭",
        "𐊱",
        "𐊲",
        "𐊳",
        "𐊴",
        "𐊵",
        "𐊶",
        "𐊸",
        "𐊺",
        "𐊻",
        "𐊼",
        "𐊽",
        "𐊾",
        "𐊿",
        "𐋀",
        "𐋁",
        "𐋃",
        "𐋄",
        "𐋅",
        "𐋆",
        "𐋇",
        "𐋈",
        "𐋊",
        "𐋋",
        "𐋌",
        "𐋎",
        "𐋐",
        "𐋢",
        "𐋩",
        "𐋫",
        "𐋬",
        "𐋭",
        "𐋱",
        "𐋺",
        "𐌇",
        "𐌈",
        "𐌍",
        "𐌎",
        "𐌏",
        "𐌑",
        "𐌒",
        "𐌕",
        "𐌖",
        "𐌗",
        "𐌘",
        "𐌙",
        "𐌞",
        "𐌟",
        "𐌡",
        "𐌢",
        "𐌣",
        "𐌮",
        "𐍐",
        "𐍑",
        "𐍒",
        "𐍓",
        "𐍝",
        "𐍠",
        "𐍢",
        "𐍥",
        "𐍧",
        "𐍪",
        "𐍫",
        "𐍮",
        "𐍯",
        "𐍱",
        "𐍵",
        "𐐃",
        "𐐄",
        "𐐅",
        "𐐉",
        "𐐎",
        "𐐗",
        "𐐘",
        "𐐜",
        "𐐝",
        "𐐟",
        "𐐡",
        "𐐧",
        "𐐫",
        "𐐬",
        "𐐭",
        "𐐱",
        "𐐶",
        "𐐿",
        "𐑀",
        "𐑄",
        "𐑉",
        "𐑏",
        "𐑥",
        "𐑫",
        "𐑯",
        "𐑵",
        "𐑸",
        "𐑹",
        "𐑺",
        "𐑻",
        "𐑿",
        "𐒀",
        "𐒂",
        "𐒅",
        "𐒆",
        "𐒇",
        "𐒊",
        "𐒌",
        "𐒚",
        "𐒠",
        "𐒢",
        "𐒣",
        "𐒤",
        "𐒥",
        "𐒧",
        "𐒨",
        "𐒩",
        "𐒰",
        "𐒱",
        "𐒲",
        "𐒳",
        "𐒴",
        "𐒶",
        "𐒽",
        "𐓀",
        "𐓁",
        "𐓂",
        "𐓃",
        "𐓅",
        "𐓌",
        "𐓍",
        "𐓐",
        "𐓑",
        "𐓘",
        "𐓙",
        "𐓚",
        "𐓜",
        "𐓞",
        "𐓥",
        "𐓨",
        "𐓩",
        "𐓪",
        "𐓫",
        "𐓭",
        "𐓴",
        "𐓵",
        "𐓸",
        "𐓹",
        "𐔀",
        "𐔁",
        "𐔂",
        "𐔃",
        "𐔆",
        "𐔊",
        "𐔋",
        "𐔐",
        "𐔑",
        "𐔘",
        "𐔝",
        "𐔟",
        "𐔧",
        "𐔳",
        "𐔴",
        "𐔹",
        "𐕂",
        "𐕈",
        "𐕉",
        "𐕒",
        "𐕓",
        "𐕔",
        "𐕗",
        "𐕙",
        "𐕚",
        "𐕜",
        "𐕞",
        "𐕟",
        "𐕠",
        "𐕡",
        "𐕢",
        "𐕣",
        "𐕰",
        "𐕴",
        "𐕼",
        "𐕽",
        "𐖂",
        "𐖃",
        "𐖉",
        "𐖐",
        "𐖔",
        "𐖕",
        "𐖗",
        "𐖛",
        "𐖤",
        "𐖩",
        "𐖪",
        "𐖰",
        "𐖷",
        "𐖻",
        "𐖼",
        "𐘁",
        "𐘂",
        "𐘃",
        "𐘉",
        "𐘌",
        "𐘎",
        "𐘏",
        "𐘑",
        "𐘠",
        "𐘡",
        "𐘣",
        "𐘤",
        "𐘨",
        "𐘷",
        "𐘹",
        "𐙘",
        "𐙨",
        "𐙬",
        "𐙳",
        "𐙽",
        "𐚅",
        "𐚒",
        "𐚖",
        "𐚙",
        "𐚜",
        "𐚹",
        "𐛗",
        "𐜐",
        "𐜑",
        "𐜝",
        "𐜥",
        "𐜧",
        "𐝇",
        "𐝠",
        "𐞀",
        "𐞃",
        "𐞈",
        "𐞉",
        "𐞊",
        "𐞋",
        "𐞌",
        "𐞍",
        "𐞓",
        "𐞙",
        "𐞜",
        "𐞞",
        "𐞟",
        "𐞡",
        "𐞣",
        "𐞤",
        "𐞧",
        "𐞬",
        "𐞭",
        "𐞮",
        "𐞰",
        "𐞲",
        "𐠊",
        "𐠌",
        "𐠙",
        "𐠣",
        "𐠤",
        "𐠨",
        "𐠩",
        "𐠪",
        "𐠮",
        "𐠯",
        "𐠰",
        "𐠱",
        "𐠳",
        "𐠵",
        "𐠸",
        "𐡑",
        "𐡤",
        "𐡫",
        "𐡱",
        "𐢎",
        "𐢞",
        "𐢪",
        "𐣫",
        "𐣬",
        "𐣮",
        "𐣲",
        "𐣿",
        "𐤅",
        "𐤆",
        "𐤈",
        "𐤌",
        "𐤎",
        "𐤒",
        "𐤔",
        "𐤙",
        "𐤛",
        "𐤮",
        "𐤯",
        "𐤲",
        "𐤳",
        "𐤴",
        "𐤵",
        "𐤶",
        "𐤷",
        "𐤹",
        "𐦇",
        "𐦥",
        "𐧃",
        "𐧇",
        "𐧺",
        "𐧼",
        "𐧽",
        "𐧾",
        "𐧿",
        "𐨀",
        "𐨏",
        "𐨑",
        "𐨒",
        "𐨗",
        "𐨛",
        "𐨥",
        "𐨨",
        "𐨫",
        "𐨬",
        "𐨯",
        "𐨱",
        "𐨳",
        "𐩁",
        "𐪂",
        "𐪏",
        "𐪗",
        "𐪛",
        "𐫤",
        "𐬀",
        "𐬅",
        "𐬕",
        "𐬙",
        "𐬚",
        "𐬝",
        "𐬣",
        "𐬬",
        "𐬯",
        "𐬾",
        "𐬿",
        "𐭇",
        "𐭔",
        "𐭕",
        "𐭛",
        "𐭰",
        "𐭻",
        "𐭿",
        "𐮫",
        "𐰊",
        "𐰌",
        "𐰗",
        "𐰢",
        "𐰤",
        "𐰥",
        "𐰦",
        "𐰧",
        "𐰬",
        "𐰼",
        "𐱂",
        "𐱇",
        "𐱈",
        "𐲃",
        "𐲄",
        "𐲅",
        "𐲇",
        "𐲈",
        "𐲌",
        "𐲍",
        "𐲎",
        "𐲏",
        "𐲐",
        "𐲑",
        "𐲓",
        "𐲔",
        "𐲕",
        "𐲖",
        "𐲗",
        "𐲡",
        "𐲤",
        "𐲧",
        "𐲪",
        "𐲫",
        "𐲮",
        "𐲰",
        "𐲱",
        "𐲲",
        "𐳃",
        "𐳇",
        "𐳈",
        "𐳌",
        "𐳍",
        "𐳎",
        "𐳐",
        "𐳑",
        "𐳔",
        "𐳕",
        "𐳖",
        "𐳗",
        "𐳡",
        "𐳤",
        "𐳧",
        "𐳪",
        "𐳫",
        "𐳮",
        "𐳱",
        "𐳲",
        "𐳻",
        "𐳽",
        "𐳾",
        "𐳿",
        "𐴙",
        "𐴞",
        "𐴠",
        "𐴢",
        "𐴣",
        "𐴵",
        "𐴷",
        "𐹪",
        "𐹱",
        "𐹳",
        "𐹹",
        "𐹺",
        "𐺁",
        "𐺂",
        "𐺄",
        "𐺇",
        "𐺈",
        "𐺏",
        "𐺐",
        "𐺑",
        "𐺒",
        "𐺓",
        "𐺖",
        "𐺚",
        "𐺛",
        "𐺜",
        "𐺝",
        "𐺞",
        "𐺟",
        "𐺣",
        "𐺤",
        "𐺥",
        "𐺬",
        "𐼃",
        "𐼄",
        "𐼅",
        "𐼆",
        "𐼉",
        "𐼏",
        "𐼖",
        "𐼚",
        "𐼦",
        "𐼰",
        "𐼱",
        "𐼶",
        "𐼸",
        "𐼹",
        "𐼺",
        "𐼼",
        "𐽀",
        "𐽃",
        "𐽒",
        "𐽓",
        "𐽰",
        "𐽱",
        "𐽵",
        "𐽷",
        "𐽸",
        "𐽹",
        "𐽺",
        "𐽻",
        "𐽼",
        "𐽽",
        "𐽿",
        "𐾀",
        "𐾼",
        "𐾿",
        "𐿃",
        "𐿄",
        "𐿈",
        "𐿋",
        "𐿠",
        "𐿢",
        "𐿤",
        "𐿧",
        "𐿨",
        "𐿮",
        "𐿱",
        "𐿲",
        "𐿴",
        "𐿵",
        "𑀅",
        "𑀋",
        "𑀍",
        "𑀎",
        "𑀏",
        "𑀐",
        "𑀔",
        "𑀕",
        "𑀖",
        "𑀗",
        "𑀜",
        "𑀡",
        "𑀦",
        "𑀧",
        "𑀩",
        "𑀫",
        "𑀬",
        "𑀮",
        "𑀰",
        "𑀱",
        "𑀳",
        "𑀵",
        "𑀷",
        "𑁆",
        "𑁕",
        "𑁗",
        "𑁜",
        "𑁞",
        "𑁟",
        "𑁣",
        "𑁤",
        "𑁩",
        "𑁫",
        "𑁯",
        "𑁳",
        "𑁿",
        "𑂀",
        "𑂅",
        "𑂆",
        "𑂇",
        "𑂑",
        "𑂕",
        "𑂛",
        "𑂜",
        "𑂞",
        "𑂡",
        "𑂢",
        "𑂣",
        "𑂤",
        "𑂧",
        "𑂪",
        "𑂭",
        "𑃐",
        "𑃗",
        "𑃡",
        "𑃧",
        "𑄓",
        "𑄰",
        "𑄶",
        "𑄷",
        "𑄺",
        "𑄻",
        "𑄼",
        "𑄽",
        "𑄾",
        "𑅒",
        "𑅗",
        "𑅘",
        "𑅚",
        "𑅜",
        "𑅝",
        "𑅡",
        "𑅣",
        "𑅲",
        "𑆠",
        "𑆹",
        "𑆼",
        "𑆽",
        "𑇖",
        "𑇗",
        "𑈰",
        "𑈷",
        "𑈹",
        "𑊋",
        "𑊢",
        "𑊩",
        "𑊼",
        "𑊽",
        "𑊾",
        "𑋂",
        "𑋈",
        "𑋉",
        "𑋌",
        "𑋍",
        "𑋓",
        "𑋞",
        "𑋣",
        "𑋱",
        "𑋹",
        "𑌁",
        "𑌠",
        "𑌮",
        "𑌼",
        "𑍝",
        "𑍲",
        "𑐹",
        "𑐺",
        "𑐻",
        "𑐼",
        "𑑇",
        "𑒺",
        "𑖚",
        "𑖨",
        "𑗆",
        "𑗑",
        "𑗒",
        "𑙃",
        "𑙓",
        "𑙖",
        "𑙘",
        "𑚄",
        "𑚎",
        "𑚔",
        "𑚝",
        "𑚠",
        "𑚡",
        "𑚦",
        "𑚪",
        "𑛁",
        "𑛂",
        "𑛄",
        "𑛅",
        "𑜂",
        "𑜅",
        "𑜓",
        "𑠙",
        "𑠪",
        "𑠫",
        "𑢠",
        "𑢡",
        "𑢢",
        "𑢣",
        "𑢤",
        "𑢦",
        "𑢨",
        "𑢩",
        "𑢪",
        "𑢬",
        "𑢭",
        "𑢯",
        "𑢰",
        "𑢲",
        "𑢳",
        "𑢴",
        "𑢵",
        "𑢶",
        "𑢷",
        "𑢸",
        "𑢹",
        "𑢻",
        "𑢼",
        "𑢽",
        "𑢾",
        "𑢿",
        "𑣀",
        "𑣄",
        "𑣈",
        "𑣋",
        "𑣌",
        "𑣍",
        "𑣐",
        "𑣓",
        "𑣔",
        "𑣗",
        "𑣘",
        "𑣙",
        "𑣜",
        "𑣝",
        "𑣞",
        "𑣟",
        "𑣠",
        "𑣢",
        "𑣣",
        "𑣤",
        "𑣥",
        "𑣦",
        "𑣧",
        "𑣨",
        "𑣩",
        "𑣪",
        "𑣫",
        "𑣬",
        "𑣭",
        "𑣮",
        "𑣯",
        "𑣰",
        "𑣱",
        "𑣲",
        "𑤝",
        "𑤩",
        "𑥒",
        "𑥔",
        "𑥖",
        "𑦢",
        "𑦱",
        "𑦴",
        "𑦶",
        "𑦸",
        "𑦹",
        "𑦼",
        "𑦽",
        "𑦾",
        "𑧁",
        "𑧄",
        "𑧅",
        "𑧊",
        "𑧋",
        "𑧌",
        "𑧎",
        "𑧐",
        "𑨀",
        "𑨁",
        "𑨂",
        "𑨃",
        "𑨄",
        "𑨅",
        "𑨆",
        "𑨉",
        "𑨋",
        "𑨌",
        "𑨍",
        "𑨎",
        "𑨏",
        "𑨐",
        "𑨑",
        "𑨒",
        "𑨓",
        "𑨔",
        "𑨕",
        "𑨖",
        "𑨗",
        "𑨘",
        "𑨙",
        "𑨚",
        "𑨛",
        "𑨜",
        "𑨝",
        "𑨞",
        "𑨟",
        "𑨠",
        "𑨡",
        "𑨢",
        "𑨧",
        "𑨨",
        "𑨩",
        "𑨪",
        "𑨫",
        "𑨬",
        "𑨭",
        "𑨮",
        "𑨯",
        "𑨰",
        "𑨱",
        "𑨲",
        "𑨴",
        "𑨵",
        "𑨶",
        "𑨷",
        "𑨻",
        "𑨼",
        "𑨽",
        "𑨿",
        "𑩀",
        "𑩄",
        "𑩅",
        "𑩆",
        "𑩇",
        "𑪶",
        "𑪷",
        "𑪸",
        "𑪹",
        "𑪺",
        "𑪻",
        "𑫀",
        "𑫂",
        "𑫅",
        "𑫆",
        "𑫈",
        "𑫊",
        "𑫋",
        "𑫏",
        "𑫐",
        "𑫔",
        "𑫕",
        "𑫗",
        "𑫚",
        "𑫛",
        "𑫜",
        "𑫝",
        "𑫞",
        "𑫟",
        "𑫠",
        "𑫣",
        "𑫩",
        "𑫪",
        "𑫭",
        "𑫮",
        "𑫱",
        "𑫲",
        "𑫶",
        "𑫷",
        "𑫸",
        "𑰄",
        "𑰎",
        "𑰓",
        "𑰰",
        "𑰱",
        "𑰲",
        "𑰳",
        "𑰸",
        "𑱙",
        "𑱞",
        "𑱦",
        "𑱩",
        "𑱰",
        "𑱶",
        "𑱹",
        "𑱾",
        "𑲏",
        "𑲖",
        "𑲙",
        "𑲞",
        "𑲯",
        "𑲶",
        "𑵖",
        "𑶠",
        "𑶣",
        "𑶥",
        "𑶦",
        "𑶧",
        "𑻰",
        "𑼀",
        "𑼶",
        "𑼷",
        "𑽀",
        "𑽈",
        "𑽔",
        "𑾰",
        "𒾦",
        "𖩄",
        "𖩅",
        "𖩋",
        "𖩍",
        "𖩏",
        "𖩑",
        "𖩒",
        "𖩔",
        "𖩕",
        "𖩖",
        "𖩛",
        "𖩜",
        "𖩞",
        "𖩠",
        "𖩡",
        "𖩢",
        "𖩣",
        "𖩤",
        "𖩥",
        "𖩦",
        "𖩧",
        "𖩨",
        "𖩩",
        "𖩯",
        "𖩰",
        "𖪪",
        "𖪰",
        "𖪳",
        "𖫁",
        "𖫄",
        "𖫉",
        "𖫒",
        "𖫓",
        "𖫕",
        "𖫚",
        "𖫛",
        "𖫜",
        "𖫡",
        "𖫤",
        "𖫧",
        "𖫨",
        "𖫩",
        "𖫪",
        "𖫫",
        "𖫬",
        "𖫭",
        "𖫵",
        "𖬃",
        "𖬅",
        "𖬆",
        "𖬋",
        "𖬐",
        "𖬑",
        "𖬒",
        "𖬞",
        "𖬩",
        "𖬪",
        "𖬫",
        "𖬷",
        "𖬸",
        "𖭄",
        "𖭅",
        "𖭔",
        "𖭛",
        "𖭝",
        "𖭟",
        "𖭡",
        "𖭥",
        "𖭦",
        "𖭨",
        "𖭩",
        "𖭪",
        "𖭫",
        "𖭬",
        "𖭷",
        "𖭾",
        "𖭿",
        "𖮀",
        "𖮁",
        "𖮄",
        "𖮅",
        "𖮆",
        "𖮈",
        "𖮉",
        "𖮌",
        "𖮍",
        "𖮎",
        "𖹅",
        "𖹆",
        "𖹈",
        "𖹊",
        "𖹋",
        "𖹑",
        "𖹕",
        "𖹗",
        "𖹛",
        "𖹜",
        "𖹞",
        "𖹢",
        "𖹣",
        "𖹩",
        "𖹪",
        "𖹬",
        "𖹯",
        "𖹱",
        "𖹳",
        "𖹵",
        "𖹸",
        "𖹺",
        "𖹼",
        "𖹽",
        "𖺄",
        "𖺇",
        "𖺈",
        "𖺉",
        "𖺊",
        "𖺍",
        "𖺎",
        "𖺏",
        "𖺐",
        "𖺑",
        "𖺒",
        "𖺓",
        "𖼃",
        "𖼆",
        "𖼈",
        "𖼊",
        "𖼋",
        "𖼌",
        "𖼍",
        "𖼎",
        "𖼏",
        "𖼒",
        "𖼓",
        "𖼔",
        "𖼗",
        "𖼘",
        "𖼚",
        "𖼜",
        "𖼡",
        "𖼢",
        "𖼣",
        "𖼥",
        "𖼧",
        "𖼭",
        "𖼯",
        "𖼶",
        "𖼽",
        "𖽀",
        "𖽃",
        "𖽄",
        "𖽅",
        "𖾚",
        "𖾛",
        "𖾟",
        "𘬎",
        "𘬙",
        "𘬨",
        "𘬮",
        "𘬺",
        "𘬻",
        "𘬼",
        "𘬿",
        "𘭂",
        "𘭊",
        "𘭍",
        "𘭎",
        "𘮼",
        "𘯏",
        "𘯔",
        "𘯝",
        "𘰐",
        "𘰩",
        "𘰪",
        "𘰫",
        "𘰬",
        "𘰭",
        "𘰮",
        "𘰴",
        "𘰵",
        "𘰶",
        "𘱏",
        "𘱔",
        "𘱦",
        "𘱸",
        "𘲕",
        "𘲝",
        "𘲧",
        "𘲩",
        "𘲪",
        "𘲫",
        "𘲶",
        "𘳂",
        "𘳃",
        "𘳄",
        "𘳅",
        "𘳆",
        "𘳎",
        "𘳏",
        "𘳔",
        "𛀀",
        "𛃉",
        "𛄋",
        "𛅺",
        "𛆀",
        "𛆂",
        "𛆅",
        "𛆐",
        "𛆕",
        "𛆗",
        "𛆘",
        "𛆜",
        "𛆝",
        "𛆠",
        "𛆢",
        "𛆥",
        "𛆨",
        "𛆫",
        "𛆭",
        "𛆱",
        "𛆳",
        "𛆵",
        "𛆹",
        "𛆻",
        "𛆼",
        "𛆽",
        "𛇊",
        "𛇌",
        "𛇏",
        "𛇐",
        "𛇑",
        "𛇘",
        "𛇚",
        "𛇛",
        "𛇝",
        "𛇟",
        "𛇣",
        "𛇤",
        "𛇦",
        "𛇰",
        "𛇴",
        "𛇶",
        "𛇹",
        "𛇼",
        "𛈎",
        "𛈧",
        "𛈪",
        "𛈰",
        "𛈵",
        "𛈺",
        "𛈼",
        "𛉑",
        "𛉒",
        "𛉞",
        "𛉰",
        "𛉲",
        "𛉸",
        "𛉽",
        "𛊁",
        "𛊄",
        "𛊈",
        "𛊎",
        "𛊘",
        "𛊙",
        "𛊝",
        "𛊞",
        "𛊬",
        "𛋀",
        "𛋋",
        "𛋚",
        "𛋜",
        "𛋤",
        "𛰈",
        "𛰉",
        "𛰊",
        "𛰋",
        "𛰓",
        "𛰕",
        "𛰘",
        "𛰝",
        "𛰞",
        "𛰧",
        "𛰨",
        "𛰳",
        "𛰵",
        "𛰷",
        "𛰺",
        "𛰼",
        "𛰾",
        "𛱀",
        "𛱃",
        "𛱊",
        "𛱋",
        "𛱚",
        "𛱛",
        "𛱜",
        "𛱝",
        "𛱞",
        "𛱟",
        "𛱠",
        "𛱷",
        "𛱹",
        "𛲁",
        "𛲑",
        "𜽛",
        "𜽞",
        "𜽠",
        "𜽾",
        "𜾂",
        "𜿂",
        "𝀂",
        "𝀃",
        "𝀄",
        "𝀅",
        "𝀆",
        "𝀌",
        "𝀎",
        "𝀔",
        "𝀖",
        "𝀢",
        "𝁎",
        "𝁘",
        "𝁙",
        "𝁟",
        "𝂃",
        "𝂄",
        "𝂉",
        "𝂊",
        "𝂋",
        "𝂎",
        "𝂐",
        "𝂑",
        "𝂙",
        "𝂚",
        "𝂨",
        "𝂩",
        "𝂭",
        "𝂮",
        "𝂶",
        "𝂹",
        "𝂽",
        "𝃁",
        "𝃂",
        "𝃇",
        "𝃋",
        "𝃎",
        "𝃏",
        "𝃑",
        "𝃕",
        "𝃝",
        "𝃧",
        "𝃩",
        "𝃫",
        "𝃰",
        "𝃲",
        "𝄆",
        "𝄇",
        "𝄞",
        "𝄟",
        "𝄠",
        "𝄬",
        "𝄭",
        "𝄮",
        "𝄯",
        "𝄲",
        "𝄳",
        "𝅀",
        "𝅅",
        "𝆍",
        "𝆎",
        "𝆢",
        "𝆴",
        "𝇑",
        "𝇩",
        "𝈈",
        "𝈍",
        "𝈔",
        "𝈘",
        "𝈙",
        "𝈛",
        "𝈜",
        "𝈵",
        "𝈼",
        "𝈿",
        "𝉅",
        "𝋂",
        "𝋃",
        "𝋇",
        "𝋈",
        "𝋌",
        "𝋍",
        "𝋏",
        "𝋐",
        "𝍫",
        "𝐀",
        "𝐁",
        "𝐂",
        "𝐃",
        "𝐄",
        "𝐅",
        "𝐆",
        "𝐇",
        "𝐊",
        "𝐋",
        "𝐌",
        "𝐍",
        "𝐎",
        "𝐏",
        "𝐑",
        "𝐒",
        "𝐔",
        "𝐖",
        "𝐗",
        "𝐙",
        "𝐚",
        "𝐛",
        "𝐜",
        "𝐝",
        "𝐞",
        "𝐠",
        "𝐡",
        "𝐤",
        "𝐧",
        "𝐨",
        "𝐩",
        "𝐪",
        "𝐫",
        "𝐬",
        "𝐮",
        "𝐯",
        "𝐱",
        "𝐲",
        "𝐳",
        "𝐼",
        "𝑇",
        "𝑉",
        "𝑌",
        "𝑎",
        "𝑐",
        "𝑒",
        "𝑓",
        "𝑘",
        "𝑜",
        "𝑠",
        "𝑣",
        "𝑳",
        "𝒊",
        "𝒾",
        "𝓲",
        "𝔙",
        "𝔛",
        "𝔜",
        "𝔞",
        "𝔟",
        "𝔡",
        "𝔤",
        "𝔥",
        "𝔪",
        "𝔫",
        "𝔰",
        "𝔲",
        "𝔳",
        "𝔴",
        "𝔶",
        "𝔸",
        "𝔹",
        "𝔻",
        "𝔼",
        "𝔽",
        "𝔾",
        "𝕀",
        "𝕃",
        "𝕆",
        "𝕋",
        "𝕌",
        "𝕏",
        "𝕒",
        "𝕓",
        "𝕔",
        "𝕕",
        "𝕖",
        "𝕗",
        "𝕘",
        "𝕙",
        "𝕚",
        "𝕝",
        "𝕟",
        "𝕠",
        "𝕡",
        "𝕢",
        "𝕣",
        "𝕥",
        "𝕦",
        "𝕧",
        "𝕪",
        "𝕳",
        "𝖁",
        "𝖃",
        "𝖄",
        "𝖅",
        "𝖆",
        "𝖇",
        "𝖉",
        "𝖌",
        "𝖍",
        "𝖒",
        "𝖓",
        "𝖘",
        "𝖚",
        "𝖛",
        "𝖜",
        "𝖞",
        "𝖩",
        "𝖰",
        "𝖳",
        "𝖵",
        "𝖸",
        "𝗆",
        "𝗐",
        "𝗔",
        "𝗕",
        "𝗖",
        "𝗗",
        "𝗘",
        "𝗙",
        "𝗚",
        "𝗛",
        "𝗞",
        "𝗟",
        "𝗠",
        "𝗡",
        "𝗢",
        "𝗣",
        "𝗥",
        "𝗦",
        "𝗨",
        "𝗪",
        "𝗫",
        "𝗭",
        "𝗮",
        "𝗯",
        "𝗰",
        "𝗱",
        "𝗲",
        "𝗴",
        "𝗵",
        "𝗸",
        "𝗻",
        "𝗼",
        "𝗽",
        "𝗾",
        "𝗿",
        "𝘀",
        "𝘂",
        "𝘃",
        "𝘅",
        "𝘆",
        "𝘇",
        "𝘐",
        "𝘛",
        "𝘝",
        "𝘠",
        "𝘢",
        "𝘤",
        "𝘦",
        "𝘧",
        "𝘬",
        "𝘰",
        "𝘴",
        "𝘷",
        "𝙇",
        "𝙞",
        "𝙻",
        "𝚂",
        "𝚃",
        "𝚛",
        "𝚜",
        "𝚞",
        "𝚨",
        "𝚩",
        "𝚪",
        "𝚬",
        "𝚭",
        "𝚮",
        "𝚯",
        "𝚱",
        "𝚳",
        "𝚴",
        "𝚵",
        "𝚶",
        "𝚸",
        "𝚹",
        "𝚺",
        "𝚾",
        "𝛂",
        "𝛃",
        "𝛅",
        "𝛆",
        "𝛈",
        "𝛉",
        "𝛌",
        "𝛍",
        "𝛎",
        "𝛏",
        "𝛐",
        "𝛑",
        "𝛒",
        "𝛓",
        "𝛕",
        "𝛖",
        "𝛛",
        "𝛞",
        "𝛠",
        "𝛪",
        "𝛵",
        "𝛶",
        "𝛻",
        "𝛿",
        "𝜀",
        "𝜁",
        "𝜅",
        "𝜉",
        "𝜊",
        "𝜋",
        "𝜍",
        "𝜖",
        "𝜚",
        "𝜻",
        "𝝉",
        "𝝖",
        "𝝗",
        "𝝘",
        "𝝚",
        "𝝛",
        "𝝜",
        "𝝝",
        "𝝟",
        "𝝡",
        "𝝢",
        "𝝣",
        "𝝤",
        "𝝦",
        "𝝧",
        "𝝨",
        "𝝬",
        "𝝰",
        "𝝱",
        "𝝳",
        "𝝴",
        "𝝶",
        "𝝷",
        "𝝺",
        "𝝻",
        "𝝼",
        "𝝽",
        "𝝾",
        "𝝿",
        "𝞀",
        "𝞁",
        "𝞃",
        "𝞄",
        "𝞉",
        "𝞌",
        "𝞎",
        "𝞯",
        "𝞽",
        "𝟊",
        "𝟎",
        "𝟐",
        "𝟑",
        "𝟒",
        "𝟓",
        "𝟔",
        "𝟕",
        "𝟖",
        "𝟗",
        "𝟘",
        "𝟙",
        "𝟛",
        "𝟜",
        "𝟞",
        "𝟟",
        "𝟠",
        "𝟡",
        "𝟬",
        "𝟮",
        "𝟯",
        "𝟰",
        "𝟱",
        "𝟲",
        "𝟳",
        "𝟴",
        "𝟵",
        "𝟶",
        "𝟼",
        "𝟾",
        "𝟿",
        "𝡚",
        "𝡛",
        "𝡢",
        "𝤅",
        "𝤑",
        "𝤨",
        "𝤩",
        "𝤮",
        "𝤸",
        "𝤻",
        "𝥈",
        "𝥉",
        "𝥊",
        "𝥩",
        "𝥳",
        "𝥴",
        "𝥸",
        "𝥻",
        "𝦈",
        "𝦒",
        "𝦓",
        "𝦜",
        "𝦭",
        "𝦮",
        "𝧆",
        "𝧌",
        "𝧎",
        "𝧹",
        "𝨷",
        "𝨸",
        "𝩶",
        "𝼀",
        "𝼄",
        "𝼆",
        "𝼈",
        "𝼒",
        "𝼔",
        "𝼗",
        "𝼙",
        "𝼜",
        "𝼝",
        "𝼦",
        "𞀴",
        "𞀶",
        "𞁂",
        "𞁆",
        "𞁊",
        "𞁕",
        "𞁗",
        "𞁠",
        "𞁤",
        "𞁥",
        "𞄀",
        "𞄁",
        "𞄂",
        "𞄃",
        "𞄄",
        "𞄅",
        "𞄆",
        "𞄇",
        "𞄈",
        "𞄊",
        "𞄋",
        "𞄌",
        "𞄐",
        "𞄒",
        "𞄔",
        "𞄘",
        "𞄙",
        "𞄚",
        "𞄛",
        "𞄝",
        "𞄞",
        "𞄟",
        "𞄠",
        "𞄨",
        "𞄼",
        "𞄽",
        "𞅃",
        "𞅅",
        "𞅉",
        "𞅎",
        "𞊗",
        "𞊙",
        "𞊛",
        "𞊝",
        "𞊟",
        "𞋄",
        "𞋅",
        "𞋆",
        "𞋱",
        "𞓑",
        "𞓓",
        "𞓖",
        "𞓗",
        "𞓘",
        "𞓙",
        "𞓚",
        "𞓜",
        "𞓠",
        "𞓡",
        "𞓢",
        "𞓣",
        "𞓥",
        "𞓦",
        "𞓧",
        "𞓨",
        "𞓩",
        "𞓪",
        "𞓴",
        "𞠗",
        "𞠠",
        "𞠬",
        "𞠻",
        "𞡇",
        "𞡈",
        "𞡎",
        "𞡗",
        "𞡴",
        "𞡹",
        "𞢛",
        "𞢦",
        "𞢾",
        "𞤂",
        "𞤃",
        "𞤄",
        "𞤅",
        "𞤇",
        "𞤉",
        "𞤍",
        "𞤏",
        "𞤒",
        "𞤓",
        "𞤔",
        "𞤗",
        "𞤚",
        "𞤛",
        "𞤜",
        "𞤝",
        "𞤞",
        "𞤟",
        "𞤡",
        "𞤦",
        "𞤧",
        "𞤫",
        "𞤵",
        "𞤶",
        "𞤹",
        "𞤼",
        "𞤽",
        "𞤿",
        "𞥁",
        "𞥃",
        "𞥉",
        "𞥕",
        "𞥖",
        "𞥗",
        "𞥘",
        "𞱹",
        "𞲇",
        "𞸎",
        "𞸑",
        "𞸔",
        "𞸙",
        "𞸢",
        "𞸤",
        "𞸪",
        "𞸬",
        "𞸮",
        "𞸯",
        "𞸱",
        "𞸴",
        "𞸹",
        "𞸻",
        "𞹂",
        "𞹇",
        "𞹉",
        "𞹋",
        "𞹍",
        "𞹒",
        "𞹗",
        "𞹝",
        "𞹟",
        "𞹢",
        "𞹧",
        "𞹪",
        "𞹬",
        "𞹮",
        "𞹱",
        "𞹴",
        "𞹷",
        "𞹹",
        "𞺂",
        "𞺇",
        "𞺉",
        "𞺗",
        "🁢",
        "🁣",
        "🁤",
        "🁥",
        "🁦",
        "🁧",
        "🁨",
        "🁩",
        "🁪",
        "🁫",
        "🁬",
        "🁭",
        "🁮",
        "🁯",
        "🁰",
        "🁱",
        "🁲",
        "🁳",
        "🁴",
        "🁵",
        "🁶",
        "🁷",
        "🁸",
        "🁹",
        "🁺",
        "🁻",
        "🁼",
        "🁽",
        "🁾",
        "🁿",
        "🂀",
        "🂁",
        "🂂",
        "🂃",
        "🂄",
        "🂅",
        "🂆",
        "🂇",
        "🂈",
        "🂉",
        "🂊",
        "🂋",
        "🂌",
        "🂍",
        "🂎",
        "🂏",
        "🂐",
        "🂑",
        "🂒",
        "🂓",
        "🌢",
        "🍷",
        "🍾",
        "🎙",
        "🎚",
        "🏺",
        "👂",
        "👦",
        "👧",
        "👨",
        "👩",
        "👴",
        "💉",
        "💧",
        "🔋",
        "🖈",
        "🚏",
        "🚶",
        "🚹",
        "🜌",
        "🜘",
        "🝔",
        "🞙",
        "🞟",
        "🠑",
        "🠓",
        "🠩",
        "🠫",
        "🠭",
        "🠯",
        "🠵",
        "🠷",
        "🠹",
        "🠻",
        "🠽",
        "🠿",
        "🡑",
        "🡓",
        "🡙",
        "🤅",
        "🤇",
        "🤵",
        "🥄",
        "🧃",
        "🧳",
        "🧴",
        "🨅",
        "🨣",
        "🨩",
        "🨯",
        "🫑",
        "🮋",
        "🮲",
        "🮳",
        "🮻",
        "🮽",
        "🮾",
        "🮿",
        "🯃",
        "🯊",
        "🯰",
        "🯲",
        "🯴",
        "🯵",
        "🯶",
        "🯷",
        "🯸",
        "🯹"
    ],
    [
        "ϗ",
        "Ϫ",
        "ϰ",
        "ـ",
        "ޗ",
        "ޘ",
        "ߺ",
        "―",
        "‗",
        "⁓",
        "ℋ",
        "ℎ",
        "ℏ",
        "ℑ",
        "ℒ",
        "℔",
        "ℜ",
        "℠",
        "℡",
        "℣",
        "ℳ",
        "⅍",
        "Ⅵ",
        "Ⅶ",
        "Ⅷ",
        "ⅷ",
        "ↇ",
        "↞",
        "↠",
        "↭",
        "↮",
        "↴",
        "↶",
        "↷",
        "⇅",
        "⇈",
        "⇊",
        "⇎",
        "⇜",
        "⇝",
        "⇢",
        "⇦",
        "⇨",
        "⇶",
        "⇷",
        "⇸",
        "⇽",
        "⇾",
        "∭",
        "∰",
        "≪",
        "≫",
        "≭",
        "⋯",
        "⋰",
        "⋱",
        "⌕",
        "⌗",
        "⌦",
        "⌫",
        "⌿",
        "⍀",
        "⎯",
        "⎺",
        "⎻",
        "⎼",
        "⎽",
        "⑇",
        "⑊",
        "⒉",
        "⒊",
        "⒋",
        "⒌",
        "⒍",
        "⒎",
        "⒏",
        "⒐",
        "━",
        "┄",
        "┅",
        "┭",
        "┮",
        "┯",
        "┰",
        "┱",
        "┲",
        "┳",
        "┵",
        "┶",
        "┷",
        "┸",
        "┹",
        "┺",
        "┻",
        "┽",
        "┾",
        "┿",
        "╀",
        "╁",
        "╂",
        "╃",
        "╄",
        "╅",
        "╆",
        "╇",
        "╈",
        "╉",
        "╊",
        "╋",
        "╱",
        "╲",
        "╳",
        "╼",
        "╾",
        "▁",
        "▂",
        "▃",
        "▅",
        "▆",
        "▇",
        "▔",
        "▙",
        "▚",
        "▛",
        "▜",
        "▞",
        "▟",
        "▰",
        "▱",
        "◙",
        "◚",
        "◛",
        "◢",
        "◣",
        "◤",
        "◥",
        "◴",
        "◵",
        "◶",
        "◷",
        "◸",
        "◹",
        "◺",
        "◿",
        "☉",
        "☊",
        "☋",
        "☪",
        "☭",
        "♆",
        "♌",
        "♑",
        "♨",
        "⚹",
        "⛬",
        "❚",
        "⟋",
        "⟍",
        "ﭑ",
        "ﭕ",
        "ﭙ",
        "ﭝ",
        "ﭡ",
        "ﭥ",
        "ﭩ",
        "ﭭ",
        "ﭱ",
        "ﭵ",
        "ﭹ",
        "ﭽ",
        "ﮁ",
        "ﮏ",
        "ﮑ",
        "ﮓ",
        "ﮕ",
        "ﮗ",
        "ﮙ",
        "ﮛ",
        "ﮝ",
        "ﮣ",
        "ﮩ",
        "ﮭ",
        "ﯖ",
        "ﯧ",
        "ﯩ",
        "ﯲ",
        "ﯳ",
        "ﯿ",
        "ﰤ",
        "ﰭ",
        "ﰮ",
        "ﰯ",
        "ﱥ",
        "ﱧ",
        "ﱼ",
        "ﱽ",
        "ﲁ",
        "ﲃ",
        "ﲄ",
        "ﲆ",
        "ﲇ",
        "ﲉ",
        "ﲌ",
        "ﲛ",
        "ﲠ",
        "ﲥ",
        "ﲨ",
        "ﲪ",
        "ﲬ",
        "ﲰ",
        "ﲸ",
        "ﲹ",
        "ﳗ",
        "ﳘ",
        "ﳞ",
        "ﳟ",
        "ﳠ",
        "ﳡ",
        "ﳢ",
        "ﳣ",
        "ﳤ",
        "ﳥ",
        "ﳦ",
        "ﳫ",
        "ﳬ",
        "ﳭ",
        "ﳮ",
        "ﳯ",
        "ﳰ",
        "ﳱ",
        "ﳲ",
        "ﳳ",
        "ﳴ",
        "ﴋ",
        "ﴍ",
        "ﴎ",
        "ﴏ",
        "ﴐ",
        "ﴡ",
        "ﴢ",
        "ﴣ",
        "ﴤ",
        "ﴥ",
        "ﴦ",
        "ﴧ",
        "ﴨ",
        "ﴩ",
        "ﴪ",
        "ﴫ",
        "ﴬ",
        "ﴳ",
        "ﴺ",
        "ﴻ",
        "ﹱ",
        "ﹷ",
        "ﹹ",
        "ﹻ",
        "ﹽ",
        "ﹿ",
        "ﺂ",
        "ﺌ",
        "ﺒ",
        "ﺘ",
        "ﺜ",
        "ﺠ",
        "ﺤ",
        "ﺨ",
        "ﺴ",
        "ﺸ",
        "ﺼ",
        "ﻀ",
        "ﻄ",
        "ﻈ",
        "ﻌ",
        "ﻐ",
        "ﻔ",
        "ﻘ",
        "ﻜ",
        "ﻠ",
        "ﻤ",
        "ﻨ",
        "ﻬ",
        "ﻴ",
        "ﻶ",
        "ﾯ",
        "ﾶ",
        "ﾹ",
        "𐀺",
        "𐁂",
        "𐃰",
        "𐄘",
        "𐄽",
        "𐅇",
        "𐅖",
        "𐅣",
        "𐅥",
        "𐆔",
        "𐆖",
        "𐆜",
        "𐇔",
        "𐇡",
        "𐇨",
        "𐇰",
        "𐊙",
        "𐋂",
        "𐋣",
        "𐋲",
        "𐍕",
        "𐍖",
        "𐍜",
        "𐍤",
        "𐍦",
        "𐍨",
        "𐍲",
        "𐍳",
        "𐍴",
        "𐐏",
        "𐐦",
        "𐐷",
        "𐑅",
        "𐑎",
        "𐒙",
        "𐒜",
        "𐕹",
        "𐖠",
        "𐘄",
        "𐘵",
        "𐙌",
        "𐙠",
        "𐙺",
        "𐜊",
        "𐝦",
        "𐞫",
        "𐠑",
        "𐡣",
        "𐡴",
        "𐢗",
        "𐦃",
        "𐨙",
        "𐨜",
        "𐨟",
        "𐨠",
        "𐨦",
        "𐨧",
        "𐨵",
        "𐫍",
        "𐫓",
        "𐫳",
        "𐮚",
        "𐰒",
        "𐲭",
        "𐴀",
        "𐴁",
        "𐴂",
        "𐴃",
        "𐴄",
        "𐴅",
        "𐴆",
        "𐴇",
        "𐴈",
        "𐴉",
        "𐴊",
        "𐴋",
        "𐴌",
        "𐴍",
        "𐴎",
        "𐴏",
        "𐴐",
        "𐴒",
        "𐴓",
        "𐴔",
        "𐴖",
        "𐴗",
        "𐴘",
        "𐴚",
        "𐴛",
        "𐴜",
        "𐴝",
        "𐴟",
        "𐴡",
        "𐹴",
        "𐹶",
        "𐼒",
        "𐼓",
        "𐼙",
        "𐼟",
        "𐼧",
        "𐼲",
        "𐼳",
        "𐼾",
        "𐼿",
        "𐽁",
        "𐽂",
        "𐽅",
        "𑀇",
        "𑀞",
        "𑀠",
        "𑀢",
        "𑀣",
        "𑀨",
        "𑀪",
        "𑁋",
        "𑁒",
        "𑁓",
        "𑁔",
        "𑁨",
        "𑂎",
        "𑂐",
        "𑂔",
        "𑂖",
        "𑂝",
        "𑂟",
        "𑂦",
        "𑂨",
        "𑂬",
        "𑂾",
        "𑂿",
        "𑄘",
        "𑆇",
        "𑆟",
        "𑇕",
        "𑈕",
        "𑈟",
        "𑈦",
        "𑈧",
        "𑊂",
        "𑊑",
        "𑊛",
        "𑊟",
        "𑊥",
        "𑊰",
        "𑊶",
        "𑊷",
        "𑊸",
        "𑊹",
        "𑊺",
        "𑊿",
        "𑋀",
        "𑋁",
        "𑋅",
        "𑋆",
        "𑋇",
        "𑋋",
        "𑋎",
        "𑋏",
        "𑋐",
        "𑋑",
        "𑋒",
        "𑋔",
        "𑋗",
        "𑋘",
        "𑋴",
        "𑋵",
        "𑋷",
        "𑋸",
        "𑌇",
        "𑌉",
        "𑌓",
        "𑌙",
        "𑌤",
        "𑌦",
        "𑌯",
        "𑌰",
        "𑑐",
        "𑑓",
        "𑑖",
        "𑑝",
        "𑖆",
        "𑖖",
        "𑖛",
        "𑖣",
        "𑖤",
        "𑖪",
        "𑗉",
        "𑗛",
        "𑙄",
        "𑙐",
        "𑙑",
        "𑙩",
        "𑚅",
        "𑚋",
        "𑚑",
        "𑚒",
        "𑚞",
        "𑝃",
        "𑠔",
        "𑠕",
        "𑣿",
        "𑤂",
        "𑤄",
        "𑤐",
        "𑤨",
        "𑥓",
        "𑥕",
        "𑥘",
        "𑥙",
        "𑦮",
        "𑦯",
        "𑦰",
        "𑦲",
        "𑦳",
        "𑦵",
        "𑦷",
        "𑦻",
        "𑧀",
        "𑧃",
        "𑧆",
        "𑧇",
        "𑧉",
        "𑧍",
        "𑧏",
        "𑧣",
        "𑨣",
        "𑨤",
        "𑨥",
        "𑨦",
        "𑪝",
        "𑬂",
        "𑬃",
        "𑬆",
        "𑰂",
        "𑰆",
        "𑰈",
        "𑰏",
        "𑰣",
        "𑰨",
        "𑰬",
        "𑰮",
        "𑱘",
        "𑱝",
        "𑱨",
        "𑴈",
        "𑻸",
        "𑼗",
        "𑽄",
        "𑿂",
        "𑿈",
        "𑿚",
        "𒿆",
        "𒿇",
        "𒿊",
        "𒿢",
        "𖩱",
        "𖩼",
        "𖩽",
        "𖪟",
        "𖪤",
        "𖪩",
        "𖪮",
        "𖪴",
        "𖪶",
        "𖫆",
        "𖫇",
        "𖬍",
        "𖬏",
        "𖬚",
        "𖬛",
        "𖬤",
        "𖭓",
        "𖭮",
        "𖭽",
        "𖮂",
        "𖮃",
        "𖮊",
        "𖮋",
        "𖹇",
        "𖹉",
        "𖹌",
        "𖹏",
        "𖹖",
        "𖹝",
        "𖹤",
        "𖹧",
        "𖹮",
        "𖹿",
        "𖼉",
        "𖼑",
        "𖼕",
        "𖼙",
        "𖼛",
        "𖼝",
        "𖼤",
        "𖼾",
        "𖽁",
        "𖿰",
        "𘬠",
        "𘬥",
        "𘬭",
        "𘬲",
        "𘬸",
        "𘬹",
        "𘭋",
        "𘭑",
        "𘭴",
        "𘮅",
        "𘮆",
        "𘮉",
        "𘮑",
        "𘮕",
        "𘮗",
        "𘮘",
        "𘮙",
        "𘮚",
        "𘮛",
        "𘮝",
        "𘮞",
        "𘮟",
        "𘮠",
        "𘮢",
        "𘯂",
        "𘯌",
        "𘯕",
        "𘯗",
        "𘯘",
        "𘯛",
        "𘯜",
        "𘯞",
        "𘯟",
        "𘯣",
        "𘯤",
        "𘯯",
        "𘯺",
        "𘰅",
        "𘰉",
        "𘰌",
        "𘰍",
        "𘰎",
        "𘰏",
        "𘰒",
        "𘰳",
        "𘱋",
        "𘱌",
        "𘱍",
        "𘱎",
        "𘱒",
        "𘱖",
        "𘱗",
        "𘱚",
        "𘱝",
        "𘱤",
        "𘱥",
        "𘱧",
        "𘱶",
        "𘱹",
        "𘱼",
        "𘲂",
        "𘲆",
        "𘲔",
        "𘲗",
        "𘲘",
        "𘲚",
        "𘲛",
        "𘲞",
        "𘲡",
        "𘲢",
        "𘲣",
        "𘲤",
        "𘲭",
        "𘲮",
        "𘲯",
        "𘲰",
        "𘲵",
        "𘲷",
        "𘳈",
        "𘳊",
        "𘳌",
        "𚿲",
        "𚿶",
        "𚿺",
        "𚿾",
        "𛁆",
        "𛁚",
        "𛁠",
        "𛂍",
        "𛂽",
        "𛃚",
        "𛃰",
        "𛄲",
        "𛅦",
        "𛆆",
        "𛆏",
        "𛆖",
        "𛆚",
        "𛆛",
        "𛆟",
        "𛆦",
        "𛆮",
        "𛆰",
        "𛆿",
        "𛇂",
        "𛇄",
        "𛇅",
        "𛇋",
        "𛇍",
        "𛇎",
        "𛇒",
        "𛇓",
        "𛇔",
        "𛇕",
        "𛇗",
        "𛇞",
        "𛇠",
        "𛇡",
        "𛇩",
        "𛇪",
        "𛇫",
        "𛇬",
        "𛇲",
        "𛇷",
        "𛈁",
        "𛈂",
        "𛈄",
        "𛈆",
        "𛈇",
        "𛈈",
        "𛈏",
        "𛈑",
        "𛈒",
        "𛈗",
        "𛈚",
        "𛈟",
        "𛈡",
        "𛈣",
        "𛈥",
        "𛈫",
        "𛈬",
        "𛈭",
        "𛈯",
        "𛈲",
        "𛈳",
        "𛈶",
        "𛈷",
        "𛈹",
        "𛈻",
        "𛈾",
        "𛉀",
        "𛉊",
        "𛉋",
        "𛉍",
        "𛉎",
        "𛉓",
        "𛉗",
        "𛉘",
        "𛉚",
        "𛉛",
        "𛉝",
        "𛉠",
        "𛉡",
        "𛉢",
        "𛉤",
        "𛉨",
        "𛉪",
        "𛉫",
        "𛉬",
        "𛉹",
        "𛉻",
        "𛊆",
        "𛊉",
        "𛊍",
        "𛊓",
        "𛊕",
        "𛊚",
        "𛊛",
        "𛊟",
        "𛊢",
        "𛊤",
        "𛊥",
        "𛊦",
        "𛊨",
        "𛊩",
        "𛊪",
        "𛊫",
        "𛊭",
        "𛊰",
        "𛊱",
        "𛊸",
        "𛊽",
        "𛋄",
        "𛋏",
        "𛋒",
        "𛋟",
        "𛋭",
        "𛋯",
        "𛋳",
        "𛰰",
        "𜽘",
        "𜽬",
        "𜾀",
        "𜾇",
        "𜾋",
        "𜾑",
        "𜿁",
        "𝀒",
        "𝀕",
        "𝁤",
        "𝁿",
        "𝂇",
        "𝂌",
        "𝂥",
        "𝂰",
        "𝂾",
        "𝃄",
        "𝃈",
        "𝃴",
        "𝄋",
        "𝄴",
        "𝄵",
        "𝄺",
        "𝄻",
        "𝄼",
        "𝅁",
        "𝅂",
        "𝆌",
        "𝆏",
        "𝆐",
        "𝆑",
        "𝆜",
        "𝆝",
        "𝆡",
        "𝆣",
        "𝆤",
        "𝆳",
        "𝈚",
        "𝋄",
        "𝋉",
        "𝋎",
        "𝋑",
        "𝋒",
        "𝋓",
        "𝐉",
        "𝐐",
        "𝐓",
        "𝐕",
        "𝐘",
        "𝐦",
        "𝐰",
        "𝐴",
        "𝐶",
        "𝐺",
        "𝐾",
        "𝑂",
        "𝑄",
        "𝑆",
        "𝑈",
        "𝑏",
        "𝑛",
        "𝑞",
        "𝑟",
        "𝑢",
        "𝑦",
        "𝑰",
        "𝑻",
        "𝑽",
        "𝒀",
        "𝒂",
        "𝒄",
        "𝒆",
        "𝒇",
        "𝒐",
        "𝒒",
        "𝒔",
        "𝒗",
        "𝒪",
        "𝒷",
        "𝒻",
        "𝓁",
        "𝓞",
        "𝓫",
        "𝓯",
        "𝓵",
        "𝔄",
        "𝔅",
        "𝔇",
        "𝔈",
        "𝔉",
        "𝔊",
        "𝔍",
        "𝔎",
        "𝔏",
        "𝔐",
        "𝔑",
        "𝔒",
        "𝔓",
        "𝔔",
        "𝔖",
        "𝔗",
        "𝔘",
        "𝔚",
        "𝕁",
        "𝕂",
        "𝕄",
        "𝕍",
        "𝕎",
        "𝕐",
        "𝕜",
        "𝕞",
        "𝕨",
        "𝕩",
        "𝕬",
        "𝕭",
        "𝕯",
        "𝕰",
        "𝕱",
        "𝕲",
        "𝕴",
        "𝕵",
        "𝕶",
        "𝕷",
        "𝕸",
        "𝕹",
        "𝕺",
        "𝕻",
        "𝕼",
        "𝕽",
        "𝕾",
        "𝕿",
        "𝖀",
        "𝖂",
        "𝗝",
        "𝗤",
        "𝗧",
        "𝗩",
        "𝗬",
        "𝗺",
        "𝘄",
        "𝘈",
        "𝘊",
        "𝘎",
        "𝘒",
        "𝘖",
        "𝘘",
        "𝘚",
        "𝘜",
        "𝘣",
        "𝘩",
        "𝘯",
        "𝘲",
        "𝘳",
        "𝘶",
        "𝘺",
        "𝙄",
        "𝙏",
        "𝙑",
        "𝙔",
        "𝙖",
        "𝙘",
        "𝙚",
        "𝙛",
        "𝙤",
        "𝙦",
        "𝙨",
        "𝙫",
        "𝙱",
        "𝙲",
        "𝙳",
        "𝙴",
        "𝙵",
        "𝙷",
        "𝙿",
        "𝚇",
        "𝚊",
        "𝚋",
        "𝚌",
        "𝚎",
        "𝚐",
        "𝚑",
        "𝚔",
        "𝚗",
        "𝚘",
        "𝚙",
        "𝚚",
        "𝚟",
        "𝚡",
        "𝚢",
        "𝚥",
        "𝚫",
        "𝚲",
        "𝚷",
        "𝚻",
        "𝚼",
        "𝚽",
        "𝚿",
        "𝛀",
        "𝛁",
        "𝛄",
        "𝛔",
        "𝛗",
        "𝛘",
        "𝛙",
        "𝛚",
        "𝛝",
        "𝛟",
        "𝛡",
        "𝛢",
        "𝛥",
        "𝛩",
        "𝛫",
        "𝛬",
        "𝛯",
        "𝛰",
        "𝛳",
        "𝛹",
        "𝛼",
        "𝛾",
        "𝜂",
        "𝜃",
        "𝜈",
        "𝜎",
        "𝜐",
        "𝜑",
        "𝜔",
        "𝜕",
        "𝜗",
        "𝜘",
        "𝜙",
        "𝜛",
        "𝜤",
        "𝜯",
        "𝜰",
        "𝜵",
        "𝜹",
        "𝜺",
        "𝜽",
        "𝝀",
        "𝝃",
        "𝝄",
        "𝝅",
        "𝝇",
        "𝝐",
        "𝝔",
        "𝝙",
        "𝝠",
        "𝝥",
        "𝝩",
        "𝝪",
        "𝝫",
        "𝝭",
        "𝝮",
        "𝝯",
        "𝝲",
        "𝞂",
        "𝞅",
        "𝞆",
        "𝞇",
        "𝞈",
        "𝞋",
        "𝞍",
        "𝞏",
        "𝞘",
        "𝞣",
        "𝞤",
        "𝞩",
        "𝞭",
        "𝞮",
        "𝞱",
        "𝞴",
        "𝞷",
        "𝞸",
        "𝞹",
        "𝞻",
        "𝟄",
        "𝟈",
        "𝠀",
        "𝠁",
        "𝠂",
        "𝠆",
        "𝠇",
        "𝠈",
        "𝠉",
        "𝠊",
        "𝠎",
        "𝠏",
        "𝠐",
        "𝠑",
        "𝠓",
        "𝠕",
        "𝠖",
        "𝠗",
        "𝠘",
        "𝠚",
        "𝠛",
        "𝠜",
        "𝠝",
        "𝠧",
        "𝠴",
        "𝡇",
        "𝡈",
        "𝡊",
        "𝡠",
        "𝡭",
        "𝡱",
        "𝡶",
        "𝢁",
        "𝢌",
        "𝢯",
        "𝢵",
        "𝢶",
        "𝣀",
        "𝣆",
        "𝣇",
        "𝣈",
        "𝣊",
        "𝣤",
        "𝣥",
        "𝣺",
        "𝣻",
        "𝣼",
        "𝣽",
        "𝣾",
        "𝣿",
        "𝤁",
        "𝤃",
        "𝤄",
        "𝤚",
        "𝤟",
        "𝤹",
        "𝥅",
        "𝥷",
        "𝥼",
        "𝥽",
        "𝦉",
        "𝦌",
        "𝦔",
        "𝦖",
        "𝦗",
        "𝦚",
        "𝦛",
        "𝦩",
        "𝧕",
        "𝪆",
        "𝼘",
        "𝼪",
        "𞁬",
        "𞋈",
        "𞋎",
        "𞋏",
        "𞋐",
        "𞋑",
        "𞋥",
        "𞋰",
        "𞋲",
        "𞋵",
        "𞋷",
        "𞠀",
        "𞠁",
        "𞠂",
        "𞠃",
        "𞠆",
        "𞠧",
        "𞡚",
        "𞡛",
        "𞡜",
        "𞡝",
        "𞡻",
        "𞡾",
        "𞢑",
        "𞢕",
        "𞣃",
        "𞣎",
        "𞱴",
        "𞱷",
        "𞲆",
        "𞲋",
        "𞲤",
        "𞲫",
        "𞲱",
        "𞹎",
        "𞹑",
        "𞹔",
        "𞹙",
        "𞹨",
        "𞹺",
        "𞺈",
        "𞺎",
        "𞺑",
        "𞺔",
        "𞺙",
        "𞺚",
        "𞺬",
        "🄀",
        "🄂",
        "🄯",
        "🍦",
        "🎛",
        "🎸",
        "🏮",
        "🐛",
        "👙",
        "👵",
        "💃",
        "💈",
        "📄",
        "📎",
        "📓",
        "🔖",
        "🖁",
        "🖗",
        "🖢",
        "🖣",
        "🗴",
        "🗶",
        "🗸",
        "🙶",
        "🙷",
        "🙸",
        "🚪",
        "🛉",
        "🛊",
        "🛢",
        "🝏",
        "🠥",
        "🠧",
        "🢜",
        "🢬",
        "🤆",
        "🤰",
        "🥊",
        "🥛",
        "🥤",
        "🥬",
        "🧉",
        "🧋",
        "🧍",
        "🧬",
        "🩱",
        "🪃",
        "🪴",
        "🪵",
        "🫙",
        "🬂",
        "🬅",
        "🬆",
        "🬈",
        "🬊",
        "🬋",
        "🬌",
        "🬍",
        "🬎",
        "🬑",
        "🬒",
        "🬔",
        "🬕",
        "🬖",
        "🬗",
        "🬘",
        "🬙",
        "🬚",
        "🬛",
        "🬜",
        "🬝",
        "🬟",
        "🬡",
        "🬢",
        "🬣",
        "🬤",
        "🬥",
        "🬧",
        "🬨",
        "🬩",
        "🬪",
        "🬫",
        "🬬",
        "🬭",
        "🬮",
        "🬯",
        "🬰",
        "🬱",
        "🬲",
        "🬳",
        "🬴",
        "🬵",
        "🬶",
        "🬷",
        "🬸",
        "🬹",
        "🬺",
        "🬻",
        "🬽",
        "🬿",
        "🭁",
        "🭂",
        "🭃",
        "🭄",
        "🭅",
        "🭆",
        "🭈",
        "🭊",
        "🭌",
        "🭍",
        "🭎",
        "🭏",
        "🭐",
        "🭑",
        "🭒",
        "🭓",
        "🭔",
        "🭕",
        "🭖",
        "🭘",
        "🭚",
        "🭜",
        "🭝",
        "🭞",
        "🭟",
        "🭠",
        "🭡",
        "🭣",
        "🭥",
        "🭧",
        "🭨",
        "🭩",
        "🭪",
        "🭫",
        "🭭",
        "🭯",
        "🭶",
        "🭷",
        "🭸",
        "🭹",
        "🭺",
        "🭻",
        "🭼",
        "🭽",
        "🭾",
        "🭿",
        "🮀",
        "🮁",
        "🮂",
        "🮃",
        "🮄",
        "🮅",
        "🮆",
        "🮎",
        "🮏",
        "🮐",
        "🮑",
        "🮒",
        "🮔",
        "🮕",
        "🮖",
        "🮗",
        "🮘",
        "🮙",
        "🮚",
        "🮛",
        "🮜",
        "🮝",
        "🮞",
        "🮟",
        "🮦",
        "🮧",
        "🮨",
        "🮩",
        "🮪",
        "🮫",
        "🮬",
        "🮭",
        "🮮",
        "🮯",
        "🮱",
        "🮴",
        "🮵",
        "🮶",
        "🮹",
        "🮺",
        "🮼",
        "🯀",
        "🯁",
        "🯂",
        "🯄"
    ],
    [
        "\u0001",
        "\u0002",
        "\u0003",
        "\u0004",
        "\u0005",
        "\u0006",
        "\u0007",
        "\b",
        "\u000e",
        "\u000f",
        "\u0010",
        "\u0011",
        "\u0012",
        "\u0013",
        "\u0014",
        "\u0015",
        "\u0016",
        "\u0017",
        "\u0018",
        "\u0019",
        "\u001a",
        "\u001b",
        "\u001c",
        "\u001d",
        "\u001e",
        "\u001f",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "؀",
        "؁",
        "؂",
        "؃",
        "؄",
        "؅",
        "܏",
        "ܐ",
        "豈",
        "更",
        "車",
        "賈",
        "滑",
        "串",
        "句",
        "龜",
        "龜",
        "契",
        "金",
        "喇",
        "奈",
        "懶",
        "癩",
        "羅",
        "蘿",
        "螺",
        "裸",
        "邏",
        "樂",
        "洛",
        "烙",
        "珞",
        "落",
        "酪",
        "駱",
        "亂",
        "卵",
        "欄",
        "爛",
        "蘭",
        "鸞",
        "嵐",
        "濫",
        "藍",
        "襤",
        "拉",
        "臘",
        "蠟",
        "廊",
        "朗",
        "浪",
        "狼",
        "郎",
        "來",
        "冷",
        "勞",
        "擄",
        "櫓",
        "爐",
        "盧",
        "老",
        "蘆",
        "虜",
        "路",
        "露",
        "魯",
        "鷺",
        "碌",
        "祿",
        "綠",
        "菉",
        "錄",
        "鹿",
        "論",
        "壟",
        "弄",
        "籠",
        "聾",
        "牢",
        "磊",
        "賂",
        "雷",
        "壘",
        "屢",
        "樓",
        "淚",
        "漏",
        "累",
        "縷",
        "陋",
        "勒",
        "肋",
        "凜",
        "凌",
        "稜",
        "綾",
        "菱",
        "陵",
        "讀",
        "拏",
        "樂",
        "諾",
        "丹",
        "寧",
        "怒",
        "率",
        "異",
        "北",
        "磻",
        "便",
        "復",
        "不",
        "泌",
        "數",
        "索",
        "參",
        "塞",
        "省",
        "葉",
        "說",
        "殺",
        "辰",
        "沈",
        "拾",
        "若",
        "掠",
        "略",
        "亮",
        "兩",
        "凉",
        "梁",
        "糧",
        "良",
        "諒",
        "量",
        "勵",
        "呂",
        "女",
        "廬",
        "旅",
        "濾",
        "礪",
        "閭",
        "驪",
        "麗",
        "黎",
        "力",
        "曆",
        "歷",
        "轢",
        "年",
        "憐",
        "戀",
        "撚",
        "漣",
        "煉",
        "璉",
        "秊",
        "練",
        "聯",
        "輦",
        "蓮",
        "連",
        "鍊",
        "列",
        "劣",
        "咽",
        "烈",
        "裂",
        "說",
        "廉",
        "念",
        "捻",
        "殮",
        "簾",
        "獵",
        "令",
        "囹",
        "寧",
        "嶺",
        "怜",
        "玲",
        "瑩",
        "羚",
        "聆",
        "鈴",
        "零",
        "靈",
        "領",
        "例",
        "禮",
        "醴",
        "隸",
        "惡",
        "了",
        "僚",
        "寮",
        "尿",
        "料",
        "樂",
        "燎",
        "療",
        "蓼",
        "遼",
        "龍",
        "暈",
        "阮",
        "劉",
        "杻",
        "柳",
        "流",
        "溜",
        "琉",
        "留",
        "硫",
        "紐",
        "類",
        "六",
        "戮",
        "陸",
        "倫",
        "崙",
        "淪",
        "輪",
        "律",
        "慄",
        "栗",
        "率",
        "隆",
        "利",
        "吏",
        "履",
        "易",
        "李",
        "梨",
        "泥",
        "理",
        "痢",
        "罹",
        "裏",
        "裡",
        "里",
        "離",
        "匿",
        "溺",
        "吝",
        "燐",
        "璘",
        "藺",
        "隣",
        "鱗",
        "麟",
        "林",
        "淋",
        "臨",
        "立",
        "笠",
        "粒",
        "狀",
        "炙",
        "識",
        "什",
        "茶",
        "刺",
        "切",
        "度",
        "拓",
        "糖",
        "宅",
        "洞",
        "暴",
        "輻",
        "行",
        "降",
        "見",
        "廓",
        "兀",
        "嗀",
        "﨎",
        "﨏",
        "塚",
        "﨑",
        "晴",
        "﨓",
        "﨔",
        "凞",
        "猪",
        "益",
        "礼",
        "神",
        "祥",
        "福",
        "靖",
        "精",
        "羽",
        "﨟",
        "蘒",
        "﨡",
        "諸",
        "﨣",
        "﨤",
        "逸",
        "都",
        "﨧",
        "﨨",
        "﨩",
        "飯",
        "飼",
        "館",
        "鶴",
        "郞",
        "隷",
        "侮",
        "僧",
        "免",
        "勉",
        "勤",
        "卑",
        "喝",
        "嘆",
        "器",
        "塀",
        "墨",
        "層",
        "屮",
        "悔",
        "慨",
        "憎",
        "懲",
        "敏",
        "既",
        "暑",
        "梅",
        "海",
        "渚",
        "漢",
        "煮",
        "爫",
        "琢",
        "碑",
        "社",
        "祉",
        "祈",
        "祐",
        "祖",
        "祝",
        "禍",
        "禎",
        "穀",
        "突",
        "節",
        "練",
        "縉",
        "繁",
        "署",
        "者",
        "臭",
        "艹",
        "艹",
        "著",
        "褐",
        "視",
        "謁",
        "謹",
        "賓",
        "贈",
        "辶",
        "逸",
        "難",
        "響",
        "頻",
        "恵",
        "𤋮",
        "舘",
        "﩮",
        "﩯",
        "並",
        "况",
        "全",
        "侀",
        "充",
        "冀",
        "勇",
        "勺",
        "喝",
        "啕",
        "喙",
        "嗢",
        "塚",
        "墳",
        "奄",
        "奔",
        "婢",
        "嬨",
        "廒",
        "廙",
        "彩",
        "徭",
        "惘",
        "慎",
        "愈",
        "憎",
        "慠",
        "懲",
        "戴",
        "揄",
        "搜",
        "摒",
        "敖",
        "晴",
        "朗",
        "望",
        "杖",
        "歹",
        "殺",
        "流",
        "滛",
        "滋",
        "漢",
        "瀞",
        "煮",
        "瞧",
        "爵",
        "犯",
        "猪",
        "瑱",
        "甆",
        "画",
        "瘝",
        "瘟",
        "益",
        "盛",
        "直",
        "睊",
        "着",
        "磌",
        "窱",
        "節",
        "类",
        "絛",
        "練",
        "缾",
        "者",
        "荒",
        "華",
        "蝹",
        "襁",
        "覆",
        "視",
        "調",
        "諸",
        "請",
        "謁",
        "諾",
        "諭",
        "謹",
        "變",
        "贈",
        "輸",
        "遲",
        "醙",
        "鉶",
        "陼",
        "難",
        "靖",
        "韛",
        "響",
        "頋",
        "頻",
        "鬒",
        "龜",
        "𢡊",
        "𢡄",
        "𣏕",
        "㮝",
        "䀘",
        "䀹",
        "𥉉",
        "𥳐",
        "𧻓",
        "齃",
        "龎",
        "﫚",
        "﫛",
        "﫜",
        "﫝",
        "﫞",
        "﫟",
        "﫠",
        "﫡",
        "﫢",
        "﫣",
        "﫤",
        "﫥",
        "﫦",
        "﫧",
        "﫨",
        "﫩",
        "﫪",
        "﫫",
        "﫬",
        "﫭",
        "﫮",
        "﫯",
        "﫰",
        "﫱",
        "﫲",
        "﫳",
        "﫴",
        "﫵",
        "﫶",
        "﫷",
        "﫸",
        "﫹",
        "﫺",
        "﫻",
        "﫼",
        "﫽",
        "﫾",
        "﫿",
        "ﳧ",
        "ﳨ",
        "ﳩ",
        "ﳪ",
        "ﴴ",
        "ﴵ",
        "ﴶ",
        "ﴷ",
        "ﴸ",
        "ﴹ",
        "﵃",
        "﵅",
        "﵆",
        "﵊",
        "﵌",
        "﵎",
        "ﵜ",
        "ﵝ",
        "ﵞ",
        "ﵟ",
        "ﵤ",
        "ﵥ",
        "ﵧ",
        "ﵩ",
        "ﵮ",
        "ﵯ",
        "ﵴ",
        "ﵸ",
        "ﵺ",
        "ﵻ",
        "ﶋ",
        "ﶙ",
        "ﶞ",
        "ﶟ",
        "ﶠ",
        "ﶡ",
        "ﶢ",
        "ﶥ",
        "ﶦ",
        "ﶧ",
        "ﶨ",
        "ﶩ",
        "ﶪ",
        "ﶫ",
        "ﶶ",
        "ﶷ",
        "ﶹ",
        "ﶾ",
        "ﶿ",
        "ﷀ",
        "ﷂ",
        "ﷃ",
        "ﷄ",
        "ﷆ",
        "ﷇ",
        "﷏",
        "﷐",
        "﷑",
        "﷒",
        "﷓",
        "﷔",
        "﷕",
        "﷖",
        "﷗",
        "﷘",
        "﷙",
        "﷚",
        "﷛",
        "﷜",
        "﷝",
        "﷞",
        "﷟",
        "﷠",
        "﷡",
        "﷢",
        "﷣",
        "﷤",
        "﷥",
        "﷦",
        "﷧",
        "﷨",
        "﷩",
        "﷪",
        "﷫",
        "﷬",
        "﷭",
        "﷮",
        "﷯",
        "ﷶ",
        "ﷺ",
        "﷽",
        "﷾",
        "﹋",
        "﹌",
        "﹏",
        "！",
        "＂",
        "＃",
        "＄",
        "％",
        "＆",
        "＇",
        "（",
        "）",
        "＊",
        "，",
        "－",
        "．",
        "／",
        "０",
        "１",
        "２",
        "３",
        "４",
        "５",
        "６",
        "７",
        "８",
        "９",
        "：",
        "；",
        "＜",
        "＝",
        "＞",
        "？",
        "＠",
        "Ａ",
        "Ｂ",
        "Ｃ",
        "Ｄ",
        "Ｅ",
        "Ｆ",
        "Ｇ",
        "Ｈ",
        "Ｉ",
        "Ｊ",
        "Ｋ",
        "Ｌ",
        "Ｍ",
        "Ｎ",
        "Ｏ",
        "Ｐ",
        "Ｑ",
        "Ｒ",
        "Ｓ",
        "Ｔ",
        "Ｕ",
        "Ｖ",
        "Ｗ",
        "Ｘ",
        "Ｙ",
        "Ｚ",
        "［",
        "＼",
        "］",
        "＾",
        "＿",
        "｀",
        "ａ",
        "ｂ",
        "ｃ",
        "ｄ",
        "ｅ",
        "ｆ",
        "ｇ",
        "ｈ",
        "ｉ",
        "ｊ",
        "ｋ",
        "ｌ",
        "ｍ",
        "ｎ",
        "ｏ",
        "ｐ",
        "ｑ",
        "ｒ",
        "ｓ",
        "ｔ",
        "ｕ",
        "ｖ",
        "ｗ",
        "ｘ",
        "ｙ",
        "ｚ",
        "｛",
        "｜",
        "｝",
        "～",
        "￹",
        "￺",
        "￻",
        "𐂶",
        "𐃁",
        "𐃌",
        "𐃍",
        "𐃵",
        "𐄪",
        "𐄳",
        "𐆘",
        "𐎢",
        "𐎥",
        "𐎦",
        "𐎧",
        "𐎬",
        "𐎯",
        "𐎵",
        "𐎸",
        "𐎽",
        "𐏃",
        "𐏈",
        "𐏉",
        "𐏊",
        "𐏋",
        "𐏍",
        "𐏎",
        "𐛋",
        "𐫁",
        "𐫂",
        "𐫆",
        "𐫐",
        "𐫑",
        "𐫒",
        "𐫔",
        "𐫕",
        "𐫖",
        "𐫘",
        "𐫙",
        "𐫚",
        "𐫛",
        "𐫜",
        "𐫝",
        "𐫞",
        "𐫟",
        "𐫠",
        "𐫢",
        "𐫣",
        "𐴑",
        "𐴕",
        "𐼽",
        "𑀃",
        "𑀄",
        "𑆆",
        "𑆉",
        "𑆊",
        "𑆋",
        "𑆌",
        "𑆍",
        "𑆎",
        "𑆏",
        "𑆑",
        "𑆒",
        "𑆓",
        "𑆔",
        "𑆕",
        "𑆖",
        "𑆗",
        "𑆙",
        "𑆚",
        "𑆛",
        "𑆝",
        "𑆞",
        "𑆡",
        "𑆢",
        "𑆣",
        "𑆤",
        "𑆥",
        "𑆦",
        "𑆧",
        "𑆨",
        "𑆩",
        "𑆪",
        "𑆫",
        "𑆬",
        "𑆭",
        "𑆮",
        "𑆯",
        "𑆰",
        "𑆱",
        "𑆲",
        "𑇜",
        "𑌐",
        "𑌣",
        "𑐃",
        "𑐄",
        "𑐅",
        "𑐎",
        "𑐏",
        "𑐑",
        "𑐒",
        "𑐓",
        "𑐔",
        "𑐕",
        "𑐖",
        "𑐙",
        "𑐚",
        "𑐜",
        "𑐝",
        "𑐟",
        "𑐡",
        "𑐣",
        "𑐤",
        "𑐥",
        "𑐦",
        "𑐧",
        "𑐨",
        "𑐩",
        "𑐪",
        "𑐫",
        "𑐬",
        "𑐭",
        "𑐮",
        "𑐯",
        "𑐰",
        "𑐲",
        "𑐳",
        "𑐴",
        "𑑠",
        "𑑡",
        "𑒁",
        "𑒂",
        "𑒃",
        "𑒄",
        "𑒏",
        "𑒒",
        "𑒔",
        "𑒕",
        "𑒖",
        "𑒗",
        "𑒚",
        "𑒛",
        "𑒜",
        "𑒞",
        "𑒠",
        "𑒢",
        "𑒤",
        "𑒥",
        "𑒦",
        "𑒧",
        "𑒨",
        "𑒩",
        "𑒪",
        "𑒫",
        "𑒭",
        "𑒮",
        "𑒯",
        "𑘀",
        "𑘁",
        "𑘂",
        "𑘃",
        "𑘄",
        "𑘅",
        "𑘆",
        "𑘇",
        "𑘈",
        "𑘉",
        "𑘊",
        "𑘋",
        "𑘌",
        "𑘍",
        "𑘎",
        "𑘏",
        "𑘐",
        "𑘑",
        "𑘒",
        "𑘓",
        "𑘔",
        "𑘕",
        "𑘖",
        "𑘗",
        "𑘘",
        "𑘙",
        "𑘚",
        "𑘛",
        "𑘜",
        "𑘝",
        "𑘞",
        "𑘟",
        "𑘠",
        "𑘡",
        "𑘢",
        "𑘣",
        "𑘤",
        "𑘥",
        "𑘦",
        "𑘧",
        "𑘨",
        "𑘩",
        "𑘪",
        "𑘫",
        "𑘬",
        "𑘭",
        "𑘮",
        "𑘯",
        "𑙦",
        "𑜸",
        "𑵠",
        "𑵡",
        "𑵢",
        "𑵣",
        "𑵤",
        "𑵥",
        "𑵧",
        "𑵨",
        "𑵪",
        "𑵫",
        "𑵬",
        "𑵭",
        "𑵮",
        "𑵯",
        "𑵰",
        "𑵱",
        "𑵲",
        "𑵳",
        "𑵴",
        "𑵵",
        "𑵶",
        "𑵷",
        "𑵸",
        "𑵹",
        "𑵺",
        "𑵻",
        "𑵼",
        "𑵽",
        "𑵾",
        "𑵿",
        "𑶀",
        "𑶁",
        "𑶂",
        "𑶃",
        "𑶄",
        "𑶅",
        "𑶆",
        "𑶇",
        "𑶈",
        "𑶉",
        "𑽏",
        "𑿭",
        "𛀉",
        "𛀔",
        "𛀕",
        "𛀗",
        "𛀘",
        "𛀝",
        "𛀞",
        "𛀣",
        "𛀹",
        "𛁌",
        "𛁕",
        "𛁖",
        "𛁘",
        "𛁜",
        "𛁥",
        "𛂅",
        "𛂆",
        "𛂐",
        "𛂕",
        "𛂗",
        "𛂚",
        "𛂠",
        "𛂡",
        "𛂢",
        "𛂤",
        "𛂥",
        "𛂧",
        "𛂭",
        "𛂮",
        "𛂱",
        "𛂳",
        "𛂴",
        "𛂺",
        "𛂻",
        "𛃁",
        "𛃅",
        "𛃆",
        "𛃈",
        "𛃊",
        "𛃓",
        "𛃞",
        "𛃣",
        "𛃱",
        "𛃷",
        "𛃾",
        "𛄃",
        "𛄅",
        "𛄆",
        "𛄇",
        "𛄈",
        "𛄘",
        "𛄙",
        "𛄚",
        "𛄛",
        "𜽪",
        "𜾹",
        "𜾺",
        "𜾻",
        "𜾼",
        "𜾽",
        "𝁅",
        "𝁵",
        "𝄖",
        "𝄗",
        "𝄘",
        "𝄙",
        "𝄚",
        "𝄛",
        "𝄜",
        "𝄸",
        "𝄹",
        "𝅙",
        "𝆮",
        "𝇚",
        "𝓜",
        "𝠫",
        "𝠬",
        "𝠺",
        "𝠻",
        "𝠽",
        "𝠾",
        "𝠿",
        "𝡀",
        "𝡁",
        "𝡂",
        "𝡃",
        "𝡍",
        "𝡎",
        "𝡏",
        "𝡐",
        "𝡑",
        "𝢀",
        "𝢏",
        "𝢛",
        "𝢝",
        "𝢞",
        "𝢟",
        "𝢧",
        "𝢬",
        "𝢭",
        "𝣅",
        "𝣛",
        "𝣝",
        "𝣨",
        "𝣰",
        "𝣱",
        "𝣲",
        "𝣳",
        "𝣴",
        "𝤠",
        "𝦫",
        "𝦬",
        "𝦲",
        "𝦳",
        "𝦶",
        "𝧄",
        "𝧅",
        "𝧓",
        "𝧔",
        "𝧜",
        "𝩯",
        "𝩰",
        "𝪇",
        "𝪈",
        "𝪉",
        "𝪊",
        "𝪋",
        "🁜",
        "🄰",
        "🄱",
        "🄲",
        "🄳",
        "🄴",
        "🄵",
        "🄶",
        "🄷",
        "🄸",
        "🄹",
        "🄺",
        "🄻",
        "🄼",
        "🄽",
        "🄾",
        "🄿",
        "🅀",
        "🅁",
        "🅂",
        "🅃",
        "🅄",
        "🅅",
        "🅆",
        "🅇",
        "🅈",
        "🅉",
        "🅊",
        "🅋",
        "🅌",
        "🅍",
        "🅎",
        "🅏",
        "🅰",
        "🅱",
        "🅲",
        "🅳",
        "🅴",
        "🅵",
        "🅶",
        "🅷",
        "🅸",
        "🅹",
        "🅺",
        "🅻",
        "🅼",
        "🅽",
        "🅾",
        "🅿",
        "🆀",
        "🆁",
        "🆂",
        "🆃",
        "🆄",
        "🆅",
        "🆆",
        "🆇",
        "🆈",
        "🆉",
        "🆊",
        "🆋",
        "🆌",
        "🆍",
        "🆎",
        "🆏",
        "🆑",
        "🆒",
        "🆓",
        "🆔",
        "🆕",
        "🆖",
        "🆗",
        "🆘",
        "🆙",
        "🆚",
        "🇦",
        "🇧",
        "🇨",
        "🇩",
        "🇪",
        "🇫",
        "🇬",
        "🇭",
        "🇮",
        "🇯",
        "🇰",
        "🇱",
        "🇲",
        "🇳",
        "🇴",
        "🇵",
        "🇶",
        "🇷",
        "🇸",
        "🇹",
        "🇺",
        "🇻",
        "🇼",
        "🇽",
        "🇾",
        "🇿",
        "🈐",
        "🈑",
        "🈒",
        "🈓",
        "🈔",
        "🈕",
        "🈖",
        "🈗",
        "🈘",
        "🈙",
        "🈚",
        "🈛",
        "🈜",
        "🈝",
        "🈞",
        "🈟",
        "🈠",
        "🈡",
        "🈢",
        "🈣",
        "🈤",
        "🈥",
        "🈦",
        "🈧",
        "🈨",
        "🈩",
        "🈪",
        "🈫",
        "🈬",
        "🈭",
        "🈮",
        "🈯",
        "🈰",
        "🈱",
        "🈲",
        "🈳",
        "🈴",
        "🈵",
        "🈶",
        "🈷",
        "🈸",
        "🈹",
        "🈺",
        "🈻",
        "🉀",
        "🉁",
        "🉂",
        "🉃",
        "🉄",
        "🉅",
        "🉆",
        "🉇",
        "🉈",
        "🉐",
        "🉑",
        "🉠",
        "🉡",
        "🉤",
        "🌁",
        "🌃",
        "🌄",
        "🌅",
        "🌉",
        "🌋",
        "🌍",
        "🌎",
        "🌏",
        "🍽",
        "🎦",
        "🎰",
        "🏕",
        "🏘",
        "🏙",
        "🏡",
        "🏯",
        "🏰",
        "🐄",
        "🐉",
        "🐑",
        "🐘",
        "🐝",
        "🐞",
        "🐨",
        "🐺",
        "👋",
        "👕",
        "👣",
        "👪",
        "👰",
        "👼",
        "💁",
        "💓",
        "💡",
        "💥",
        "📸",
        "🔜",
        "🔤",
        "🕀",
        "🕁",
        "🕸",
        "🕹",
        "🗢",
        "🗺",
        "🙾",
        "🙿",
        "🚂",
        "🚃",
        "🚄",
        "🚌",
        "🚎",
        "🚒",
        "🚛",
        "🚧",
        "🚰",
        "🚱",
        "🛋",
        "🛰",
        "🛲",
        "🛶",
        "🝝",
        "🞲",
        "🞳",
        "🞴",
        "🞶",
        "🞸",
        "🞺",
        "🟃",
        "🤠",
        "🤼",
        "🥀",
        "🦣",
        "🧏",
        "🧖",
        "🩠",
        "🩡",
        "🩢",
        "🩣",
        "🩤",
        "🩥",
        "🩦",
        "🪆",
        "🪮",
        "🪹",
        "🪺",
        "🫠",
        "🫡",
        "🫣",
        "𣖔"
    ],
    [
        "؋",
        "ܘ",
        "ܠ",
        "ݍ",
        "℮",
        "⅀",
        "ⅅ",
        "ⅆ",
        "⋿",
        "⍾",
        "␈",
        "⚤",
        "⟃",
        "⟄",
        "𐀜",
        "𐁁",
        "𐂊",
        "𐂋",
        "𐂜",
        "𐂥",
        "𐂲",
        "𐃡",
        "𐃸",
        "𐆙",
        "𐇑",
        "𐇘",
        "𐇬",
        "𐇯",
        "𐇳",
        "𐇻",
        "𐏐",
        "𐒐",
        "𐘟",
        "𐘼",
        "𐘽",
        "𐙇",
        "𐙓",
        "𐙚",
        "𐙜",
        "𐙣",
        "𐙥",
        "𐙵",
        "𐙾",
        "𐚇",
        "𐚎",
        "𐚐",
        "𐚽",
        "𐚿",
        "𐛉",
        "𐛔",
        "𐛘",
        "𐛙",
        "𐛚",
        "𐛛",
        "𐛜",
        "𐛲",
        "𐛴",
        "𐛵",
        "𐛶",
        "𐜇",
        "𐜙",
        "𐜚",
        "𐝑",
        "𐠋",
        "𐠥",
        "𐠷",
        "𐠿",
        "𐡢",
        "𐡯",
        "𐡰",
        "𐡵",
        "𐡶",
        "𐡽",
        "𐢙",
        "𐢚",
        "𐣤",
        "𐦂",
        "𐦅",
        "𐦒",
        "𐦕",
        "𐦛",
        "𐨝",
        "𐨰",
        "𐩒",
        "𐩔",
        "𐬂",
        "𐬄",
        "𐬈",
        "𐬉",
        "𐬑",
        "𐭌",
        "𐮬",
        "𐹬",
        "𐹵",
        "𐹻",
        "𑁧",
        "𑁪",
        "𑁵",
        "𑂍",
        "𑂒",
        "𑂓",
        "𑂮",
        "𑄃",
        "𑄅",
        "𑄈",
        "𑄉",
        "𑄌",
        "𑄎",
        "𑄑",
        "𑄔",
        "𑄕",
        "𑄙",
        "𑄛",
        "𑄜",
        "𑄝",
        "𑄞",
        "𑄡",
        "𑄢",
        "𑄤",
        "𑅇",
        "𑅨",
        "𑅪",
        "𑅬",
        "𑇙",
        "𑇡",
        "𑇧",
        "𑈃",
        "𑈉",
        "𑈊",
        "𑈋",
        "𑈐",
        "𑈙",
        "𑈠",
        "𑈡",
        "𑈣",
        "𑈤",
        "𑊄",
        "𑊈",
        "𑊊",
        "𑊌",
        "𑊏",
        "𑊓",
        "𑊔",
        "𑊙",
        "𑊜",
        "𑊝",
        "𑊠",
        "𑊡",
        "𑊨",
        "𑊱",
        "𑌅",
        "𑌕",
        "𑌖",
        "𑌥",
        "𑌧",
        "𑌨",
        "𑌬",
        "𑌭",
        "𑌵",
        "𑌶",
        "𑍐",
        "𑍞",
        "𑐇",
        "𑐉",
        "𑐊",
        "𑐗",
        "𑑔",
        "𑒀",
        "𑓐",
        "𑓒",
        "𑖀",
        "𑖁",
        "𑖂",
        "𑖃",
        "𑖄",
        "𑖉",
        "𑖌",
        "𑖔",
        "𑖙",
        "𑖡",
        "𑖬",
        "𑗘",
        "𑙠",
        "𑙢",
        "𑚀",
        "𑚊",
        "𑚏",
        "𑚐",
        "𑚓",
        "𑚗",
        "𑚘",
        "𑚚",
        "𑚛",
        "𑚜",
        "𑚣",
        "𑚨",
        "𑜀",
        "𑜁",
        "𑜇",
        "𑜊",
        "𑜌",
        "𑜐",
        "𑜒",
        "𑜔",
        "𑜘",
        "𑜙",
        "𑜲",
        "𑜴",
        "𑝀",
        "𑝂",
        "𑝄",
        "𑠌",
        "𑠎",
        "𑠏",
        "𑠒",
        "𑠓",
        "𑠖",
        "𑠗",
        "𑠛",
        "𑠟",
        "𑠢",
        "𑠦",
        "𑠩",
        "𑤉",
        "𑤞",
        "𑤣",
        "𑤭",
        "𑦣",
        "𑦥",
        "𑩐",
        "𑩜",
        "𑩝",
        "𑩞",
        "𑩟",
        "𑩠",
        "𑩡",
        "𑩢",
        "𑩣",
        "𑩤",
        "𑩥",
        "𑩦",
        "𑩧",
        "𑩨",
        "𑩩",
        "𑩪",
        "𑩫",
        "𑩬",
        "𑩭",
        "𑩮",
        "𑩯",
        "𑩰",
        "𑩱",
        "𑩲",
        "𑩳",
        "𑩴",
        "𑩵",
        "𑩶",
        "𑩷",
        "𑩸",
        "𑩹",
        "𑩺",
        "𑩻",
        "𑩼",
        "𑩽",
        "𑩾",
        "𑩿",
        "𑪀",
        "𑪁",
        "𑪂",
        "𑪃",
        "𑬇",
        "𑰃",
        "𑰅",
        "𑰌",
        "𑰍",
        "𑰗",
        "𑰙",
        "𑰜",
        "𑰞",
        "𑰠",
        "𑰩",
        "𑱡",
        "𑱢",
        "𑶤",
        "𑶨",
        "𑻯",
        "𑼈",
        "𑼊",
        "𑼕",
        "𑼫",
        "𑼳",
        "𑿑",
        "𒿛",
        "𒿜",
        "𖩳",
        "𖩴",
        "𖩶",
        "𖩷",
        "𖩾",
        "𖪀",
        "𖪆",
        "𖪇",
        "𖪊",
        "𖪋",
        "𖪎",
        "𖪔",
        "𖪘",
        "𖪠",
        "𖪡",
        "𖪭",
        "𖪱",
        "𖪾",
        "𖹠",
        "𖿠",
        "𖿢",
        "𖿣",
        "𘬁",
        "𘬂",
        "𘬄",
        "𘬅",
        "𘬆",
        "𘬌",
        "𘬕",
        "𘬧",
        "𘬫",
        "𘬯",
        "𘬰",
        "𘬱",
        "𘭏",
        "𘭖",
        "𘭞",
        "𘭟",
        "𘭠",
        "𘭢",
        "𘭫",
        "𘭯",
        "𘮇",
        "𘮊",
        "𘮋",
        "𘮒",
        "𘮬",
        "𘮭",
        "𘮮",
        "𘮷",
        "𘮺",
        "𘮻",
        "𘮽",
        "𘮾",
        "𘮿",
        "𘯀",
        "𘯁",
        "𘯆",
        "𘯇",
        "𘯎",
        "𘯑",
        "𘯥",
        "𘯦",
        "𘯨",
        "𘯩",
        "𘯪",
        "𘯫",
        "𘯬",
        "𘯭",
        "𘯰",
        "𘯱",
        "𘯴",
        "𘯵",
        "𘯸",
        "𘯻",
        "𘰁",
        "𘰄",
        "𘰇",
        "𘰈",
        "𘰊",
        "𘰋",
        "𘰗",
        "𘰟",
        "𘰠",
        "𘰡",
        "𘱊",
        "𘱛",
        "𘱟",
        "𘱠",
        "𘱢",
        "𘱪",
        "𘱫",
        "𘱬",
        "𘱭",
        "𘱮",
        "𘱯",
        "𘱰",
        "𘱱",
        "𘱲",
        "𘱳",
        "𘱺",
        "𘱻",
        "𘲀",
        "𘲁",
        "𘲄",
        "𘲅",
        "𘲉",
        "𘲐",
        "𘲒",
        "𘲓",
        "𘲙",
        "𘲟",
        "𘲨",
        "𘲱",
        "𘲴",
        "𘲻",
        "𘲼",
        "𘲽",
        "𘳁",
        "𘳕",
        "𚿻",
        "𛀍",
        "𛀒",
        "𛁳",
        "𛃏",
        "𛃨",
        "𛃪",
        "𛃫",
        "𛃯",
        "𛄗",
        "𛅑",
        "𛅒",
        "𛅥",
        "𛅿",
        "𛆬",
        "𛆲",
        "𛆾",
        "𛇾",
        "𛇿",
        "𛈃",
        "𛈊",
        "𛈙",
        "𛈠",
        "𛈽",
        "𛉇",
        "𛉈",
        "𛉧",
        "𛉯",
        "𛉱",
        "𛉳",
        "𛉴",
        "𛉵",
        "𛉷",
        "𛉾",
        "𛊀",
        "𛊂",
        "𛊅",
        "𛊑",
        "𛊔",
        "𛊲",
        "𛊴",
        "𛊵",
        "𛊹",
        "𛊺",
        "𛊻",
        "𛊾",
        "𛋂",
        "𛋆",
        "𛋈",
        "𛋌",
        "𛋑",
        "𛋔",
        "𛋖",
        "𛋘",
        "𛋞",
        "𛋢",
        "𛋣",
        "𛋧",
        "𛋫",
        "𛋰",
        "𛋵",
        "𛋻",
        "𛱄",
        "𛱔",
        "𜽡",
        "𜾈",
        "𝀟",
        "𝀨",
        "𝀯",
        "𝁀",
        "𝁂",
        "𝁖",
        "𝁨",
        "𝁩",
        "𝂁",
        "𝂍",
        "𝂒",
        "𝂔",
        "𝂠",
        "𝃍",
        "𝃨",
        "𝄐",
        "𝄑",
        "𝄝",
        "𝅜",
        "𝆗",
        "𝆘",
        "𝆷",
        "𝆸",
        "𝇊",
        "𝇋",
        "𝇌",
        "𝇍",
        "𝇎",
        "𝇗",
        "𝇛",
        "𝇝",
        "𝈀",
        "𝈁",
        "𝈃",
        "𝈄",
        "𝈅",
        "𝈎",
        "𝈏",
        "𝈝",
        "𝈞",
        "𝈟",
        "𝈠",
        "𝈦",
        "𝈩",
        "𝈭",
        "𝈯",
        "𝈱",
        "𝈳",
        "𝈴",
        "𝈶",
        "𝈷",
        "𝈸",
        "𝈹",
        "𝈺",
        "𝈻",
        "𝈽",
        "𝈾",
        "𝉀",
        "𝉁",
        "𝍬",
        "𝑩",
        "𝑬",
        "𝑭",
        "𝑯",
        "𝑱",
        "𝑲",
        "𝑴",
        "𝑵",
        "𝑷",
        "𝑹",
        "𝑾",
        "𝑿",
        "𝒁",
        "𝒅",
        "𝒋",
        "𝒎",
        "𝒑",
        "𝒘",
        "𝒙",
        "𝒛",
        "𝒢",
        "𝒰",
        "𝒴",
        "𝒽",
        "𝓀",
        "𝓅",
        "𝓍",
        "𝓖",
        "𝓤",
        "𝓨",
        "𝓱",
        "𝓴",
        "𝓹",
        "𝔁",
        "𝘽",
        "𝙀",
        "𝙁",
        "𝙃",
        "𝙅",
        "𝙆",
        "𝙈",
        "𝙉",
        "𝙋",
        "𝙍",
        "𝙒",
        "𝙓",
        "𝙕",
        "𝙙",
        "𝙟",
        "𝙢",
        "𝙥",
        "𝙬",
        "𝙭",
        "𝙯",
        "𝙰",
        "𝙼",
        "𝚅",
        "𝚆",
        "𝚠",
        "𝛺",
        "𝜇",
        "𝜝",
        "𝜞",
        "𝜠",
        "𝜡",
        "𝜢",
        "𝜥",
        "𝜧",
        "𝜨",
        "𝜫",
        "𝜬",
        "𝜮",
        "𝜱",
        "𝜲",
        "𝜴",
        "𝜷",
        "𝝆",
        "𝝍",
        "𝝒",
        "𝞑",
        "𝞒",
        "𝞔",
        "𝞕",
        "𝞖",
        "𝞙",
        "𝞛",
        "𝞜",
        "𝞟",
        "𝞠",
        "𝞢",
        "𝞥",
        "𝞦",
        "𝞨",
        "𝞫",
        "𝞺",
        "𝟁",
        "𝟆",
        "𝠋",
        "𝠌",
        "𝠍",
        "𝠔",
        "𝠥",
        "𝠮",
        "𝡆",
        "𝡉",
        "𝡗",
        "𝡙",
        "𝡦",
        "𝡨",
        "𝡫",
        "𝡻",
        "𝢋",
        "𝢗",
        "𝢨",
        "𝣖",
        "𝣚",
        "𝣟",
        "𝣸",
        "𝤂",
        "𝤐",
        "𝤙",
        "𝤞",
        "𝤥",
        "𝤯",
        "𝤰",
        "𝤱",
        "𝤲",
        "𝥃",
        "𝥎",
        "𝥏",
        "𝥪",
        "𝥫",
        "𝥬",
        "𝥭",
        "𝦁",
        "𝦂",
        "𝦋",
        "𝦦",
        "𝦧",
        "𝦨",
        "𝦴",
        "𝧈",
        "𝧐",
        "𝧟",
        "𝧠",
        "𝧨",
        "𝧫",
        "𝧯",
        "𝧱",
        "𝧲",
        "𝧳",
        "𝧴",
        "𞅏",
        "𞋕",
        "𞋖",
        "𞋚",
        "𞋜",
        "𞋦",
        "𞋸",
        "𞟠",
        "𞟣",
        "𞟦",
        "𞠎",
        "𞠙",
        "𞠱",
        "𞡺",
        "𞢁",
        "𞢃",
        "𞢎",
        "𞢺",
        "𞣈",
        "𞣊",
        "𞲈",
        "𞲠",
        "𞲴",
        "𞴃",
        "𞴆",
        "𞴈",
        "𞴳",
        "𞴷",
        "𞺄",
        "🌲",
        "🌳",
        "🌵",
        "🍌",
        "🍞",
        "🍡",
        "🍴",
        "🎈",
        "🐏",
        "🐐",
        "🐧",
        "🐰",
        "👊",
        "👍",
        "👎",
        "👛",
        "👢",
        "👮",
        "👲",
        "👽",
        "💺",
        "📋",
        "📒",
        "📡",
        "🔇",
        "🔉",
        "🔒",
        "🔰",
        "🕆",
        "🕇",
        "🕈",
        "🕏",
        "🕩",
        "🕱",
        "🕺",
        "🕼",
        "🖒",
        "🖓",
        "🖨",
        "🖯",
        "🖹",
        "🖺",
        "🖻",
        "🗄",
        "🗕",
        "🚈",
        "🚊",
        "🚍",
        "🚼",
        "🚽",
        "🛗",
        "🛼",
        "🜚",
        "🜝",
        "🜳",
        "🜸",
        "🝌",
        "🝎",
        "🝕",
        "🝖",
        "🝯",
        "🝱",
        "🝿",
        "🠅",
        "🠇",
        "🠜",
        "🠞",
        "🤄",
        "🤛",
        "🤜",
        "🥂",
        "🥍",
        "🥭",
        "🦑",
        "🦻",
        "🧘",
        "🧜",
        "🧦",
        "🧲",
        "🩼",
        "🪀",
        "🪖",
        "🪝",
        "🪧",
        "🪳",
        "🫀",
        "🫵",
        "𠂤",
        "𠬝"
    ],
    [
        "͸",
        "͹",
        "΀",
        "΁",
        "΂",
        "΃",
        "΋",
        "΍",
        "΢",
        "҈",
        "҉",
        "԰",
        "՗",
        "՘",
        "֋",
        "֌",
        "׈",
        "׉",
        "׊",
        "׋",
        "׌",
        "׍",
        "׎",
        "׏",
        "׫",
        "׬",
        "׭",
        "׮",
        "׵",
        "׶",
        "׷",
        "׸",
        "׹",
        "׺",
        "׻",
        "׼",
        "׽",
        "׾",
        "׿",
        "ڪ",
        "܋",
        "܍",
        "܎",
        "ܓ",
        "ܔ",
        "ܗ",
        "ܚ",
        "ܛ",
        "ܜ",
        "ܞ",
        "ܥ",
        "ܦ",
        "ܧ",
        "ܨ",
        "ܫ",
        "ܭ",
        "ܮ",
        "݋",
        "݌",
        "ݎ",
        "ݏ",
        "޲",
        "޳",
        "޴",
        "޵",
        "޶",
        "޷",
        "޸",
        "޹",
        "޺",
        "޻",
        "޼",
        "޽",
        "޾",
        "޿",
        "߻",
        "߼",
        "﬇",
        "﬈",
        "﬉",
        "﬊",
        "﬋",
        "﬌",
        "﬍",
        "﬎",
        "﬏",
        "﬐",
        "﬑",
        "﬒",
        "﬘",
        "﬙",
        "﬚",
        "﬛",
        "﬜",
        "ﬡ",
        "ﬢ",
        "ﬣ",
        "ﬤ",
        "ﬧ",
        "ﬨ",
        "﬷",
        "﬽",
        "﬿",
        "﭂",
        "﭅",
        "﯃",
        "﯄",
        "﯅",
        "﯆",
        "﯇",
        "﯈",
        "﯉",
        "﯊",
        "﯋",
        "﯌",
        "﯍",
        "﯎",
        "﯏",
        "﯐",
        "﯑",
        "﯒",
        "ﲭ",
        "ﲮ",
        "ﲯ",
        "ﲱ",
        "ﲲ",
        "ﲴ",
        "ﲵ",
        "ﲶ",
        "ﳻ",
        "ﳼ",
        "ﳽ",
        "ﳾ",
        "ﴭ",
        "ﴮ",
        "ﴯ",
        "ﴰ",
        "ﴱ",
        "ﴲ",
        "﴾",
        "﴿",
        "﵀",
        "﵇",
        "﵈",
        "﵉",
        "﵍",
        "﵏",
        "ﵐ",
        "ﵑ",
        "ﵒ",
        "ﵓ",
        "ﵔ",
        "ﵕ",
        "ﵖ",
        "ﵗ",
        "ﵙ",
        "ﵶ",
        "ﵹ",
        "ﵽ",
        "ﶁ",
        "ﶂ",
        "ﶆ",
        "ﶇ",
        "ﶉ",
        "ﶊ",
        "ﶌ",
        "ﶍ",
        "ﶎ",
        "ﶏ",
        "﶐",
        "﶑",
        "ﶒ",
        "ﶴ",
        "ﶽ",
        "﷈",
        "﷉",
        "﷊",
        "﷋",
        "﷌",
        "﷍",
        "﷎",
        "ﷰ",
        "ﷵ",
        "ﷷ",
        "ﷸ",
        "ﷹ",
        "﷿",
        "︗",
        "︘",
        "︚",
        "︛",
        "︜",
        "︝",
        "︞",
        "︟",
        "︵",
        "︶",
        "︷",
        "︸",
        "︹",
        "︺",
        "︻",
        "︼",
        "︽",
        "︾",
        "︿",
        "﹀",
        "﹇",
        "﹈",
        "﹊",
        "﹎",
        "﹓",
        "﹧",
        "﹬",
        "﹭",
        "﹮",
        "﹯",
        "﹵",
        "﻽",
        "﻾",
        "＀",
        "﾿",
        "￀",
        "￁",
        "￈",
        "￉",
        "￐",
        "￑",
        "￘",
        "￙",
        "￝",
        "￞",
        "￟",
        "￠",
        "￡",
        "￥",
        "￦",
        "￧",
        "￯",
        "𐁙",
        "𐁜",
        "𐂂",
        "𐂄",
        "𐂭",
        "𐂺",
        "𐃀",
        "𐃄",
        "𐃅",
        "𐃇",
        "𐃈",
        "𐃎",
        "𐃐",
        "𐃕",
        "𐃖",
        "𐃘",
        "𐃚",
        "𐃛",
        "𐃞",
        "𐃟",
        "𐃢",
        "𐃧",
        "𐃪",
        "𐃶",
        "𐃷",
        "𐃹",
        "𐄣",
        "𐄥",
        "𐄦",
        "𐄧",
        "𐄨",
        "𐄩",
        "𐄬",
        "𐄮",
        "𐄰",
        "𐄱",
        "𐄲",
        "𐇭",
        "𐇮",
        "𐎀",
        "𐎁",
        "𐎅",
        "𐎈",
        "𐎉",
        "𐎏",
        "𐎐",
        "𐎑",
        "𐎔",
        "𐎖",
        "𐎙",
        "𐎚",
        "𐎛",
        "𐎡",
        "𐎣",
        "𐎩",
        "𐎭",
        "𐎰",
        "𐎱",
        "𐎲",
        "𐎻",
        "𐎼",
        "𐎿",
        "𐏁",
        "𐏂",
        "𐏌",
        "𐒎",
        "𐒝",
        "𐘋",
        "𐘛",
        "𐘝",
        "𐙅",
        "𐙻",
        "𐚁",
        "𐚗",
        "𐚘",
        "𐚛",
        "𐛀",
        "𐛄",
        "𐛇",
        "𐛓",
        "𐛟",
        "𐛧",
        "𐛨",
        "𐛮",
        "𐛯",
        "𐛺",
        "𐜃",
        "𐜄",
        "𐜌",
        "𐜔",
        "𐜕",
        "𐜠",
        "𐜦",
        "𐜬",
        "𐜳",
        "𐜶",
        "𐝔",
        "𐡷",
        "𐡸",
        "𐡾",
        "𐢭",
        "𐦀",
        "𐦈",
        "𐦜",
        "𐦝",
        "𐧆",
        "𐧎",
        "𐧛",
        "𐧜",
        "𐧝",
        "𐧟",
        "𐧠",
        "𐧡",
        "𐧢",
        "𐧣",
        "𐩕",
        "𐩘",
        "𐫬",
        "𐫭",
        "𐫮",
        "𐫲",
        "𐬃",
        "𐬳",
        "𐭄",
        "𐮐",
        "𐮯",
        "𐼛",
        "𐼠",
        "𐼡",
        "𑅔",
        "𑅶",
        "𑇚",
        "𑇢",
        "𑇣",
        "𑇤",
        "𑇥",
        "𑇨",
        "𑇪",
        "𑇬",
        "𑇯",
        "𑇱",
        "𑇲",
        "𑇴",
        "𑌆",
        "𑌏",
        "𑍠",
        "𑍡",
        "𑐌",
        "𑐍",
        "𑐘",
        "𑐱",
        "𑑛",
        "𑒇",
        "𑒈",
        "𑒋",
        "𑒌",
        "𑒍",
        "𑒎",
        "𑒐",
        "𑒑",
        "𑒘",
        "𑓅",
        "𑖏",
        "𑗁",
        "𑗕",
        "𑗖",
        "𑗗",
        "𑙥",
        "𑙫",
        "𑙬",
        "𑜿",
        "𑠁",
        "𑤁",
        "𑥆",
        "𑴔",
        "𑴟",
        "𑴨",
        "𑴬",
        "𑴭",
        "𑴮",
        "𑼅",
        "𑼌",
        "𑽅",
        "𑽆",
        "𑿀",
        "𑿄",
        "𑿆",
        "𑿇",
        "𑿉",
        "𑿊",
        "𑿌",
        "𑿎",
        "𑿏",
        "𑿒",
        "𑿓",
        "𑿕",
        "𑿗",
        "𑿘",
        "𑿙",
        "𑿛",
        "𑿜",
        "𑿝",
        "𑿞",
        "𑿟",
        "𑿡",
        "𑿤",
        "𑿧",
        "𑿩",
        "𑿪",
        "𑿮",
        "𑿯",
        "𑿰",
        "𒾞",
        "𒾫",
        "𒾳",
        "𒾴",
        "𒾵",
        "𒿂",
        "𒿄",
        "𒿉",
        "𒿘",
        "𒿞",
        "𖪙",
        "𖪣",
        "𖹀",
        "𛀂",
        "𛀅",
        "𛀇",
        "𛀌",
        "𛀎",
        "𛀏",
        "𛀑",
        "𛀓",
        "𛀖",
        "𛀛",
        "𛀜",
        "𛀟",
        "𛀠",
        "𛀥",
        "𛀩",
        "𛀭",
        "𛀯",
        "𛀲",
        "𛀸",
        "𛀻",
        "𛀽",
        "𛁀",
        "𛁄",
        "𛁇",
        "𛁈",
        "𛁋",
        "𛁐",
        "𛁛",
        "𛁬",
        "𛁮",
        "𛁯",
        "𛁲",
        "𛁴",
        "𛁺",
        "𛁻",
        "𛁽",
        "𛂀",
        "𛂂",
        "𛂋",
        "𛂎",
        "𛂘",
        "𛂜",
        "𛂝",
        "𛂩",
        "𛂬",
        "𛂶",
        "𛂹",
        "𛃂",
        "𛃎",
        "𛃒",
        "𛃖",
        "𛃡",
        "𛃤",
        "𛃭",
        "𛄉",
        "𛄖",
        "𛄠",
        "𛰯",
        "𜽕",
        "𜽥",
        "𜾉",
        "𜾊",
        "𜾗",
        "𜾙",
        "𜾞",
        "𜾟",
        "𜾠",
        "𜾡",
        "𜾢",
        "𜾤",
        "𜾥",
        "𜾬",
        "𜾭",
        "𜾮",
        "𜾵",
        "𜾶",
        "𜾷",
        "𜾸",
        "𜾿",
        "𝀩",
        "𝀪",
        "𝀭",
        "𝀷",
        "𝀻",
        "𝀿",
        "𝁃",
        "𝁋",
        "𝁌",
        "𝁡",
        "𝁥",
        "𝁰",
        "𝁱",
        "𝁳",
        "𝁹",
        "𝂡",
        "𝂳",
        "𝂼",
        "𝃅",
        "𝃆",
        "𝇡",
        "𝋢",
        "𝋣",
        "𝋤",
        "𝋥",
        "𝋦",
        "𝋧",
        "𝋨",
        "𝋩",
        "𝋪",
        "𝋫",
        "𝋬",
        "𝋭",
        "𝋮",
        "𝋯",
        "𝋰",
        "𝋱",
        "𝋲",
        "𝋳",
        "𝍯",
        "𝍱",
        "𝍸",
        "𝡘",
        "𝡧",
        "𝢊",
        "𝣒",
        "𝣓",
        "𝣔",
        "𝣗",
        "𝣧",
        "𝣪",
        "𝣭",
        "𝣮",
        "𝦞",
        "𝦪",
        "𝦱",
        "𝦵",
        "𝧃",
        "𝧑",
        "𝧗",
        "𝧘",
        "𝧙",
        "𝧛",
        "𝧮",
        "𝧸",
        "𝩭",
        "𝩮",
        "𝩳",
        "𝩴",
        "𞟡",
        "𞟢",
        "𞟨",
        "𞟩",
        "𞟫",
        "𞟰",
        "𞟱",
        "𞟲",
        "𞟶",
        "𞠐",
        "𞠑",
        "𞠘",
        "𞠴",
        "𞠶",
        "𞠷",
        "𞠸",
        "𞠾",
        "𞠿",
        "𞡀",
        "𞡐",
        "𞡖",
        "𞡠",
        "𞡡",
        "𞢇",
        "𞢝",
        "𞢤",
        "𞢥",
        "𞢪",
        "𞢬",
        "𞢰",
        "𞢱",
        "𞢷",
        "𞴋",
        "𞴍",
        "𞴎",
        "𞴖",
        "𞴗",
        "𞴘",
        "𞴙",
        "𞴚",
        "𞴛",
        "𞴜",
        "𞴝",
        "𞴥",
        "𞴦",
        "𞴧",
        "𞴨",
        "𞴩",
        "𞴪",
        "𞴫",
        "𞴬",
        "𞴭",
        "𞴸",
        "𞴹",
        "𞺡",
        "𞺥",
        "𞺦",
        "𞺩",
        "𞺯",
        "𞺰",
        "𞺲",
        "𞺳",
        "𞺵",
        "𞺶",
        "𞺷",
        "𞻰",
        "𞻱",
        "🁀",
        "🄐",
        "🄑",
        "🄒",
        "🄓",
        "🄔",
        "🄕",
        "🄖",
        "🄗",
        "🄚",
        "🄛",
        "🄜",
        "🄝",
        "🄞",
        "🄟",
        "🄡",
        "🄢",
        "🄤",
        "🄦",
        "🄧",
        "🄩",
        "🄪",
        "🄮",
        "🅯",
        "🆐",
        "🆛",
        "🆜",
        "🆝",
        "🆞",
        "🆟",
        "🆠",
        "🆡",
        "🆢",
        "🆣",
        "🆤",
        "🆥",
        "🆦",
        "🆧",
        "🆨",
        "🆩",
        "🆪",
        "🆫",
        "🆬",
        "🌈",
        "🌑",
        "🌒",
        "🌓",
        "🌔",
        "🌕",
        "🌖",
        "🌗",
        "🌘",
        "🌚",
        "🌝",
        "🌞",
        "🌠",
        "🌤",
        "🌫",
        "🌬",
        "🌭",
        "🌮",
        "🌯",
        "🌴",
        "🌶",
        "🌽",
        "🌾",
        "🌿",
        "🍃",
        "🍈",
        "🍉",
        "🍗",
        "🍛",
        "🍝",
        "🍣",
        "🍥",
        "🍩",
        "🍮",
        "🍰",
        "🍳",
        "🍶",
        "🍻",
        "🎁",
        "🎑",
        "🎒",
        "🎕",
        "🎞",
        "🎟",
        "🎠",
        "🎢",
        "🎤",
        "🎧",
        "🎭",
        "🎮",
        "🎺",
        "🎼",
        "🎿",
        "🏃",
        "🏄",
        "🏆",
        "🏇",
        "🏊",
        "🏋",
        "🏓",
        "🏔",
        "🏖",
        "🏗",
        "🏜",
        "🏝",
        "🏞",
        "🏥",
        "🏧",
        "🏭",
        "🏷",
        "🐀",
        "🐇",
        "🐈",
        "🐌",
        "🐍",
        "🐎",
        "🐓",
        "🐔",
        "🐕",
        "🐖",
        "🐗",
        "🐚",
        "🐟",
        "🐡",
        "🐢",
        "🐤",
        "🐥",
        "🐩",
        "🐪",
        "🐫",
        "🐬",
        "🐴",
        "🐵",
        "🐽",
        "🐿",
        "👈",
        "👉",
        "👏",
        "👒",
        "👓",
        "👘",
        "👜",
        "👠",
        "👡",
        "👯",
        "👱",
        "👶",
        "👻",
        "👾",
        "👿",
        "💂",
        "💋",
        "💏",
        "💑",
        "💒",
        "💔",
        "💟",
        "💤",
        "💦",
        "💨",
        "💭",
        "💯",
        "💱",
        "💳",
        "💴",
        "💵",
        "💶",
        "💷",
        "💸",
        "💹",
        "📇",
        "📈",
        "📉",
        "📊",
        "📏",
        "📜",
        "📟",
        "📠",
        "📣",
        "📧",
        "📩",
        "📮",
        "📲",
        "📷",
        "📹",
        "📼",
        "📽",
        "🔐",
        "🔕",
        "🔗",
        "🔙",
        "🔚",
        "🔛",
        "🔞",
        "🔟",
        "🔣",
        "🔦",
        "🔴",
        "🔵",
        "🕂",
        "🕉",
        "🕊",
        "🕬",
        "🕳",
        "🕶",
        "🖂",
        "🖃",
        "🖄",
        "🖅",
        "🖆",
        "🖇",
        "🖉",
        "🖌",
        "🖍",
        "🖕",
        "🖚",
        "🖛",
        "🖜",
        "🖝",
        "🖦",
        "🖲",
        "🖴",
        "🖵",
        "🖸",
        "🖼",
        "🖽",
        "🖾",
        "🗁",
        "🗃",
        "🗔",
        "🗖",
        "🗘",
        "🗜",
        "🗝",
        "🗟",
        "🗠",
        "🗪",
        "🗫",
        "🗬",
        "🗭",
        "🗮",
        "🗯",
        "🗰",
        "🗱",
        "🗳",
        "🗻",
        "😈",
        "😏",
        "🙅",
        "🙌",
        "🙍",
        "🙎",
        "🙜",
        "🙝",
        "🙞",
        "🙟",
        "🙠",
        "🙡",
        "🙢",
        "🙣",
        "🙤",
        "🙥",
        "🙦",
        "🙧",
        "🙰",
        "🙱",
        "🙵",
        "🚁",
        "🚑",
        "🚖",
        "🚚",
        "🚜",
        "🚠",
        "🚲",
        "🚳",
        "🚴",
        "🚵",
        "🚷",
        "🛀",
        "🛁",
        "🛃",
        "🛆",
        "🛈",
        "🛌",
        "🛍",
        "🛏",
        "🛖",
        "🛜",
        "🛥",
        "🛩",
        "🛳",
        "🛵",
        "🛺",
        "🛻",
        "🜓",
        "🜩",
        "🜽",
        "🝉",
        "🝛",
        "🝽",
        "🞅",
        "🞆",
        "🞇",
        "🞈",
        "🞉",
        "🞊",
        "🞋",
        "🞎",
        "🞏",
        "🞐",
        "🞑",
        "🞒",
        "🞓",
        "🞔",
        "🞕",
        "🞖",
        "🞩",
        "🞫",
        "🞭",
        "🞰",
        "🞼",
        "🞾",
        "🟁",
        "🟊",
        "🟠",
        "🟡",
        "🟢",
        "🟣",
        "🟤",
        "🟦",
        "🟧",
        "🟨",
        "🟩",
        "🟪",
        "🟫",
        "🠀",
        "🠂",
        "🠄",
        "🠆",
        "🠐",
        "🠒",
        "🠔",
        "🠖",
        "🠠",
        "🠢",
        "🠤",
        "🠦",
        "🠰",
        "🠲",
        "🠴",
        "🠶",
        "🡄",
        "🡆",
        "🡐",
        "🡒",
        "🡘",
        "🡠",
        "🡢",
        "🡨",
        "🡪",
        "🡰",
        "🡲",
        "🡴",
        "🡵",
        "🡶",
        "🡷",
        "🡸",
        "🡺",
        "🡼",
        "🡽",
        "🡾",
        "🡿",
        "🢀",
        "🢂",
        "🢄",
        "🢅",
        "🢆",
        "🢇",
        "🢘",
        "🢚",
        "🢠",
        "🢡",
        "🢢",
        "🢣",
        "🢤",
        "🢥",
        "🢦",
        "🢧",
        "🢨",
        "🢩",
        "🢪",
        "🢫",
        "🤝",
        "🤨",
        "🤷",
        "🤿",
        "🥒",
        "🥓",
        "🥕",
        "🥜",
        "🥯",
        "🥻",
        "🥽",
        "🥿",
        "🦂",
        "🦄",
        "🦅",
        "🦋",
        "🦏",
        "🦐",
        "🦒",
        "🦓",
        "🦔",
        "🦗",
        "🦜",
        "🦝",
        "🦠",
        "🦢",
        "🦤",
        "🦥",
        "🦦",
        "🦫",
        "🦭",
        "🦮",
        "🦶",
        "🧀",
        "🧇",
        "🧊",
        "🧗",
        "🧞",
        "🧨",
        "🧩",
        "🧫",
        "🧮",
        "🧰",
        "🧱",
        "🧵",
        "🧻",
        "🧿",
        "🩧",
        "🩨",
        "🩩",
        "🩪",
        "🩫",
        "🩬",
        "🩭",
        "🩴",
        "🩹",
        "🩻",
        "🪇",
        "🪗",
        "🪚",
        "🪛",
        "🪢",
        "🪤",
        "🪥",
        "🪪",
        "🪬",
        "🪭",
        "🪰",
        "🪱",
        "🪸",
        "🪽",
        "🫕",
        "🫖",
        "🫛",
        "🫤",
        "🫥",
        "🫱",
        "🫲",
        "🫷",
        "🫸",
        "𠂉",
        "𠝏",
        "𠠇",
        "𣇵",
        "𤸎",
        "𥹥",
        "𧚓",
        "𧝒",
        "𨂊",
        "𨛗",
        "𨥉",
        "𨴐",
        "𨵱",
        "𨷻",
        "𩷛"
    ],
    [
        "܀",
        "ﰽ",
        "ﰾ",
        "𐀶",
        "𐁛",
        "𐂗",
        "𐂢",
        "𐂣",
        "𐂪",
        "𐂬",
        "𐃓",
        "𐃤",
        "𐃦",
        "𐃨",
        "𐄹",
        "𐆗",
        "𐇒",
        "𐇓",
        "𐇕",
        "𐇛",
        "𐇝",
        "𐇤",
        "𐇱",
        "𐇲",
        "𐇵",
        "𐇷",
        "𐇼",
        "𐊎",
        "𐊘",
        "𐊚",
        "𐘚",
        "𐘞",
        "𐘢",
        "𐘥",
        "𐘦",
        "𐘮",
        "𐘴",
        "𐘸",
        "𐘻",
        "𐙁",
        "𐙄",
        "𐙐",
        "𐙔",
        "𐙕",
        "𐙛",
        "𐙯",
        "𐙰",
        "𐙷",
        "𐙹",
        "𐚋",
        "𐚚",
        "𐚠",
        "𐚢",
        "𐚣",
        "𐚧",
        "𐚩",
        "𐚪",
        "𐚬",
        "𐚱",
        "𐛃",
        "𐛆",
        "𐛈",
        "𐛌",
        "𐛑",
        "𐛒",
        "𐛡",
        "𐛢",
        "𐛣",
        "𐜎",
        "𐜖",
        "𐜩",
        "𐜪",
        "𐜫",
        "𐝍",
        "𐝎",
        "𐝡",
        "𐝢",
        "𐝤",
        "𐝥",
        "𐝧",
        "𐠄",
        "𐠍",
        "𐠝",
        "𐠡",
        "𐡡",
        "𐡪",
        "𐡻",
        "𐦤",
        "𐧄",
        "𐨓",
        "𐨕",
        "𐨖",
        "𐨩",
        "𐨭",
        "𐨮",
        "𐩇",
        "𐬒",
        "𐬓",
        "𐬖",
        "𐮇",
        "𐮜",
        "𑀆",
        "𑀈",
        "𑀌",
        "𑀑",
        "𑀒",
        "𑀓",
        "𑀙",
        "𑁌",
        "𑁛",
        "𑁝",
        "𑁡",
        "𑁢",
        "𑂃",
        "𑂏",
        "𑂥",
        "𑂫",
        "𑄆",
        "𑄇",
        "𑄋",
        "𑄖",
        "𑄗",
        "𑄟",
        "𑄠",
        "𑄥",
        "𑆅",
        "𑆘",
        "𑆜",
        "𑇁",
        "𑇔",
        "𑇞",
        "𑇦",
        "𑈓",
        "𑈔",
        "𑈖",
        "𑈘",
        "𑈚",
        "𑈛",
        "𑈜",
        "𑈞",
        "𑈢",
        "𑈨",
        "𑈪",
        "𑊆",
        "𑊐",
        "𑊖",
        "𑊗",
        "𑊚",
        "𑊤",
        "𑊳",
        "𑋃",
        "𑋄",
        "𑋖",
        "𑋜",
        "𑋝",
        "𑌗",
        "𑌘",
        "𑌟",
        "𑌡",
        "𑌢",
        "𑌪",
        "𑌫",
        "𑐆",
        "𑐈",
        "𑑒",
        "𑑗",
        "𑑘",
        "𑑙",
        "𑑟",
        "𑓑",
        "𑓓",
        "𑓗",
        "𑖇",
        "𑖈",
        "𑖓",
        "𑖗",
        "𑖜",
        "𑖟",
        "𑖠",
        "𑖥",
        "𑗃",
        "𑗈",
        "𑗏",
        "𑙂",
        "𑙣",
        "𑚁",
        "𑚉",
        "𑚌",
        "𑚍",
        "𑚢",
        "𑚥",
        "𑚧",
        "𑜋",
        "𑜍",
        "𑜎",
        "𑜏",
        "𑜳",
        "𑜶",
        "𑜹",
        "𑝁",
        "𑝅",
        "𑝆",
        "𑠄",
        "𑠈",
        "𑠉",
        "𑠊",
        "𑠐",
        "𑠑",
        "𑠝",
        "𑠠",
        "𑠡",
        "𑠣",
        "𑠤",
        "𑠥",
        "𑠧",
        "𑠨",
        "𑤅",
        "𑤆",
        "𑤌",
        "𑤍",
        "𑤎",
        "𑤏",
        "𑤑",
        "𑤒",
        "𑤓",
        "𑤕",
        "𑤖",
        "𑤘",
        "𑤙",
        "𑤚",
        "𑤛",
        "𑤜",
        "𑤟",
        "𑤠",
        "𑤡",
        "𑤢",
        "𑤤",
        "𑤥",
        "𑤦",
        "𑤪",
        "𑤫",
        "𑤬",
        "𑤮",
        "𑤯",
        "𑦤",
        "𑦦",
        "𑰀",
        "𑰁",
        "𑰇",
        "𑰐",
        "𑰑",
        "𑰕",
        "𑰢",
        "𑰤",
        "𑰦",
        "𑰭",
        "𑱓",
        "𑱕",
        "𑱟",
        "𑱤",
        "𑱥",
        "𑱧",
        "𑶢",
        "𑶩",
        "𑻠",
        "𑻡",
        "𑻢",
        "𑻣",
        "𑻤",
        "𑻥",
        "𑻦",
        "𑻧",
        "𑻨",
        "𑻩",
        "𑻪",
        "𑻬",
        "𑻭",
        "𑻮",
        "𑻲",
        "𑼒",
        "𑼓",
        "𑼔",
        "𑼙",
        "𑼜",
        "𑼝",
        "𑼠",
        "𑼡",
        "𑼢",
        "𑼣",
        "𑼤",
        "𑼥",
        "𑼦",
        "𑼧",
        "𑼩",
        "𑼭",
        "𑼮",
        "𑼯",
        "𑼰",
        "𑼱",
        "𑼲",
        "𑽌",
        "𑽍",
        "𑽎",
        "𑽑",
        "𑽒",
        "𑽓",
        "𒾐",
        "𒾑",
        "𒾒",
        "𒾓",
        "𒾔",
        "𒾕",
        "𒾖",
        "𒾗",
        "𒾘",
        "𒾧",
        "𒾨",
        "𒾪",
        "𒾬",
        "𒾭",
        "𒿔",
        "𒿕",
        "𒿖",
        "𒿟",
        "𒿪",
        "𖩵",
        "𖪉",
        "𖪫",
        "𖪯",
        "𖪵",
        "𖪻",
        "𖫀",
        "𖫃",
        "𖹦",
        "𘬃",
        "𘬇",
        "𘬋",
        "𘬏",
        "𘬐",
        "𘬑",
        "𘬒",
        "𘬓",
        "𘬔",
        "𘬖",
        "𘬗",
        "𘬘",
        "𘬚",
        "𘬛",
        "𘬜",
        "𘬝",
        "𘬞",
        "𘬟",
        "𘬡",
        "𘬢",
        "𘬣",
        "𘬤",
        "𘬦",
        "𘬩",
        "𘬪",
        "𘬬",
        "𘬳",
        "𘬴",
        "𘬵",
        "𘬶",
        "𘬷",
        "𘬽",
        "𘬾",
        "𘭀",
        "𘭁",
        "𘭃",
        "𘭄",
        "𘭅",
        "𘭆",
        "𘭇",
        "𘭈",
        "𘭉",
        "𘭌",
        "𘭐",
        "𘭒",
        "𘭓",
        "𘭔",
        "𘭕",
        "𘭗",
        "𘭘",
        "𘭙",
        "𘭚",
        "𘭛",
        "𘭜",
        "𘭝",
        "𘭣",
        "𘭤",
        "𘭥",
        "𘭦",
        "𘭧",
        "𘭨",
        "𘭩",
        "𘭪",
        "𘭬",
        "𘭭",
        "𘭮",
        "𘭰",
        "𘭱",
        "𘭲",
        "𘭳",
        "𘭵",
        "𘭶",
        "𘭷",
        "𘭸",
        "𘭹",
        "𘭻",
        "𘭼",
        "𘮀",
        "𘮁",
        "𘮃",
        "𘮄",
        "𘮈",
        "𘮍",
        "𘮎",
        "𘮏",
        "𘮐",
        "𘮓",
        "𘮜",
        "𘮡",
        "𘮣",
        "𘮤",
        "𘮥",
        "𘮦",
        "𘮧",
        "𘮨",
        "𘮩",
        "𘮪",
        "𘮫",
        "𘮯",
        "𘮰",
        "𘮱",
        "𘮲",
        "𘮳",
        "𘮴",
        "𘮵",
        "𘮶",
        "𘮸",
        "𘮹",
        "𘯃",
        "𘯄",
        "𘯅",
        "𘯈",
        "𘯉",
        "𘯊",
        "𘯋",
        "𘯐",
        "𘯒",
        "𘯓",
        "𘯖",
        "𘯙",
        "𘯚",
        "𘯠",
        "𘯡",
        "𘯢",
        "𘯮",
        "𘯲",
        "𘯳",
        "𘯶",
        "𘯹",
        "𘯼",
        "𘯽",
        "𘯾",
        "𘯿",
        "𘰀",
        "𘰂",
        "𘰃",
        "𘰆",
        "𘰑",
        "𘰓",
        "𘰔",
        "𘰖",
        "𘰘",
        "𘰙",
        "𘰚",
        "𘰛",
        "𘰜",
        "𘰝",
        "𘰞",
        "𘰢",
        "𘰣",
        "𘰤",
        "𘰥",
        "𘰦",
        "𘰧",
        "𘰨",
        "𘰯",
        "𘰰",
        "𘰱",
        "𘰲",
        "𘰷",
        "𘰸",
        "𘰹",
        "𘰺",
        "𘰻",
        "𘰼",
        "𘰽",
        "𘰾",
        "𘰿",
        "𘱀",
        "𘱁",
        "𘱂",
        "𘱃",
        "𘱄",
        "𘱅",
        "𘱆",
        "𘱇",
        "𘱈",
        "𘱉",
        "𘱐",
        "𘱑",
        "𘱓",
        "𘱕",
        "𘱘",
        "𘱙",
        "𘱜",
        "𘱞",
        "𘱡",
        "𘱣",
        "𘱨",
        "𘱩",
        "𘱴",
        "𘱵",
        "𘱷",
        "𘱽",
        "𘱾",
        "𘲃",
        "𘲇",
        "𘲈",
        "𘲊",
        "𘲋",
        "𘲌",
        "𘲍",
        "𘲎",
        "𘲏",
        "𘲑",
        "𘲖",
        "𘲜",
        "𘲥",
        "𘲦",
        "𘲲",
        "𘲳",
        "𘲸",
        "𘲹",
        "𘲺",
        "𘲾",
        "𘲿",
        "𘳀",
        "𘳇",
        "𘳉",
        "𘳍",
        "𘳐",
        "𘳑",
        "𘳓",
        "𛀚",
        "𛁵",
        "𛂌",
        "𛅐",
        "𛅕",
        "𛅤",
        "𛅧",
        "𛅸",
        "𛆌",
        "𛆪",
        "𛆯",
        "𛆸",
        "𛇁",
        "𛇖",
        "𛇜",
        "𛇢",
        "𛇥",
        "𛇧",
        "𛇨",
        "𛇭",
        "𛇵",
        "𛇻",
        "𛈀",
        "𛈉",
        "𛈋",
        "𛈍",
        "𛈐",
        "𛈘",
        "𛈜",
        "𛈝",
        "𛈞",
        "𛈢",
        "𛈦",
        "𛈨",
        "𛈩",
        "𛈮",
        "𛈱",
        "𛈴",
        "𛉃",
        "𛉆",
        "𛉉",
        "𛉏",
        "𛉐",
        "𛉔",
        "𛉕",
        "𛉙",
        "𛉣",
        "𛉦",
        "𛉭",
        "𛉮",
        "𛉼",
        "𛊃",
        "𛊇",
        "𛊊",
        "𛊏",
        "𛊐",
        "𛊗",
        "𛊡",
        "𛊣",
        "𛊧",
        "𛊮",
        "𛊯",
        "𛊳",
        "𛊿",
        "𛋁",
        "𛋅",
        "𛋊",
        "𛋍",
        "𛋓",
        "𛋛",
        "𛋝",
        "𛋠",
        "𛋪",
        "𛋬",
        "𛋲",
        "𛋶",
        "𛋹",
        "𛰫",
        "𛰬",
        "𛱳",
        "𛱴",
        "𛱵",
        "𛱶",
        "𛱸",
        "𛲟",
        "𜽟",
        "𜽫",
        "𜽭",
        "𜾐",
        "𝀉",
        "𝀡",
        "𝀱",
        "𝀸",
        "𝀺",
        "𝁔",
        "𝁼",
        "𝁽",
        "𝂬",
        "𝃉",
        "𝃒",
        "𝃖",
        "𝃦",
        "𝇘",
        "𝐵",
        "𝐷",
        "𝐸",
        "𝐹",
        "𝐻",
        "𝐽",
        "𝑀",
        "𝑁",
        "𝑃",
        "𝑅",
        "𝑊",
        "𝑋",
        "𝑍",
        "𝑑",
        "𝑔",
        "𝑗",
        "𝑚",
        "𝑝",
        "𝑤",
        "𝑥",
        "𝑧",
        "𝑨",
        "𝑪",
        "𝑫",
        "𝑮",
        "𝑶",
        "𝑸",
        "𝑺",
        "𝑼",
        "𝒃",
        "𝒉",
        "𝒌",
        "𝒏",
        "𝒓",
        "𝒖",
        "𝒚",
        "𝒬",
        "𝒮",
        "𝒿",
        "𝓇",
        "𝓉",
        "𝓏",
        "𝓔",
        "𝓠",
        "𝓢",
        "𝓮",
        "𝓳",
        "𝓻",
        "𝓽",
        "𝔃",
        "𝘉",
        "𝘋",
        "𝘌",
        "𝘍",
        "𝘏",
        "𝘑",
        "𝘔",
        "𝘕",
        "𝘗",
        "𝘙",
        "𝘞",
        "𝘟",
        "𝘡",
        "𝘥",
        "𝘨",
        "𝘫",
        "𝘮",
        "𝘱",
        "𝘸",
        "𝘹",
        "𝘻",
        "𝘼",
        "𝘾",
        "𝘿",
        "𝙂",
        "𝙊",
        "𝙌",
        "𝙎",
        "𝙐",
        "𝙗",
        "𝙝",
        "𝙠",
        "𝙣",
        "𝙧",
        "𝙪",
        "𝙮",
        "𝙶",
        "𝙹",
        "𝙺",
        "𝙽",
        "𝙾",
        "𝚀",
        "𝚁",
        "𝚄",
        "𝚈",
        "𝚍",
        "𝚖",
        "𝛣",
        "𝛤",
        "𝛦",
        "𝛧",
        "𝛨",
        "𝛭",
        "𝛮",
        "𝛱",
        "𝛲",
        "𝛴",
        "𝛷",
        "𝛸",
        "𝛽",
        "𝜌",
        "𝜓",
        "𝜜",
        "𝜟",
        "𝜣",
        "𝜦",
        "𝜩",
        "𝜪",
        "𝜭",
        "𝜳",
        "𝜶",
        "𝜸",
        "𝜼",
        "𝜿",
        "𝝂",
        "𝝈",
        "𝝊",
        "𝝋",
        "𝝎",
        "𝝏",
        "𝝑",
        "𝝓",
        "𝝕",
        "𝞐",
        "𝞓",
        "𝞗",
        "𝞚",
        "𝞝",
        "𝞞",
        "𝞡",
        "𝞧",
        "𝞪",
        "𝞬",
        "𝞰",
        "𝞳",
        "𝞶",
        "𝞼",
        "𝞾",
        "𝞿",
        "𝟂",
        "𝟃",
        "𝟅",
        "𝟇",
        "𝟉",
        "𝠙",
        "𝠰",
        "𝡓",
        "𝡔",
        "𝡕",
        "𝡖",
        "𝡳",
        "𝡿",
        "𝢆",
        "𝢇",
        "𝢮",
        "𝢴",
        "𝢷",
        "𝢹",
        "𝣐",
        "𝤀",
        "𝤊",
        "𝤍",
        "𝤡",
        "𝤧",
        "𝤺",
        "𝤼",
        "𝤿",
        "𝥂",
        "𝥆",
        "𝥇",
        "𝥑",
        "𝥒",
        "𝥓",
        "𝥔",
        "𝥵",
        "𝥹",
        "𝦇",
        "𝦊",
        "𝦍",
        "𝦠",
        "𝦥",
        "𝦯",
        "𝦷",
        "𝦸",
        "𝦽",
        "𝦾",
        "𝦿",
        "𝧀",
        "𝧇",
        "𝧍",
        "𝧏",
        "𝧢",
        "𝧵",
        "𝧷",
        "𝧺",
        "𞋇",
        "𞋓",
        "𞋔",
        "𞋗",
        "𞋘",
        "𞋠",
        "𞋡",
        "𞋢",
        "𞋣",
        "𞋤",
        "𞋧",
        "𞋴",
        "𞋹",
        "𞠈",
        "𞠉",
        "𞠊",
        "𞠟",
        "𞠵",
        "𞡉",
        "𞡓",
        "𞡔",
        "𞡕",
        "𞡶",
        "𞢆",
        "𞢊",
        "𞢋",
        "𞢍",
        "𞢏",
        "𞢙",
        "𞢡",
        "𞢨",
        "𞢫",
        "𞢹",
        "𞢼",
        "𞣀",
        "𞣉",
        "𞱳",
        "𞱶",
        "𞱸",
        "𞲄",
        "𞲥",
        "𞲦",
        "𞲧",
        "𞲨",
        "𞲩",
        "𞲪",
        "𞴄",
        "𞴇",
        "𞴉",
        "𞴮",
        "𞴰",
        "𞴴",
        "𞴶",
        "𞹤",
        "🄁",
        "🄃",
        "🄄",
        "🄅",
        "🄆",
        "🄇",
        "🄈",
        "🄉",
        "🄊",
        "🌀",
        "🌷",
        "🍍",
        "🍐",
        "🍫",
        "🍵",
        "🍸",
        "🎇",
        "🎗",
        "🎵",
        "🏅",
        "🏠",
        "🏱",
        "🏲",
        "🏳",
        "🏴",
        "👆",
        "👇",
        "👖",
        "📁",
        "📔",
        "🔅",
        "🔬",
        "🔸",
        "🔹",
        "🔼",
        "🔽",
        "🖥",
        "🖩",
        "🖰",
        "🖱",
        "🗅",
        "🗆",
        "🗈",
        "🗉",
        "🗋",
        "🗌",
        "🗎",
        "🗏",
        "🗒",
        "🗓",
        "🙺",
        "🚆",
        "🚔",
        "🚘",
        "🚩",
        "🚺",
        "🚿",
        "🛕",
        "🜁",
        "🜂",
        "🜃",
        "🜄",
        "🜈",
        "🜍",
        "🜎",
        "🜏",
        "🜔",
        "🜕",
        "🜖",
        "🜗",
        "🜞",
        "🜠",
        "🜡",
        "🜨",
        "🜯",
        "🜰",
        "🜵",
        "🜹",
        "🜻",
        "🜿",
        "🝁",
        "🝘",
        "🝢",
        "🝦",
        "🝧",
        "🝨",
        "🝩",
        "🝭",
        "🝴",
        "🝵",
        "🠙",
        "🠛",
        "🠡",
        "🠣",
        "🡀",
        "🡂",
        "🤌",
        "🤘",
        "🤞",
        "🤾",
        "🥚",
        "🥫",
        "🧂",
        "🧄",
        "🧧",
        "🧷",
        "🧹",
        "🧾",
        "🨂",
        "🨃",
        "🨠",
        "🨡",
        "🨦",
        "🨧",
        "🨬",
        "🨭",
        "🩋",
        "🩌",
        "🩍",
        "🪫",
        "🫃",
        "🫄",
        "🫅",
        "🫰",
        "𧾷"
    ],
    [
        "ʰ",
        "ʱ",
        "ʳ",
        "ʴ",
        "ʶ",
        "ʸ",
        "˔",
        "˕",
        "˖",
        "˗",
        "ˢ",
        "˥",
        "˦",
        "˧",
        "˨",
        "˩",
        "˪",
        "˫",
        "ˬ",
        "˯",
        "˰",
        "Ͱ",
        "ͳ",
        "΅",
        "Ϛ",
        "ϝ",
        "Ϡ",
        "ϡ",
        "ϥ",
        "ϳ",
        "ϵ",
        "϶",
        "؉",
        "؍",
        "م",
        "٢",
        "٪",
        "ٱ",
        "۲",
        "ݥ",
        "ݦ",
        "ݴ",
        "ހ",
        "ބ",
        "ކ",
        "ވ",
        "މ",
        "ފ",
        "ލ",
        "ގ",
        "ޓ",
        "ޖ",
        "ޙ",
        "ޚ",
        "ޤ",
        "ޥ",
        "߂",
        "߆",
        "߉",
        "ߍ",
        "ߎ",
        "ߏ",
        "ߐ",
        "ߒ",
        "ߕ",
        "ߖ",
        "ߙ",
        "ߜ",
        "ߠ",
        "ߡ",
        "ߢ",
        "ߣ",
        "ߦ",
        "ߧ",
        "ߪ",
        "߶",
        "זּ",
        "לּ",
        "ﭐ",
        "﮴",
        "﮵",
        "﮶",
        "﮷",
        "﮸",
        "﮹",
        "﮼",
        "﯂",
        "ﯪ",
        "ﯶ",
        "ﰼ",
        "ﱂ",
        "ﱒ",
        "ﱣ",
        "ﲅ",
        "ﳁ",
        "ﳃ",
        "ﴼ",
        "ﴽ",
        "﹆",
        "﹘",
        "﹟",
        "﹢",
        "﹩",
        "ﹲ",
        "ﹸ",
        "ﹼ",
        "ﻡ",
        "ﻹ",
        "ﻻ",
        "｢",
        "｣",
        "ﾄ",
        "ﾘ",
        "ﾞ",
        "ﾢ",
        "ﾨ",
        "ﾱ",
        "ﾵ",
        "ﾷ",
        "ﾸ",
        "ﾺ",
        "ￇ",
        "ￋ",
        "ￌ",
        "ￏ",
        "ￒ",
        "ￓ",
        "ￗ",
        "￪",
        "￬",
        "𐀅",
        "𐀙",
        "𐀞",
        "𐀟",
        "𐀪",
        "𐀫",
        "𐀸",
        "𐀼",
        "𐀽",
        "𐁌",
        "𐁑",
        "𐂓",
        "𐂻",
        "𐄂",
        "𐄉",
        "𐄋",
        "𐄌",
        "𐄏",
        "𐄸",
        "𐄻",
        "𐄼",
        "𐄾",
        "𐅙",
        "𐅡",
        "𐅹",
        "𐅻",
        "𐆁",
        "𐆋",
        "𐆍",
        "𐇜",
        "𐇦",
        "𐇩",
        "𐊀",
        "𐊁",
        "𐊈",
        "𐊋",
        "𐊑",
        "𐊓",
        "𐊔",
        "𐊛",
        "𐊢",
        "𐊤",
        "𐊥",
        "𐊦",
        "𐊩",
        "𐊮",
        "𐊯",
        "𐊹",
        "𐋍",
        "𐋡",
        "𐋤",
        "𐋯",
        "𐋰",
        "𐋳",
        "𐋵",
        "𐋶",
        "𐋹",
        "𐌂",
        "𐌄",
        "𐌅",
        "𐌆",
        "𐌊",
        "𐌋",
        "𐌐",
        "𐌓",
        "𐌚",
        "𐌛",
        "𐌜",
        "𐌭",
        "𐎂",
        "𐎃",
        "𐎇",
        "𐎟",
        "𐏑",
        "𐏒",
        "𐐆",
        "𐐈",
        "𐐓",
        "𐐙",
        "𐐞",
        "𐐢",
        "𐐮",
        "𐐹",
        "𐐻",
        "𐑁",
        "𐑃",
        "𐑊",
        "𐑐",
        "𐑒",
        "𐑓",
        "𐑕",
        "𐑘",
        "𐑙",
        "𐑚",
        "𐑜",
        "𐑝",
        "𐑟",
        "𐑢",
        "𐑣",
        "𐑤",
        "𐑧",
        "𐑨",
        "𐑩",
        "𐑪",
        "𐑬",
        "𐑮",
        "𐑳",
        "𐑴",
        "𐑶",
        "𐒑",
        "𐒒",
        "𐒖",
        "𐒗",
        "𐒛",
        "𐒡",
        "𐒦",
        "𐔤",
        "𐕶",
        "𐕾",
        "𐕿",
        "𐖅",
        "𐖆",
        "𐖈",
        "𐖏",
        "𐖝",
        "𐖣",
        "𐖫",
        "𐖭",
        "𐖯",
        "𐖱",
        "𐘅",
        "𐘆",
        "𐘇",
        "𐘈",
        "𐘊",
        "𐘍",
        "𐘗",
        "𐙉",
        "𐙊",
        "𐙑",
        "𐙖",
        "𐙢",
        "𐙪",
        "𐙫",
        "𐙱",
        "𐙲",
        "𐙸",
        "𐙿",
        "𐚉",
        "𐚌",
        "𐚳",
        "𐛬",
        "𐛭",
        "𐝀",
        "𐝁",
        "𐝆",
        "𐝏",
        "𐝐",
        "𐝓",
        "𐞄",
        "𐞅",
        "𐞎",
        "𐞒",
        "𐞖",
        "𐞗",
        "𐞘",
        "𐞠",
        "𐞢",
        "𐞥",
        "𐞦",
        "𐞨",
        "𐞩",
        "𐞪",
        "𐞯",
        "𐠃",
        "𐠅",
        "𐠒",
        "𐠞",
        "𐠟",
        "𐠠",
        "𐠦",
        "𐠫",
        "𐠭",
        "𐡀",
        "𐡁",
        "𐡂",
        "𐡃",
        "𐡈",
        "𐡎",
        "𐡏",
        "𐡐",
        "𐡓",
        "𐡞",
        "𐡩",
        "𐡮",
        "𐡿",
        "𐢑",
        "𐢕",
        "𐢛",
        "𐢩",
        "𐢫",
        "𐤁",
        "𐤉",
        "𐤊",
        "𐤋",
        "𐤏",
        "𐤓",
        "𐤕",
        "𐤘",
        "𐤢",
        "𐤣",
        "𐤤",
        "𐤥",
        "𐤨",
        "𐤩",
        "𐤰",
        "𐦗",
        "𐦞",
        "𐦡",
        "𐦨",
        "𐦪",
        "𐦴",
        "𐦽",
        "𐧂",
        "𐧷",
        "𐧸",
        "𐩑",
        "𐩠",
        "𐩢",
        "𐩣",
        "𐩤",
        "𐩥",
        "𐩨",
        "𐩩",
        "𐩪",
        "𐩫",
        "𐩬",
        "𐩭",
        "𐩮",
        "𐩯",
        "𐩰",
        "𐩱",
        "𐩲",
        "𐩳",
        "𐩵",
        "𐩷",
        "𐩸",
        "𐩹",
        "𐩺",
        "𐩻",
        "𐩼",
        "𐩾",
        "𐪅",
        "𐪈",
        "𐪉",
        "𐪊",
        "𐪋",
        "𐪌",
        "𐪍",
        "𐪎",
        "𐪑",
        "𐪒",
        "𐪓",
        "𐪘",
        "𐪙",
        "𐪚",
        "𐪞",
        "𐪟",
        "𐫇",
        "𐫈",
        "𐫗",
        "𐫶",
        "𐬍",
        "𐬏",
        "𐬐",
        "𐬦",
        "𐬨",
        "𐬩",
        "𐬭",
        "𐬰",
        "𐬻",
        "𐭂",
        "𐭃",
        "𐭙",
        "𐭚",
        "𐭝",
        "𐭢",
        "𐭣",
        "𐭥",
        "𐭨",
        "𐭫",
        "𐭹",
        "𐭺",
        "𐮄",
        "𐮭",
        "𐮮",
        "𐰀",
        "𐰁",
        "𐰂",
        "𐰅",
        "𐰉",
        "𐰋",
        "𐰍",
        "𐰎",
        "𐰐",
        "𐰓",
        "𐰔",
        "𐰕",
        "𐰖",
        "𐰘",
        "𐰚",
        "𐰜",
        "𐰟",
        "𐰠",
        "𐰡",
        "𐰩",
        "𐰰",
        "𐰱",
        "𐰲",
        "𐰳",
        "𐰴",
        "𐰵",
        "𐰸",
        "𐰹",
        "𐰺",
        "𐰻",
        "𐱀",
        "𐱁",
        "𐱃",
        "𐱄",
        "𐱅",
        "𐱆",
        "𐲂",
        "𐲊",
        "𐲒",
        "𐲚",
        "𐲞",
        "𐲠",
        "𐲨",
        "𐲩",
        "𐲬",
        "𐳂",
        "𐳄",
        "𐳅",
        "𐳊",
        "𐳏",
        "𐳒",
        "𐳓",
        "𐳚",
        "𐳠",
        "𐳦",
        "𐳨",
        "𐳩",
        "𐳬",
        "𐳰",
        "𐳼",
        "𐴰",
        "𐴲",
        "𐹠",
        "𐹥",
        "𐹧",
        "𐹯",
        "𐹲",
        "𐺆",
        "𐺉",
        "𐺊",
        "𐺔",
        "𐺕",
        "𐺠",
        "𐺡",
        "𐺦",
        "𐺧",
        "𐺰",
        "𐼇",
        "𐼣",
        "𐼤",
        "𐼷",
        "𐽄",
        "𐽙",
        "𐿀",
        "𐿁",
        "𐿂",
        "𐿇",
        "𐿥",
        "𑀉",
        "𑀊",
        "𑀝",
        "𑀟",
        "𑀯",
        "𑁖",
        "𑂗",
        "𑂘",
        "𑂠",
        "𑃑",
        "𑃕",
        "𑃚",
        "𑃛",
        "𑃜",
        "𑃠",
        "𑃤",
        "𑃳",
        "𑃶",
        "𑅃",
        "𑅑",
        "𑅖",
        "𑅛",
        "𑅢",
        "𑅫",
        "𑅵",
        "𑇑",
        "𑇒",
        "𑇓",
        "𑇝",
        "𑇟",
        "𑑈",
        "𑑌",
        "𑑏",
        "𑚖",
        "𑚙",
        "𑚤",
        "𑚹",
        "𑜚",
        "𑢱",
        "𑢺",
        "𑣑",
        "𑣛",
        "𑣡",
        "𑥅",
        "𑧂",
        "𑧈",
        "𑧢",
        "𑩃",
        "𑪚",
        "𑫦",
        "𑫧",
        "𑰟",
        "𑱀",
        "𑱂",
        "𑱄",
        "𑱐",
        "𑱔",
        "𑱖",
        "𑱛",
        "𑱲",
        "𑱳",
        "𑱴",
        "𑱵",
        "𑱷",
        "𑱸",
        "𑱻",
        "𑱼",
        "𑱽",
        "𑲂",
        "𑲄",
        "𑲆",
        "𑲈",
        "𑲉",
        "𑲊",
        "𑲌",
        "𑲍",
        "𑲎",
        "𑶡",
        "𑼬",
        "𑽉",
        "𑽊",
        "𑽋",
        "𒿱",
        "𖩮",
        "𖪈",
        "𖫔",
        "𖬻",
        "𖬾",
        "𖹥",
        "𖹶",
        "𖹻",
        "𖹾",
        "𖺚",
        "𖼬",
        "𖼳",
        "𖼷",
        "𖼸",
        "𖼹",
        "𖽆",
        "𖽐",
        "𖾓",
        "𖾕",
        "𖾜",
        "𖾞",
        "𘮌",
        "𚿰",
        "𚿱",
        "𚿽",
        "𛅰",
        "𛅱",
        "𛅵",
        "𛅷",
        "𛅹",
        "𛆁",
        "𛆉",
        "𛆒",
        "𛆔",
        "𛆙",
        "𛆩",
        "𛇙",
        "𛈅",
        "𛈌",
        "𛈖",
        "𛉶",
        "𛰅",
        "𛰆",
        "𛰗",
        "𛰙",
        "𛰚",
        "𛰡",
        "𛰢",
        "𛰹",
        "𛱁",
        "𛱂",
        "𛱈",
        "𛱉",
        "𛱏",
        "𛱡",
        "𛱣",
        "𛱼",
        "𜽜",
        "𜽯",
        "𜽽",
        "𜾁",
        "𜾃",
        "𝀀",
        "𝀁",
        "𝀏",
        "𝀑",
        "𝀗",
        "𝀘",
        "𝀜",
        "𝀤",
        "𝁣",
        "𝂀",
        "𝂆",
        "𝂏",
        "𝂗",
        "𝂘",
        "𝂛",
        "𝂜",
        "𝂟",
        "𝂴",
        "𝂺",
        "𝂻",
        "𝂿",
        "𝃘",
        "𝃙",
        "𝃟",
        "𝃠",
        "𝃡",
        "𝃥",
        "𝃪",
        "𝃯",
        "𝃵",
        "𝄔",
        "𝄪",
        "𝄾",
        "𝅃",
        "𝅄",
        "𝅈",
        "𝅉",
        "𝅎",
        "𝅏",
        "𝅔",
        "𝅕",
        "𝅦",
        "𝆔",
        "𝆕",
        "𝆚",
        "𝇟",
        "𝇢",
        "𝇣",
        "𝇤",
        "𝇥",
        "𝇨",
        "𝈉",
        "𝋀",
        "𝑙",
        "𝑡",
        "𝔠",
        "𝔣",
        "𝔩",
        "𝔷",
        "𝖈",
        "𝖋",
        "𝖑",
        "𝖟",
        "𝖨",
        "𝖿",
        "𝗂",
        "𝗃",
        "𝗅",
        "𝗍",
        "𝘭",
        "𝘵",
        "𝙸",
        "𝚒",
        "𝚓",
        "𝚕",
        "𝚣",
        "𝚤",
        "𝛊",
        "𝝸",
        "𝟣",
        "𝟷",
        "𝤈",
        "𝤋",
        "𝤖",
        "𝤛",
        "𝤢",
        "𝤦",
        "𝤪",
        "𝤫",
        "𝤬",
        "𝤭",
        "𝥕",
        "𝥖",
        "𝥗",
        "𝥘",
        "𝥙",
        "𝥚",
        "𝥛",
        "𝥜",
        "𝥝",
        "𝥞",
        "𝥟",
        "𝥠",
        "𝥡",
        "𝥢",
        "𝥣",
        "𝥤",
        "𝥥",
        "𝥦",
        "𝥧",
        "𝥨",
        "𝦕",
        "𝦘",
        "𝨹",
        "𝨺",
        "𝼋",
        "𝼐",
        "𞀳",
        "𞀹",
        "𞀽",
        "𞁀",
        "𞁌",
        "𞁏",
        "𞁔",
        "𞁚",
        "𞁝",
        "𞁧",
        "𞁨",
        "𞁪",
        "𞁭",
        "𞄣",
        "𞄤",
        "𞄥",
        "𞄦",
        "𞄫",
        "𞄺",
        "𞊐",
        "𞊒",
        "𞊓",
        "𞊔",
        "𞊕",
        "𞊖",
        "𞊚",
        "𞊜",
        "𞊠",
        "𞊡",
        "𞊢",
        "𞊭",
        "𞋶",
        "𞓞",
        "𞠇",
        "𞠚",
        "𞠪",
        "𞠮",
        "𞠯",
        "𞠰",
        "𞡂",
        "𞡄",
        "𞡅",
        "𞡆",
        "𞡢",
        "𞡧",
        "𞡬",
        "𞡮",
        "𞡱",
        "𞡲",
        "𞢞",
        "𞢣",
        "𞢩",
        "𞢲",
        "𞢳",
        "𞣏",
        "𞲣",
        "𞲬",
        "𞲭",
        "𞲯",
        "𞴂",
        "𞴓",
        "𞴼",
        "𞸌",
        "𞹡",
        "𞹩",
        "𞺌",
        "🌡",
        "🍢",
        "🍼",
        "💄",
        "💊",
        "📱",
        "🕽",
        "🜢",
        "🜣",
        "🜥",
        "🜦",
        "🜧",
        "🜬",
        "🜭",
        "🜮",
        "🝋",
        "🞘",
        "🞞",
        "🠁",
        "🠃",
        "🠼",
        "🠾",
        "🢑",
        "🢓",
        "🢰",
        "🪑",
        "🪕",
        "🮉",
        "🯅",
        "🯆",
        "🯇",
        "🯈",
        "🯉"
    ],
    [
        "۞",
        "۩",
        "ﬦ",
        "ﴗ",
        "ﴘ",
        "ﴙ",
        "ﴚ",
        "ﴛ",
        "ﴜ",
        "ﴝ",
        "ﴞ",
        "ﴟ",
        "ﴠ",
        "﵁",
        "﵂",
        "﵄",
        "﵋",
        "ﵘ",
        "ﵚ",
        "ﵛ",
        "ﵠ",
        "ﵡ",
        "ﵢ",
        "ﵣ",
        "ﵦ",
        "ﵨ",
        "ﵪ",
        "ﵫ",
        "ﵬ",
        "ﵭ",
        "ﵰ",
        "ﵱ",
        "ﵲ",
        "ﵳ",
        "ﵵ",
        "ﵷ",
        "ﵼ",
        "ﵾ",
        "ﵿ",
        "ﶄ",
        "ﶅ",
        "ﶓ",
        "ﶔ",
        "ﶖ",
        "ﶗ",
        "ﶚ",
        "ﶛ",
        "ﶝ",
        "ﶣ",
        "ﶤ",
        "ﶬ",
        "ﶭ",
        "ﶮ",
        "ﶯ",
        "ﶰ",
        "ﶱ",
        "ﶲ",
        "ﶳ",
        "ﶻ",
        "ﶼ",
        "ﷁ",
        "ﷅ",
        "ﷻ",
        "𐁄",
        "𐁗",
        "𐂃",
        "𐂅",
        "𐂷",
        "𐃫",
        "𐃱",
        "𐃳",
        "𐄯",
        "𐎄",
        "𐎆",
        "𐎋",
        "𐎌",
        "𐎍",
        "𐎗",
        "𐎜",
        "𐎠",
        "𐎨",
        "𐎪",
        "𐎫",
        "𐎮",
        "𐎳",
        "𐎴",
        "𐎶",
        "𐎷",
        "𐎹",
        "𐎺",
        "𐏀",
        "𐏏",
        "𐘩",
        "𐚄",
        "𐚼",
        "𐚾",
        "𐛕",
        "𐛥",
        "𐛦",
        "𐛽",
        "𐛾",
        "𐜒",
        "𐜓",
        "𐜡",
        "𐜣",
        "𐜤",
        "𐜮",
        "𐜵",
        "𐣴",
        "𐦆",
        "𐦋",
        "𐦌",
        "𐦓",
        "𐧞",
        "𐫰",
        "𐽔",
        "𑆐",
        "𑇩",
        "𑇰",
        "𑇳",
        "𑐀",
        "𑐁",
        "𑒝",
        "𑒟",
        "𑒣",
        "𑒬",
        "𑓇",
        "𑓕",
        "𑗊",
        "𑗋",
        "𑗍",
        "𑗎",
        "𑗐",
        "𑶘",
        "𑼍",
        "𑿃",
        "𑿅",
        "𑿔",
        "𑿖",
        "𑿠",
        "𑿢",
        "𑿣",
        "𑿥",
        "𑿨",
        "𑿫",
        "𑿱",
        "𑿿",
        "𒾟",
        "𒾰",
        "𒾺",
        "𒿓",
        "𒿥",
        "𛀄",
        "𛀆",
        "𛀊",
        "𛀋",
        "𛀐",
        "𛀡",
        "𛀢",
        "𛀤",
        "𛀧",
        "𛀨",
        "𛀪",
        "𛀱",
        "𛀶",
        "𛀺",
        "𛀾",
        "𛁁",
        "𛁂",
        "𛁉",
        "𛁍",
        "𛁎",
        "𛁑",
        "𛁔",
        "𛁗",
        "𛁝",
        "𛁞",
        "𛁟",
        "𛁣",
        "𛁤",
        "𛁦",
        "𛁧",
        "𛁨",
        "𛁪",
        "𛁫",
        "𛁭",
        "𛁰",
        "𛁶",
        "𛁸",
        "𛁹",
        "𛁼",
        "𛁿",
        "𛂁",
        "𛂃",
        "𛂄",
        "𛂇",
        "𛂉",
        "𛂊",
        "𛂏",
        "𛂑",
        "𛂖",
        "𛂙",
        "𛂛",
        "𛂞",
        "𛂨",
        "𛂫",
        "𛂯",
        "𛂵",
        "𛂷",
        "𛂸",
        "𛂼",
        "𛂾",
        "𛂿",
        "𛃀",
        "𛃃",
        "𛃇",
        "𛃑",
        "𛃕",
        "𛃗",
        "𛃜",
        "𛃝",
        "𛃟",
        "𛃠",
        "𛃢",
        "𛃥",
        "𛃦",
        "𛃧",
        "𛃩",
        "𛃬",
        "𛃲",
        "𛃵",
        "𛃸",
        "𛃹",
        "𛃼",
        "𛃽",
        "𛃿",
        "𛄁",
        "𛄊",
        "𛄍",
        "𛄎",
        "𛄏",
        "𛄐",
        "𛄓",
        "𛄔",
        "𛄕",
        "𛄜",
        "𛄝",
        "𛄞",
        "𛄟",
        "𛄡",
        "𛄢",
        "𛰩",
        "𛰪",
        "𛰭",
        "𛰮",
        "𜽦",
        "𜽨",
        "𜽩",
        "𜾣",
        "𜾰",
        "𜾱",
        "𜾲",
        "𜾳",
        "𜾴",
        "𜾾",
        "𝀫",
        "𝀲",
        "𝀽",
        "𝁴",
        "𝂪",
        "𝄉",
        "𝄊",
        "𝄏",
        "𝄓",
        "𝄩",
        "𝅪",
        "𝅫",
        "𝅬",
        "𝆒",
        "𝆓",
        "𝆖",
        "𝆰",
        "𝆶",
        "𝒲",
        "𝓦",
        "𝡌",
        "𝡞",
        "𝡮",
        "𝡸",
        "𝢚",
        "𝢜",
        "𝢫",
        "𝣄",
        "𝣋",
        "𝣯",
        "𝤆",
        "𝤒",
        "𝤴",
        "𝤵",
        "𝤶",
        "𝤷",
        "𝥯",
        "𝥰",
        "𝥱",
        "𝥲",
        "𝦑",
        "𝦻",
        "𝦼",
        "𝧝",
        "𝧞",
        "𝧤",
        "𝧦",
        "𝩱",
        "𝩿",
        "𝪁",
        "𝪃",
        "𞟪",
        "𞟭",
        "𞟮",
        "𞡼",
        "𞲟",
        "𞲢",
        "𞴞",
        "𞴟",
        "𞴠",
        "𞴡",
        "𞴢",
        "𞴣",
        "𞴤",
        "𞴺",
        "𞴻",
        "𞺢",
        "𞺧",
        "𞺮",
        "𞺱",
        "𞺴",
        "𞺹",
        "🀰",
        "🀱",
        "🀲",
        "🀳",
        "🀴",
        "🀵",
        "🀶",
        "🀷",
        "🀸",
        "🀹",
        "🀺",
        "🀻",
        "🀼",
        "🀽",
        "🀾",
        "🀿",
        "🁁",
        "🁂",
        "🁃",
        "🁄",
        "🁅",
        "🁆",
        "🁇",
        "🁈",
        "🁉",
        "🁊",
        "🁋",
        "🁌",
        "🁍",
        "🁎",
        "🁏",
        "🁐",
        "🁑",
        "🁒",
        "🁓",
        "🁔",
        "🁕",
        "🁖",
        "🁗",
        "🁘",
        "🁙",
        "🁚",
        "🁛",
        "🁝",
        "🁞",
        "🁟",
        "🁠",
        "🁡",
        "🄋",
        "🄌",
        "🄍",
        "🄎",
        "🄏",
        "🄙",
        "🄠",
        "🄣",
        "🄥",
        "🄨",
        "🄫",
        "🄬",
        "🄭",
        "🅐",
        "🅑",
        "🅒",
        "🅓",
        "🅔",
        "🅕",
        "🅖",
        "🅗",
        "🅘",
        "🅙",
        "🅚",
        "🅛",
        "🅜",
        "🅝",
        "🅞",
        "🅟",
        "🅠",
        "🅡",
        "🅢",
        "🅣",
        "🅤",
        "🅥",
        "🅦",
        "🅧",
        "🅨",
        "🅩",
        "🅭",
        "🅮",
        "🆭",
        "🈀",
        "🈁",
        "🉢",
        "🉣",
        "🉥",
        "🌆",
        "🌇",
        "🌐",
        "🌟",
        "🌥",
        "🌦",
        "🌺",
        "🎂",
        "🎋",
        "🎌",
        "🎎",
        "🎘",
        "🎪",
        "🎱",
        "🎲",
        "🎳",
        "🎹",
        "🏎",
        "🏐",
        "🐂",
        "🐃",
        "🐅",
        "🐆",
        "🐊",
        "🐋",
        "🐒",
        "🐙",
        "🐭",
        "🐮",
        "🐯",
        "🐱",
        "🐲",
        "🐳",
        "🐶",
        "🐹",
        "🐻",
        "🐼",
        "🐾",
        "👀",
        "👁",
        "👄",
        "👅",
        "👐",
        "👥",
        "👫",
        "👬",
        "👭",
        "👸",
        "💌",
        "💎",
        "💘",
        "💝",
        "💞",
        "💮",
        "💿",
        "📀",
        "📢",
        "📨",
        "📬",
        "📭",
        "📯",
        "📴",
        "📶",
        "📾",
        "🔀",
        "🔑",
        "🔓",
        "🕎",
        "🕐",
        "🕑",
        "🕒",
        "🕓",
        "🕔",
        "🕕",
        "🕖",
        "🕗",
        "🕘",
        "🕙",
        "🕚",
        "🕛",
        "🕜",
        "🕝",
        "🕞",
        "🕟",
        "🕠",
        "🕡",
        "🕢",
        "🕣",
        "🕤",
        "🕥",
        "🕦",
        "🕧",
        "🕰",
        "🕲",
        "🕷",
        "🖎",
        "🖐",
        "🖑",
        "🖖",
        "🗽",
        "😀",
        "😁",
        "😂",
        "😃",
        "😄",
        "😅",
        "😆",
        "😇",
        "😉",
        "😊",
        "😋",
        "😌",
        "😍",
        "😎",
        "😐",
        "😑",
        "😒",
        "😓",
        "😔",
        "😕",
        "😖",
        "😗",
        "😘",
        "😙",
        "😚",
        "😛",
        "😜",
        "😝",
        "😞",
        "😟",
        "😠",
        "😡",
        "😢",
        "😣",
        "😤",
        "😥",
        "😦",
        "😧",
        "😨",
        "😩",
        "😪",
        "😫",
        "😬",
        "😭",
        "😮",
        "😯",
        "😰",
        "😱",
        "😲",
        "😳",
        "😴",
        "😵",
        "😶",
        "😷",
        "😸",
        "😹",
        "😺",
        "😻",
        "😼",
        "😽",
        "😾",
        "😿",
        "🙀",
        "🙁",
        "🙂",
        "🙃",
        "🙄",
        "🙆",
        "🙇",
        "🙈",
        "🙉",
        "🙊",
        "🙏",
        "🙩",
        "🙫",
        "🚀",
        "🚅",
        "🚋",
        "🚓",
        "🚕",
        "🚗",
        "🚻",
        "🚾",
        "🛝",
        "🛞",
        "🛣",
        "🛧",
        "🛨",
        "🝜",
        "🝪",
        "🝼",
        "🞱",
        "🞵",
        "🞷",
        "🞹",
        "🞿",
        "🟂",
        "🟐",
        "🟑",
        "🟙",
        "🢁",
        "🢃",
        "🤀",
        "🤁",
        "🤂",
        "🤐",
        "🤑",
        "🤒",
        "🤓",
        "🤔",
        "🤕",
        "🤗",
        "🤡",
        "🤢",
        "🤣",
        "🤤",
        "🤥",
        "🤦",
        "🤧",
        "🤩",
        "🤪",
        "🤫",
        "🤬",
        "🤭",
        "🤮",
        "🤯",
        "🤲",
        "🤶",
        "🤹",
        "🤽",
        "🥆",
        "🥏",
        "🥘",
        "🥝",
        "🥞",
        "🥧",
        "🥰",
        "🥱",
        "🥲",
        "🥳",
        "🥴",
        "🥵",
        "🥶",
        "🥷",
        "🥸",
        "🥹",
        "🥺",
        "🦁",
        "🦆",
        "🦈",
        "🦌",
        "🦕",
        "🦖",
        "🦙",
        "🦡",
        "🦧",
        "🦨",
        "🦬",
        "🦰",
        "🦱",
        "🦲",
        "🦳",
        "🦸",
        "🦹",
        "🧌",
        "🧐",
        "🧒",
        "🧓",
        "🧝",
        "🧟",
        "🧢",
        "🧤",
        "🧶",
        "🧸",
        "🨁",
        "🨛",
        "🨜",
        "🨝",
        "🨟",
        "🨥",
        "🨫",
        "🩅",
        "🩆",
        "🩇",
        "🩎",
        "🩑",
        "🩵",
        "🩶",
        "🩷",
        "🪂",
        "🪙",
        "🪯",
        "🪷",
        "🪻",
        "🪼",
        "🪿",
        "🫏",
        "🫚",
        "🫢",
        "🫨",
        "🫶",
        "𠀋",
        "𠂢",
        "𠆢",
        "𠈓",
        "𠌫",
        "𠍱",
        "𠎁",
        "𠏹",
        "𠑊",
        "𠔉",
        "𠗖",
        "𠘨",
        "𠠺",
        "𠢹",
        "𠥼",
        "𠫓",
        "𠮟",
        "𠵅",
        "𠷡",
        "𠹤",
        "𠹭",
        "𠺕",
        "𠽟",
        "𡈽",
        "𡉕",
        "𡉴",
        "𡉻",
        "𡋗",
        "𡋤",
        "𡋽",
        "𡌛",
        "𡌶",
        "𡍄",
        "𡏄",
        "𡑭",
        "𡑮",
        "𡗗",
        "𡙇",
        "𡚴",
        "𡜆",
        "𡝂",
        "𡢽",
        "𡧃",
        "𡱖",
        "𡴭",
        "𡵢",
        "𡵸",
        "𡶒",
        "𡶜",
        "𡶡",
        "𡶷",
        "𡷠",
        "𡸳",
        "𡸴",
        "𡼞",
        "𡽶",
        "𢅻",
        "𢈘",
        "𢌞",
        "𢎭",
        "𢛳",
        "𢡛",
        "𢢫",
        "𢦏",
        "𢪸",
        "𢭆",
        "𢭏",
        "𢭐",
        "𢮦",
        "𢰝",
        "𢰤",
        "𢷡",
        "𣆶",
        "𣇃",
        "𣇄",
        "𣍲",
        "𣏐",
        "𣏒",
        "𣏓",
        "𣏕",
        "𣏚",
        "𣏟",
        "𣏤",
        "𣑊",
        "𣑋",
        "𣑑",
        "𣑥",
        "𣓤",
        "𣕚",
        "𣗄",
        "𣘸",
        "𣘹",
        "𣘺",
        "𣙇",
        "𣜌",
        "𣜜",
        "𣜿",
        "𣝣",
        "𣝤",
        "𣟧",
        "𣟿",
        "𣠤",
        "𣠽",
        "𣪘",
        "𣱿",
        "𣳾",
        "𣴀",
        "𣴎",
        "𣵀",
        "𣷓",
        "𣷹",
        "𣷺",
        "𣽾",
        "𤂖",
        "𤄃",
        "𤇆",
        "𤇾",
        "𤎼",
        "𤘩",
        "𤚥",
        "𤟱",
        "𤢖",
        "𤩍",
        "𤭖",
        "𤭯",
        "𤰖",
        "𤸷",
        "𤹪",
        "𤺋",
        "𥁊",
        "𥁕",
        "𥄢",
        "𥆩",
        "𥇍",
        "𥇥",
        "𥈞",
        "𥉌",
        "𥐮",
        "𥒎",
        "𥓙",
        "𥔎",
        "𥖧",
        "𥝱",
        "𥞩",
        "𥞴",
        "𥧄",
        "𥧔",
        "𥫣",
        "𥫤",
        "𥫱",
        "𥮲",
        "𥱋",
        "𥱤",
        "𥶡",
        "𥸮",
        "𥹖",
        "𥹢",
        "𥻂",
        "𥻘",
        "𥻨",
        "𥼣",
        "𥽜",
        "𥿔",
        "𥿠",
        "𥿻",
        "𦀌",
        "𦀗",
        "𦁠",
        "𦃭",
        "𦊆",
        "𦍌",
        "𦐂",
        "𦙾",
        "𦚰",
        "𦜝",
        "𦣪",
        "𦧝",
        "𦨞",
        "𦩘",
        "𦪌",
        "𦪷",
        "𦫿",
        "𦱳",
        "𦳝",
        "𦹀",
        "𦹥",
        "𦾔",
        "𦿶",
        "𦿷",
        "𦿸",
        "𧃴",
        "𧄍",
        "𧄹",
        "𧏚",
        "𧏛",
        "𧏾",
        "𧐐",
        "𧑉",
        "𧘔",
        "𧘕",
        "𧘱",
        "𧚄",
        "𧜎",
        "𧜣",
        "𧦅",
        "𧪄",
        "𧮳",
        "𧮾",
        "𧯇",
        "𧲸",
        "𧸐",
        "𨂻",
        "𨉷",
        "𨊂",
        "𨋳",
        "𨏍",
        "𨐌",
        "𨑕",
        "𨕫",
        "𨗈",
        "𨗉",
        "𨛺",
        "𨥆",
        "𨥫",
        "𨦇",
        "𨦈",
        "𨦺",
        "𨦻",
        "𨨞",
        "𨨩",
        "𨩃",
        "𨩱",
        "𨪙",
        "𨫍",
        "𨫝",
        "𨫤",
        "𨯁",
        "𨯯",
        "𨸟",
        "𨸶",
        "𨺉",
        "𨻫",
        "𨼲",
        "𨿸",
        "𩊠",
        "𩊱",
        "𩒐",
        "𩗏",
        "𩛰",
        "𩜙",
        "𩝐",
        "𩣆",
        "𩩲",
        "𩸕",
        "𩸽",
        "𩹉",
        "𩺊",
        "𩻄",
        "𩻛",
        "𩻩",
        "𩿎",
        "𪀚",
        "𪀯",
        "𪂂",
        "𪃹",
        "𪆐",
        "𪎌",
        "𪐷",
        "𪗱",
        "𪘂",
        "𪘚",
        "𪚲"
    ],
    [
        "ʲ",
        "ʾ",
        "ʿ",
        "˂",
        "˃",
        "ˆ",
        "ˇ",
        "ˉ",
        "ˊ",
        "ˋ",
        "ˍ",
        "ˎ",
        "ˏ",
        "˒",
        "˓",
        "˚",
        "˳",
        "˴",
        "ʹ",
        "͵",
        "ء",
        "ة",
        "ه",
        "١",
        "٫",
        "ٴ",
        "ۀ",
        "۔",
        "ە",
        "ۥ",
        "۱",
        "۽",
        "ܝ",
        "ޒ",
        "ޱ",
        "߃",
        "߄",
        "ߓ",
        "ߔ",
        "ߗ",
        "ߞ",
        "ߤ",
        "ߥ",
        "߸",
        "߹",
        "‣",
        "⁃",
        "ₖ",
        "℩",
        "∘",
        "≬",
        "⊦",
        "⊧",
        "⌈",
        "⌉",
        "⌊",
        "⌋",
        "⌌",
        "⌍",
        "⌎",
        "⌏",
        "⌜",
        "⌝",
        "⌞",
        "⌟",
        "⍤",
        "⍪",
        "⍳",
        "⍸",
        "⎧",
        "⎨",
        "⎩",
        "⎫",
        "⎬",
        "⎭",
        "⎷",
        "┍",
        "┕",
        "┝",
        "╭",
        "╰",
        "╴",
        "╶",
        "╸",
        "╺",
        "▖",
        "▗",
        "▘",
        "▝",
        "▪",
        "▫",
        "▮",
        "▯",
        "▸",
        "▹",
        "◂",
        "◃",
        "◖",
        "◗",
        "◜",
        "◝",
        "◞",
        "◟",
        "◽",
        "☝",
        "☟",
        "☡",
        "❗",
        "❙",
        "❨",
        "❩",
        "⟨",
        "⟩",
        "⟬",
        "⟭",
        "נּ",
        "ﮤ",
        "ﮦ",
        "﮽",
        "﮾",
        "﮿",
        "ﰰ",
        "ﰴ",
        "︒",
        "﹅",
        "﹠",
        "﹣",
        "﹦",
        "﹨",
        "﹪",
        "﹫",
        "ﹰ",
        "ﹴ",
        "ﹶ",
        "ﹺ",
        "ﹾ",
        "ﺀ",
        "ﺓ",
        "ﻩ",
        "｡",
        "ﾟ",
        "ﾩ",
        "ￔ",
        "ￖ",
        "𐀄",
        "𐃊",
        "𐄈",
        "𐄊",
        "𐄐",
        "𐄑",
        "𐄒",
        "𐄙",
        "𐆌",
        "𐊄",
        "𐊡",
        "𐋥",
        "𐋧",
        "𐌁",
        "𐌔",
        "𐌝",
        "𐏔",
        "𐐋",
        "𐐖",
        "𐐤",
        "𐐰",
        "𐐳",
        "𐐾",
        "𐑆",
        "𐑌",
        "𐑑",
        "𐑛",
        "𐑰",
        "𐕯",
        "𐕲",
        "𐖥",
        "𐖦",
        "𐖬",
        "𐖶",
        "𐙂",
        "𐙆",
        "𐙗",
        "𐝕",
        "𐠐",
        "𐡉",
        "𐡊",
        "𐡍",
        "𐡙",
        "𐡭",
        "𐢈",
        "𐢬",
        "𐢯",
        "𐣩",
        "𐣯",
        "𐣼",
        "𐤍",
        "𐤐",
        "𐧁",
        "𐧈",
        "𐧹",
        "𐧻",
        "𐨞",
        "𐨣",
        "𐩆",
        "𐩈",
        "𐩗",
        "𐩦",
        "𐩧",
        "𐩴",
        "𐩿",
        "𐪆",
        "𐪇",
        "𐫅",
        "𐫏",
        "𐫡",
        "𐬹",
        "𐭆",
        "𐭦",
        "𐭭",
        "𐭽",
        "𐮆",
        "𐮪",
        "𐰃",
        "𐰄",
        "𐰆",
        "𐰇",
        "𐰈",
        "𐰙",
        "𐰛",
        "𐰝",
        "𐰞",
        "𐰪",
        "𐰫",
        "𐰭",
        "𐰮",
        "𐰯",
        "𐲉",
        "𐲙",
        "𐳉",
        "𐳘",
        "𐳙",
        "𐹦",
        "𐼀",
        "𐼂",
        "𐼊",
        "𐼘",
        "𐼝",
        "𐼢",
        "𐼴",
        "𐼵",
        "𐽑",
        "𐽕",
        "𐽖",
        "𐽲",
        "𐾷",
        "𐿦",
        "𑀤",
        "𑁈",
        "𑁊",
        "𑁚",
        "𑂻",
        "𑃓",
        "𑃙",
        "𑃞",
        "𑃨",
        "𑃰",
        "𑃱",
        "𑃲",
        "𑃷",
        "𑃸",
        "𑅂",
        "𑅠",
        "𑇆",
        "𑈽",
        "𑓆",
        "𑗂",
        "𑙁",
        "𑛇",
        "𑠻",
        "𑣇",
        "𑥄",
        "𑫨",
        "𑫫",
        "𑫬",
        "𑫳",
        "𑫴",
        "𑫵",
        "𑰡",
        "𑱱",
        "𑽃",
        "𒿲",
        "𖬹",
        "𖭀",
        "𖹫",
        "𖺔",
        "𖺕",
        "𖺖",
        "𖾔",
        "𖾖",
        "𖾗",
        "𖾘",
        "𖾙",
        "𖾝",
        "𖿱",
        "𘬀",
        "𘮖",
        "𘯧",
        "𘳋",
        "𚿷",
        "𚿸",
        "𛅳",
        "𛅶",
        "𛆊",
        "𛆍",
        "𛆷",
        "𛰃",
        "𛰄",
        "𛰑",
        "𛰒",
        "𛱇",
        "𛱌",
        "𛱍",
        "𛱎",
        "𛱥",
        "𛱦",
        "𛱨",
        "𛱩",
        "𛱺",
        "𛲅",
        "𛲆",
        "𛲕",
        "𛲖",
        "𛲙",
        "𜽝",
        "𝁓",
        "𝁗",
        "𝃞",
        "𝃭",
        "𝄂",
        "𝄃",
        "𝄕",
        "𝄥",
        "𝄦",
        "𝄽",
        "𝅆",
        "𝅇",
        "𝅊",
        "𝅋",
        "𝅌",
        "𝅍",
        "𝅐",
        "𝅑",
        "𝅒",
        "𝅓",
        "𝅗",
        "𝅘",
        "𝅚",
        "𝅛",
        "𝅝",
        "𝅗𝅥",
        "𝅘𝅥",
        "𝆞",
        "𝆹𝅥𝅮",
        "𝆺𝅥𝅮",
        "𝆹𝅥𝅯",
        "𝆺𝅥𝅯",
        "𝇒",
        "𝇠",
        "𝋁",
        "𝍪",
        "𝔦",
        "𝔧",
        "𝖎",
        "𝖏",
        "𝜾",
        "𝞲",
        "𝤗",
        "𝤜",
        "𝩾",
        "𝼊",
        "𞁍",
        "𞁩",
        "𞄷",
        "𞄸",
        "𞄹",
        "𞄻",
        "𞅂",
        "𞊘",
        "𞠣",
        "𞠤",
        "𞠼",
        "𞥒",
        "𞥞",
        "𞲰",
        "🕻",
        "🗦",
        "🗧",
        "🞄",
        "🢞",
        "🬀",
        "🬁",
        "🬃",
        "🬄",
        "🬇",
        "🬉",
        "🬏",
        "🬐",
        "🬓",
        "🬞",
        "🬠",
        "🬦",
        "🬼",
        "🬾",
        "🭀",
        "🭇",
        "🭉",
        "🭋",
        "🭗",
        "🭙",
        "🭛",
        "🭢",
        "🭤",
        "🭦",
        "🭬",
        "🭮",
        "🮌",
        "🮍",
        "🮠",
        "🮡",
        "🮢",
        "🮣",
        "🮤",
        "🮥"
    ],
    [
        "܌",
        "ܟ",
        "ܡ",
        "ܢ",
        "ܣ",
        "ܤ",
        "ܬ",
        "ސ",
        "ޝ",
        "ޞ",
        "ޟ",
        "ﬥ",
        "ﶀ",
        "ﶃ",
        "ﶈ",
        "ﶕ",
        "ﶘ",
        "ﶜ",
        "ﶵ",
        "ﶸ",
        "ﶺ",
        "ﷲ",
        "ﷳ",
        "﷼",
        "﹉",
        "﹍",
        "𐀎",
        "𐁅",
        "𐁍",
        "𐁝",
        "𐂎",
        "𐂙",
        "𐂝",
        "𐂟",
        "𐂠",
        "𐂡",
        "𐂦",
        "𐂩",
        "𐂫",
        "𐂮",
        "𐂯",
        "𐂰",
        "𐂱",
        "𐂸",
        "𐂹",
        "𐂽",
        "𐂿",
        "𐃂",
        "𐃆",
        "𐃏",
        "𐃔",
        "𐃜",
        "𐃠",
        "𐃥",
        "𐃬",
        "𐃭",
        "𐃮",
        "𐃺",
        "𐄷",
        "𐄿",
        "𐅭",
        "𐅮",
        "𐇞",
        "𐇧",
        "𐇪",
        "𐇺",
        "𐊏",
        "𐊰",
        "𐊷",
        "𐎝",
        "𐎾",
        "𐒓",
        "𐘒",
        "𐘓",
        "𐘔",
        "𐘪",
        "𐙋",
        "𐙡",
        "𐙭",
        "𐙴",
        "𐙶",
        "𐚂",
        "𐚈",
        "𐚍",
        "𐚕",
        "𐚡",
        "𐚥",
        "𐚦",
        "𐚫",
        "𐚭",
        "𐚸",
        "𐚺",
        "𐚻",
        "𐛊",
        "𐛍",
        "𐛐",
        "𐛖",
        "𐛝",
        "𐛩",
        "𐛫",
        "𐛱",
        "𐛷",
        "𐛸",
        "𐛹",
        "𐜂",
        "𐜅",
        "𐜆",
        "𐜉",
        "𐜘",
        "𐜞",
        "𐜟",
        "𐜭",
        "𐜯",
        "𐜴",
        "𐝌",
        "𐠈",
        "𐠔",
        "𐠖",
        "𐠢",
        "𐠲",
        "𐠴",
        "𐠼",
        "𐢀",
        "𐢄",
        "𐣡",
        "𐣢",
        "𐦄",
        "𐦊",
        "𐦐",
        "𐦘",
        "𐦠",
        "𐦼",
        "𐧉",
        "𐧊",
        "𐧌",
        "𐧍",
        "𐧏",
        "𐧒",
        "𐧓",
        "𐧔",
        "𐧕",
        "𐧖",
        "𐧗",
        "𐧘",
        "𐧙",
        "𐧚",
        "𐧤",
        "𐧥",
        "𐧦",
        "𐧧",
        "𐧨",
        "𐧩",
        "𐧪",
        "𐧫",
        "𐧬",
        "𐧭",
        "𐧮",
        "𐧯",
        "𐧰",
        "𐧱",
        "𐧲",
        "𐧳",
        "𐧴",
        "𐧵",
        "𐩓",
        "𐬛",
        "𐬠",
        "𐬫",
        "𐬱",
        "𐬴",
        "𐭀",
        "𐭁",
        "𐭑",
        "𐹭",
        "𐹸",
        "𐼥",
        "𑁍",
        "𑅩",
        "𑆃",
        "𑆄",
        "𑇍",
        "𑇫",
        "𑇭",
        "𑇮",
        "𑈿",
        "𑉀",
        "𑌔",
        "𑌛",
        "𑌹",
        "𑐐",
        "𑐞",
        "𑐠",
        "𑐢",
        "𑑉",
        "𑑕",
        "𑒅",
        "𑒆",
        "𑒉",
        "𑒊",
        "𑓄",
        "𑖅",
        "𑖍",
        "𑖐",
        "𑖒",
        "𑖕",
        "𑖩",
        "𑗌",
        "𑙡",
        "𑙤",
        "𑙨",
        "𑜖",
        "𑜱",
        "𑜺",
        "𑠍",
        "𑤃",
        "𑦡",
        "𑦧",
        "𑦭",
        "𑬈",
        "𑴡",
        "𑴤",
        "𑴩",
        "𑴯",
        "𑼇",
        "𑼚",
        "𑼛",
        "𑽙",
        "𑿁",
        "𑿍",
        "𑿦",
        "𑿬",
        "𒾠",
        "𒾡",
        "𒾢",
        "𒾣",
        "𒾱",
        "𒾲",
        "𒾶",
        "𒾷",
        "𒾸",
        "𒾹",
        "𒾼",
        "𒾽",
        "𒿌",
        "𒿍",
        "𒿎",
        "𒿏",
        "𒿑",
        "𒿗",
        "𒿚",
        "𒿝",
        "𒿣",
        "𒿧",
        "𒿨",
        "𒿩",
        "𒿫",
        "𒿬",
        "𒿭",
        "𒿮",
        "𒿯",
        "𒿰",
        "𖩻",
        "𖪅",
        "𖪌",
        "𖪑",
        "𖪝",
        "𖹃",
        "𖹍",
        "𖺙",
        "𘮂",
        "𛀫",
        "𛀬",
        "𛀮",
        "𛀳",
        "𛀴",
        "𛀵",
        "𛁡",
        "𛁩",
        "𛂈",
        "𛂓",
        "𛂔",
        "𛂟",
        "𛂣",
        "𛃄",
        "𛃌",
        "𛃍",
        "𛃐",
        "𛃔",
        "𛃛",
        "𛃴",
        "𛃶",
        "𛄀",
        "𛄑",
        "𛄒",
        "𛉜",
        "𛋩",
        "𛱐",
        "𛱙",
        "𛱰",
        "𛱱",
        "𛱲",
        "𜽒",
        "𜽓",
        "𜽢",
        "𜽤",
        "𜽧",
        "𜽮",
        "𜽳",
        "𜽷",
        "𜽻",
        "𜽼",
        "𜾎",
        "𜾏",
        "𜾘",
        "𜾫",
        "𜿀",
        "𝀈",
        "𝀙",
        "𝀬",
        "𝀵",
        "𝀶",
        "𝁞",
        "𝁠",
        "𝁢",
        "𝁧",
        "𝁪",
        "𝁮",
        "𝁯",
        "𝁶",
        "𝁸",
        "𝁻",
        "𝂂",
        "𝂓",
        "𝂕",
        "𝂢",
        "𝂦",
        "𝂷",
        "𝂸",
        "𝃊",
        "𝄶",
        "𝄷",
        "𝆱",
        "𝇏",
        "𝇜",
        "𝋠",
        "𝌀",
        "𝌁",
        "𝌂",
        "𝌃",
        "𝌄",
        "𝌅",
        "𝌆",
        "𝌇",
        "𝌈",
        "𝌉",
        "𝌊",
        "𝌋",
        "𝌌",
        "𝌍",
        "𝌎",
        "𝌏",
        "𝌐",
        "𝌑",
        "𝌒",
        "𝌓",
        "𝌔",
        "𝌕",
        "𝌖",
        "𝌗",
        "𝌘",
        "𝌙",
        "𝌚",
        "𝌛",
        "𝌜",
        "𝌝",
        "𝌞",
        "𝌟",
        "𝌠",
        "𝌡",
        "𝌢",
        "𝌣",
        "𝌤",
        "𝌥",
        "𝌦",
        "𝌧",
        "𝌨",
        "𝌩",
        "𝌪",
        "𝌫",
        "𝌬",
        "𝌭",
        "𝌮",
        "𝌯",
        "𝌰",
        "𝌱",
        "𝌲",
        "𝌳",
        "𝌴",
        "𝌵",
        "𝌶",
        "𝌷",
        "𝌸",
        "𝌹",
        "𝌺",
        "𝌻",
        "𝌼",
        "𝌽",
        "𝌾",
        "𝌿",
        "𝍀",
        "𝍁",
        "𝍂",
        "𝍃",
        "𝍄",
        "𝍅",
        "𝍆",
        "𝍇",
        "𝍈",
        "𝍉",
        "𝍊",
        "𝍋",
        "𝍌",
        "𝍍",
        "𝍎",
        "𝍏",
        "𝍐",
        "𝍑",
        "𝍒",
        "𝍓",
        "𝍔",
        "𝍕",
        "𝍖",
        "𝍠",
        "𝍡",
        "𝍢",
        "𝍣",
        "𝍤",
        "𝍥",
        "𝍦",
        "𝍧",
        "𝍨",
        "𝍭",
        "𝍮",
        "𝍰",
        "𝍲",
        "𝍳",
        "𝍴",
        "𝍵",
        "𝍶",
        "𝒟",
        "𝒦",
        "𝒯",
        "𝒱",
        "𝒶",
        "𝒹",
        "𝓆",
        "𝓓",
        "𝓚",
        "𝓡",
        "𝓣",
        "𝓥",
        "𝓪",
        "𝓭",
        "𝓺",
        "𝝌",
        "𝟀",
        "𝠩",
        "𝠪",
        "𝠸",
        "𝠹",
        "𝠼",
        "𝡅",
        "𝡒",
        "𝡬",
        "𝢐",
        "𝢑",
        "𝢙",
        "𝢸",
        "𝢽",
        "𝢾",
        "𝢿",
        "𝣂",
        "𝣃",
        "𝣑",
        "𝣘",
        "𝣙",
        "𝣦",
        "𝣩",
        "𝣫",
        "𝤏",
        "𝤤",
        "𝤳",
        "𝥍",
        "𝥮",
        "𝥿",
        "𝦀",
        "𝦆",
        "𝦏",
        "𝦤",
        "𝧂",
        "𝧊",
        "𝧋",
        "𝧚",
        "𝧽",
        "𝩲",
        "𝪀",
        "𝪂",
        "𞋟",
        "𞋩",
        "𞟤",
        "𞟵",
        "𞟷",
        "𞟺",
        "𞟼",
        "𞠲",
        "𞠺",
        "𞠽",
        "𞡏",
        "𞡟",
        "𞡨",
        "𞡩",
        "𞡫",
        "𞡭",
        "𞡰",
        "𞡿",
        "𞢀",
        "𞢄",
        "𞢒",
        "𞢚",
        "𞢟",
        "𞢢",
        "𞢮",
        "𞢯",
        "𞢵",
        "𞣂",
        "𞱺",
        "𞱻",
        "𞱽",
        "𞲏",
        "𞲐",
        "𞲑",
        "𞲒",
        "𞲓",
        "𞲔",
        "𞲙",
        "𞲚",
        "𞲛",
        "𞴌",
        "𞴏",
        "𞴒",
        "𞺨",
        "𞺺",
        "𞺻",
        "🀀",
        "🀁",
        "🀂",
        "🀄",
        "🀅",
        "🀆",
        "🀇",
        "🀈",
        "🀉",
        "🀊",
        "🀋",
        "🀍",
        "🀎",
        "🀏",
        "🀐",
        "🀑",
        "🀒",
        "🀔",
        "🀕",
        "🀖",
        "🀗",
        "🀘",
        "🀙",
        "🀚",
        "🀛",
        "🀜",
        "🀝",
        "🀞",
        "🀟",
        "🀠",
        "🀡",
        "🀢",
        "🀣",
        "🀤",
        "🀥",
        "🀦",
        "🀧",
        "🀨",
        "🀩",
        "🀪",
        "🀫",
        "🄘",
        "🌌",
        "🌙",
        "🌣",
        "🌱",
        "🌸",
        "🌹",
        "🌻",
        "🌼",
        "🍁",
        "🍆",
        "🍇",
        "🍊",
        "🍑",
        "🍒",
        "🍓",
        "🍔",
        "🍕",
        "🍟",
        "🍬",
        "🍱",
        "🎃",
        "🎄",
        "🎅",
        "🎆",
        "🎊",
        "🎍",
        "🎔",
        "🎫",
        "🎶",
        "🏍",
        "🏒",
        "🏚",
        "🏛",
        "🏟",
        "🏢",
        "🏣",
        "🏤",
        "🏦",
        "🏨",
        "🏩",
        "🏪",
        "🏫",
        "🏬",
        "🏵",
        "🏶",
        "🏸",
        "🏻",
        "🏼",
        "🏽",
        "🏾",
        "🏿",
        "🐜",
        "🐦",
        "🐷",
        "🐸",
        "👌",
        "👑",
        "👔",
        "👗",
        "👚",
        "👝",
        "👟",
        "👷",
        "👺",
        "💀",
        "💅",
        "💆",
        "💇",
        "💍",
        "💖",
        "💗",
        "💙",
        "💚",
        "💛",
        "💜",
        "💩",
        "💪",
        "💫",
        "💼",
        "📐",
        "📖",
        "📛",
        "📤",
        "📥",
        "📪",
        "📫",
        "📳",
        "🔊",
        "🔌",
        "🔏",
        "🔨",
        "🔩",
        "🔯",
        "🔶",
        "🔷",
        "🔺",
        "🔻",
        "🔾",
        "🔿",
        "🕋",
        "🕍",
        "🕪",
        "🕮",
        "🕾",
        "🕿",
        "🖀",
        "🖊",
        "🖋",
        "🖘",
        "🖙",
        "🖤",
        "🖧",
        "🖮",
        "🗇",
        "🗊",
        "🗍",
        "🗐",
        "🗗",
        "🗲",
        "🙋",
        "🙘",
        "🙙",
        "🙚",
        "🙛",
        "🙨",
        "🙪",
        "🙬",
        "🙭",
        "🙮",
        "🙯",
        "🙳",
        "🚇",
        "🚉",
        "🚐",
        "🚙",
        "🚝",
        "🚞",
        "🚟",
        "🚢",
        "🚣",
        "🚤",
        "🚨",
        "🛄",
        "🛎",
        "🛐",
        "🛑",
        "🛓",
        "🛔",
        "🛟",
        "🛠",
        "🛬",
        "🛱",
        "🛷",
        "🛸",
        "🛹",
        "🜀",
        "🜐",
        "🜙",
        "🜜",
        "🜟",
        "🜪",
        "🜫",
        "🜲",
        "🜴",
        "🜷",
        "🜾",
        "🝃",
        "🝄",
        "🝅",
        "🝆",
        "🝐",
        "🝒",
        "🝓",
        "🝤",
        "🝲",
        "🞚",
        "🞛",
        "🞜",
        "🞡",
        "🞣",
        "🞥",
        "🞧",
        "🞨",
        "🞪",
        "🞬",
        "🞮",
        "🞯",
        "🞻",
        "🞽",
        "🟀",
        "🟄",
        "🟅",
        "🟆",
        "🟇",
        "🟈",
        "🟉",
        "🟋",
        "🟌",
        "🟍",
        "🟎",
        "🟏",
        "🟒",
        "🟓",
        "🟔",
        "🟕",
        "🟖",
        "🟗",
        "🟘",
        "🟥",
        "🡹",
        "🡻",
        "🤍",
        "🤎",
        "🤟",
        "🤺",
        "🤻",
        "🥅",
        "🥋",
        "🥎",
        "🥑",
        "🥔",
        "🥗",
        "🥟",
        "🥡",
        "🥥",
        "🥨",
        "🥪",
        "🥼",
        "🦀",
        "🦇",
        "🦉",
        "🦊",
        "🦎",
        "🦘",
        "🦚",
        "🦛",
        "🦩",
        "🦵",
        "🦷",
        "🦺",
        "🦼",
        "🧁",
        "🧆",
        "🧈",
        "🧑",
        "🧔",
        "🧕",
        "🧛",
        "🧡",
        "🧥",
        "🧭",
        "🧯",
        "🧺",
        "🧼",
        "🨀",
        "🨆",
        "🨇",
        "🨈",
        "🨉",
        "🨊",
        "🨌",
        "🨏",
        "🨐",
        "🨒",
        "🨕",
        "🨖",
        "🨘",
        "🨞",
        "🨤",
        "🨪",
        "🨰",
        "🨱",
        "🨲",
        "🨳",
        "🨴",
        "🨶",
        "🨹",
        "🨺",
        "🨼",
        "🨿",
        "🩀",
        "🩂",
        "🩈",
        "🩉",
        "🩊",
        "🩏",
        "🩒",
        "🩲",
        "🩳",
        "🪁",
        "🪈",
        "🪐",
        "🪟",
        "🪡",
        "🪶",
        "🫁",
        "🫂",
        "🫎",
        "🫐",
        "🫓",
        "🫔",
        "🫗",
        "🫦",
        "🫳",
        "🫴",
        "𠦝",
        "𡈁",
        "𡵅",
        "𦉰",
        "𦣝",
        "𦥯",
        "𦰩",
        "𧶠"
    ],
    [
        "֍",
        "֎",
        "؇",
        "۝",
        "ܩ",
        "ﷱ",
        "﹁",
        "﹂",
        "﹃",
        "﹄",
        "𐀏",
        "𐀢",
        "𐀣",
        "𐀤",
        "𐁆",
        "𐁋",
        "𐁒",
        "𐁘",
        "𐂌",
        "𐂍",
        "𐂐",
        "𐂕",
        "𐂖",
        "𐂛",
        "𐂞",
        "𐃒",
        "𐃗",
        "𐃝",
        "𐃣",
        "𐃴",
        "𐄡",
        "𐅬",
        "𐆈",
        "𐇗",
        "𐇶",
        "𐎊",
        "𐎒",
        "𐎕",
        "𐏕",
        "𐘘",
        "𐘙",
        "𐘜",
        "𐘫",
        "𐘶",
        "𐘺",
        "𐘾",
        "𐘿",
        "𐙀",
        "𐙃",
        "𐙈",
        "𐙍",
        "𐙎",
        "𐙏",
        "𐙝",
        "𐙞",
        "𐙟",
        "𐙩",
        "𐙮",
        "𐙼",
        "𐚃",
        "𐚊",
        "𐚏",
        "𐚑",
        "𐚓",
        "𐚤",
        "𐚨",
        "𐚯",
        "𐚰",
        "𐚲",
        "𐚵",
        "𐚶",
        "𐚷",
        "𐛂",
        "𐛅",
        "𐛎",
        "𐛏",
        "𐛞",
        "𐛠",
        "𐛤",
        "𐛰",
        "𐛳",
        "𐛻",
        "𐛿",
        "𐜀",
        "𐜁",
        "𐜋",
        "𐜗",
        "𐜜",
        "𐜢",
        "𐜨",
        "𐜰",
        "𐜱",
        "𐝉",
        "𐝊",
        "𐝋",
        "𐝒",
        "𐝣",
        "𐠀",
        "𐠁",
        "𐠂",
        "𐠎",
        "𐠏",
        "𐠓",
        "𐠕",
        "𐠗",
        "𐠘",
        "𐠚",
        "𐠛",
        "𐠧",
        "𐡧",
        "𐢊",
        "𐦖",
        "𐦚",
        "𐦟",
        "𐦫",
        "𐦭",
        "𐦱",
        "𐦵",
        "𐦶",
        "𐧅",
        "𐨐",
        "𐨚",
        "𐨲",
        "𐩂",
        "𐫯",
        "𐬁",
        "𐬔",
        "𐬡",
        "𐬧",
        "𐬲",
        "𐬵",
        "𐭈",
        "𐭧",
        "𐭮",
        "𐮛",
        "𐹰",
        "𐽘",
        "𑀲",
        "𑁱",
        "𑁲",
        "𑂈",
        "𑂼",
        "𑄄",
        "𑄊",
        "𑄍",
        "𑄏",
        "𑄐",
        "𑄒",
        "𑄚",
        "𑄣",
        "𑄦",
        "𑄹",
        "𑅄",
        "𑅦",
        "𑅧",
        "𑆈",
        "𑇄",
        "𑈀",
        "𑈄",
        "𑈅",
        "𑈈",
        "𑈌",
        "𑈍",
        "𑈎",
        "𑈏",
        "𑈗",
        "𑈩",
        "𑈫",
        "𑊀",
        "𑊃",
        "𑊒",
        "𑊕",
        "𑊘",
        "𑊣",
        "𑌈",
        "𑌋",
        "𑌌",
        "𑌜",
        "𑌝",
        "𑌲",
        "𑐂",
        "𑐋",
        "𑐛",
        "𑒙",
        "𑓔",
        "𑖎",
        "𑖞",
        "𑖢",
        "𑖦",
        "𑖮",
        "𑗇",
        "𑗓",
        "𑗔",
        "𑗙",
        "𑗚",
        "𑙔",
        "𑙕",
        "𑙗",
        "𑙙",
        "𑙪",
        "𑚈",
        "𑚸",
        "𑜄",
        "𑜕",
        "𑜵",
        "𑜷",
        "𑠅",
        "𑠆",
        "𑠇",
        "𑠘",
        "𑠚",
        "𑠜",
        "𑤀",
        "𑦠",
        "𑪞",
        "𑪟",
        "𑪠",
        "𑪡",
        "𑪢",
        "𑰊",
        "𑰋",
        "𑰒",
        "𑰘",
        "𑰧",
        "𑰫",
        "𑱠",
        "𑱣",
        "𑱪",
        "𑱬",
        "𑴒",
        "𑴗",
        "𑴠",
        "𑴫",
        "𑴰",
        "𑻫",
        "𑼆",
        "𑼎",
        "𑼐",
        "𑼖",
        "𑼘",
        "𑼞",
        "𑼟",
        "𑼨",
        "𑼪",
        "𑽇",
        "𑽖",
        "𑿋",
        "𒾛",
        "𒾜",
        "𒾝",
        "𒾤",
        "𒾥",
        "𒾩",
        "𒾮",
        "𒾻",
        "𒾿",
        "𒿀",
        "𒿁",
        "𒿃",
        "𒿅",
        "𒿈",
        "𒿋",
        "𒿐",
        "𒿠",
        "𒿡",
        "𒿤",
        "𒿦",
        "𖩹",
        "𖪁",
        "𖪃",
        "𖪄",
        "𖪒",
        "𖪓",
        "𖪕",
        "𖪗",
        "𖪚",
        "𖪛",
        "𖪜",
        "𖪞",
        "𖪥",
        "𖪦",
        "𖪨",
        "𖪲",
        "𖪷",
        "𖪼",
        "𖪽",
        "𖫅",
        "𖹁",
        "𖹎",
        "𖹘",
        "𖹙",
        "𖹚",
        "𖹭",
        "𖹰",
        "𘬈",
        "𘬊",
        "𘭺",
        "𘭽",
        "𘭾",
        "𘭿",
        "𘯍",
        "𘯷",
        "𘰕",
        "𘱿",
        "𘳒",
        "𛀈",
        "𛀦",
        "𛀰",
        "𛀼",
        "𛀿",
        "𛁏",
        "𛁒",
        "𛁢",
        "𛁾",
        "𛂒",
        "𛂪",
        "𛂲",
        "𛃘",
        "𛃻",
        "𛄂",
        "𛄌",
        "𛇱",
        "𛈓",
        "𛈸",
        "𛉩",
        "𛉿",
        "𛊋",
        "𛊌",
        "𛊜",
        "𛊠",
        "𛊶",
        "𛊷",
        "𛊼",
        "𛋇",
        "𛋎",
        "𛋕",
        "𛋗",
        "𛋙",
        "𛋥",
        "𛋨",
        "𛋮",
        "𛋴",
        "𛋷",
        "𛰛",
        "𛰜",
        "𛰟",
        "𛰠",
        "𛰱",
        "𛱅",
        "𛱗",
        "𛱘",
        "𛲜",
        "𜽐",
        "𜽑",
        "𜽔",
        "𜽰",
        "𜽱",
        "𜽲",
        "𜽴",
        "𜽵",
        "𜽶",
        "𜽸",
        "𜽹",
        "𜽺",
        "𜾄",
        "𜾌",
        "𜾒",
        "𜾓",
        "𜾔",
        "𜾕",
        "𜾖",
        "𜾚",
        "𜾧",
        "𜾯",
        "𜿃",
        "𝀓",
        "𝀞",
        "𝀣",
        "𝀦",
        "𝀼",
        "𝁁",
        "𝁆",
        "𝁉",
        "𝁊",
        "𝁍",
        "𝁒",
        "𝁕",
        "𝁛",
        "𝁜",
        "𝁦",
        "𝁫",
        "𝂈",
        "𝂝",
        "𝂤",
        "𝂧",
        "𝂯",
        "𝂱",
        "𝃀",
        "𝃃",
        "𝃓",
        "𝃗",
        "𝄌",
        "𝄍",
        "𝄎",
        "𝄡",
        "𝆙",
        "𝆦",
        "𝆧",
        "𝆯",
        "𝆵",
        "𝇇",
        "𝇈",
        "𝇉",
        "𝈊",
        "𝈐",
        "𝈢",
        "𝈧",
        "𝒈",
        "𝒜",
        "𝒥",
        "𝒳",
        "𝒸",
        "𝓃",
        "𝓎",
        "𝓐",
        "𝓑",
        "𝓕",
        "𝓗",
        "𝓘",
        "𝓙",
        "𝓧",
        "𝓬",
        "𝓰",
        "𝓷",
        "𝔂",
        "𝙜",
        "𝝁",
        "𝞵",
        "𝠃",
        "𝡜",
        "𝡝",
        "𝡟",
        "𝡡",
        "𝡣",
        "𝡤",
        "𝡥",
        "𝡩",
        "𝡰",
        "𝡲",
        "𝡴",
        "𝡵",
        "𝡷",
        "𝡹",
        "𝡺",
        "𝡼",
        "𝢈",
        "𝢉",
        "𝢍",
        "𝢎",
        "𝢕",
        "𝢘",
        "𝢠",
        "𝢡",
        "𝢤",
        "𝢥",
        "𝢩",
        "𝢪",
        "𝢰",
        "𝢱",
        "𝢲",
        "𝢺",
        "𝢻",
        "𝢼",
        "𝣌",
        "𝣍",
        "𝣎",
        "𝣏",
        "𝤇",
        "𝤓",
        "𝤔",
        "𝤕",
        "𝤣",
        "𝤽",
        "𝥀",
        "𝥐",
        "𝥶",
        "𝥺",
        "𝥾",
        "𝦃",
        "𝦐",
        "𝦟",
        "𝦰",
        "𝧁",
        "𝧡",
        "𝧣",
        "𝧥",
        "𝧧",
        "𝧩",
        "𝧪",
        "𝧬",
        "𝧶",
        "𝪅",
        "𝼥",
        "𝼧",
        "𝼨",
        "𝼩",
        "𞋀",
        "𞋁",
        "𞋂",
        "𞋃",
        "𞋉",
        "𞋊",
        "𞋋",
        "𞋌",
        "𞋍",
        "𞋒",
        "𞋝",
        "𞋪",
        "𞋳",
        "𞟥",
        "𞟸",
        "𞟹",
        "𞠋",
        "𞠌",
        "𞠍",
        "𞠏",
        "𞠡",
        "𞠥",
        "𞠭",
        "𞠹",
        "𞡁",
        "𞡷",
        "𞢈",
        "𞢓",
        "𞢗",
        "𞢠",
        "𞢧",
        "𞢻",
        "𞣄",
        "𞲞",
        "𞴑",
        "𞴔",
        "𞴕",
        "𞴲",
        "𞴵",
        "𞺫",
        "𞺭",
        "🂠",
        "🂡",
        "🂢",
        "🂣",
        "🂤",
        "🂥",
        "🂦",
        "🂧",
        "🂨",
        "🂩",
        "🂪",
        "🂫",
        "🂬",
        "🂭",
        "🂮",
        "🂱",
        "🂲",
        "🂳",
        "🂴",
        "🂵",
        "🂶",
        "🂷",
        "🂸",
        "🂹",
        "🂺",
        "🂻",
        "🂼",
        "🂽",
        "🂾",
        "🂿",
        "🃁",
        "🃂",
        "🃃",
        "🃄",
        "🃅",
        "🃆",
        "🃇",
        "🃈",
        "🃉",
        "🃊",
        "🃋",
        "🃌",
        "🃍",
        "🃎",
        "🃏",
        "🃑",
        "🃒",
        "🃓",
        "🃔",
        "🃕",
        "🃖",
        "🃗",
        "🃘",
        "🃙",
        "🃚",
        "🃛",
        "🃜",
        "🃝",
        "🃞",
        "🃟",
        "🃠",
        "🃡",
        "🃢",
        "🃣",
        "🃤",
        "🃥",
        "🃦",
        "🃧",
        "🃨",
        "🃩",
        "🃪",
        "🃫",
        "🃬",
        "🃭",
        "🃮",
        "🃯",
        "🃰",
        "🃱",
        "🃲",
        "🃳",
        "🃴",
        "🃵",
        "🅪",
        "🅫",
        "🅬",
        "🌛",
        "🌜",
        "🍀",
        "🍎",
        "🍏",
        "🍙",
        "🍚",
        "🍜",
        "🍤",
        "🍭",
        "🍲",
        "🍿",
        "🎀",
        "🎐",
        "🎴",
        "🏂",
        "🏈",
        "🏉",
        "🏌",
        "🏏",
        "🐣",
        "💐",
        "💰",
        "📂",
        "📆",
        "📌",
        "📍",
        "📑",
        "📕",
        "📗",
        "📘",
        "📙",
        "📵",
        "📿",
        "🔆",
        "🔭",
        "🕃",
        "🕄",
        "🕫",
        "🕭",
        "🕵",
        "🖔",
        "🗑",
        "🗤",
        "🗥",
        "🗼",
        "🙑",
        "🙒",
        "🙔",
        "🙗",
        "🙹",
        "🙼",
        "🙽",
        "🚬",
        "🚸",
        "🛅",
        "🛦",
        "🜅",
        "🜆",
        "🜇",
        "🜊",
        "🜋",
        "🜑",
        "🜒",
        "🜛",
        "🜼",
        "🝀",
        "🝂",
        "🝈",
        "🝊",
        "🝍",
        "🝗",
        "🝙",
        "🝞",
        "🝟",
        "🝠",
        "🝥",
        "🝮",
        "🝶",
        "🝾",
        "🞁",
        "🞃",
        "🞠",
        "🠉",
        "🠋",
        "🠝",
        "🠟",
        "🡁",
        "🡃",
        "🡅",
        "🡇",
        "🡡",
        "🡣",
        "🡩",
        "🡫",
        "🢕",
        "🢗",
        "🢙",
        "🢛",
        "🤃",
        "🤖",
        "🤙",
        "🤱",
        "🤴",
        "🤸",
        "🥁",
        "🥃",
        "🥇",
        "🥈",
        "🥉",
        "🥌",
        "🥢",
        "🥦",
        "🥮",
        "🦍",
        "🦞",
        "🦟",
        "🦴",
        "🦿",
        "🧙",
        "🧣",
        "🩸",
        "🪄",
        "🪒",
        "🪔",
        "🪘",
        "🪠",
        "🪨",
        "🪩",
        "🫒",
        "𩙿"
    ],
    [
        "ʼ",
        "ʽ",
        "˸",
        "։",
        "،",
        "؛",
        "٬",
        "܁",
        "܂",
        "܃",
        "܄",
        "‛",
        "․",
        "‧",
        "⁚",
        "⁝",
        "∣",
        "∶",
        "≀",
        "⋅",
        "⋮",
        "⎜",
        "⎟",
        "⎢",
        "⎥",
        "⎪",
        "┃",
        "┇",
        "┋",
        "╏",
        "╹",
        "╻",
        "╽",
        "╿",
        "▎",
        "❘",
        "﮲",
        "﮳",
        "︐",
        "︓",
        "︔",
        "︙",
        "︰",
        "﹒",
        "﹕",
        "﹙",
        "﹚",
        "･",
        "￤",
        "𐄁",
        "𐅛",
        "𐍘",
        "𐡆",
        "𐡘",
        "𐤟",
        "𐩀",
        "𐫫",
        "𐫴",
        "𐫵",
        "𐮅",
        "𐮩",
        "𐺀",
        "𐺭",
        "𐾶",
        "𐾸",
        "𐾾",
        "𑅴",
        "𑇐",
        "𑈺",
        "𑑋",
        "𑛀",
        "𑜼",
        "𑪛",
        "𑫯",
        "𑫰",
        "𑱁",
        "𑱃",
        "𑻷",
        "𘮔",
        "𛰀",
        "𛰁",
        "𛲄",
        "𛲔",
        "𝀛",
        "𝂅",
        "𝃢",
        "𝃣",
        "𝄈",
        "𝄒",
        "𝅮",
        "𝅯",
        "𝅰",
        "𝅱",
        "𝅲",
        "𝋡",
        "𞠦",
        "𞠩",
        "🞌",
        "🢟",
        "🮇",
        "🯱"
    ],
    [
        "¸",
        "ʹ",
        "ː",
        "ˑ",
        "˛",
        "ˡ",
        "˱",
        "˲",
        "˹",
        "˺",
        "˻",
        "˼",
        "ͺ",
        "΄",
        "ӏ",
        "؞",
        "أ",
        "إ",
        "٠",
        "۰",
        "ܙ",
        "ݳ",
        "߅",
        "ߴ",
        "ߵ",
        "יּ",
        "﯀",
        "﯁",
        "︑",
        "︴",
        "﹐",
        "﹑",
        "﹔",
        "﹖",
        "﹛",
        "﹜",
        "﹝",
        "﹞",
        "﹡",
        "﹤",
        "﹥",
        "ﹳ",
        "ﺃ",
        "ﺇ",
        "､",
        "ￂ",
        "ￃ",
        "ￄ",
        "ￅ",
        "ￆ",
        "ￊ",
        "ￛ",
        "𐄛",
        "𐇚",
        "𐋪",
        "𐒃",
        "𐖙",
        "𐞁",
        "𐞂",
        "𐞷",
        "𐞸",
        "𐞹",
        "𐡅",
        "𐡋",
        "𐡦",
        "𐡹",
        "𐢔",
        "𐢨",
        "𐣥",
        "𐣾",
        "𐦣",
        "𐩐",
        "𐩡",
        "𐬆",
        "𐬇",
        "𐬌",
        "𐬎",
        "𐬢",
        "𐬤",
        "𐬺",
        "𐭉",
        "𐭪",
        "𐮂",
        "𐮙",
        "𐰣",
        "𐰨",
        "𐲋",
        "𐳋",
        "𐼎",
        "𐽴",
        "𐾁",
        "𐾆",
        "𐾱",
        "𐾺",
        "𐿆",
        "𑀭",
        "𑁉",
        "𑁦",
        "𑃀",
        "𑃵",
        "𑅱",
        "𑇇",
        "𑈸",
        "𑌽",
        "𑑍",
        "𑑎",
        "𑑚",
        "𑗄",
        "𑗅",
        "𖭁",
        "𖭐",
        "𖺗",
        "𖼩",
        "𛁅",
        "𛱆",
        "𛱤",
        "𛱧",
        "𛱪",
        "𛱻",
        "𛲀",
        "𛲂",
        "𛲐",
        "𛲒",
        "𜽖",
        "𜾅",
        "𜾆",
        "𝀊",
        "𝀋",
        "𝀹",
        "𝁏",
        "𝁺",
        "𝃜",
        "𝃤",
        "𝃱",
        "𝃳",
        "𝄁",
        "𝆃",
        "𝆄",
        "𝆛",
        "𝆟",
        "𝆹",
        "𝆺",
        "𝆹𝅥",
        "𝆺𝅥",
        "𝇆",
        "𝇐",
        "𝇓",
        "𝇔",
        "𝜄",
        "𞁐",
        "𞅁",
        "𞲃",
        "𞲮",
        "𞴽",
        "𞺀",
        "🞍",
        "🞗",
        "🞝",
        "🢐",
        "🢒",
        "🮈"
    ],
    [
        "ˈ",
        "׀",
        "ا",
        "ߊ",
        "︕",
        "︱",
        "︲",
        "︳",
        "﹗",
        "ﺍ",
        "ﾠ",
        "ￜ",
        "￨",
        "𐄀",
        "𐄇",
        "𐊊",
        "𐌉",
        "𐌠",
        "𐍭",
        "𐑦",
        "𐔎",
        "𐞶",
        "𐢉",
        "𐢧",
        "𐣦",
        "𐣭",
        "𐣻",
        "𐤖",
        "𐤦",
        "𐧀",
        "𐧶",
        "𐩖",
        "𐩽",
        "𐪝",
        "𐬥",
        "𐭘",
        "𐭸",
        "𐰾",
        "𐲥",
        "𐳥",
        "𐳺",
        "𐴱",
        "𐼈",
        "𐼐",
        "𐿅",
        "𐿩",
        "𑁇",
        "𑅁",
        "𑇅",
        "𑇈",
        "𑩁",
        "𑩂",
        "𑫥",
        "𖩙",
        "𖼨",
        "𚿵",
        "𛰂",
        "𛰇",
        "𛰌",
        "𛲈",
        "𛲘",
        "𝃚",
        "𝃛",
        "𝄀",
        "𝄄",
        "𝄅",
        "𝅥",
        "𝅭",
        "𝆠",
        "𝇁",
        "𝇂",
        "𝇃",
        "𝇄",
        "𝇅",
        "𝍩",
        "𝍷",
        "𝧿",
        "𝩷",
        "𝩸",
        "𝩹",
        "𝩺",
        "𝩻",
        "𝩼",
        "𝩽",
        "𞓱",
        "𞠢",
        "𞠫",
        "𞣇",
        "𞥋",
        "𞥑",
        "𞴁",
        "𞸀",
        "🭰",
        "🭱",
        "🭲",
        "🭳",
        "🭴",
        "🭵"
    ],
    [
        "Æ",
        "æ",
        "Œ",
        "œ",
        "Ǆ",
        "ǅ",
        "ǆ",
        "Ǉ",
        "ǈ",
        "Ǌ",
        "ǋ",
        "ǌ",
        "Ǣ",
        "ǣ",
        "Ǳ",
        "ǲ",
        "ǳ",
        "Ǽ",
        "ǽ",
        "ȸ",
        "ȹ",
        "ɶ",
        "ʣ",
        "ʤ",
        "ʥ",
        "ʩ",
        "ϓ",
        "Ӕ",
        "ӕ",
        "Ԙ",
        "ԙ",
        "Ԫ",
        "ԫ",
        "ﬄ",
        "ﬆ",
        "ﬓ",
        "ﬔ",
        "ﬕ",
        "ﬖ",
        "ﬗ"
    ],
    [
        "ƕ",
        "Ƕ",
        "ʦ",
        "ʨ",
        "Щ",
        "Җ",
        "Ҧ",
        "ҧ",
        "Ԃ",
        "ԃ",
        "Ԉ",
        "ԉ",
        "Ԋ",
        "ԋ",
        "Ԕ",
        "ԕ",
        "Ԗ",
        "ԗ",
        "Ԡ",
        "ԡ",
        "Ԣ",
        "ԣ",
        "ﬃ",
        "🌊",
        "🌧",
        "🍖",
        "🎣",
        "🏹",
        "🔱",
        "🗡"
    ]
]