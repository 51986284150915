import React, { useEffect } from "react";
import PageOne from "./pages/PageOne";
import PageTwo from "./pages/PageTwo";

// !IMPORTANT in utils.ts generateTree, we need to make the path correct for each node. Maybe use real curr path?

/**
 * TODO
    1. Add back button
    -- 2. Add ways to change paths
    -- 3. Fix Backend so for an iron nugget you dont need to get a tool and then it, just use iron ingot (if possible? idr)
    -- 4. First see summery of what you need to craft, then see the tree
    -- 5. for example hopper_minecraft, need to merge 2 nodes (iron ore)
    -- 6. piston err?
    -- 7. upscale icon quality
    -- 8. In backend, make amounts round up correctly (children affect parent not just round up)
    -- 9. When clicking a node, show the recipe in a modal from the child
    -- 10. Add lock for item paths so if the best match thing is on we could stop paths from changing
    -- 11. Fix backend, when wanting 1 stick it says get 1 log, then craft 1 plank then craft 1 stick, might be (8)
    -- 12. When at first we use the shortest path, pass the path index to PageTwo so it shows as selected path
    -- 13. Fix items needed for example crossbow
    -- 14. barrel, too many items
    -- 15. Creating iron ingot from iron ore has craft?
    -- 16. might be a problem: with curr path select, if we rn show to craft item x we need 2 items, but theres an option for 3 items, i wont get that. FIND A WAY TO FIX, might not even be items like that so check.
    -- 17. Still path select problem with crossbow
    -- 18. Get recipes for a bed, try to switch out the bed, it errors
    -- 19. get crossbow, change the bamboo of stick of tripwire hook, something else changes as well.
    -- 20. multiple items to craft, err
    -- 21. Andesite wall, 4xcobble and 4xquartz to get 4 andesite? to get 4 andesite we need 2 of each. maybe need to divide by path.length? check with other items
    -- 22. When having piston, dispenser and redstone dust, we can see the dispeanser has overlap
    -- 23. when we have a lot of base items in the numbers they overlap
    -- 24. Add tooltip of item name in crafting recipe and path select
    -- 25. In options, add an item to an option only if that path's length is not thr same as the current 1, barrel allows to select the lab and plank in 1 replace
    26. Add small icons on the left for view craft recipe and replace items
    -- 27. Sort items by cats
    -- 28. Fix path select, after filter its messed up
    29. Add little star on items that can be replaced
    30. Maybe the replacements are the ones with the same index, or if the length isnt the same, everything
 */

const App = () => {
    const [currentPage, setCurrentPage] = React.useState(0);
    const [itemAssetData, setItemAssetData] = React.useState([]);
    const [itemsToCraft, setItemsToCraft] = React.useState({});
    const [crafts, setCrafts] = React.useState({});
    const [loadedAssets, setLoadedAssets] = React.useState(false);
    const [loadedRecipes, setLoadedRecipes] = React.useState(false);

    useEffect(() => {
        fetch("https://raw.githubusercontent.com/K9Developer/MinecraftData/main/items/atlas_metadata.json")
            .then((res) => {
                res.json().then((data) => {
                    setItemAssetData(data);
                    setLoadedAssets(true);
                });
            })
            .catch((err) => {
                console.error(err);
                alert("Failed to fetch item asset data. Please try again later.");
            });

        fetch("https://raw.githubusercontent.com/K9Developer/MinecraftData/main/recipe_trees/crafts.json")
            .then((res) => {
                res.json().then((data) => {
                    setCrafts(data);
                    setLoadedRecipes(true);
                    // setItemsToCraft({
                    //     "minecraft:birch_chest_boat": 1,
                    //     "minecraft:ender_chest": 2,
                    //     "minecraft:tnt": 3,
                    //     "minecraft:golden_pickaxe": 4,
                    //     "minecraft:crimson_fence": 5,
                    //     "minecraft:sticky_piston": 23,
                    // });
                    // setCurrentPage(1);
                });
            })
            .catch((err) => {
                console.error(err);
                alert("Failed to fetch item recipes. Please try again later.");
            });
    }, []);

    if (!loadedRecipes || !loadedAssets) return <div>Loading...</div>;

    return (
        <div className="w-full h-full">
            {currentPage === 0 ? (
                <PageOne
                    itemAssetData={itemAssetData}
                    crafts={crafts}
                    pageTwoCallback={(itemsToCraft: any) => {
                        setItemsToCraft(itemsToCraft);
                        setCurrentPage(1);
                    }}
                />
            ) : (
                <PageTwo items={itemsToCraft} itemAssetData={itemAssetData} itemRecipes={crafts} />
            )}
        </div>
    );
};

export default App;
