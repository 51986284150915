import { getTagType, TAG } from "nbtify";
import React, { useEffect, useState } from "react";
import EditableParagraph from "./EditableParagraph";
import ContextMenu from "./ContextMenu";
import Modal from "./Modal";
import styled from "styled-components";

const editTypeRegex = [/^(\w|_)*$/, /^(^-)?\d+$/, /^-?\d+(\.\d+)?$/, /^.*$/, /^(^-)?\d+$/, /^(^-)?\d+$/, /^(^-)?\d+$/, /^(^-)?\d+(\.\d+)?$/];

const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type: EDIT_TYPES) => {
    const newValue = event.target.value;

    if (!editTypeRegex[type].test(newValue)) {
        return {
            error: true,
            reason: "The input does not match the accepted pattern.",
        };
    } else if (type === EDIT_TYPES.INT && (isNaN(Number(newValue)) || Number(newValue) > 2147483647 || Number(newValue) < -2147483648)) {
        return {
            error: true,
            reason: "The input is not a valid integer.",
        };
    } else if (
        type === EDIT_TYPES.LONG &&
        (isNaN(Number(newValue)) || Number(newValue) > 9223372036854775807 || Number(newValue) < -9223372036854775808)
    ) {
        return {
            error: true,
            reason: "The input is not a valid long.",
        };
    } else if (type === EDIT_TYPES.BYTE && (isNaN(Number(newValue)) || Number(newValue) > 127 || Number(newValue) < -128)) {
        return {
            error: true,
            reason: "The input is not a valid byte.",
        };
    } else if (type === EDIT_TYPES.SHORT && (isNaN(Number(newValue)) || Number(newValue) > 32767 || Number(newValue) < -32768)) {
        return {
            error: true,
            reason: "The input is not a valid short.",
        };
    } else if (type === EDIT_TYPES.FLOAT && (isNaN(Number(newValue)) || Number(newValue) > 3.4028235e38 || Number(newValue) < -3.4028235e38)) {
        return {
            error: true,
            reason: "The input is not a valid float.",
        };
    } else if (
        type === EDIT_TYPES.DOUBLE &&
        (isNaN(Number(newValue)) || Number(newValue) > 1.7976931348623157e308 || Number(newValue) < -1.7976931348623157e308)
    ) {
        return {
            error: true,
            reason: "The input is not a valid double.",
        };
    } else if (type === EDIT_TYPES.STRING && newValue.length > 32767) {
        return {
            error: true,
            reason: "The input is too long.",
        };
    } else {
        return {
            error: false,
            reason: "",
        };
    }
};

function updateValuePreserveType<T>(originalValue: T, newValue: number | bigint): T {
    console.log(typeof originalValue, newValue);
    if (typeof originalValue === "number") {
        return newValue as T;
    } else if (typeof originalValue === "bigint") {
        return BigInt(newValue) as T;
    } else if (originalValue instanceof Object && "valueOf" in originalValue) {
        const ConstructorFunction = originalValue.constructor as new (value: number | bigint) => T;
        return new ConstructorFunction(newValue);
    } else {
        throw new Error("Unsupported type for value update");
    }
}

const ArrowClosed = ({ onClick }: any) => (
    <svg onClick={onClick} className="fill-white w-6 h-6 absolute cursor-pointer m-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
        <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
    </svg>
);

const ArrowOpen = ({ onClick }: any) => (
    <svg onClick={onClick} className="fill-white w-6 h-6 absolute cursor-pointer m-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
    </svg>
);

const Select = styled.select`
    background-color: #121212;
    color: #ffffff;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #0077ff;
    }
`;

interface Props {
    label: string;
    type: number;
    parentType: number;
    currentPath: string[];
    onUpdate: (path: string[], newKey: any, newValue: any, action?: string, type?: number) => void;
    children: any;
    depth: number;
    currentResult: string[];
}

const tagPositions: { [key: number]: { x: number; y: number } } = {
    1: { x: 0, y: 0 },
    6: { x: 1, y: 0 },
    5: { x: 2, y: 0 },
    3: { x: 3, y: 0 },
    4: { x: 0, y: 1 },
    2: { x: 1, y: 1 },
    8: { x: 2, y: 1 },
    10: { x: 3, y: 1 },
    7: { x: 0, y: 2 },
    11: { x: 1, y: 2 },
    9: { x: 2, y: 2 },
    12: { x: 0, y: 3 },
};

enum EDIT_TYPES {
    TAG_NAME,
    INT,
    FLOAT,
    STRING,
    LONG,
    BYTE,
    SHORT,
    DOUBLE,
}

const getEditType = (type: number) => {
    return type === TAG.INT
        ? EDIT_TYPES.INT
        : type === TAG.FLOAT
        ? EDIT_TYPES.FLOAT
        : type === TAG.LONG
        ? EDIT_TYPES.LONG
        : type === TAG.BYTE
        ? EDIT_TYPES.BYTE
        : type === TAG.SHORT
        ? EDIT_TYPES.SHORT
        : type === TAG.DOUBLE
        ? EDIT_TYPES.DOUBLE
        : EDIT_TYPES.STRING;
};

const scale = 0.8;
const openableTypes = [TAG.COMPOUND, TAG.LIST, TAG.BYTE_ARRAY, TAG.INT_ARRAY, TAG.LONG_ARRAY];
const listTypes = [TAG.LIST, TAG.BYTE_ARRAY, TAG.INT_ARRAY, TAG.LONG_ARRAY];
const Branch = ({ label, type, parentType, currentPath, onUpdate, children, depth, currentResult }: Props) => {
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState(label);
    const [value, setValue] = useState(children);
    const [currType, setCurrType] = useState<number>(type);
    const [showAddElemModal, setShowAddElemModal] = useState(false);
    const [contextMenu, setContextMenu] = useState<{ x: number; y: number } | null>(null);
    const [selectedTypeModal, setSelectedTypeModal] = useState<number>(TAG.BYTE);
    const [keyModal, setKeyModal] = useState<string>("");
    const [valueModal, setValueModal] = useState<string>("");
    const [goodModalKey, setGoodModalKey] = useState({ error: false, reason: "" });
    const [goodModalValue, setGoodModalValue] = useState({ error: false, reason: "" });
    const [highlighted, setHighlighted] = useState(false);

    useEffect(() => {
        if (!currentResult || currentResult.length === 0) {
            setHighlighted(false);
            return;
        }
        const shouldHighlight = currentPath.join(".") === currentResult.join(".");
        const shouldOpen = !shouldHighlight && currentResult.includes(label);
        if (shouldOpen) setOpen(true);
        if (shouldHighlight) {
            // Focus on the element
            const element = document.getElementById(currentPath.join(".")) as HTMLElement;
            element.scrollIntoView({ behavior: "smooth", block: "center" });
            setHighlighted(true);
        } else {
            setHighlighted(false);
        }
    }, [currentResult]);

    useEffect(() => {
        setValue(children);
    }, [children]);

    useEffect(() => {
        if (parentType === TAG.INT_ARRAY) {
            setCurrType(TAG.INT);
        } else if (parentType === TAG.LONG_ARRAY) {
            setCurrType(TAG.LONG);
        } else if (parentType === TAG.BYTE_ARRAY) {
            setCurrType(TAG.BYTE);
        }
    }, []);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const closeContextMenu = () => {
        setContextMenu(null);
    };

    const contextMenuOptions = [
        {
            label: "Delete",
            action: () => {
                onUpdate(currentPath, null, null, "delete");
            },
        },
    ];

    const setBranchValue = (v: any) => {
        if (type === TAG.INT) {
            setValue(updateValuePreserveType(value, parseInt(v)));
        } else if (type === TAG.FLOAT) {
            setValue(updateValuePreserveType(value, parseFloat(v)));
        } else if (type === TAG.LONG) {
            setValue(updateValuePreserveType(value, Number(BigInt(v))));
        } else if (type === TAG.BYTE) {
            setValue(updateValuePreserveType(value, parseInt(v)));
        } else if (type === TAG.SHORT) {
            setValue(updateValuePreserveType(value, parseInt(v)));
        } else if (type === TAG.DOUBLE) {
            setValue(updateValuePreserveType(value, parseFloat(v)));
        } else {
            setValue(v);
        }
    };

    useEffect(() => {
        if (key !== label) {
            onUpdate(currentPath, key, value);
            currentPath[currentPath.length - 1] = key;
        }
    }, [key]);

    useEffect(() => {
        if (value !== children) {
            onUpdate(currentPath, key, value);
        }
    }, [value]);

    const isOpenable = openableTypes.includes(currType);
    return (
        <div
            className="flex gap-2"
            id={currentPath.join(".")}
            style={{
                flexDirection: isOpenable ? "column" : "row",
                color: highlighted ? "#0077ff" : "white",
            }}
        >
            <Modal isOpen={showAddElemModal} onClose={() => setShowAddElemModal(false)} title="Add Element">
                <div className="flex flex-col gap-2">
                    {!listTypes.includes(currType) && (
                        <div className="flex flex-row items-center">
                            <label className="mr-2 min-w-12">Key</label>
                            <input
                                type="text"
                                placeholder="my_key"
                                className="p-2 rounded-md w-full"
                                onChange={(e) => {
                                    setGoodModalKey(handleChange(e, EDIT_TYPES.TAG_NAME));
                                    setKeyModal(e.target.value);
                                }}
                            />
                        </div>
                    )}

                    {!openableTypes.includes(selectedTypeModal) && (
                        <div className="flex flex-row items-center">
                            <label className="mr-2 min-w-12">Value</label>
                            <input
                                type="text"
                                placeholder="my_value"
                                className="p-2 rounded-md w-full"
                                onChange={(e) => {
                                    setGoodModalValue(handleChange(e, getEditType(selectedTypeModal)));
                                    setValueModal(e.target.value);
                                }}
                            />
                        </div>
                    )}

                    {[TAG.LIST, TAG.COMPOUND].includes(currType) && (
                        <div className="flex flex-row items-center">
                            <label className="mr-2 min-w-12">Type</label>
                            <Select onChange={(e) => setSelectedTypeModal(parseInt(e.target.value))}>
                                <option value={TAG.BYTE}>Byte</option>
                                <option value={TAG.SHORT}>Short</option>
                                <option value={TAG.INT}>Int</option>
                                <option value={TAG.LONG}>Long</option>
                                <option value={TAG.FLOAT}>Float</option>
                                <option value={TAG.DOUBLE}>Double</option>
                                <option value={TAG.BYTE_ARRAY}>Byte Array</option>
                                <option value={TAG.INT_ARRAY}>Int Array</option>
                                <option value={TAG.LONG_ARRAY}>Long Array</option>
                                <option value={TAG.STRING}>String</option>
                                <option value={TAG.COMPOUND}>Compound</option>
                            </Select>
                        </div>
                    )}

                    <div className="mt-3 bg-[#1e1e1e] p-2 rounded-md" hidden={!goodModalKey.error && !goodModalValue.error}>
                        <div className="flex flex-row items-center">
                            {goodModalKey.reason.length > 0 && <p className="mr-2">Key: </p>}
                            {<div className="text-red-500 font-semibold">{goodModalKey.reason}</div>}
                        </div>
                        <div className="flex flex-row items-center">
                            {goodModalValue.reason.length > 0 && <p className="mr-2">Value: </p>}
                            {<div className="text-red-500 font-semibold">{goodModalValue.reason}</div>}
                        </div>
                    </div>
                    <div className="flex flex-row justify-end gap-2 mt-3">
                        <button className="bg-red-500" onClick={() => setShowAddElemModal(false)}>
                            Cancel
                        </button>
                        <button
                            className="bg-blue-500"
                            disabled={goodModalKey.error || goodModalValue.error}
                            onClick={() => {
                                onUpdate(currentPath, keyModal, valueModal, "add", selectedTypeModal);
                                setShowAddElemModal(false);
                            }}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </Modal>
            <div onContextMenu={(e) => handleContextMenu(e)} className="flex flex-row gap-2 w-max relative items-center">
                {isOpenable && (open ? <ArrowOpen onClick={() => setOpen(false)} /> : <ArrowClosed onClick={() => setOpen(true)} />)}
                <div className="flex flex-row ml-7 gap-2 items-center h-full">
                    <div
                        style={{
                            backgroundImage: "url('https://raw.githubusercontent.com/K9Developer/MinecraftData/main/NBTAtlas.png')",
                            backgroundPosition: `-${tagPositions[currType].x * 32 * scale}px -${tagPositions[currType].y * 32 * scale}px`,
                            backgroundSize: 128 * scale,
                            width: 32 * scale,
                            height: 32 * scale,
                            borderRadius: "5px",
                        }}
                    ></div>
                    <EditableParagraph initialText={`${label}`} editType={EDIT_TYPES.TAG_NAME} onUpdate={(v: any) => setKey(v)} />
                    {isOpenable ? "" : ":"}
                    {openableTypes.includes(currType) && open && (
                        <div className="aspect-square h-[90%]">
                            <button className="p-0 w-full h-full" onClick={() => setShowAddElemModal(true)}>
                                +
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {((open && isOpenable) || !isOpenable) &&
                (isOpenable ? (
                    <div className="flex flex-col gap-2" style={{ marginLeft: depth * 10 + "px" }}>
                        {open &&
                            Object.keys(value).map((tag) => (
                                <Branch
                                    key={currentPath.join(".") + tag}
                                    label={tag}
                                    type={Number(getTagType(value[tag]))}
                                    parentType={currType}
                                    onUpdate={onUpdate}
                                    currentPath={[...currentPath, tag]}
                                    children={value[tag]}
                                    depth={depth + 1}
                                    currentResult={currentResult}
                                />
                            ))}
                    </div>
                ) : (
                    <EditableParagraph initialText={value.toString()} editType={getEditType(currType)} onUpdate={(v: any) => setBranchValue(v)} />
                ))}
            {contextMenu && <ContextMenu x={contextMenu.x} y={contextMenu.y} onClose={closeContextMenu} options={contextMenuOptions} />}
        </div>
    );
};

export default Branch;
