import React, { useEffect } from "react";
import block_colors from "./data/block_colors";
import block_ids from "./data/block_ids";
import HorizontalNumberPicker from "./components/HorizontalNumberPicker";
import * as NBT from "nbtify";
import { CompoundTag } from "nbtify";

interface blockData {
    [item_id: string]: { name: string; color: string };
}
const App = () => {
    const [blocks, setBlocks] = React.useState<string[][]>([[]]);
    const previewRef = React.useRef<HTMLCanvasElement>(null);
    const [originalImage, setOriginalImage] = React.useState<string | null>(null);
    const [image, setImage] = React.useState<string | null>(null);
    const [brightness, setBrightness] = React.useState<number>(0);
    const [saturation, setSaturation] = React.useState<number>(0);

    const getBlockData = (block: string): { name: string; color: string } | null => {
        for (const [item_id, data] of Object.entries(block_colors)) {
            if (item_id === block) {
                return data;
            }
        }
        return null;
    };

    const downloadSchem = async (size = 128) => {
        // read ./data/emptyNBT.nbt
        const NBTData = {
            "Made With": "WiseHosting Map Generator",
            Width: size,
            Height: 1,
            Length: size,
            Materials: "Alpha",
            Blocks: new Int8Array(size * size),
            Data: new Int8Array(size * size),
            x: 0,
            y: 0,
            z: 0,
        };
        const amounts: any = {};
        for (let y = 0; y < blocks.length; y++) {
            for (let x = 0; x < blocks[y].length; x++) {
                const block = blocks[y][x];
                if (!amounts[block]) amounts[block] = 0;
                amounts[block]++;
                const blockId = block_ids[block];
                if (blockId) {
                    NBTData.Blocks.set([blockId.id], y * size + x);
                    NBTData.Data.set([blockId.data], y * size + x);
                }
            }
        }

        // Write to NBT
        const nbt = new NBT.NBTWriter();
        const buffer = nbt.write(NBTData);
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "map.schematic";
        a.click();
    };

    const drawMap = () => {
        const canvas = previewRef.current;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
                const fullScale = canvas.clientWidth;
                canvas.width = fullScale;
                canvas.height = fullScale;
                ctx.clearRect(0, 0, fullScale, fullScale);
                const blockSize = fullScale / blocks[0].length;
                const aspect = canvas.clientWidth / canvas.clientHeight;
                const yOffset = (canvas.clientHeight - blockSize * blocks.length * aspect) / 2;
                for (let y = 0; y < blocks.length; y++) {
                    for (let x = 0; x < blocks[y].length; x++) {
                        const block = blocks[y][x];
                        const data = getBlockData(block);
                        if (data) {
                            ctx.fillStyle = data.color;
                            ctx.fillRect(x * blockSize, y * blockSize * aspect + yOffset, blockSize, blockSize * aspect);
                        }
                    }
                }
            }
        }
    };

    useEffect(drawMap, [blocks]);

    function hexToRgb(hex: string): [number, number, number] {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
    }

    function getClosestColor(rgbColor: [number, number, number], hexColorList: blockData): { block: string; color: string } {
        let closest = { block: "", color: "" };
        let min_distance = Infinity;
        for (const [block, { color }] of Object.entries(hexColorList)) {
            const [r, g, b] = hexToRgb(color);
            const distance = Math.sqrt(Math.pow(r - rgbColor[0], 2) + Math.pow(g - rgbColor[1], 2) + Math.pow(b - rgbColor[2], 2));
            if (distance < min_distance) {
                min_distance = distance;
                closest = { block, color };
            }
        }
        return closest;
    }

    useEffect(() => {
        setTimeout(() => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            if (ctx) {
                const img = new Image();
                img.src = originalImage ?? "";
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;

                    // Adjust brightness calculation
                    const adjustedBrightness = (brightness - 50) * 2 + 100;
                    const adjustedSaturation = (saturation - 50) * 2 + 100;

                    // Apply filters
                    ctx.filter = `brightness(${adjustedBrightness}%) saturate(${adjustedSaturation}%)`;

                    // Draw the image with the filter applied
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    // Convert canvas to base64 and update the image state
                    const newImageDataUrl = canvas.toDataURL("image/jpeg");
                    setImage(newImageDataUrl);
                    setTimeout(() => {
                        getBlocks(newImageDataUrl);
                    }, 1);
                };
            }
        }, 1);
    }, [brightness, saturation, originalImage]);

    const getBlocks = async (img: HTMLImageElement | string) => {
        if (typeof img === "string") {
            const imgElement = document.createElement("img");
            imgElement.src = img;
            imgElement.onload = () => {
                getBlocks(imgElement);
            };
            return;
        }

        const tmp_blocks: string[][] = [];
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (ctx) {
            // Make the canvas square with the larger dimension of the image
            const size = Math.max(img.width, img.height);
            canvas.width = size;
            canvas.height = size;

            // Fill the canvas with a transparent background
            ctx.clearRect(0, 0, size, size);

            // Calculate position to center the image
            const offsetX = (size - img.width) / 2;
            const offsetY = (size - img.height) / 2;

            // Draw the image centered on the canvas
            ctx.drawImage(img, offsetX, offsetY);

            // Resize to 128x128
            const resizeCanvas = document.createElement("canvas");
            resizeCanvas.width = 128;
            resizeCanvas.height = 128;
            const resizeCtx = resizeCanvas.getContext("2d");
            if (resizeCtx) {
                resizeCtx.drawImage(canvas, 0, 0, size, size, 0, 0, 128, 128);
                const data = resizeCtx.getImageData(0, 0, 128, 128).data;

                let curr_layer = 0;
                for (let i = 0; i < data.length; i += 4) {
                    const rgb = [data[i], data[i + 1], data[i + 2]];
                    const alpha = data[i + 3];

                    const { block } = getClosestColor(rgb as [number, number, number], block_colors);
                    if (!tmp_blocks[curr_layer]) {
                        tmp_blocks[curr_layer] = [];
                    }
                    if (alpha === 0) tmp_blocks[curr_layer].push("0");
                    else tmp_blocks[curr_layer].push(block);
                    if (tmp_blocks[curr_layer].length === 128) {
                        curr_layer++;
                    }
                }
            }
        }
        setBlocks(tmp_blocks);
    };

    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    setOriginalImage(img.src);
                    getBlocks(img);
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="flex flex-col h-full p-4 gap-4">
            <div className="flex sm:flex-row flex-col gap-2 h-fit sm:h-1/2">
                {/* image upload container */}
                <div className="sm:w-1/2 max-sm:h-1/2 w-full relative bg-transparent border-2 border-dashed border-slate-600 rounded-lg aspect-square">
                    <div className="absolute inset-0">
                        <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-2xl">
                            {image ? "" : "Drop an image here"}
                        </p>
                        <input type="file" className="absolute w-full h-full opacity-0" accept="image/*" onChange={onUpload} />
                        <img hidden={!image} src={image ?? ""} alt="" className="absolute p-10 w-full h-full object-contain" />
                    </div>
                </div>

                {/* canvas */}
                {window.innerWidth > 640 ? (
                    <div className="flex-grow bg-[#1c1c1c] flex items-center justify-center p-10">
                        <canvas ref={previewRef} className="object-contain max-w-full max-h-full" style={{ imageRendering: "pixelated" }} />
                    </div>
                ) : (
                    <div className="flex-grow bg-[#1c1c1c] flex items-center justify-center sm:h-full h-1/2">
                        <canvas ref={previewRef} className="object-contain h-full" style={{ imageRendering: "pixelated" }} />
                    </div>
                )}
            </div>

            {/* controls */}
            <div className="w-full flex justify-center flex-grow">
                <div className="flex flex-col gap-4 sm:w-1/2 w-full">
                    <button
                        className="px-4 py-2 rounded"
                        onClick={() => {
                            setOriginalImage(null);
                            setImage(null);
                            setBlocks([[]]);
                        }}
                    >
                        Re-Upload
                    </button>
                    <HorizontalNumberPicker
                        onChange={(val: number) => setBrightness(val)}
                        startNumber={0}
                        totalSteps={101}
                        title={"Brightness"}
                        defaultValue={50}
                        disabled={!originalImage}
                    />
                    <HorizontalNumberPicker
                        onChange={(val: number) => setSaturation(val)}
                        startNumber={0}
                        totalSteps={101}
                        title={"Saturation"}
                        defaultValue={50}
                        disabled={!originalImage}
                    />
                    <button className="px-4 py-2 bg-green-500 text-white rounded mb-2" onClick={() => downloadSchem()}>
                        Download Schematic
                    </button>
                </div>
            </div>
        </div>
    );
};

export default App;
