import React, { useEffect } from "react";
import { getItemOptions, TreeNode } from "../utils";
import ItemPickerTooltip from "./ItemPickerTooltip";
import { Item } from "./ItemIcon";

type Props = {
    visible: boolean;
    position: { x: number; y: number };
    nodeData: any;
    trees: { [key: string]: TreeNode };
    itemAssetData: any;
    onPathChange: (path: number[][], itemId: string) => void;
    currPathData: any;
    recipes: any;
    viewRecipeCallback: (recipe: any, action: string | null, item: Item, amount: number) => void;
};

const NodeTooltip = ({ visible, position, nodeData, trees, itemAssetData, onPathChange, currPathData, recipes, viewRecipeCallback }: Props) => {
    const [currItemOptions, setCurrItemOptions] = React.useState<any>(null);
    const [itemPickerVisible, setItemPickerVisible] = React.useState(false);

    useEffect(() => {
        if (!visible) {
            setItemPickerVisible(false);
        }
    }, [visible]);

    const handleItemChoose = (option: any) => {
        onPathChange(option.pathData, nodeData.item.id);
    };

    const handleReplaceMaterials = () => {
        const tree = JSON.parse(JSON.stringify(trees[nodeData.treeParent]));
        const path = JSON.parse(JSON.stringify(nodeData.path));
        setCurrItemOptions(
            getItemOptions(tree, path, currPathData, {
                id: nodeData.item.id,
                amount: nodeData.amount,
                action: nodeData.action,
            })
        );
        setItemPickerVisible(true);
    };

    const handleViewRecipe = () => {
        const ingredients = new Set(nodeData.incomers);
        const r = recipes["minecraft:" + nodeData.item.id];
        for (let recipe of r) {
            recipe = recipe["pattern"];
            const recipeIngredients = new Set(recipe.filter((ingredient: any) => ingredient !== " "));
            if (Array.from(ingredients).every((ingredient) => recipeIngredients.has("minecraft:" + ingredient))) {
                viewRecipeCallback(recipe, nodeData.action, nodeData.item, nodeData.amount);
                return;
            }
        }
    };

    if (!visible) return null;
    if (itemPickerVisible)
        return <ItemPickerTooltip options={currItemOptions} position={position} itemAssetData={itemAssetData} onChoose={handleItemChoose} />;

    return (
        <div
            className={
                "bg-gray-900 rounded-md overflow-hidden flex flex-col w-40 absolute z-50" +
                (nodeData.isStartOfBranch || nodeData.isEndOfBranch ? " h-10" : " h-20")
            }
            style={{
                top: position.y - 20 * 0.25 * 16,
                left: position.x - 40 * 0.25 * 16,
            }}
        >
            {!nodeData.isEndOfBranch && (
                <button
                    className={"p-0 w-full rounded-none h-full" + (!nodeData.isStartOfBranch ? " border-b-2 border-b-gray-700" : "")}
                    onClick={handleViewRecipe}
                >
                    View Recipe
                </button>
            )}
            {!nodeData.isStartOfBranch && (
                <button className="p-0 w-full h-full rounded-none" onClick={handleReplaceMaterials}>
                    Replace Material
                </button>
            )}
        </div>
    );
};

export default NodeTooltip;
