import React, { useState, useCallback, useEffect, useRef } from "react";
import { createEditor, Transforms, Text, Node, Editor, Element, Operation, BaseSelection, Range, Point } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import isHotkey from "is-hotkey";
import { getTextWidth, getAllLeaves, unmergeLeaves, getLeavesFromMotdLine, LeafElement, BlockElement } from "../utils";

const HOTKEYS: { [key: string]: string } = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+s": "strike",
    "mod+o": "obf",
};

interface Params {
    setData: React.Dispatch<React.SetStateAction<any>>;
    currentColor: string;
    alignment: string;
    selectedStyles: string[];
    setStyles: React.Dispatch<React.SetStateAction<string[]>>;
    setColor: React.Dispatch<React.SetStateAction<string>>;
    reloadChildren: number;
    maxLineWidth: number;
    setMaxLineWidth: React.Dispatch<React.SetStateAction<number>>;
    initialValue: any;
    setAlignment: React.Dispatch<React.SetStateAction<string>>;
    currentStyle: { style: string; newState: boolean } | null;
    setCurrentStyle: React.Dispatch<React.SetStateAction<{ style: string; newState: boolean } | null>>;
}

let widthCache: Map<string, number> = new Map();
let fntSize = window.innerWidth >= 1200 ? "1.65vw" : window.innerWidth >= 640 ? "1.6vw" : "2vw";
let maxLineWidthLocal = 0;

const RichMotdEditor = ({
    setData,
    currentColor,
    alignment,
    selectedStyles,
    setStyles,
    setColor,
    reloadChildren,
    maxLineWidth,
    setMaxLineWidth,
    initialValue,
    setAlignment,
    currentStyle,
    setCurrentStyle,
}: Params) => {
    const [editor] = useState(() => withReact(createEditor()));
    const MAX_PARAGRAPHS = 2;

    const styles = {
        editable: {
            fontFamily: "minecraftFont",
            whiteSpace: "pre",
            width: window.innerWidth >= 900 ? "89%" : "95%",
        },
        element: {
            margin: 0,
            lineHeight: "1.5em",
        },
    };

    useEffect(() => {
        editor.children = initialValue;
        editor.onChange();
    }, [reloadChildren]);

    const handleResize = () => {
        fntSize = window.innerWidth >= 1200 ? "1.65vw" : window.innerWidth >= 640 ? "1.6vw" : "2vw";
        maxLineWidthLocal = getTextWidth("a".repeat(45), "minecraftFont", fntSize);
        setMaxLineWidth(maxLineWidthLocal);

        let elem = document.getElementById("editor");
        if (elem) {
            elem.style.fontSize = fntSize;
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        window.onload = () => handleResize();

        setTimeout(() => {
            handleResize();
        }, 10);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const processColor = (leaf: LeafElement, baseElement: any) => {
        if (currentColor && leaf.color) {
            baseElement.props.style.color = leaf.color;
        }
    };

    const processStyles = (leaf: LeafElement, baseElement: any) => {
        let textDecor = "";

        if (leaf.bold) baseElement.props.style.fontWeight = "bold";
        if (leaf.italic) baseElement.props.style.fontStyle = "italic";
        if (leaf.underline) textDecor += "underline ";
        if (leaf.strike) textDecor += "line-through";
        if (textDecor) baseElement.props.style.textDecoration = textDecor;

        if (leaf.obf) {
            baseElement.props.style.color = "black";
            baseElement.props.style.backgroundColor = "white";
        }
    };

    const Leaf = ({ attributes, children, leaf }: { attributes: any; children: Element[]; leaf: LeafElement }) => {
        let baseElement = (
            <span {...attributes} style={{ ...styles.element }}>
                {children}
            </span>
        );

        processColor(leaf, baseElement);
        processStyles(leaf, baseElement);

        return baseElement;
    };

    const Element = ({ attributes, children, element }: { attributes: any; children: Element[]; element: BlockElement }) => {
        let baseElement = (
            <p {...attributes} style={{ ...styles.element, textAlign: element.alignment }}>
                {children}
            </p>
        );

        return baseElement;
    };

    const setMarks = (style: { style: string; newState: boolean }) => {
        if (!style) return;
        Editor.addMark(editor, style.style, style.newState);
    };

    const getAllUnmergedLeaves = (editor: Editor) => {
        const mergedLeaves = getAllLeaves(editor);
        return unmergeLeaves(mergedLeaves as LeafElement[]);
    };

    const areLeafStylesEqual = (leaf1: LeafElement, leaf2: LeafElement) => {
        return (
            leaf1.bold === leaf2.bold &&
            leaf1.italic === leaf2.italic &&
            leaf1.underline === leaf2.underline &&
            leaf1.strike === leaf2.strike &&
            leaf1.obf === leaf2.obf &&
            leaf1.color === leaf2.color
        );
    };

    const mergeLeaves = (leaves: LeafElement[], breakIndex = 0) => {
        const newLeaves = [];
        let currLeaf = leaves[0];
        let index = 0;
        for (let leaf of leaves) {
            if (index === 0) {
                index++;
                continue;
            }

            if (areLeafStylesEqual(currLeaf, leaf) && (breakIndex ? index !== breakIndex : true)) {
                currLeaf.text += leaf.text;
            } else {
                newLeaves.push(currLeaf);
                currLeaf = leaf;
            }
            index++;
        }
        newLeaves.push(currLeaf);
        return newLeaves;
    };

    const clearEditor = (editor: Editor) => {
        editor.children = [];
        editor.selection = null;
    };

    const getAbsoluteOffset = (editor: Editor) => {
        const { selection } = editor;

        if (!selection) {
            return 0; // No selection
        }

        const { anchor } = selection;
        const { path, offset } = anchor;

        let absoluteOffset = offset;

        // Traverse the document from the start to the anchor path
        for (let i = 0; i < path.length; i++) {
            const subPath = path.slice(0, i);
            const node = Node.get(editor, subPath);

            // Sum up the lengths of all preceding siblings
            for (let j = 0; j < path[i]; j++) {
                const siblingNode = (node as Element).children[j];
                absoluteOffset += Node.string(siblingNode).length;
            }
        }

        return absoluteOffset;
    };

    const getPathFromAbsoluteOffset = (editor: Editor, absoluteOffset: number) => {
        let remainingOffset = absoluteOffset;
        let path: number[] = [];

        const traverse = (node: LeafElement | BlockElement | Editor, currentPath: number[]) => {
            if (Text.isText(node)) {
                if (remainingOffset <= node.text.length) {
                    path = currentPath;
                    return true;
                }
                remainingOffset -= node.text.length;
            } else {
                for (let i = 0; i < node.children.length; i++) {
                    const childPath = currentPath.concat(i);
                    if (traverse((node as BlockElement).children[i], childPath)) {
                        return true;
                    }
                }
            }
            return false;
        };

        traverse(editor, []);

        return {
            path,
            offset: remainingOffset,
        };
    };

    const applyUnmergedLeaves = (leaves: LeafElement[], breakIndex: number) => {
        // Save the current selection
        const savedLoc = getAbsoluteOffset(editor);

        const firstLineLeaves = leaves.slice(0, breakIndex);
        const secondLineLeaves = leaves.slice(breakIndex, leaves.length);

        const firstLineMerged = mergeLeaves(firstLineLeaves);
        const secondLineMerged = mergeLeaves(secondLineLeaves);

        clearEditor(editor);

        Transforms.insertNodes(editor, {
            type: "paragraph",
            children: firstLineMerged,
        } as Element);

        Transforms.insertNodes(editor, {
            type: "paragraph",
            children: secondLineMerged,
        } as Element);

        const savedPath = getPathFromAbsoluteOffset(editor, savedLoc);
        editor.selection = {
            anchor: savedPath,
            focus: savedPath,
        };
    };

    const getLeafsInRange = (editor: any, range: any) => {
        return Editor.nodes(editor, {
            at: range,
            match: Text.isText,
            mode: "lowest",
        });
    };

    const getSelectedAndUnselectedText = (editor: Editor) => {
        const { selection } = editor;
        if (!selection || Range.isCollapsed(selection)) {
            return null;
        }

        const getLeafsInRange = (range: Range) => {
            return Editor.nodes(editor, {
                at: range,
                match: Text.isText,
                mode: "lowest",
            });
        };

        const splitLeafOnRange = (leaf: LeafElement, range: any) => {
            const { text, ...attributes } = leaf;
            const before = text.slice(0, range.offset);
            const selected = text.slice(range.offset, range.focus.offset);
            const after = text.slice(range.focus.offset);
            return [
                before ? { text: before, ...attributes } : null,
                { text: selected, ...attributes },
                after ? { text: after, ...attributes } : null,
            ].filter(Boolean);
        };

        const allLeafs = Array.from(getLeafsInRange(Editor.range(editor, [])));
        const result: any = [];

        let currentLine: any = [];
        let currentLineIndex = 0;

        allLeafs.forEach(([node, path]: [any, any]) => {
            const nodeRange = Editor.range(editor, path);
            const intersectionRange = Range.intersection(nodeRange, selection);

            if (path[0] !== currentLineIndex) {
                if (currentLine.length > 0) {
                    result.push(currentLine);
                }
                currentLine = [];
                currentLineIndex = path[0];
            }

            if (!intersectionRange) {
                currentLine.push({ ...node, selected: false });
            } else if (Range.equals(nodeRange, intersectionRange)) {
                currentLine.push({ ...node, selected: true });
            } else {
                const [before, selected, after] = splitLeafOnRange(node, {
                    ...intersectionRange,
                    offset: intersectionRange.anchor.offset - nodeRange.anchor.offset,
                    focus: {
                        ...intersectionRange.focus,
                        offset: intersectionRange.focus.offset - nodeRange.anchor.offset,
                    },
                });
                if (before) currentLine.push({ ...before, selected: false });
                if (selected) currentLine.push({ ...selected, selected: true });
                if (after) currentLine.push({ ...after, selected: false });
            }
        });

        // Push the last line
        if (currentLine.length > 0) {
            result.push(currentLine);
        }

        return result;
    };

    const isGoodEditor = (event: { [key: string]: string }) => {
        const currLine = getCurrentLineIndex(editor);
        const firstLineWidth = Math.floor(getLineWidth(0));
        const secondLineWidth = Math.floor(getLineWidth(1));

        if (event.type === "text") {
            const inputTextWidth = getTextWidth(event.data, "minecraftFont", fntSize, isMarkActive("bold") ? 800 : 400);

            // second line full, trying to edit second line
            if (currLine === 1 && secondLineWidth + inputTextWidth >= maxLineWidthLocal) {
                console.log("Second line full, trying to edit second line - CANCEL");
                return false;
            }

            // first line full, trying to edit first line and second line is full
            if (currLine === 0 && firstLineWidth + inputTextWidth >= maxLineWidthLocal && secondLineWidth + inputTextWidth >= maxLineWidthLocal) {
                console.log("First line full, trying to edit first line and second line is full - CANCEL");
                return false;
            }

            // first line full, trying to edit first line and second line is not full
            if (currLine === 0 && firstLineWidth + inputTextWidth >= maxLineWidthLocal && secondLineWidth + inputTextWidth <= maxLineWidthLocal) {
                console.log("First line full, trying to edit first line and second line is not full - ALLOW");
                console.log(
                    `[INFO]: First line width: ${firstLineWidth}, Second line width: ${secondLineWidth}, Input text width: ${inputTextWidth}, Max line width: ${maxLineWidthLocal}`
                );
                const savedMarks = Editor.marks(editor) as { [key: string]: any };

                // Create 2nd line and move cursor to 2nd line
                if (editor.children.length === 1) {
                    console.log("SHOULD NOT BE HERE a 2nd TIME");
                    Transforms.insertNodes(editor, { type: "paragraph", children: [] } as Element, { at: [1] });
                }

                // If the current cursor position is not the end of the first line, then remove the last char in the first line and put it in the start of the 2nd line
                const selection = editor.selection;
                const lines = getLines(editor);
                const isAtEndOfFirstLine = selection && selection.anchor.path[0] === 0 && selection.anchor.offset === lines[0].length;
                if (isAtEndOfFirstLine) {
                    const secondLineStart = Editor.after(editor, [0], { unit: "line" });
                    if (secondLineStart) {
                        Transforms.select(editor, secondLineStart);
                    }
                    console.log("Cursor is at the end of the line");
                } else {
                    console.log("Cursor is NOT at the end of the line");
                    const umergedLeaves = getAllUnmergedLeaves(editor).slice(0, lines[0].length);
                    const lastLeaf = umergedLeaves.pop();
                    if (lastLeaf) {
                        const savedPath = getPathFromAbsoluteOffset(editor, getAbsoluteOffset(editor));
                        Transforms.delete(editor, {
                            at: {
                                anchor: Editor.before(editor, Editor.end(editor, [0])) || Editor.start(editor, [0]),
                                focus: Editor.end(editor, [0]),
                            },
                        });
                        Transforms.insertNodes(editor, lastLeaf as LeafElement, { at: [1, 0] });
                        editor.selection = { anchor: savedPath, focus: savedPath };
                    }
                }
                for (const [style, val] of Object.entries(savedMarks)) {
                    Editor.addMark(editor, style, val);
                }
                Transforms.insertNodes(editor, { text: event.data, ...savedMarks } as LeafElement);

                return false;
            }

            return true;
        } else if (event.type === "mark") {
            // TODO: If first line full, second like isn't full, and trying to bold, allow it

            if (event.data === "bold") {
                if (currLine === 0 && firstLineWidth >= maxLineWidthLocal) return false;
                if (currLine === 1 && secondLineWidth >= maxLineWidthLocal) return false;

                const selectionData = getSelectedAndUnselectedText(editor);
                const lines = getLines(editor);

                if (!selectionData) return true;
                if (currLine === 0) {
                    // Get text in selection
                    const selection = editor.selection;
                    if (!selection) return false;

                    const selectionDataChanged = [
                        selectionData[0].map((leaf: LeafElement) => {
                            if (leaf.selected) {
                                leaf.bold = true;
                            }
                            return leaf;
                        }),
                        selectionData[1] ?? [],
                    ];
                    const unselectedLeaves = selectionDataChanged[0].filter((leaf: LeafElement) => !leaf.selected);
                    const selectedLeaves = selectionDataChanged[0].filter((leaf: LeafElement) => leaf.selected);

                    const unselectedFirstLineWidth = getSlateWidth(unselectedLeaves);
                    const selectedTextWidth = getSlateWidth(selectedLeaves);

                    if (unselectedFirstLineWidth + selectedTextWidth > maxLineWidthLocal && secondLineWidth + selectedTextWidth > maxLineWidthLocal)
                        return false;

                    // Move some of the first line (from the end) to the start of the second line until it fits
                    let currWidth = 0;
                    let breakIndex = 0;
                    const unmeregedLeaves = unmergeLeaves(selectionDataChanged.flat());
                    for (let i = 0; i < lines[0].length; i++) {
                        const leaf = unmeregedLeaves[i];
                        const leafWidth = getTextWidth(leaf.text, "minecraftFont", fntSize, leaf.bold ? 800 : 400);
                        currWidth += leafWidth;
                        if (currWidth > maxLineWidthLocal) {
                            break;
                        }
                        breakIndex++;
                    }

                    if (breakIndex && breakIndex < lines[0].length) {
                        applyUnmergedLeaves(unmeregedLeaves, breakIndex);
                    }
                } else if (currLine === 1) {
                    const selection = editor.selection;
                    if (!selection) return false;

                    const selectionDataChanged = selectionData[1].map((leaf: LeafElement) => {
                        if (leaf.selected) {
                            leaf.bold = true;
                        }
                        return leaf;
                    });
                    const unselectedLeaves = selectionDataChanged.filter((leaf: LeafElement) => !leaf.selected);
                    const selectedLeaves = selectionDataChanged.filter((leaf: LeafElement) => leaf.selected);

                    const unselectedLineWidth = getSlateWidth(unselectedLeaves);
                    const selectedTextWidth = getSlateWidth(selectedLeaves);

                    if (unselectedLineWidth + selectedTextWidth > maxLineWidthLocal) return false;
                } else if (currLine === -1) {
                    const selection = editor.selection;
                    if (!selection) return false;

                    const unselectedFirstLineWidth = getSlateWidth(selectionData.unselected[0]);
                    const unselectedSecondLineWidth = getSlateWidth(selectionData.unselected[1]);

                    const selectedFirstTextWidth = getSlateWidth(
                        selectionData.selected[0].map((leaf: LeafElement) => {
                            leaf.bold = true;
                            return leaf;
                        })
                    );

                    const selectedSecondTextWidth = getSlateWidth(
                        selectionData.selected[1].map((leaf: LeafElement) => {
                            leaf.bold = true;
                            return leaf;
                        })
                    );

                    if (unselectedFirstLineWidth + selectedFirstTextWidth > maxLineWidthLocal) return false;
                    if (unselectedSecondLineWidth + selectedSecondTextWidth > maxLineWidthLocal) return false;
                }

                return true;
            }
        }
    };

    useEffect(() => {
        if (!currentStyle) return;
        if (currentStyle.style === "bold" && !isGoodEditor({ type: "mark", data: "bold" })) {
            setCurrentStyle(null);
            setStyles((p: string[]) => p.filter((v) => v !== "bold"));
            return;
        }
        setMarks(currentStyle);
        setData(editor.children);
    }, [currentStyle]);

    useEffect(() => {
        Editor.addMark(editor, "color", currentColor);
    }, [currentColor]);

    useEffect(() => {
        const props: any = {
            alignment: alignment,
            type: "paragraph",
        };
        Transforms.setNodes(editor, props);
    }, [alignment]);

    const getCurrentLineIndex = (editor: Editor) => {
        if (editor.selection) {
            const [start, end] = Editor.edges(editor, editor.selection);
            if (start.path[0] !== end.path[0]) {
                // Selection spans multiple lines
                return -1;
            }

            const block = Editor.above(editor, {
                match: (n) => Editor.isBlock(editor, n as Element),
            });

            if (block) {
                const [, path] = block;
                const lineIndex = path[0];
                return lineIndex || 0;
            }
        }
        return 0; // Default to 0 if no selection or block is found
    };

    const getLines = (editor: Editor) => {
        return editor.children.map((n) => Node.string(n));
    };

    const isMarkActive = (format: string) => {
        if (!editor) return false;
        const marks = Editor.marks(editor) as Record<string, any>;
        return marks && (marks[format] === true || (marks[format] === false && false) || marks[format]);
    };

    const renderLeaf = useCallback(
        (props: any) => {
            return <Leaf {...props} />;
        },
        [selectedStyles, currentColor]
    );

    const renderElement = useCallback(
        (props: any) => {
            return <Element {...props} />;
        },
        [alignment]
    );

    const onSelectionChange = (selection: BaseSelection) => {
        // if a selection is a selection of text and not a cursor

        if (selection && selection.anchor.offset !== selection.focus.offset) return;

        const [block]: any[] =
            Editor.above(editor, {
                match: (n) => Editor.isBlock(editor, n as Element),
            }) || [];

        if (alignment !== block?.alignment || "left") {
            setAlignment(block?.alignment || "left");
        }

        const marks = Editor.marks(editor) as Record<string, any>;
        if (marks) {
            const marksPair = Object.entries(marks);
            // Add rest of the marks
            for (let mark of ["bold", "italic", "underline", "strike", "obf", "color"]) {
                if (marks[mark] === undefined) {
                    marksPair.push([mark, mark === "color" ? "#AAAAAA" : false]);
                }
            }

            const styles: string[] = [];

            for (const [key, value] of marksPair) {
                if (!["bold", "italic", "underline", "strike", "obf", "color"].includes(key)) continue;

                if (key === "color") {
                    setColor(value);
                } else if (value) {
                    styles.push(key);
                }
            }

            setStyles(styles);
        }
    };

    const getSlateWidth = (leaves: LeafElement[], style = true) => {
        let width = 0;
        for (const leaf of leaves) {
            const cacheKey = leaf.text + "-" + (leaf.bold ?? false);

            const cachedValue = widthCache.get(cacheKey);
            if (cachedValue !== undefined) {
                width += cachedValue;
                continue;
            }
            const currWidth = getTextWidth(leaf.text, "minecraftFont", fntSize, style ? (leaf.bold ? 800 : 400) : 400);

            widthCache.set(cacheKey, currWidth);
            width += currWidth;
        }
        return width;
    };

    const getLineWidth = (lineInd: number) => {
        const lines = getLines(editor);

        if (lineInd >= lines.length) {
            return 0;
        }

        const allData = getAllUnmergedLeaves(editor);
        const startInd = lineInd === 0 ? 0 : lines[0].length;
        const endInd = lineInd === 0 ? lines[0].length : allData.length;
        const width = getSlateWidth(allData.slice(startInd, endInd));
        return width;
    };

    const revertOperations = (ops: any[]) => {
        // Revert all ops except the marker ones that are not "bold"
        const reversedOps = ops.map((op) => {
            if (op.type === "insert_node" || op.type === "remove_node" || op.type === "insert_text") return Operation.inverse(op);
            if (op.newProperties && "bold" in op.newProperties) return Operation.inverse(op);
            return null;
        });

        Editor.withoutNormalizing(editor, () => {
            reversedOps.forEach((op) => {
                if (op) editor.apply(op);
            });
        });

        // Fix styling if cant bold but manually clicked bold
        const boldAdded = ops.some((op) => op.type === "set_node" && op.newProperties && op.newProperties.bold === true);
        if (boldAdded) setStyles((p: string[]) => p.filter((v) => v !== "bold"));
    };

    const handleCopy = useCallback(
        (event: any) => {
            event.preventDefault();
            const { selection } = editor;
            if (selection) {
                const fragment = editor.getFragment();
                const serialized = JSON.stringify(fragment);
                event.clipboardData.setData("application/x-slate-fragment", serialized);
                event.clipboardData.setData("text/plain", Node.string({ children: fragment }));
            }
        },
        [editor]
    );

    const insertLeaves = (editor: Editor, index: number, leaves: LeafElement[]) => {
        // Ensure the index is not negative
        if (index < 0) {
            console.warn("Invalid index");
            return;
        }

        // Create text nodes from the leaves
        const nodes = leaves.map((leaf) => ({
            ...leaf,
            text: leaf.text || "",
        }));

        // Create new paragraphs if needed
        while (index >= editor.children.length) {
            Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] } as BlockElement, { at: [editor.children.length] });
        }

        // Create the path for insertion
        const path = [index, 0];

        // Insert the nodes at the specified path
        Transforms.insertNodes(editor, nodes, { at: path });
    };

    const handlePaste = useCallback(
        (event: any) => {
            event.preventDefault();

            Editor.deleteFragment(editor);

            const rawSlateJSCP = event.clipboardData.getData("application/x-slate-fragment");
            const slateData = JSON.parse(rawSlateJSCP === "" ? "[]" : rawSlateJSCP);
            const slateLeaves: any[] = [];
            for (const paragraph of slateData) {
                slateLeaves.push(...paragraph.children);
            }

            const lineIndex = getCurrentLineIndex(editor);
            const firstLineWidth = getLineWidth(0);
            const secondLineWidth = getLineWidth(1);

            if (slateData.length) {
                const fragmentWidth = getSlateWidth(slateLeaves);

                if (lineIndex !== 1 && firstLineWidth + secondLineWidth + fragmentWidth > maxLineWidthLocal * 2) return;

                if (lineIndex !== 0 && secondLineWidth + fragmentWidth > maxLineWidthLocal) return;

                editor.insertFragment(slateData);
            } else {
                const text = event.clipboardData.getData("text/plain");
                const leavesLines: any[] = [];

                leavesLines.push(getLeavesFromMotdLine(text.split("\\n")[0]));
                if (text.split("\\n").length > 1) {
                    leavesLines.push(getLeavesFromMotdLine(text.split("\\n")[1]));
                }

                const firstLineLength = leavesLines[0].length;
                const addLeaves: any[] = [];
                let currWidthFirst = firstLineWidth;
                let currWidthSecond = secondLineWidth;
                for (const leaf of leavesLines.flat()) {
                    const leafWidth = getSlateWidth([leaf]);

                    //     currWidthFirst + currWidthSecond + leafWidth > maxLineWidthLocal,
                    //     currWidthFirst > maxLineWidthLocal && currWidthSecond + leafWidth > maxLineWidthLocal && lineIndex == 0,
                    //     currWidthSecond > maxLineWidthLocal && (lineIndex == 1 || addLeaves.length > firstLineLength),
                    //     "|",
                    //     `First: ${currWidthFirst}`,
                    //     `Second: ${currWidthSecond}`,
                    //     `Leaf Width: ${leafWidth}`,
                    //     "Leaf:",
                    //     leaf,
                    //     "Max:",
                    //     maxLineWidthLocal
                    // );
                    if (
                        currWidthFirst + currWidthSecond + leafWidth > maxLineWidthLocal ||
                        (currWidthFirst > maxLineWidthLocal && currWidthSecond + leafWidth > maxLineWidthLocal && lineIndex === 0) ||
                        (currWidthSecond > maxLineWidthLocal && (lineIndex === 1 || addLeaves.length > firstLineLength))
                    )
                        break;
                    addLeaves.push(leaf);
                }

                if (addLeaves.length === 0) return;

                if (addLeaves.length > firstLineLength) {
                    const firstLine = addLeaves.slice(0, firstLineLength);
                    const secondLine = addLeaves.slice(firstLineLength);

                    // Transforms.insertNodes(editor, firstLine, { at: [0] });
                    insertLeaves(editor, 0, firstLine);
                    insertLeaves(editor, 1, secondLine);
                    // Transforms.insertNodes(editor, secondLine, { at: [1] });
                } else {
                    Transforms.insertNodes(editor, addLeaves);
                }

                // Insert list of leaves
            }
            editor.onChange();
        },
        [editor]
    );

    return (
        <Slate editor={editor} initialValue={initialValue} onSelectionChange={onSelectionChange} onChange={() => setData(editor.children)}>
            <Editable
                className="bg-[#212121] text-white p-2 rounded-md sm:px-5"
                style={{ ...styles.editable }}
                id="editor"
                renderLeaf={renderLeaf}
                renderElement={renderElement}
                onCopy={handleCopy}
                onCut={(e) => {
                    handleCopy(e);

                    Editor.deleteFragment(editor);
                }}
                onPaste={handlePaste}
                onDOMBeforeInput={(event) => {
                    let eventData = event.data;
                    if (event.inputType === "insert_text") eventData?.slice(eventData.length - 1, eventData.length);

                    const shouldPrevent = eventData && !isGoodEditor({ type: "text", data: eventData });
                    if (shouldPrevent) {
                        event.preventDefault();
                    }
                    let paragraphCount = editor.children.map((val) => (val as BlockElement).type === "paragraph").length;

                    if (event.inputType === "insertParagraph" && paragraphCount >= MAX_PARAGRAPHS) {
                        event.preventDefault();
                    }
                }}
                onKeyDown={(event) => {
                    for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event as any)) {
                            event.preventDefault();
                            const mark = HOTKEYS[hotkey];

                            setCurrentStyle({
                                style: mark,
                                newState: selectedStyles.includes(mark) ? false : true,
                            });

                            const isActive = isMarkActive(mark);
                            if (selectedStyles.includes(mark) && isActive) {
                                setStyles(selectedStyles.filter((style) => style !== mark));
                            } else {
                                setStyles([...selectedStyles, mark]);
                            }
                        }
                    }
                    setData(editor.children);
                }}
            />
        </Slate>
    );
};

export default RichMotdEditor;
