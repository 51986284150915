import Colors from "./Colors";
import Styles from "./Styles";
import Actions from "./Actions";

interface Params {
    setColor: (color: string) => void;
    color: string;
    styleCallback: (style: string) => void;
    actionCallback: (action: string) => void;
    selectedStyles: string[];
}

const Toolbar = ({ setColor, color, styleCallback, actionCallback, selectedStyles }: Params) => {
    return (
        <div className="flex flex-col sm:flex-row items-center gap-3 sm:gap-3 h-5/6 sm:h-1/2 mb-2 w-full sm:mb-9">
            <Colors setColor={setColor} selectedColor={color} />
            <Styles styleCallback={styleCallback} selectedStyles={selectedStyles} />
            <Actions actionCallback={actionCallback} />
        </div>
    );
};

export default Toolbar;
