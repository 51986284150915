import React, { useState } from "react";

interface Item {
    name: string;
    id: string;
    offsetX: number;
    offsetY: number;
}

interface ItemImageProps {
    item: Item;
    amount?: number;
    size?: number;
}

const ItemImage = ({ item, amount = 0, size = 42 }: ItemImageProps) => {
    const scale = size / 64;

    return (
        <div className={`relative`} style={{ width: `${size}px`, height: `${size}px` }}>
            <div
                className="w-full h-full"
                style={{
                    backgroundImage: `url('https://raw.githubusercontent.com/K9Developer/MinecraftData/main/items/atlas.png')`,
                    backgroundPosition: `-${item.offsetX * scale}px -${item.offsetY * scale}px`,
                    backgroundSize: `${2368 * scale}px ${2432 * scale}px`,
                    backgroundRepeat: "no-repeat",
                    imageRendering: "pixelated",
                }}
            />
            {amount > 1 && (
                <div
                    className={`absolute bottom-[-6px] right-[-8px] text-white text-[16px] px-1 rounded-lg font-bold`}
                    style={{ fontFamily: "minecraftFont" }}
                >
                    {amount}
                </div>
            )}
        </div>
    );
};

export default ItemImage;
