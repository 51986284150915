import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

interface VerticalSliderProps {
    values: number[];
    onChange: (value: number) => void;
    label: string;
    _value: number;
    initialValue?: number;
    className?: string;
}

const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    padding: 20px 0;
    background-color: #1a1919;
    border-radius: 0.25rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SliderTrack = styled.div`
    position: relative;
    width: 4px;
    height: 100%;
    border-radius: 2px;
`;

const SliderThumb = styled.div<{ position: number }>`
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #3498db;
    border-radius: 50%;
    bottom: ${(props) => props.position}%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #2980b9;
    }
`;

const SliderStep = styled.div<{ active: boolean; index: number; totalSteps: number }>`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background: ${(props) =>
        props.active
            ? `linear-gradient(to right, 
                rgb(${70 + (148 - 70) * (props.index / (props.totalSteps - 1))}, 
                    ${35 + (87 - 35) * (props.index / (props.totalSteps - 1))}, 
                    ${92 + (186 - 92) * (props.index / (props.totalSteps - 1))}), 
                rgb(${70 + (148 - 70) * ((props.index + 1) / (props.totalSteps - 1))}, 
                    ${35 + (87 - 35) * ((props.index + 1) / (props.totalSteps - 1))}, 
                    ${92 + (186 - 92) * ((props.index + 1) / (props.totalSteps - 1))}))`
            : "#7f8c8d"};
`;

const SliderLabel = styled.div`
    color: #ecf0f1;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
`;

const SliderValue = styled.input`
    color: #ecf0f1;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
    width: 80%;
    background: none;
`;

const VerticalSlider: React.FC<VerticalSliderProps> = ({ values, onChange, label, _value, initialValue, className }) => {
    const [value, setValue] = useState(initialValue ?? values[0]);
    const [isDragging, setIsDragging] = useState(false);
    const sliderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setValue(_value);
    }, [_value]);

    const updateValue = (clientY: number) => {
        if (sliderRef.current) {
            const rect = sliderRef.current.getBoundingClientRect();
            const clickPosition = rect.bottom - clientY;
            const index = Math.round((clickPosition / rect.height) * (values.length - 1));
            const clampedIndex = Math.max(0, Math.min(values.length - 1, index));
            const newValue = values[clampedIndex];
            setValue(newValue);
            onChange(newValue);
        }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        updateValue(event.clientY);
    };

    const handleTouchDown = (event: React.TouchEvent<HTMLDivElement>) => {
        setIsDragging(true);
        updateValue(event.touches[0].clientY);
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (isDragging) {
            updateValue(event.clientY);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchMove = (event: TouchEvent) => {
        if (isDragging) {
            updateValue(event.touches[0].clientY);
        }
    };

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchend", handleMouseUp);
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("touchmove", handleTouchMove);
            document.removeEventListener("touchend", handleMouseUp);
        };
    }, [isDragging]);

    const valueIndex = values.indexOf(value) === -1 ? values.length - 1 : values.indexOf(value);

    return (
        <SliderContainer className={className}>
            <SliderLabel>{label}</SliderLabel>
            <SliderTrack ref={sliderRef} onMouseDown={handleMouseDown} onTouchStart={handleTouchDown}>
                {values.map((val, index) => (
                    <SliderStep
                        key={index}
                        active={index <= valueIndex}
                        index={index}
                        totalSteps={values.length}
                        style={{ bottom: `${(index / (values.length - 1)) * 100}%` }}
                    />
                ))}
                <SliderThumb position={(valueIndex / (values.length - 1)) * 100} />
            </SliderTrack>
            <SliderValue
                value={value}
                inputMode="numeric"
                onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    if (!isNaN(newValue) && newValue <= Math.max(...values) * 1.5) {
                        setValue(newValue);
                        onChange(newValue);
                    } else {
                        setValue(value);
                    }
                }}
            />
        </SliderContainer>
    );
};

export default VerticalSlider;
