import React from "react";

const Spinner = () => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div
                className="w-12 h-12 rounded-full animate-spin
        border-4 border-solid border-white border-t-transparent shadow-md"
            ></div>
        </div>
    );
};

export default Spinner;
