import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import MotdEditor from "./pages/motd_editor/App";
import CoordCalc from "./pages/coord_calc/App";
import SkinGrabber from "./pages/skin_grabber/App";
import Picker from "./Picker";
import TickCalc from "./pages/tick_calculator/App";
import CircleGen from "./pages/circle_generator/App";
import CraftingGen from "./pages/crafting_generator/App";
import NbtEditor from "./pages/nbt_editor/App";
import ResourceCalc from "./pages/resource_calc/App";
import MapGenerator from "./pages/map_generator/App";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" Component={Picker} />
                <Route path="/motd_editor" Component={MotdEditor} />
                <Route path="/coord_calc" Component={CoordCalc} />
                <Route path="/skin_grabber" Component={SkinGrabber} />
                <Route path="/tick_calc" Component={TickCalc} />
                <Route path="/circle_gen" Component={CircleGen} />
                <Route path="/crafting_gen" Component={CraftingGen} />
                <Route path="/nbt_editor" Component={NbtEditor} />
                <Route path="/resource_calc" Component={ResourceCalc} />
                <Route path="/map_generator" Component={MapGenerator} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
