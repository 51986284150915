import { useEffect, useState } from "react";
import { getTrees, getPath, generateRecipeTree, getShortestPath, getMatchingPath, getPathIndex } from "../utils";
import { Background, BackgroundVariant, ReactFlow, Node, Edge, getNodesBounds, useNodes, useReactFlow } from "@xyflow/react";
import RecipeItemNode from "../components/RecipeItemNode";
import RecipeItemEdge from "../components/RecipeItemEdge";
import "@xyflow/react/dist/style.css";
import NodeTooltip from "../components/NodeTooltip";
import { Item } from "../components/ItemIcon";
import RecipeViewModal from "../components/RecipeViewModal";
import PathPanel from "../components/PathPanel";

const nodeTypes = {
    recipeItem: RecipeItemNode,
};

const edgeTypes = {
    recipeEdge: RecipeItemEdge,
};

type Props = {
    items: any;
    itemAssetData: any;
    itemRecipes: any;
};

const PageTwo = ({ items, itemAssetData, itemRecipes }: Props) => {
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<{ id: string; source: string; target: string }[]>([]);
    const [itemsPaths, setItemsPaths] = useState<any>({});
    const [fullTrees, setFullTrees] = useState<any>(null);
    const [itemPathDatas, setItemPathDatas] = useState<{ [item: string]: number[][] }>({});
    const [nodeTooltipData, setNodeTooltipData] = useState<{ x: number; y: number; visible: boolean; selectedNodeId: string; nodeData: any }>({
        x: 0,
        y: 0,
        visible: false,
        selectedNodeId: "",
        nodeData: {},
    });
    const [recipeModalData, setRecipeModalData] = useState<{
        visible: boolean;
        recipe: any;
        action: string | null;
        item: Item | null;
        amount: number;
    }>({
        visible: false,
        recipe: {},
        action: null,
        item: null,
        amount: 0,
    });

    useEffect(() => {
        window.onclick = (e: any) => {
            // if didnt click a buttom element
            if (e.target.tagName !== "BUTTON") setNodeTooltipData({ x: 0, y: 0, visible: false, selectedNodeId: "", nodeData: {} });
        };
    }, []);

    const viewRecipeCallback = (recipe: any, action: string | null, item: Item, amount: number) => {
        setRecipeModalData({ visible: true, recipe, action, item, amount });
    };

    const handleNodeClick = (data: any, node: Node | undefined, position: { x: number; y: number }) => {
        if (!node) return;
        const tooltipData = { x: position.x, y: position.y, visible: true, selectedNodeId: node.id, nodeData: data };
        setNodeTooltipData(tooltipData);
    };

    useEffect(() => {
        if (fullTrees !== null) return;

        getTrees(items).then((data) => {
            setFullTrees(data);
            if (!data) {
                return;
            }
            const paths: any = {};
            let shortFirstPathItem = Object.keys(data)[0];
            let index = 0;
            const itemIndices: { [key: string]: number[][] } = {};
            for (const item in data) {
                if (index === 0) {
                    const pathData = getShortestPath(data[item]);
                    paths[item] = getPath(JSON.parse(JSON.stringify(data[item])), pathData, items[item], itemRecipes);
                    itemIndices[item] = pathData;
                } else {
                    const pathData = getMatchingPath(data[shortFirstPathItem], data[item], itemRecipes);
                    paths[item] = getPath(JSON.parse(JSON.stringify(data[item])), pathData, items[item], itemRecipes);
                    itemIndices[item] = pathData;
                }
                index++;
            }

            for (let otherItem in items) {
                otherItem = otherItem.replace("minecraft:", "");
                paths[otherItem] = { ...paths[otherItem], base_item_paths: data[otherItem].base_item_paths };
            }

            setItemPathDatas(itemIndices);
            setItemsPaths(paths);
        });
    }, [items, fullTrees]);

    const generateRandomColor = () => {
        const getRandomValue = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

        // Generate RGB values that are light and soft
        const r = getRandomValue(180, 255); // Light red
        const g = getRandomValue(180, 255); // Light green
        const b = getRandomValue(180, 255); // Light blue

        // Return the hex color
        return `rgba(${r},${g},${b},0.5)`;
    };

    const darkenColor = (color: string, amount: number) => {
        const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/);

        if (!match) {
            throw new Error('Invalid color format. Expected "rgba(r,g,b,a)" or "rgb(r,g,b)".');
        }

        const [r, g, b] = match.slice(1).map(Number);

        // Darken each component by the given amount, ensuring it stays within [0, 255]
        const newR = Math.max(0, r - amount);
        const newG = Math.max(0, g - amount);
        const newB = Math.max(0, b - amount);

        // Return the new rgb color without opacity
        return `rgb(${newR},${newG},${newB})`;
    };

    useEffect(() => {
        const SPACING_BETWEEN_GROUPS = 130;
        const PADDING = { x: 30, y: 30, x2: 60, y2: 60 };

        const getTree = async () => {
            const nodeData = [];
            let graphMinX = 0;
            let graphMaxX = 0;
            let currYOffset = 0;

            const allNodes: Node[] = [];
            const allEdges: Edge[] = [];

            for (const item in itemsPaths) {
                const { nodes, edges } = await generateRecipeTree(
                    { [item]: itemsPaths[item] },
                    itemAssetData,
                    itemPathDatas[item],
                    JSON.parse(JSON.stringify(fullTrees))
                );

                let maxNodeGroupY = 0;
                for (const node of nodes) {
                    node.data.onClick = (data: any, node: Node | undefined, position: { x: number; y: number }) =>
                        handleNodeClick(data, node, position);
                    if (node.position) {
                        graphMinX = Math.min(node.position.x, graphMinX);
                        graphMaxX = Math.max(node.position.x, graphMaxX);
                        maxNodeGroupY = Math.max(maxNodeGroupY, node.position.y);
                    }
                }

                for (const node of nodes) {
                    if (node.position) {
                        node.position.y += currYOffset;
                    }
                }

                // const leafNodes = nodes.filter((node) => node.data.isEndOfBranch);
                // const baseNodeY = leafNodes[0].position.y;
                // // Ensure they have 10px spacing between them
                // for (let i = 0; i < leafNodes.length; i++) {
                //     leafNodes[i].position.y = baseNodeY + i * 100;
                // }

                currYOffset += maxNodeGroupY + SPACING_BETWEEN_GROUPS;

                nodeData.push({ nodes, edges });
            }
            for (const { nodes, edges } of nodeData) {
                for (const node of nodes) {
                    if (node.data.isEndOfBranch) {
                        node.position.x = graphMinX;
                    } else if (node.data.isStartOfBranch) {
                        node.position.x = graphMaxX;
                    }
                }

                const groupBoundingBox = getNodesBounds(nodes);
                const groupColor = generateRandomColor();
                const groupNode: Node = {
                    id: "group-" + nodes[0].data.item.id,
                    type: "group",
                    position: { x: groupBoundingBox.x - PADDING.x, y: groupBoundingBox.y - PADDING.y },
                    data: { label: nodes[0].data.item.name },
                    style: {
                        width: groupBoundingBox.width + 150 + PADDING.x2,
                        height: groupBoundingBox.height + 60 + PADDING.y2,
                        zIndex: -1,
                        backgroundColor: groupColor,
                        borderWidth: 2,
                        borderColor: darkenColor(groupColor, 10),
                        borderStyle: "dashed",
                        borderRadius: 20,
                        opacity: 0.2,
                    },
                };

                allNodes.push(...nodes, groupNode);
                allEdges.push(...edges);
            }

            setNodes(allNodes);
            setEdges(allEdges);
        };

        if (itemsPaths) {
            getTree();
        } // TODO: Sort this, the problem is that we push back nodes that dont have space back
    }, [itemAssetData, itemsPaths]);

    return (
        <div className="w-full h-full">
            <RecipeViewModal
                data={recipeModalData}
                itemAssetData={itemAssetData}
                onClose={() => {
                    setRecipeModalData({ visible: false, recipe: {}, action: null, item: null, amount: 0 });
                }}
            />
            <div className="h-[10%] bg-[#121212]">
                <PathPanel trees={itemsPaths} itemAssetData={itemAssetData} recipeData={itemRecipes} />
            </div>
            <div className="w-screen h-[90%]">
                <ReactFlow nodes={nodes} edges={edges} edgeTypes={edgeTypes} nodeTypes={nodeTypes} proOptions={{ hideAttribution: true }} fitView>
                    <NodeTooltip
                        visible={nodeTooltipData.visible}
                        position={{ x: nodeTooltipData.x, y: nodeTooltipData.y }}
                        nodeData={nodeTooltipData.nodeData}
                        trees={fullTrees}
                        itemAssetData={itemAssetData}
                        recipes={itemRecipes}
                        onPathChange={(pathData, itemId) => {
                            const item = nodeTooltipData.nodeData.treeParent;
                            const path = getPath(JSON.parse(JSON.stringify(fullTrees[item])), pathData, items[item], itemRecipes);
                            setItemsPaths({ ...itemsPaths, [item]: path });
                            setItemPathDatas({ ...itemPathDatas, [item]: pathData });
                        }}
                        currPathData={itemPathDatas[nodeTooltipData.nodeData.treeParent]}
                        viewRecipeCallback={viewRecipeCallback}
                    />
                    <Background id="1" gap={10} color="#404040" variant={BackgroundVariant.Dots} />
                </ReactFlow>
            </div>
        </div>
    );
};

export default PageTwo;
