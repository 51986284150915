import React, { useState, useRef, useEffect } from "react";

interface EditableParagraphProps {
    initialText: string;
    editType: EDIT_TYPES;
    onUpdate: (newValue: any) => void;
    className?: string;
}

enum EDIT_TYPES {
    TAG_NAME,
    INT,
    FLOAT,
    STRING,
    LONG,
    BYTE,
    SHORT,
    DOUBLE,
}

const editTypeRegex = [/^(\w|_)*$/, /^(^-)?\d+$/, /^-?\d+(\.\d+)?$/, /^.*$/, /^(^-)?\d+$/, /^(^-)?\d+$/, /^(^-)?\d+$/, /^(^-)?\d+(\.\d+)?$/];

const EditableParagraph: React.FC<EditableParagraphProps> = ({ initialText, editType, onUpdate, className }) => {
    const [text, setText] = useState(initialText);
    const [isEditing, setIsEditing] = useState(false);
    const [originalText, setOriginalText] = useState(initialText);
    const [badInput, setBadInput] = useState(false);
    const [badInputReason, setBadInputReason] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);

    const handleDoubleClick = (event: React.MouseEvent<HTMLParagraphElement>) => {
        event.preventDefault();
        setIsEditing(true);
        setOriginalText(text);
    };

    const handleBlur = () => {
        finishEditing();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            finishEditing();
        } else if (event.key === "Escape") {
            event.preventDefault();
            cancelEditing();
        }
    };

    const finishEditing = () => {
        if (!badInput) {
            setIsEditing(false);
            if (inputRef.current) {
                setText(inputRef.current.value);
                onUpdate(inputRef.current.value);
            }
        }
    };

    const cancelEditing = () => {
        setIsEditing(false);
        setText(originalText);
        setBadInput(false);
        setBadInputReason("");
    };

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0, inputRef.current.value.length);
        }
    }, [isEditing]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        if (!editTypeRegex[editType].test(newValue)) {
            setBadInput(true);
            setBadInputReason("The input does not match the accepted pattern.");
        } else if (editType === EDIT_TYPES.INT && (isNaN(Number(newValue)) || Number(newValue) > 2147483647 || Number(newValue) < -2147483648)) {
            setBadInput(true);
            setBadInputReason("The input is not a valid integer.");
        } else if (
            editType === EDIT_TYPES.LONG &&
            (isNaN(Number(newValue)) || Number(newValue) > 9223372036854775807 || Number(newValue) < -9223372036854775808)
        ) {
            setBadInput(true);
            setBadInputReason("The input is not a valid long.");
        } else if (editType === EDIT_TYPES.BYTE && (isNaN(Number(newValue)) || Number(newValue) > 127 || Number(newValue) < -128)) {
            setBadInput(true);
            setBadInputReason("The input is not a valid byte.");
        } else if (editType === EDIT_TYPES.SHORT && (isNaN(Number(newValue)) || Number(newValue) > 32767 || Number(newValue) < -32768)) {
            setBadInput(true);
            setBadInputReason("The input is not a valid short.");
        } else if (
            editType === EDIT_TYPES.FLOAT &&
            (isNaN(Number(newValue)) || Number(newValue) > 3.4028235e38 || Number(newValue) < -3.4028235e38)
        ) {
            setBadInput(true);
            setBadInputReason("The input is not a valid float.");
        } else if (
            editType === EDIT_TYPES.DOUBLE &&
            (isNaN(Number(newValue)) || Number(newValue) > 1.7976931348623157e308 || Number(newValue) < -1.7976931348623157e308)
        ) {
            setBadInput(true);
            setBadInputReason("The input is not a valid double.");
        } else if (editType === EDIT_TYPES.STRING && newValue.length > 32767) {
            setBadInput(true);
            setBadInputReason("The input is too long.");
        } else {
            setBadInput(false);
            setBadInputReason("");
        }
    };

    return isEditing ? (
        <div className={"relative " + className}>
            <input
                ref={inputRef}
                type="text"
                defaultValue={text}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                className={`bg-[#141414] p-2 rounded-md outline-none ${badInput ? "outline outline-red-500" : "outline outline-blue-500"}`}
            />
            {badInput && <div className="absolute left-0 bottom-full mb-1 bg-red-500 text-white p-1 rounded text-sm">{badInputReason}</div>}
        </div>
    ) : (
        <p onDoubleClick={handleDoubleClick} className="select-none h-fit bg-[#141414] p-2 rounded-md">
            {text}
        </p>
    );
};

export default EditableParagraph;
