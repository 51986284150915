import { node } from "slate";
import ItemIcon, { Item } from "./ItemIcon";
import { Handle, Position, NodeProps, useReactFlow, Node, getIncomers, getOutgoers } from "@xyflow/react";
import ActionIcon from "./ActionIcon";
import { useEffect, useRef } from "react";
import Tooltip from "./Tooltip";

const ACTION_TO_NAME: { [key: string]: string } = {
    craft: "Craft",
    smelt: "Smelt",
    stonecut: "Stonecut",
    get: "Get",
};

const getNodeText = (nodeData: { item: Item; amount: number; action: string | null; isStartOfBranch: boolean; isEndOfBranch: boolean }) => {
    let action = ACTION_TO_NAME[nodeData.action || "get"];
    if (nodeData.isEndOfBranch) {
        action = "Get";
    }

    return `${action} ${Math.ceil(nodeData.amount)} ${nodeData.item.name}`;
};

const getOffset = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top - 200,
    };
};

const RecipeItemNode = ({ data, id }: NodeProps) => {
    const reactFlow = useReactFlow();
    const nodeRef = useRef(null);
    const nodeData = data as {
        item: Item;
        amount: number;
        action: string | null;
        isStartOfBranch: boolean;
        isEndOfBranch: boolean;
        path: number[];
        incomers: string[];
        treeParent: string;
        hasOtherMaterials: boolean;
    };

    useEffect(() => {
        const currNode = reactFlow.getNode(id);
        if (!currNode) return;
        getOutgoers({ id: id }, reactFlow.getNodes(), reactFlow.getEdges()).forEach((node) => {
            const item_id = (node.data.item as any).id;
            if (!currNode?.data.incomers) {
                currNode.data.incomers = [];
            }
            (currNode.data.incomers as string[]).push(item_id);
        });
    }, []);

    if (!nodeData.item) return null;
    return (
        <div
            ref={nodeRef}
            className={
                "bg-[#101012] px-5 py-3 rounded-lg flex flex-row items-center justify-center relative w-[150px] h-[60px] cursor-pointer " +
                (nodeData.isStartOfBranch ? "bg-blue-600 " : "") +
                (nodeData.isEndOfBranch ? "bg-green-900 " : "")
            }
            style={{ fontFamily: "minecraftFont" }}
            onClick={(e) => {
                setTimeout(() => {
                    if (!nodeRef.current) return;
                    (data.onClick as (data: any, node: Node | undefined, position: { x: number; y: number }) => void)(data, reactFlow.getNode(id), {
                        x: e.clientX,
                        y: e.clientY - window.innerHeight * 0.1,
                    });
                }, 0); // for the click event on window
            }}
        >
            <Tooltip content={getNodeText(nodeData)}>
                <div className="flex flex-row justify-center items-center">
                    {!nodeData.isStartOfBranch && <Handle type="target" position={Position.Right} id={"b" + id} />}
                    {Math.ceil(nodeData.amount)} <p className="mx-1">&times;</p>
                    <ItemIcon item={nodeData.item} size={32} />
                    {!nodeData.isEndOfBranch && <Handle type="source" position={Position.Left} id={"a" + id} />}
                </div>
            </Tooltip>

            {!nodeData.isEndOfBranch && (
                <div className="absolute left-[-70px]">
                    <ActionIcon action={nodeData.action} />
                </div>
            )}

            {nodeData.hasOtherMaterials && <p className="text-orange-500 text-xl absolute top-0 right-1 font-serif">*</p>}
            <div className="absolute h-full left-0 top-0 flex flex-col justify-between">
                <div className="justify-center items-center flex bg-slate-600 px-1 rounded-tl-md h-1/2">c</div>
                <div className="justify-center items-center flex bg-slate-600 px-1 rounded-bl-md h-1/2">v</div>
            </div>
        </div>
    );
};

export default RecipeItemNode;
