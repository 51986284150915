import React from "react";
import CraftingTable from "../assets/crafting_table_icon.png";
import Furnace from "../assets/furnace_icon.png";
import StoneCutter from "../assets/stonecutter_icon.png";
import Tooltip from "./Tooltip";

const ACTION_TO_NAME: { [key: string]: string } = {
    craft: "Using a Crafting Table",
    smelt: "Using a Furnace",
    stonecut: "Using a Stone Cutter",
};

const ActionIcon = ({ action }: { action: string | null }) => {
    return (
        <Tooltip content={action ? ACTION_TO_NAME[action] : "N/A"}>
            <div>
                {action === "craft" && <img src={CraftingTable} alt="Crafting Table" className="h-6" />}
                {action === "smelt" && <img src={Furnace} alt="Furnace" className="h-6" />}
                {action === "stonecut" && <img src={StoneCutter} alt="Stone Cutter" className="h-6" />}
            </div>
        </Tooltip>
    );
};

export default ActionIcon;
