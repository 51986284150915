import React from "react";

interface Params {
    onClick: () => void;
    label?: string;
    cIcon?: React.JSX.Element;
    style: React.CSSProperties;
    selected?: boolean;
}

const StyleOption = ({ onClick, label, cIcon, style, selected = false }: Params) => {
    return (
        <div
            onClick={onClick}
            style={{ ...style, filter: selected ? "brightness(0.7)" : "brightness(1)" }}
            className="w-full text-center bg-gray-300 rounded-md text-gray-700 h-full flex justify-center items-center cursor-pointer"
            onMouseDown={(e) => e.preventDefault()}
        >
            {label ?? (cIcon && <>{cIcon}</>)}
        </div>
    );
};

export default StyleOption;
