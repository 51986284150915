interface Params {
    color: string;
    selected: boolean;
    onSelect: (color: string) => void;
}

const ColorOption = ({ color, selected, onSelect }: Params) => {
    return (
        <div
            className="w-8 h-full rounded-md sm:w-full cursor-pointer"
            style={{
                backgroundColor: color,
                borderStyle: "solid inset",
                borderWidth: selected ? 2 : 0,
                borderColor: color === "#000000" ? "white" : "black",
            }}
            onClick={() => {
                onSelect(color);
            }}
            onMouseDown={(e) => e.preventDefault()}
        ></div>
    );
};

export default ColorOption;
