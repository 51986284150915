import React from "react";

const Section = ({
    title,
    children,
    className,
    titleElement,
}: {
    title: string;
    children: React.ReactNode;
    className?: string;
    titleElement?: React.ReactNode;
}) => {
    return (
        <div className={"pb-3 bg-gray-600 rounded-md w-[80%] h-min mb-6"}>
            <p className="text-slate-200 text-center bg-gray-700 p-3 rounded-t-md font-bold mb-3">
                {title} {titleElement}
            </p>
            <div className={className + " px-3"}>{children}</div>
        </div>
    );
};

export default Section;
