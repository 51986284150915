import React from "react";
import RichMotdEditor from "./RichMotdEditor";
import Toolbar from "./Toolbar";
import Modal from "./Modal";
import { getTextWidth, styleCodes, motdToSlate, isValidMotd, LeafElement, BlockElement } from "../utils";
import pako from "pako";

interface Params {
    setData: React.Dispatch<React.SetStateAction<any>>;
    data: any;
    setMaxLineWidth: React.Dispatch<React.SetStateAction<number>>;
    maxLineWidth: number;
    setServerName: React.Dispatch<React.SetStateAction<string>>;
    setServerIcon: React.Dispatch<React.SetStateAction<string>>;
    setReloadChildren: React.Dispatch<React.SetStateAction<number>>;
    reloadChildren: number;
}

const MotdEditor = ({ setData, data, setMaxLineWidth, maxLineWidth, setServerName, setServerIcon, setReloadChildren, reloadChildren }: Params) => {
    const [color, setColor] = React.useState("#AAAAAA");
    const [selectedStyles, setSelectedStyles] = React.useState<string[]>([]);
    const [alignment, setAlignment] = React.useState("left");
    const [importModalVisible, setImportModalVisible] = React.useState(false);
    const [importError, setImportError] = React.useState("");
    const [currentStyle, setCurrentStyle] = React.useState<{ style: string; newState: boolean } | null>(null);
    const inputRef = React.useRef(null);

    const styleCallback = (style: string) => {
        let styles = [...selectedStyles];

        if (["right", "center", "left"].includes(style)) {
            setAlignment(style);
            return;
        }

        setCurrentStyle({
            style,
            newState: styles.includes(style) ? false : true,
        });

        if (styles.includes(style)) {
            styles = styles.filter((s) => s !== style);
        } else {
            styles = [...styles, style];
        }

        setSelectedStyles(styles);
    };

    const getLeaves = (data: any[]) => {
        return [data.length >= 1 ? data[0]?.children ?? [] : [], data.length === 2 ? data[1]?.children ?? [] : []];
    };

    const convertLeafToMinecraft = (leaf: LeafElement) => {
        console.log(leaf);
        let styleString = styleCodes.reset;
        for (const style in leaf) {
            if (!style || !leaf[style]) continue;

            if (styleCodes[style] || style === "color") {
                styleString += styleCodes[style === "color" ? leaf[style] ?? style : style] ?? "";
            }
        }

        styleString += leaf.text;

        return styleString;
    };

    const getFontSize = () => {
        if (window.innerWidth < 640) {
            return window.innerWidth * 0.02 + "px";
        } else if (window.innerWidth < 1250) {
            return window.innerWidth * 0.017 + "px";
        } else {
            return 16 * 1.45 + "px";
        }
    };

    const applyAlignmentLine = (line: LeafElement[], motdLine: string, alignment: string) => {
        if (alignment === "left") {
            return motdLine;
        } else {
            const fontSize = getFontSize();
            const lineWidth = line.reduce((acc, leaf) => {
                return acc + getTextWidth(leaf.text, "minecraftFont", fontSize, leaf.bold ? 800 : 400);
            }, 0);

            const spaceCharWidth = getTextWidth(" ", "minecraftFont", fontSize, 400);
            const spaceCount = Math.ceil((maxLineWidth - lineWidth) / spaceCharWidth);
            if (alignment === "center") {
                return " ".repeat(spaceCount / 2) + motdLine;
            } else if (alignment === "right") {
                return " ".repeat(spaceCount) + motdLine;
            }
        }
    };

    const applyAlignment = (motdLines: string[], data: BlockElement[]) => {
        const newMotdLines = [];
        const lineLeaves = getLeaves(data);

        for (let i = 0; i < motdLines.length; i++) {
            if (!motdLines[i]) continue;
            newMotdLines.push(applyAlignmentLine(lineLeaves[i], motdLines[i], data[i].alignment ?? "left"));
        }
        return newMotdLines;
    };

    const exportMotd = (data: BlockElement[]) => {
        const motdLines = [];
        const lineLeaves = getLeaves(data);
        for (const line of lineLeaves) {
            let motdString = "";
            for (const leaf of line) {
                motdString += convertLeafToMinecraft(leaf);
            }
            motdLines.push(motdString);
        }
        const newMotdLines = applyAlignment(motdLines, data);
        newMotdLines[0] = styleCodes.reset + newMotdLines[0];
        if (newMotdLines.length > 1) newMotdLines[1] = styleCodes.reset + newMotdLines[1];
        return newMotdLines;
    };

    const exportMotdToShareID = (data: BlockElement[]) => {
        let motdString = exportMotd(data).join("\n");
        motdString = optimizeMotdString(motdString);
        const compressed = pako.deflateRaw(motdString, { level: 9 });
        let encoded = btoa(String.fromCharCode.apply(null, Array.from(compressed))).replace(/=/g, "");
        encoded = encodeURIComponent(encoded);
        return encoded;
    };
    const getBlocks = (motdString: string) => {
        motdString = motdString.replace("\n", "\\n");
        const regex = /§[0-9a-fl-or]|§#......|\\n|./g;
        const elements = Array.from(motdString.matchAll(regex), (match) => match[0]);
        const blocks = [];
        let blockIndex = -1;
        for (const element of elements) {
            if (element === "§r") {
                blockIndex++;
                blocks[blockIndex] = [element];
                continue;
            }
            if (blocks[blockIndex]) {
                blocks[blockIndex].push(element);
            }
        }
        return blocks;
    };

    const hasColor = (styles: string[]) => {
        return styles.some((style) => style.match(/§[a-f0-9]/gi));
    };

    const getColor = (styles: string[]) => {
        return styles.find((style) => style.match(/§[a-f0-9]/gi));
    };

    const optimizeMotdString = (motdString: string) => {
        motdString = motdString.replace(/\\u00a7/gi, "§");

        // Remove redundant resets
        const blocks = getBlocks(motdString);
        let lastBlockStyles: string[] = [];
        const newBlocks = [];
        for (let block of blocks) {
            let currBlockStyles = block.filter((b) => b.startsWith("§"));
            const currBlockText = block.filter((b) => !b.startsWith("§"));
            const isTextVisible = currBlockText.some((text) => text.trim().length > 0);
            if (!isTextVisible) {
                currBlockStyles = [];
                block = currBlockText;
            }

            // Check if some last block styles are not present in current block styles, and make sure that colors are not reset between blocks
            const shouldResetBetweenBlocks = lastBlockStyles.some(
                (style) => !currBlockStyles.includes(style) && !(hasColor(currBlockStyles) && hasColor(lastBlockStyles))
            );
            if (!shouldResetBetweenBlocks && newBlocks.length > 0) {
                block.shift();
            }
            newBlocks.push(block);
            lastBlockStyles = currBlockStyles;
            lastBlockStyles.push("§r");
        }

        // Compress styles
        lastBlockStyles = [];
        let blockIndex = 0;
        for (let i = 0; i < newBlocks.length; i++) {
            let block = newBlocks[i];
            if (block.length === 0) continue;

            const currBlockStyles = block.filter((b) => b.startsWith("§"));
            let stylesToRemove = lastBlockStyles.filter((style) => currBlockStyles.includes(style));
            if (currBlockStyles.includes("§r")) {
                stylesToRemove = [];
            }

            let foundStarterColor = false;
            if (blockIndex === 0 && getColor(currBlockStyles) === "§7") {
                stylesToRemove.push("§7");
                foundStarterColor = true;
            }

            if (!hasColor(currBlockStyles) || foundStarterColor) {
                for (const style of stylesToRemove) {
                    block = block.splice(block.indexOf(style), 1);
                }
            }

            lastBlockStyles = currBlockStyles;
            blocks[i] = block;
            blockIndex++;
        }

        motdString = newBlocks.flat().join("");

        // Last cleanup
        if (motdString.trimStart().length !== motdString.length) {
            motdString = "§r" + motdString;
        }

        return motdString;
    };

    const actionCallback = (action: string) => {
        if (action === "copy") {
            const exportedMotd = exportMotd(data);
            const optimizedMotd = optimizeMotdString(exportedMotd.join("\n"));
            console.log("ASS", optimizedMotd.replaceAll("§", "\\u00a7").split(""));

            navigator.clipboard.writeText(optimizedMotd.replaceAll("§", "\\u00a7"));
        } else if (action === "import") {
            setImportModalVisible(true);
        } else if (action === "share") {
            const shareID = exportMotdToShareID(data);
            const href = window.location.href.replace(/\?id=.*/, "");
            navigator.clipboard.writeText(href + "?id=" + shareID);
        }
    };

    const validateInput = (input: string) => {
        // Is an ip or domain
        if (
            input.match(
                /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63})$/gi
            )
        ) {
            return "ip";
        }

        const { valid, reason } = isValidMotd(input, maxLineWidth);
        if (valid) {
            return "motd";
        } else {
            setImportError(reason);
        }
        return "invalid";
    };

    const getMotdFromIp = async (ip: string) => {
        try {
            const res = await fetch(`https://wisehosting.coreware.nl/api/motd/${ip}`);
            const data = await res.json();

            return data;
        } catch (e) {
            return null;
        }
    };

    return (
        <div className="p-2 bg-[#0d0d0d] rounded-md h-fit sm:max-w-[70%] w-[100%] flex flex-col items-center" style={{ height: "17rem" }}>
            <Modal
                isOpen={importModalVisible}
                onClose={() => {
                    setImportError("");
                    setImportModalVisible(false);
                }}
                title={"Import MOTD"}
            >
                <input
                    type="text"
                    ref={inputRef}
                    className="w-[95%] rounded-md border-none p-2 text-lg"
                    placeholder="Enter MOTD here / A server IP"
                    style={{ fontFamily: "Minecraft" }}
                />
                <p className="text-red-400 m-0">{importError}</p>

                <button
                    className="tracking-widest mt-5 bg-blue-500"
                    style={{ fontFamily: "Minecraft" }}
                    onClick={async () => {
                        const input = (inputRef.current as HTMLInputElement | null)?.value;

                        if (!input) {
                            return;
                        }

                        let ipData: any = {};
                        const res = validateInput(input);
                        if (res !== "invalid") setImportError("");
                        let motdText = "";
                        if (res === "ip") {
                            ipData = await getMotdFromIp(input);
                            if (!ipData) {
                                setImportError("Failed to fetch MOTD");
                                return;
                            }
                            motdText = ipData.motd;
                        } else {
                            motdText = input;
                        }

                        if (res !== "invalid") {
                            const leaves = motdToSlate(motdText);
                            setData(leaves);
                            setReloadChildren((p) => p + 1);
                            setImportModalVisible(false);

                            if (res === "ip") {
                                setServerName(input);
                                setServerIcon(ipData.favicon);
                            }
                        }
                    }}
                >
                    import
                </button>
                <button
                    className="tracking-widest mt-5 bg-red-500 ml-2"
                    style={{ fontFamily: "Minecraft" }}
                    onClick={() => setImportModalVisible(false)}
                >
                    cancel
                </button>
            </Modal>

            <Toolbar
                setColor={setColor}
                color={color}
                styleCallback={styleCallback}
                actionCallback={actionCallback}
                selectedStyles={[...selectedStyles, alignment]}
            />
            <RichMotdEditor
                setData={setData}
                currentColor={color}
                alignment={alignment}
                selectedStyles={selectedStyles}
                setColor={setColor}
                setStyles={setSelectedStyles}
                reloadChildren={reloadChildren}
                setMaxLineWidth={setMaxLineWidth}
                maxLineWidth={maxLineWidth}
                initialValue={data}
                setAlignment={setAlignment}
                currentStyle={currentStyle}
                setCurrentStyle={setCurrentStyle}
            />
        </div>
    );
};

export default MotdEditor;
