import React from "react";

const CoordinateInput = ({ label, onChange, value }: { label: string; onChange: (val: string) => any; value: number }) => {
    return (
        <div className={"pl-3 p-1 bg-slate-700 rounded-md flex flex-row items-center mb-3 sm:mr-3"}>
            <label className="text-slate-500 font-semibold mr-2">{label}</label>
            <input
                value={value}
                className="bg-slate-600 text-slate-200 p-2 px-4 rounded-md outline-none w-full"
                type="number"
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
};

export default CoordinateInput;
