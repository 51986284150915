import ToolButton from "./components/ToolButton";

const Picker = () => {
    return (
        <div>
            <div className="rounded-xl w-fit px-2 bg-slate-600 m-5">V.3.7</div>
            <div className="flex flex-row flex-wrap gap-5 p-10">
                <ToolButton title="MOTD Editor" href="/motd_editor" />
                <ToolButton title="Coordinate Calculator" href="/coord_calc" />
                <ToolButton title="Skin Grabber" href="/skin_grabber" />
                <ToolButton title="Tick Calculator" href="/tick_calc" />
                <ToolButton title="Circle Generator" href="/circle_gen" />
                <ToolButton title="Crafting Generator" href="/crafting_gen" />
                <ToolButton title="NBT Editor" href="/nbt_editor" />
                <ToolButton title="Resource Calculator" href="/resource_calc" />
                <ToolButton title="Map Generator" href="/map_generator" />
            </div>
        </div>
    );
};

export default Picker;
