import React, { useState } from "react";

export interface Item {
    name: string;
    id: string;
    offsetX: number;
    offsetY: number;
}

interface ItemIconProps {
    item: Item;
    size?: number;
    amount?: number;
    alwaysShowCount?: boolean;
}

const ItemIcon = ({ item, size = 64, amount = 1, alwaysShowCount = false }: ItemIconProps) => {
    const scale = size / 64;

    return (
        <div className={`relative flex flex-col justify-end`} style={{ minWidth: `${size}px`, minHeight: `${size}px` }}>
            <div
                className="absolute z-0"
                style={{
                    backgroundImage: `url('https://raw.githubusercontent.com/K9Developer/MinecraftData/main/items/atlas.png')`,
                    backgroundPosition: `-${item.offsetX * scale}px -${item.offsetY * scale}px`,
                    backgroundSize: `${2368 * scale}px ${2432 * scale}px`,
                    backgroundRepeat: "no-repeat",
                    imageRendering: "pixelated",
                    width: `${size}px`,
                    height: `${size}px`,
                }}
            />

            {((!alwaysShowCount && amount > 1) || alwaysShowCount) && (
                <div
                    className={`text-white text-[${scale}vw] rounded-lg font-bold z-10 text-right ml-8 mt-2 stroke-black stroke-2`}
                    style={{ fontFamily: "minecraftFont" }}
                >
                    {amount}
                </div>
            )}
        </div>
    );
};

export default ItemIcon;
