import React, { useEffect } from "react";
import Section from "./Section";
import CoordinateInput from "./CoordinateInput";

const block: { [key: string]: number } = { x: 0, y: 0, z: 0 };
const chunk: { [key: string]: number } = { x: 0, y: 0, z: 0 };
const region: { [key: string]: number } = { x: 0, z: 0 };
const TechnicalCalculator = () => {
    const [blockView, setBlockView] = React.useState<{ x: number; y: number; z: number }>({ x: 0, y: 0, z: 0 });
    const [chunkView, setChunkView] = React.useState<{ x: number; y: number; z: number }>({ x: 0, y: 0, z: 0 });
    const [regionView, setRegionView] = React.useState<{ x: number; z: number }>({ x: 0, z: 0 });

    const updateFromBlock = (changeType: string) => {
        if (changeType === "block") return;
        console.log(`Block Updated! block: (${block.x}, ${block.y}, ${block.z}) -> chunk: (${block.x >> 4}, ${block.y >> 4}, ${block.z >> 4})`);
        chunk.x = block.x >> 4;
        chunk.y = block.y >> 4;
        chunk.z = block.z >> 4;
        setChunkView({ x: chunk.x, y: chunk.y, z: chunk.z });
        updateFromChunk(changeType);
    };
    const updateFromChunk = (changeType: string) => {
        if (changeType === "chunk") return;
        console.log(`Chunk Updated! chunk: (${chunk.x}, ${chunk.y}, ${chunk.z}) -> region: (${chunk.x >> 5}, ${chunk.z >> 5})`);
        region.x = chunk.x >> 5;
        region.z = chunk.z >> 5;
        setRegionView({ x: region.x, z: region.z });
        updateFromRegion(changeType);
    };

    const updateFromRegion = (changeType: string) => {
        if (changeType === "region") return;
        console.log(`Region Updated! region: (${region.x}, ${region.z}) -> chunk: (${region.x << 5}, ${region.z << 5})`);
        block.x = region.x << 9;
        block.y = 0;
        block.z = region.z << 9;
        setBlockView({ x: block.x, y: block.y, z: block.z });
        updateFromBlock(changeType);
    };

    const blockChange = (val: string, type: string) => {
        block[type] = parseInt(val);
        updateFromBlock("region");
        setBlockView({ x: block.x, y: block.y, z: block.z });
    };

    useEffect(() => {
        console.log(block);
    }, [block]);

    const chunkChange = (val: string, type: string) => {
        chunk[type] = parseInt(val);
        updateFromChunk("block");
        setChunkView({ x: chunk.x, y: chunk.y, z: chunk.z });
    };

    const regionChange = (val: string, type: string) => {
        region[type] = parseInt(val);
        updateFromRegion("chunk");
        setRegionView({ x: region.x, z: region.z });
    };

    return (
        <div className="flex items-center justify-center pt-6 h-min flex-col">
            <Section title={"Block Coordinates"} className="flex sm:flex-row flex-col sm:px-[20%]">
                <CoordinateInput label="X" onChange={(v: string) => blockChange(v, "x")} value={blockView.x} />
                <CoordinateInput label="Y" onChange={(v: string) => blockChange(v, "y")} value={blockView.y} />
                <CoordinateInput label="Z" onChange={(v: string) => blockChange(v, "z")} value={blockView.z} />
            </Section>
            <Section title={"Chunk Section Coordinates"} className="flex sm:flex-row flex-col sm:px-[20%]">
                <CoordinateInput label="X" onChange={(v: string) => chunkChange(v, "x")} value={chunkView.x} />
                <CoordinateInput label="Y" onChange={(v: string) => chunkChange(v, "y")} value={chunkView.y} />
                <CoordinateInput label="Z" onChange={(v: string) => chunkChange(v, "z")} value={chunkView.z} />
            </Section>
            <Section
                title={"Region Coordinates"}
                className="flex flex-col sm:px-[20%]"
                titleElement={
                    <span className="text-slate-400 ml-3 rounded-lg font-semibold">
                        Region File: r.{regionView.x}.{regionView.z}.mca
                    </span>
                }
            >
                <div className="flex sm:flex-row flex-col w-full">
                    <CoordinateInput label="X" onChange={(v: string) => regionChange(v, "x")} value={regionView.x} />
                    <CoordinateInput label="Z" onChange={(v: string) => regionChange(v, "z")} value={regionView.z} />
                </div>
            </Section>
        </div>
    );
};

export default TechnicalCalculator;
