import React, { useEffect, useState } from "react";
import StoneCutterImage from "../assets/stonecutter.png";
import ItemIcon from "../components/ItemIcon";

interface Item {
    name: string;
    id: string;
    offsetX: number;
    offsetY: number;
}

interface Material {
    item: Item;
    amount: number;
}

interface Props {
    slotData: Material[];
}

const presetSlotPositions = [
    { x: 46, y: 77, width: 46, height: 46, index: 0 },
    { x: 125, y: 36, width: 46, height: 46, index: 1 },
    { x: 339, y: 67, width: 64, height: 64, index: 9 },
];

const StoneCutter = ({ slotData }: Props) => {
    const [slotPositions, setSlotPositions] = useState<{ x: number; y: number; width: number; height: number; index: number }[]>([]);
    const imgRef = React.createRef<HTMLImageElement>();

    const windowSizeChanged = () => {
        if (imgRef.current) {
            const width = imgRef.current.width;
            const factor = width / 432; // The width we calced with

            const newSlotPositions = presetSlotPositions.map((slot) => {
                return {
                    x: slot.x * factor,
                    y: slot.y * factor,
                    width: slot.width * factor,
                    height: slot.height * factor,
                    index: slot.index,
                };
            });

            setSlotPositions(newSlotPositions);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", windowSizeChanged);
        setTimeout(windowSizeChanged, 100);

        return () => {
            window.removeEventListener("resize", windowSizeChanged);
        };
    }, [windowSizeChanged]);

    return (
        <div className="h-full w-full flex relative">
            <img ref={imgRef} src={StoneCutterImage} alt="StoneCutter" className="object-contain" />
            {slotPositions.map((slot, index) => (
                <div
                    key={index}
                    className={`absolute flex justify-center items-center`}
                    style={{
                        left: slot.x,
                        top: slot.y,
                        width: slot.width,
                        height: slot.height,
                        backgroundColor: "transparent",
                    }}
                >
                    {slotData[slot.index] && (
                        <ItemIcon item={slotData[slot.index].item} size={slot.width * 0.8} amount={slotData[slot.index].amount} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default StoneCutter;
