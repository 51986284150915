import Tabs from "./components/Tabs";
import DimensionCalculator from "./components/DimensionCalculator";
import TechnicalCalculator from "./components/TechnicalCalculator";

const tabs = [
    {
        title: "Dimension Calculator",
        children: <DimensionCalculator />,
    },
    {
        title: "Technical Calculator",
        children: <TechnicalCalculator />,
    },
];

function App() {
    return (
        <div className="w-full">
            <Tabs tabsData={tabs} />
        </div>
    );
}

export default App;
