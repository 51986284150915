import React, { useState, useEffect, useRef } from "react";
import Iter1 from "./assets/iter1.png";
import Iter2 from "./assets/iter2.png";
import Center from "./assets/center.png";
import SideMid from "./assets/side_mid.png";
import Connect from "./assets/connect.png";
import VerticalSlider from "./components/VerticalSlider";

const generateCircleCoordinates = (radius: number) => {
    const coordinates = [];

    for (let x = -radius; x <= radius; x++) {
        for (let z = -radius; z <= radius; z++) {
            if (Math.round(Math.sqrt(x * x + z * z)) === radius) {
                coordinates.push([x, z]);
            }
        }
    }

    return coordinates;
};

const iter1 = new Image();
const iter2 = new Image();
const center = new Image();
const side_mid = new Image();
const connect = new Image();

iter1.src = Iter1;
iter2.src = Iter2;
center.src = Center;
side_mid.src = SideMid;
connect.src = Connect;

const getPrioritizedAdjacentBlock = (blocks: any, coordinate: any) => {
    const x = coordinate[0];
    const y = coordinate[1];

    if (blocks[`${x - 1},${y}`]) return blocks[`${x - 1},${y}`];
    if (blocks[`${x + 1},${y}`]) return blocks[`${x + 1},${y}`];
    if (blocks[`${x},${y - 1}`]) return blocks[`${x},${y - 1}`];
    if (blocks[`${x},${y + 1}`]) return blocks[`${x},${y + 1}`];

    if (blocks[`${x - 1},${y - 1}`]) return blocks[`${x - 1},${y - 1}`];
    if (blocks[`${x + 1},${y - 1}`]) return blocks[`${x + 1},${y - 1}`];
    if (blocks[`${x - 1},${y + 1}`]) return blocks[`${x - 1},${y + 1}`];
    if (blocks[`${x + 1},${y + 1}`]) return blocks[`${x + 1},${y + 1}`];

    return null;
};

function App() {
    const [radius, setRadius] = useState(1);
    const [zoom, setZoom] = useState(1);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasSize = window.innerWidth > 1000 ? window.innerWidth * 0.3 : window.innerWidth * 0.58; // Fixed canvas size
    const blockSize = 16; // Assuming our images are 32x32 pixels
    const [panX, setPanX] = useState(0);
    const [panY, setPanY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [blockCount, setBlockCount] = useState(0);

    const resetPan = () => {
        setPanX(0);
        setPanY(0);
        setZoom(1);
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
        console.log("start drag");
        if (zoom > 1) {
            setIsDragging(true);
            setDragStart({ x: event.clientX - panX, y: event.clientY - panY });
        }
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (isDragging) {
            const newPanX = event.clientX - dragStart.x;
            const newPanY = event.clientY - dragStart.y;
            setPanX(newPanX);
            setPanY(newPanY);
            console.log("dragging - ", newPanX, newPanY);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        console.log("end drag");
    };

    const handleTouchDown = (event: React.TouchEvent<HTMLCanvasElement>) => {
        if (zoom > 1) {
            setIsDragging(true);
            setDragStart({ x: event.touches[0].clientX - panX, y: event.touches[0].clientY - panY });
        }
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
        if (isDragging) {
            const newPanX = event.touches[0].clientX - dragStart.x;
            const newPanY = event.touches[0].clientY - dragStart.y;
            setPanX(newPanX);
            setPanY(newPanY);
        }
    };

    const handleTouchUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (zoom <= 1) {
            resetPan();
        }

        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const coordinates = generateCircleCoordinates(radius);
        setBlockCount(coordinates.length);
        const scale = Math.min(4, (canvasSize - blockSize) / (radius * 2 * blockSize)) * zoom * 0.9;

        canvas.width = canvasSize;
        canvas.height = canvasSize;

        // Clear canvas with transparent background
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "#1a1919";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const totalSize = (2 * radius + 1) * blockSize * scale;

        // Calculate the maximum allowed pan
        const maxPanX = Math.max(0, (totalSize - canvasSize) / 2);
        const maxPanY = Math.max(0, (totalSize - canvasSize) / 2);

        // Clamp the pan values
        const clampedPanX = -Math.max(-maxPanX, Math.min(maxPanX, panX));
        const clampedPanY = -Math.max(-maxPanY, Math.min(maxPanY, panY));

        // Calculate the offset
        let offsetX = (canvasSize - totalSize) / 2 - clampedPanX;
        let offsetY = (canvasSize - totalSize) / 2 - clampedPanY;

        // Limit panning to keep the circle within view
        const maxX = Math.max(...coordinates.map((c) => c[0]));
        const maxY = Math.max(...coordinates.map((c) => c[1]));

        const blockMap: { [key: string]: string } = {};
        for (let i = 0; i < coordinates.length; i++) {
            const adjacentBlock = getPrioritizedAdjacentBlock(blockMap, coordinates[i]);
            const blockImage = adjacentBlock === "iter1" ? "iter2" : "iter1";

            blockMap[`${coordinates[i][0]},${coordinates[i][1]}`] = blockImage;
            // Draw coordinate as iter1 or iter2
            ctx.drawImage(
                blockImage === "iter1" ? iter1 : iter2,
                offsetX + (coordinates[i][0] + radius) * blockSize * scale,
                offsetY + (coordinates[i][1] + radius) * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );
        }

        // Draw center
        ctx.drawImage(center, offsetX + radius * blockSize * scale, offsetY + radius * blockSize * scale, blockSize * scale, blockSize * scale);

        // Draw side centers
        ctx.drawImage(
            side_mid,
            offsetX + (radius + maxX) * blockSize * scale,
            offsetY + radius * blockSize * scale,
            blockSize * scale,
            blockSize * scale
        );
        ctx.drawImage(
            side_mid,
            offsetX + radius * blockSize * scale,
            offsetY + (radius + maxY) * blockSize * scale,
            blockSize * scale,
            blockSize * scale
        );
        ctx.drawImage(
            side_mid,
            offsetX + (radius - maxX) * blockSize * scale,
            offsetY + radius * blockSize * scale,
            blockSize * scale,
            blockSize * scale
        );
        ctx.drawImage(
            side_mid,
            offsetX + radius * blockSize * scale,
            offsetY + (radius - maxY) * blockSize * scale,
            blockSize * scale,
            blockSize * scale
        );

        // Draw connects - X+
        for (let i = 1; i < maxX; i++)
            ctx.drawImage(
                connect,
                offsetX + (radius + i) * blockSize * scale,
                offsetY + radius * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );

        // Draw connects - X-
        for (let i = 1; i < maxX; i++)
            ctx.drawImage(
                connect,
                offsetX + (radius - i) * blockSize * scale,
                offsetY + radius * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );

        // Draw connects - Y+
        for (let i = 1; i < maxY; i++)
            ctx.drawImage(
                connect,
                offsetX + radius * blockSize * scale,
                offsetY + (radius + i) * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );

        // Draw connects - Y-
        for (let i = 1; i < maxY; i++)
            ctx.drawImage(
                connect,
                offsetX + radius * blockSize * scale,
                offsetY + (radius - i) * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );
    }, [radius, zoom, panX, panY]);

    return (
        <div className="flex justify-center">
            <div className="flex flex-col w-fit gap-3">
                <div className="flex flex-row gap-3 justify-center pt-5">
                    <canvas
                        ref={canvasRef}
                        width={canvasSize}
                        height={canvasSize}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onTouchStart={handleTouchDown}
                        onTouchEnd={handleTouchUp}
                        onTouchMove={handleTouchMove}
                        onMouseLeave={handleMouseLeave}
                        style={{ cursor: zoom > 1 ? "move" : "default", borderRadius: "10px" }}
                    ></canvas>
                    <VerticalSlider
                        _value={zoom}
                        label="ZOOM"
                        onChange={(v: number) => {
                            setZoom(v);
                        }}
                        values={Array.from({ length: 5 }, (_, i) => i + 1)}
                    />
                    <VerticalSlider
                        _value={radius}
                        label="RADIUS"
                        onChange={(v: number) => {
                            setRadius(v);
                            resetPan();
                        }}
                        values={Array.from({ length: 50 }, (_, i) => i + 1)}
                    />
                </div>
                <div className="w-full p-3 rounded-sm bg-[#1a1919] text-center">
                    This circle will take <span className="font-bold">{blockCount}</span> blocks to build.
                </div>
            </div>
        </div>
    );
}

export default App;
